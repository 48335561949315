import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  followUpHeading: {
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px'
    }
  },
  padding: {
    paddingLeft: '36px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5px'
    }
  },
  questionText: {
    fontSize: '16px',
    paddingTop: '18px',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      paddingTop: '12px'
    }
  },
  hoverable: {
    cursor: 'pointer',
    background: 'gray',
    color: '#1D1D1D',
    padding: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    backgroundColor: '#F0F0F0',
    borderRadius: '8px',
    border: '1px solid #E3EBFD',
    transition: 'background-color 0.3s', // Optional: Adding a smooth transition on hover
    '&:hover': {
      background: 'linear-gradient(to right, #004CFF, #06BCC1)',
      color: '#FFFFFF'
    }
  },
  hoverableLoading: {
    cursor: 'default',
    background: 'gray',
    color: '#1D1D1D',
    padding: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    backgroundColor: '#F0F0F0',
    borderRadius: '8px',
    border: '1px solid #E3EBFD',
    transition: 'background-color 0.3s', // Optional: Adding a smooth transition on hover
    '&:hover': {
      background: '#8E8E8E',
      color: '#000000'
    }
  }
}));

function FollowUpComponent({ messageData, handleCardQuestionClick }) {
  const classes = useStyles();
  const loading = useSelector((state) => state.tokenReducer.loading);
  const loadingAnswerStream = useSelector((state) => state.tokenReducer.loadingAnswerStream);
  return (
    <>
      <div className={classes.padding} id="followUpMain">
        {messageData.follow_up_q && messageData.follow_up_q.length > 0 && (
          <Typography
            id="followUpMainTypo"
            style={{ color: '#1D1D1D', fontWeight: 600 }}
            className={classes.followUpHeading}
          >
            Follow Up Questions
          </Typography>
        )}

        <div style={{ display: 'flex', flexDirection: 'column' }} id="followUpChild">
          {messageData.follow_up_q &&
            messageData.follow_up_q.map((question, index) => (
              <Typography
                id="followUpChildTypo"
                style={{
                  color: '#1D1D1D',
                  fontWeight: 500,
                  display: 'flex',
                  marginRight: '7px',
                  paddingTop: '8px'
                }}
                key={index}
                className={classes.questionText}
              >
                <div
                  id="followUpChildDiv"
                  className={`${classes.hoverable} ${
                    loading || loadingAnswerStream ? classes.hoverableLoading : ''
                  }`}
                  onClick={
                    !loading && !loadingAnswerStream
                      ? (event) => {
                          handleCardQuestionClick(event, question);
                        }
                      : undefined
                  }
                >
                  {question}
                </div>
              </Typography>
            ))}
        </div>
      </div>
      <br />
    </>
  );
}

FollowUpComponent.propTypes = {
  messageData: PropTypes.any
};
export default FollowUpComponent;
