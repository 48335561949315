import { getApi } from '../../../../../services/token';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AutocompleteComponent from '../../../../atoms/multiSearchableAutocomplete';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { createKeyForSearch } from '../../../../screener/helper';
import { useSelector, useDispatch } from 'react-redux';
import { setCompanSearchData } from '../../../../../redux/pages/common/company/action';
import { makeStyles } from '@material-ui/core';

const isDefaultOption = (option) => {
  return (
    option.entity_type === 'No options' &&
    option.entity_name === 'No options' &&
    option.entity_id === 'custom_entity_id' &&
    option.logo === ''
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      height: '48px',
      width: '340px',
      [theme.breakpoints.down('md')]: {
        width: '280px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  },
  '@media print': {
    root: {
      visibility: 'hidden'
    }
  }
}));

const Company = ({
  getReports,
  updateParamsInURL,
  navigate,
  location,
  COMPANY_API_URL,
  queryParamName,
  selectedItem,
  setSelectedItem,
  //   activeEntityType,
  //   query_definition_type,
  //   setOpenThemeDialog,
  //   getSimilarEntities,
  //   onEditTheme,
  //   onDeleteTheme,
  //   closeCompThemeSearch,
  //   setCloseCompThemeSearch,
  //loading,
  setLoading
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSingleSelect = true;
  //   const [isModified, setIsModified] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectValueFromQuery, setSelectValueFromQuery] = useState(false);
  const debouncedInputValue = useDebounce(inputValue, 300);
  const selectedItems = selectedItem;
  const currentLocation = useLocation();
  const initialLoadRef = useRef(true);

  const storeOptions = useSelector((state) => state.companyReducer.data);

  useEffect(() => {
    return () => {
      setSelectedItem(null);
    };
  }, []);

  // Reusable API function for fetching entities
  const fetchData = async (searchText, pageNumber = 1) => {
    const searchParams = new URLSearchParams(currentLocation.search);
    const currentEntityType = searchParams.get('entity_type') || 'company';

    if (currentEntityType == 'company') {
      try {
        setLoading(true);
        const queryParams = new URLSearchParams({
          entity_types: 'company',
          page_size: 500,
          page_number: pageNumber,
          search_text: searchText || ''
        });

        const currentKey = createKeyForSearch(queryParams);

        let newOptions = [];
        let totalRecords = 0;

        const storeOptionsData = storeOptions[currentKey];

        if (storeOptionsData) {
          newOptions = storeOptionsData.newOptions || [];
          totalRecords = storeOptionsData.total_records || 0;
        } else {
          const response = await getApi(
            `${COMPANY_API_URL}/v1/entity/search?${queryParams.toString()}`
          );

          newOptions = response.data?.data?.items || [];
          totalRecords = response.data?.data?.metadata?.total_records || 0;
          dispatch(
            setCompanSearchData({
              key: currentKey,
              data: {
                newOptions,
                total_records: totalRecords
              }
            })
          );
        }

        setTimeout(() => {
          if (pageNumber === 1) {
            setData(newOptions);
          } else {
            setData((prev) => [...prev, ...newOptions]);
          }
          const newData = pageNumber === 1 ? newOptions : [...data, ...newOptions];
          setHasMore(newData.length < totalRecords);
          setLoading(false);
        }, 0);
      } catch (error) {
        setLoading(false);
        console.error('Fetching data failed', error);
      }
    }
  };

  const onAutoSelectUrlQuerySearch = async () => {
    if (initialLoadRef.current) {
      const searchParams = new URLSearchParams(currentLocation.search);
      const selectedEntityName = searchParams.get(queryParamName);
      if (selectedEntityName) {
        await fetchData(selectedEntityName);
        setInputValue(selectedEntityName);
        setSelectValueFromQuery(true);
      }
      initialLoadRef.current = false;
    }
  };

  useEffect(() => {
    if (selectValueFromQuery) {
      if (page === 1 && data.length > 0) {
        setSelectedItem(data[0]);
        getReports(data[0]);
      }
      setSelectValueFromQuery(false);
    }
  }, [selectValueFromQuery]);

  // Load preselected entity from URL when page loads
  useEffect(() => {
    onAutoSelectUrlQuerySearch();
  }, [currentLocation.search]);

  const onInputChange = (event, newValue) => {
    if (!newValue) {
      updateParamsInURL({
        navigate,
        location,
        removeParams: [queryParamName, 'report_id']
      });
    }
  };

  // Fetch data when user types in the input field
  useEffect(() => {
    const searchParams = new URLSearchParams(currentLocation.search);
    const selectedEntityName = searchParams.get(queryParamName);
    if (!initialLoadRef.current || !selectedEntityName) {
      fetchData(debouncedInputValue, page);
    }
  }, [debouncedInputValue, page, currentLocation.search]);

  useEffect(() => {
    const searchParams = new URLSearchParams(currentLocation.search);
    const selectedEntityName = searchParams.get(queryParamName);
    if (inputValue !== '' && !selectedEntityName) {
      setPage(1);
      setData([]);
    }
  }, [debouncedInputValue, currentLocation.search]);

  const onSelectedItems = async (selectedOption) => {
    // setIsModified(false);

    if (selectedOption && isDefaultOption(selectedOption)) {
      return;
    }

    setSelectedItem(selectedOption);

    if (
      selectedOption?.entity_id &&
      (!selectedItem || selectedItem.entity_id !== selectedOption.entity_id)
    ) {
      updateParamsInURL({
        navigate,
        location,
        addParams: { [queryParamName]: selectedOption.entity_name },
        removeParams: ['report_id']
      });

      await getReports(selectedOption);
    } else if (selectedOption?.entity_name) {
      updateParamsInURL({
        navigate,
        location,
        addParams: { [queryParamName]: selectedOption.entity_name },
        removeParams: ['report_id']
      });
    }
  };
  return (
    <>
      {/* {JSON.stringify(data[0], undefined, 1)} */}
      <div className={classes.root}>
        <AutocompleteComponent
          data={data}
          hasMore={hasMore}
          setInputValue={setInputValue}
          onInputChange={onInputChange}
          page={page}
          setPage={setPage}
          selectedItems={selectedItems}
          setSelectedItems={onSelectedItems}
          property={{ key: 'entity_id', value: 'entity_name', type: 'entity_type' }}
          isSingleSelect={isSingleSelect}
          label="Search"
          placeholder="Search"
          disabled={false}
          icon="34px"
          //isApiCallInProgress={loading}
        />
      </div>
    </>
  );
};

export default Company;
