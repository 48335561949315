import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  timeText: {
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  padding: {
    paddingRight: '20px',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '5px'
    }
  },
  questionText: {
    fontSize: '16px',
    paddingTop: '8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      paddingTop: '2px'
    }
  }
}));

function UserComponent({ messageData }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.padding} id="askBotChildMessageEndRef">
        <Typography
          style={{ color: '#888888', fontWeight: 500 }}
          id="askBotTypoMessageEndRef"
          className={classes.timeText}
        >
          {messageData.question_time}
        </Typography>
        <Typography
          id="askBotTypo2MessageEndRef"
          style={{
            color: '#888888',
            fontWeight: 500,
            lineHeight: '24.3px'
          }}
          className={classes.questionText}
        >
          {messageData.question}
        </Typography>
      </div>
      <br />
    </>
  );
}

UserComponent.propTypes = {
  messageData: PropTypes.any
};

export default UserComponent;
