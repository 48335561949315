import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setScreenerData } from '../../../../redux/pages/screener/action';
import Typography from '@material-ui/core/Typography';
import { Button } from '@mui/material';
import useStyles from '../../styles';
import { getScreenrTableData } from '../../services';
import { createFilterPayload, getSelectedTabData } from '../../helper';
import { setGlobalInfoMessage } from '../../../shared/action';
import { dispatchHideShowMessage } from '../../../../helpers';
import { setGlobalErrorMessage } from '../../../shared/action';
import { setToggleScreening } from '../../../../redux/pages/screener/action';
// Exported function
export const postQueryFilterData = async (
  dispatch,
  screenerData,
  selectedTabData,
  selectedTabDataIndex,
  uuid,
  page_number = 1,
  pageSize = null,
  question_id = ''
) => {
  let body = {
    entity_type: selectedTabData.entityType,
    query_type: selectedTabData.filterType,
    session_id: uuid,
    question_id
  };

  if (selectedTabData.filterType === 'filter') {
    const filter_details = createFilterPayload(
      selectedTabData?.intialFilterConfig?.selectedFilters
    );
    body.query_details = {
      query_text: '',
      filters: filter_details.filter
    };
  } else {
    body.query_details = {
      query_text: selectedTabData.queryText
    };
  }

  const currentPageSize = pageSize ? pageSize : selectedTabData.pageSize;

  let params = `?page_number=${page_number}&page_size=${currentPageSize}&session_id=${uuid}`;

  try {
    const tableData = await getScreenrTableData(
      dispatch,
      screenerData,
      selectedTabData,
      selectedTabDataIndex
    )(body, params);

    let updatedTabData = { ...selectedTabData };
    updatedTabData = {
      ...updatedTabData,
      tableData: tableData.data.data,
      question_id: tableData?.data?.data?.metadata?.question_id,
      isLoading: false,
      tableDataError: false,
      tableDataErrorMessage: ''
    };
    const updatedScreenerData = screenerData.map((item, i) =>
      i === selectedTabDataIndex ? updatedTabData : item
    );

    if (!(tableData.data.data.items && tableData.data.data.items.length > 0)) {
      dispatchHideShowMessage(
        dispatch,
        `No results found. Please try again with different ${
          selectedTabData?.filterType === 'filter' ? 'filters' : 'query'
        }.`,
        setGlobalInfoMessage,
        30000
      );
    }
    dispatch(setScreenerData(updatedScreenerData));
    dispatch(setToggleScreening(false));
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const ScreenerActionBtns = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { screenerData, selectedTabData, selectedTabDataIndex } = getSelectedTabData(useSelector);
  const uuid = useSelector((state) => state.tokenReducer.uuID);

  const handleSaveQueryBtn = () => {
    console.log('save');
  };

  const handleResetBtn = useCallback(() => {
    console.log('reset called');
    let updatedTabData = { ...selectedTabData };

    if (selectedTabData.filterType === 'filter') {
      updatedTabData = {
        ...updatedTabData,
        queryText: '',
        tableData: [],
        pageNumber: 1,
        question_id: '',
        intialFilterConfig: {
          ...updatedTabData.intialFilterConfig,
          selectedFilters: {}
        }
      };
    } else if (selectedTabData.filterType === 'custom') {
      updatedTabData = {
        ...updatedTabData,
        queryText: '',
        tableData: [],
        pageNumber: 1,
        question_id: ''
      };
    }

    const updatedScreenerData = screenerData.map((item, i) =>
      i === selectedTabDataIndex ? updatedTabData : item
    );

    dispatch(setScreenerData(updatedScreenerData));
  }, [dispatch, screenerData]);

  const validateFilters = useCallback(() => {
    const errors = {};
    const selectedFilters = selectedTabData?.intialFilterConfig?.selectedFilters || {};

    Object.keys(selectedFilters).forEach((filterKey) => {
      const filter = selectedFilters[filterKey];

      if (filter.type === 'multi-select') {
        if (filter.selected.length === 0) {
          errors[filterKey] = `${filter.display_name} filter should have at least one option.`;
        }
      }

      if (filter.type === 'range') {
        const { min_value, max_value } = filter;
        if (min_value == null && max_value == null) {
          errors[filterKey] = `${filter.display_name} filter min or max value cannot be empty`;
          return;
        }
        if (min_value > max_value) {
          errors[
            filterKey
          ] = `${filter.display_name} filter min value cannot be greater than max value.`;
          return;
        }
      }

      if (filter.type === 'dropdown') {
        console.log('filter ' + JSON.stringify(filter));
        if (filter.selected.length === 0) {
          errors[filterKey] = `${filter.display_name} filter should be selected`;
        } else if (filter.selected.length === 0 || filter.selected.length === 1) {
          errors[filterKey] = `${filter.display_name} filter should be selected`;
        }
      }
    });

    return errors;
  }, [dispatch, screenerData]);

  const handleRunScreenerBtn = useCallback(async () => {
    const filterValidationError = validateFilters();

    if (filterValidationError && Object.keys(filterValidationError).length > 0) {
      const firstErrorKey = Object.keys(filterValidationError)[0];
      dispatchHideShowMessage(
        dispatch,
        filterValidationError[firstErrorKey],
        setGlobalErrorMessage,
        3000
      );
      return;
    }

    await postQueryFilterData(
      dispatch,
      screenerData,
      selectedTabData,
      selectedTabDataIndex,
      uuid,
      1
    );
  }, [dispatch, screenerData, selectedTabData, uuid]);

  return (
    <div>
      <div className={classes.buttonBox} style={{ padding: '20px' }}>
        <Button
          disabled={selectedTabData?.isLoading}
          style={{ display: 'none' }}
          className={classes.saveQuery}
          onClick={handleSaveQueryBtn}
          disableRipple
        >
          <Typography className={classes.saveQueryTxt}>Save Query</Typography>
        </Button>
        <Button
          disabled={selectedTabData?.isLoading}
          style={{ opacity: selectedTabData?.isLoading ? 0.4 : 1 }}
          className={classes.resetQuery}
          onClick={handleResetBtn}
          disableRipple
        >
          <Typography className={classes.resetQueryTxt}>Reset</Typography>
        </Button>
        <Button
          disabled={selectedTabData?.isLoading}
          style={{ opacity: selectedTabData?.isLoading ? 0.4 : 1 }}
          className={classes.runQuery}
          onClick={handleRunScreenerBtn}
          disableRipple
        >
          <Typography className={classes.runQueryTxt}>Run Screener</Typography>
        </Button>
      </div>
    </div>
  );
};

export default ScreenerActionBtns;
