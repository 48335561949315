import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setScreenerData } from '../../../../redux/pages/screener/action';
// import QueryActionBtns from './queryAction';
import FilterActionBtns from './buttonAction';
import { TextField } from '@material-ui/core';
import useStyles from '../../styles';
import { getSelectedTabData } from '../../helper';
import { postQueryFilterData } from './buttonAction';

const TextQuery = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const uuid = useSelector((state) => state.tokenReducer.uuID);
  const { screenerData, selectedTabData, selectedTabDataIndex } = getSelectedTabData(useSelector);

  const handleQuestionSubmit = async () => {
    try {
      await postQueryFilterData(
        dispatch,
        screenerData,
        selectedTabData,
        selectedTabDataIndex,
        uuid,
        1
      );
    } catch (error) {
      console.error('Error during query submission:', error);
    }
  };

  const handleInputChange = useCallback(
    (e) => {
      // const updatedScreenerData = screenerData.map((item) =>
      //   item.active ? { ...item, queryText: e.target.value } : item
      // );
      let updatedTabData = { ...selectedTabData };
      updatedTabData = {
        ...updatedTabData,
        queryText: e.target.value
      };
      const updatedScreenerData = screenerData.map((item, i) =>
        i === selectedTabDataIndex ? updatedTabData : item
      );
      dispatch(setScreenerData(updatedScreenerData));
    },
    [dispatch, screenerData]
  );

  return (
    <>
      <div style={{ paddingTop: '16px' }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className={classes.inputContainer}
        >
          <TextField
            fullWidth
            variant="outlined"
            disabled={selectedTabData?.isLoading}
            placeholder="Write your own query"
            className={classes.root}
            value={selectedTabData.queryText}
            onInput={handleInputChange}
            multiline
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleQuestionSubmit();
              }
            }}
          />
        </form>
      </div>
      <FilterActionBtns />
    </>
  );
};

export default TextQuery;
