import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { AuthContext, AuthStatus } from '../../auth/contexts/authContext';
import CustomRoutes from '../../../routes/route';
import { Grid } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as MessageIcon } from '../../icons/sideBar/Message.svg';
import { ReactComponent as DashBoardNewIcon } from '../../icons/sideBar/dashboard-new.svg';
import { ReactComponent as ReportIcon } from '../../icons/sideBar/Report.svg';
import { ReactComponent as ThemeIcon } from '../../icons/sideBar/theme-report.svg';
import { ReactComponent as DocSearchIcon } from '../../icons/sideBar/docs.svg';
import { ReactComponent as ExploreIcon } from '../../icons/sideBar/explore_company.svg';
import { ReactComponent as GraphIcon } from '../../icons/sideBar/tracker.svg';
import { ReactComponent as NewsTracker } from '../../icons/sideBar/news.svg';
import { ReactComponent as MainLibIcon } from '../../icons/sideBar/main_Lib.svg';
import { ReactComponent as LibIcon } from '../../icons/sideBar/lib.svg';
import { ReactComponent as ScreenerIcon } from '../../icons/sideBar/screener.svg';
import { ReactComponent as PortfolioTracker } from '../../icons/sideBar/portfolio-tracker.svg';

import { ReactComponent as CompReportIcon } from '../../icons/sideBar/comp-report.svg';
import { ReactComponent as DocExpIcon } from '../../icons/sideBar/docs-exp.svg';
import { ReactComponent as DocComIcon } from '../../icons/sideBar/docs-com.svg';

import { ReactComponent as DocLibIcon } from '../../icons/sideBar/doc-Lib.svg';

import { ReactComponent as MessageIconGradient } from '../../icons/sideBar/Gradient/Message.svg';
import { ReactComponent as DashBoardNewIconGradient } from '../../icons/sideBar/Gradient/dashboard-new.svg';
import { ReactComponent as PortfolioTrackerGradient } from '../../icons/sideBar/Gradient/portfolio-tracker.svg';

import { ReactComponent as ReportIconGradient } from '../../icons/sideBar/Gradient/Report.svg';
import { ReactComponent as ThemeIconGradient } from '../../icons/sideBar/Gradient/theme-report.svg';
import { ReactComponent as DocSearchIconGradient } from '../../icons/sideBar/Gradient/docs.svg';
import { ReactComponent as ExploreIconGradient } from '../../icons/sideBar/Gradient/explore_company.svg';
import { ReactComponent as GraphIconGradient } from '../../icons/sideBar/Gradient/tracker.svg';
import { ReactComponent as NewsTrackerGradient } from '../../icons/sideBar/Gradient/news.svg';
import { ReactComponent as MainLibIconGradient } from '../../icons/sideBar/Gradient/main_Lib.svg';
import { ReactComponent as LibIconGradient } from '../../icons/sideBar/Gradient/lib.svg';

import { ReactComponent as ScreenerIconGradient } from '../../icons/sideBar/Gradient/screener.svg';

import { ReactComponent as CompReportIconGradient } from '../../icons/sideBar/Gradient/comp-report.svg';
import { ReactComponent as DocExpIconGradient } from '../../icons/sideBar/Gradient/docs-exp.svg';
import { ReactComponent as DocComIconGradient } from '../../icons/sideBar/Gradient/docs-com.svg';

import { ReactComponent as DocLibIconGradient } from '../../icons/sideBar/Gradient/doc-Lib.svg';

import { useDispatch } from 'react-redux';
import { setTearsheetUrl } from '../action';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import RequestCompanyPopover from '../exploreCompanies/requestCompanyPopover';
import SuccessToast from '../../atoms/successToast';
import ErrorToast from '../../atoms/errorToast';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useMediaQuery } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const useStyles = makeStyles(() => ({
  // Parent menu item container
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    background: '#F7F9FD',
    borderRight: '1px solid #F0F0F0',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      background: '#EAF1FE',
      '@media (max-width: 960px)': {
        '& $childItem': {
          background: '#EAF1FE'
        }
      }
    },
    '&.active': {
      background:
        'var(--Gradients-Light-Gradients-blue-gradient-40, linear-gradient(270deg, rgba(205, 241, 243, 0.40) 0%, rgba(205, 219, 255, 0.40) 100%))',
      '& $boxText': {
        background: 'linear-gradient(to right, #004CFF, #06BCC1)',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        color: 'transparent'
      }
    },
    '@media (max-width: 960px)': {
      padding: '8px 16px',
      justifyContent: 'flex-start',
      borderRight: 'none',
      lineHeight: '1.5'
      // borderBottom: '1px solid #F0F0F0'
    }
  },
  '@media print': {
    boxContainer: {
      visibility: 'hidden'
    },
    navBarGridStyle: {
      visibility: 'hidden'
    },
    requestCompanyDivStyle: {
      visibility: 'hidden'
    }
  },
  boxImage: {
    width: '24px',
    height: '24px',
    '@media (max-width: 960px)': {
      width: '20px',
      height: '20px'
    }
  },
  chboxImage: {
    width: '20px',
    height: '20px'
  },
  boxText: {
    margin: '0',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#616161',
    '@media (max-width: 960px)': {
      fontSize: '14px'
    }
  },
  // Container for child menus (shown on hover)
  childContainer: {
    position: 'absolute',
    top: 0,
    left: '100%',
    background: '#F7F9FD',
    border: '1px solid #F0F0F0',
    zIndex: 10,
    minWidth: '110px',
    '&:hover': {
      background: '#EAF1FE'
    },
    '@media (max-width: 960px)': {
      position: 'static',
      border: 'none',
      background: '#EAF1FE'
      // paddingLeft: '16px'
    }
  },
  // Style for each child menu item
  childItem: {
    padding: '10px',
    cursor: 'pointer',
    background: '#F7F9FD',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: '#EAF1FE'
    },
    '&.active': {
      background:
        'var(--Gradients-Light-Gradients-blue-gradient-40, linear-gradient(270deg, rgba(205, 241, 243, 0.40) 0%, rgba(205, 219, 255, 0.40) 100%))',
      '& $boxText': {
        background: 'linear-gradient(to right, #004CFF, #06BCC1)',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        color: 'transparent'
      }
    },
    '@media (max-width: 960px)': {
      padding: '8px 16px'
    }
  },
  pdLf24px: {
    '@media (max-width: 960px)': {
      paddingLeft: '24px!important'
    }
  },
  childIconBox: {
    width: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 960px)': {
      width: '20px'
    }
  },
  childTextBox: {
    flex: 1,
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center'
  },
  mdLt10: {
    'margin-left': '10px'
  },
  uploadButtonTextDiv: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  textBtn: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    fontWeight: '700 !important',
    color: '#171717 !important',
    padding: '0px 10px'
  },
  borderButtonStyle: {
    marginLeft: 'auto',
    position: 'relative',
    zIndex: 1,
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    textTransform: 'none !important',
    fontSize: '14px !important',
    lineHeight: '18.9px !important',
    fontFamily: 'inherit !important',
    fontWeight: 'normal !important',
    color: '#171717',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 18px',
    borderRadius: '4px',
    cursor: 'pointer',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '4px',
      backgroundColor: '#FFFFFF',
      zIndex: -1,
      transition: '200ms'
    },
    '& span': {
      display: 'inline-block',
      textAlign: 'center',
      whiteSpace: 'nowrap' // Prevents text from wrapping
    }
  },
  requestCompanyDivStyle: {
    position: 'fixed', // Keeps it within the navbar
    left: 0, // Ensures it remains above the footer
    width: '80px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#F7F9FD',
    bottom: '10px',

    '@media (max-height: 600px)': {
      display: 'flex',
      position: 'absolute',
      left: 0,
      bottom: '1% !important',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%'
    }
  },
  //  '@media print': {
  //     requestCompanyDivStyle: {
  //       visibility: 'hidden'
  //     }
  //   },
  requestCompanyButtonStyle: {
    fontFamily: 'Satoshi !important',
    color: '#004CFF !important',
    fontWeight: '700 !important',
    cursor: 'pointer !important',
    alignItems: 'center !important',
    fontSize: '10px !important'
  },
  requestCompanyTypoStyle: {
    fontFamily: 'Satoshi !important',
    color: '#616161 !important',
    fontWeight: '400 !important',
    textAlign: 'center !important',
    fontSize: '12px !important',
    lineHeight: '13px !important'
  },
  navBarGridStyle: {
    width: '80px!important',
    background: '#F7F9FD!important',
    borderRight: '1px solid #F0F0F0!important',
    position: 'sticky!important',
    top: '0!important',
    left: '0!important',
    height: '100vh!important',
    zIndex: '1000!important',
    '@media (max-width: 960px)': {
      width: '100%!important',
      borderRight: 'none!important'
    }
    // '@media print': {
    //   navBarGridStyle: {
    //     visibility: 'hidden'
    //   }
    // }
  },
  boxContent: {
    textAlign: 'center',
    '@media (max-width: 960px)': {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      textAlign: 'left',
      width: '100%'
      // justifyContent: 'space-between'
    }
  }
}));

const enablePortfolio = process.env.REACT_APP_ENABLE_PORTFOLIO === 'true';

const Menus = [
  {
    text: 'Ask Byz',
    icon: MessageIcon,
    gradientIcon: MessageIconGradient,
    route: 'askbyz',
    matchedRoute: 'askbyz'
  },
  {
    text: 'Dashboard',
    icon: DashBoardNewIcon,
    gradientIcon: DashBoardNewIconGradient,
    route: 'dashboard',
    matchedRoute: 'dashboard'
  },
  {
    text: 'Screener',
    icon: ScreenerIcon,
    gradientIcon: ScreenerIconGradient,
    route: 'screener',
    matchedRoute: 'screener'
  },
  {
    text: 'Analysis',
    icon: ReportIcon,
    gradientIcon: ReportIconGradient,
    matchedRoute: 'report',
    children: [
      {
        text: 'Company Analysis',
        icon: CompReportIcon,
        gradientIcon: CompReportIconGradient,
        route: 'report?entity_type=company',
        matchedRoute: 'report'
      },
      {
        text: 'Theme Analysis',
        icon: ThemeIcon,
        gradientIcon: ThemeIconGradient,
        route: 'report?entity_type=theme',
        matchedRoute: 'report'
      }
    ]
  },
  {
    text: 'Tracker',
    icon: GraphIcon,
    gradientIcon: GraphIconGradient,
    matchedRoute: 'tracker',
    children: [
      {
        text: 'Company Tracker',
        icon: PortfolioTracker,
        gradientIcon: PortfolioTrackerGradient,
        route: 'portfolio-tracker',
        matchedRoute: 'portfolio-tracker'
      },
      ...(enablePortfolio
        ? [
            {
              text: 'News Tracker',
              icon: NewsTracker,
              gradientIcon: NewsTrackerGradient,
              route: 'tracker',
              matchedRoute: 'tracker'
            }
          ]
        : [])
    ]
  },
  {
    text: 'Companies',
    icon: ExploreIcon,
    gradientIcon: ExploreIconGradient,
    route: 'explore-all-companies',
    matchedRoute: 'explore-all-companies'
  },
  {
    text: 'Documents',
    icon: DocSearchIcon,
    gradientIcon: DocSearchIconGradient,
    matchedRoute: 'doc-explorer',
    children: [
      {
        text: 'Document Explorer',
        icon: DocExpIcon,
        route: 'doc-explorer',
        gradientIcon: DocExpIconGradient,
        matchedRoute: 'doc-explorer'
      },
      {
        text: 'Document Comparison',
        icon: DocComIcon,
        gradientIcon: DocComIconGradient,
        route: 'doc-comparison',
        matchedRoute: 'doc-comparison'
      }
    ]
  },
  {
    text: 'Library',
    icon: MainLibIcon,
    gradientIcon: MainLibIconGradient,
    matchedRoute: 'my-templates',
    children: [
      {
        text: 'Document Library',
        icon: DocLibIcon,
        gradientIcon: DocLibIconGradient,
        route: 'document-upload/manage',
        matchedRoute: 'document-upload/manage'
      },
      {
        text: 'Template Library',
        icon: LibIcon,
        gradientIcon: LibIconGradient,
        route: 'my-templates',
        matchedRoute: 'my-templates'
      }
    ]
  }
];

const BodyComponent = ({ prevRoute = '', hideRoutes = false, hideMenues = false }) => {
  const { t } = useTranslation();
  const isMobileOrTablet = useMediaQuery('(max-width:960px)');
  const dispatch = useDispatch();
  const classes = useStyles();
  const { authStatus } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const isSignedIn = authStatus === AuthStatus.SignedIn && location.pathname !== '/signin';
  const fullWidth = isSignedIn ? 11 : 12;
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [displayPopoverSubmitCopany, setDisplayPopoverSubmitCopany] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const globalErrorMessage = useSelector((state) => state.tokenReducer.globalErrorMessage);
  const [expandedMenus, setExpandedMenus] = useState({});

  const toggleExpand = (menuKey) => {
    // setExpandedMenus((prev) => ({
    //   ...prev,
    //   [menuKey]: !prev[menuKey]
    // }));
    setExpandedMenus((prev) => {
      const isCurrentlyOpen = prev[menuKey];
      return isCurrentlyOpen ? {} : { [menuKey]: true };
    });
  };

  const updateTearsheetUrl = (url) => {
    dispatch(setTearsheetUrl(url));
  };

  const getPurePath = (route) => '/' + route.split('?')[0];
  const bottomOffsetRef = useRef('10px');

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // Determine the new bottom offset
      const newBottomOffset = scrollY + windowHeight >= documentHeight - 35 ? '45px' : '10px';

      // Update the ref value
      bottomOffsetRef.current = newBottomOffset;

      // Directly update the element's style to avoid re-renders
      const element = document.querySelector(`.${classes.requestCompanyDivStyle}`);
      if (element) {
        element.style.bottom = newBottomOffset;
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isActiveRoute = (route) => {
    const purePath = getPurePath(route);
    return location.pathname === purePath || location.pathname.startsWith(purePath + '/');
  };

  // Helper: determine if a (parent or child) menu is active based on pathname and query params
  const isMenuActive = (menu) => {
    if (menu.children) {
      return menu.children.some((child) => isActiveRoute(child.route));
    } else {
      const searchParams = new URLSearchParams(location.search);
      const entityType = searchParams.get('entity_type');
      const expected = new URLSearchParams(menu.route).get('entity_type');

      return isActiveRoute(menu.route) && (expected ? entityType === expected : true);
    }
  };

  // Helper: check if a child menu is active
  const isChildActive = (child) => {
    if (child.matchedRoute === 'report') {
      return (location.pathname + location.search).includes(child.route);
    }
    return isActiveRoute(child.route);
  };

  const renderChildItems = (menu) =>
    menu.children.map((child) => {
      const childActive = isChildActive(child);
      const ChildIcon = childActive ? child.gradientIcon : child.icon;
      return (
        <div
          key={child.text}
          className={`${classes.childItem} ${childActive ? 'active' : ''} ${classes.pdLf24px}`}
          onClick={(e) => {
            e.stopPropagation();
            if (child.matchedRoute === 'report') {
              updateTearsheetUrl(child.route.split('report').pop());
            }
            navigate(`/${child.route}`);
          }}
        >
          <div className={classes.childIconBox}>
            <ChildIcon className={classes.chboxImage} />
          </div>
          <div className={classes.childTextBox}>
            <p className={classes.boxText}>{child.text}</p>
          </div>
        </div>
      );
    });

  return (
    <>
      <Grid container>
        {!hideMenues && isSignedIn && (
          <Grid item className={classes.navBarGridStyle}>
            {Menus.map((menu) => {
              const menuKey = menu.route || menu.text;
              const parentActive = isMenuActive(menu);
              // Determine which Icon to use (gradient if active)
              const ParentIcon = parentActive ? menu.gradientIcon : menu.icon;
              const haveChildren = menu.children && menu.children.length > 0;
              return (
                // Wrap each menu item in a relative container to allow absolutely positioned child menus
                <div
                  key={menuKey}
                  onMouseEnter={() => menu.children && setHoveredMenu(menuKey)}
                  onMouseLeave={() => menu.children && setHoveredMenu(null)}
                  style={{ position: 'relative' }}
                >
                  <div
                    className={`${classes.boxContainer} ${parentActive ? 'active' : ''}`}
                    onClick={() => {
                      if (haveChildren && isMobileOrTablet) {
                        toggleExpand(menuKey);
                      } else {
                        if (!haveChildren && menu.matchedRoute === 'report') {
                          updateTearsheetUrl(menu.route.split('report').pop());
                        }
                        if (!haveChildren) navigate(`/${menu.route}`);
                      }
                    }}
                  >
                    <div className={classes.boxContent}>
                      <ParentIcon className={classes.boxImage} />
                      <p className={classes.boxText}>{menu.text}</p>
                      {haveChildren && isMobileOrTablet && (
                        <span style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                          {expandedMenus[menuKey] ? (
                            <KeyboardArrowUpIcon fontSize="small" />
                          ) : (
                            <KeyboardArrowDownIcon fontSize="small" />
                          )}
                        </span>
                      )}
                    </div>
                  </div>

                  {/* If menu has children and is hovered, show the child menu container */}
                  {haveChildren &&
                    (isMobileOrTablet
                      ? expandedMenus[menuKey] && (
                          <div className={classes.childContainer}>{renderChildItems(menu)}</div>
                        )
                      : hoveredMenu === menuKey && (
                          <div className={classes.childContainer}>{renderChildItems(menu)}</div>
                        ))}
                </div>
              );
            })}
            {process.env.REACT_APP_ENABLE_ADD_NEW_COMPANY_FLOW === 'true' && (
              <div
                style={{
                  bottom: bottomOffsetRef //'${bottomOffset}px'
                }}
                className={classes.requestCompanyDivStyle}
              >
                <Typography className={classes.requestCompanyTypoStyle}>
                  {t('exploreAllCompanies.requestCompanyText')}
                </Typography>
                <Typography
                  className={classes.requestCompanyButtonStyle}
                  onClick={() => {
                    setDisplayPopoverSubmitCopany(true);
                  }}
                >
                  {t('exploreAllCompanies.requestCompanyBtn')}
                </Typography>
              </div>
            )}
          </Grid>
        )}
        {!hideRoutes && (
          <Grid item sm={fullWidth} xs={fullWidth}>
            <CustomRoutes prevRoute={prevRoute} />
          </Grid>
        )}
      </Grid>
      <RequestCompanyPopover
        displayPopoverSubmitCopany={displayPopoverSubmitCopany}
        setDisplayPopoverSubmitCopany={setDisplayPopoverSubmitCopany}
        setSuccessMessage={setSuccessMessage}
      />
      {successMessage && (
        <SuccessToast
          successMessage="Our team will thoroughly review the details and add it to our database. You’ll receive a confirmation once it’s live."
          successHeading="Thank you for submitting the company!"
        />
      )}
      <ErrorToast errorMessage={globalErrorMessage} />
    </>
  );
};

export default BodyComponent;
