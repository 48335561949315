import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Chip, Pagination, useMediaQuery, Typography, Grid, Checkbox } from '@mui/material';
import { getApi, deleteApi, putApi } from '../../../services/token';
import CustomSelect from '../../atoms/select';
import DeleteDialog from '../../atoms/delete-dialog';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../atoms/Dialog';
import SearchComp from '../../atoms/searchComp';
import SearchTextComp from '../../atoms/searchTextComp';
import CloseIcon from '@material-ui/icons/Close';
import ErrorToast from '../../atoms/errorToast';
import SuccessToast from '../../atoms/successToast';
import LoadingPopover from '../../shared/loadingPopover/loadingPopover';
import TableComp from './table';
import { columnHeadersList, manageInitialSortState } from '../../../constants/docLib/list';
import {
  FileDeleteCellComp,
  FileFormatCellComp,
  FileNameCellComp,
  FileProcessedAtCellComp,
  FileSizeCellComp,
  FileStatusCellComp,
  FileTagsCellComp,
  NoFilesUploadedTableComp
} from './manageTableComps';
import { statusOptions } from './statusChip';
import ConfirmationPopOver from '../../atoms/confirmation-dialog';
import AutocompleteComponent from '../../atoms/multiSearchableAutocomplete';
import { useDebounce } from '../../../hooks/useDebounce';
const ingestionUrl = process.env.REACT_APP_INGESTION_API_URL;

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      height: '44px'
    },
    '& .MuiInputBase-input': {
      height: '10px'
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#a1a1a1',
      opacity: 1
    },
    '& .MuiAutocomplete-listbox .MuiTypography-root': {
      fontSize: '26px !important', // Override font size
      fontWeight: '400 !important' // Optional: override font weight
    }
  },
  option: {
    fontSize: '16px !important'
  },
  stickyColumn: {
    position: 'sticky',
    padding: '0px 11px !important',
    right: 0,
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderBottom: '0.667px solid #e5e5e5',
      borderLeft: '0.667px solid #e5e5e5',
      zIndex: -1,
      boxShadow: '-4px 0 4px 0px rgba(0, 0, 0, 0.1)'
    }
  },
  pagination: {
    padding: '0px'
  },
  componentFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '40px',
    marginTop: '8px'
  },
  hoverColor: {
    textDecoration: 'None',
    color: 'inherit',
    '&:hover': {
      cursor: 'pointer',
      color: 'blue'
    }
  },
  companyFilterPd: {
    padding: '5px'
  },
  autoCompleteForm: {
    width: '100%!important'
  },
  thead: {
    textAlign: 'left',
    color: '#171717',
    fontWeight: 700,
    fontFamily: 'Satoshi',
    padding: '20px !important',
    background: '#EDEDED',
    height: '34px !important',
    fontSize: '14px !important'
  },
  docDetials: {
    fontSize: '14px !important',
    display: 'flex',
    fontFamily: 'Satoshi',
    fontWeight: '700 !important'
  },
  subDoc1: {
    fontFamily: 'Satoshi',
    fontWeight: '700 !important',
    fontSize: '14px',
    color: '#5E5E5E',
    lineHeight: '32px',
    margin: '0px'
  },
  subDoc2: {
    fontFamily: 'Satoshi',
    fontWeight: '400 !important',
    fontSize: '14px',
    color: '#5E5E5E',
    margin: '0px'
  },
  fileName: {
    fontFamily: 'Satoshi',
    fontWeight: '700 !important',
    fontSize: '16px'
  },
  tblThstyle: {
    border: '1px solid #EDEDED',
    padding: '10px'
  },
  tblTrstyle: {
    border: '1px solid #EDEDED',
    padding: '10px',
    fontSize: '14px'
  },
  rowCell: {
    padding: '0px 11px !important'
  },
  deleteCell: {
    background: '#fff'
  },
  fileNameCell: {
    minWidth: '120px',
    maxWidth: '200px',
    wordWrap: 'break-word'
  },
  fileSizeCell: {
    minWidth: '70px'
  },
  checkCls: {
    'align-items': 'center!important',
    position: 'relative!important',
    display: 'grid!important'
  }
}));

const GetDropDown = ({ dropdownProps }) => {
  const { value, options, callbackFn, label, placeholder } = dropdownProps;
  return (
    <CustomSelect
      value={value}
      options={options}
      handleChange={(value) => callbackFn(value)}
      label={label}
      placeholder={placeholder}
    />
  );
};

const PAGE_NUMBER = 1;
const PAGE_SIZE = 5;

const DocLibList = ({ propsData, refreshFlag, hasMore, setPage, setInputValue, page }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { allCompanies, allSectors, allDocNames } = propsData;
  const [openCompanyFilter, setOpenCompanyFilter] = useState(false);
  const [sortState, setSortState] = useState(manageInitialSortState);
  const [loading, setLoading] = useState(true);
  const [allIngestionData, setIngestionData] = useState([]);
  const [allIngestionDataTags, setIngestionDataTags] = useState([]);
  const [pageNumber, setPageNumber] = useState(PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [filterStatus, setFilterStatus] = useState([]);
  const [filterTags, setFilterTags] = useState([]);

  const [sortOrder, setSortOrder] = useState('Desc');
  const [sortBy, setSortBy] = useState('updated_at');
  const [totalRecords, setTotalRecords] = useState(0);
  const [deleteDocumentInfo, setDeleteDocinfo] = useState(null);

  const [cin, setCin] = useState([]);
  const [names, setNames] = useState([]);
  const [searchDocName, setSearchDocName] = useState([]);
  const [cinSector, setCinSector] = useState([]);

  const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [dialogConfirmationTitle, setDialogConfirmationTitle] = useState('');
  const [dialogConfirmationContent, setDialogConfirmationContent] = useState('');
  const [currentConfirmationRow, setCurrentConfirmationRow] = useState(null);
  const [confirmationAction, setConfirmationAction] = useState(null);
  const [loadingTableChange, setLoadingTableChange] = useState(false);

  const ConfirmationActionType = {
    DOC_EXPLORER: 'DOC_EXPLORER',
    COMPANY_PORTFOLIO: 'COMPANY_PORTFOLIO'
  };

  const handleConfirmationClose = () => {
    setOpenConfirmationDialog(false);
    setDialogConfirmationTitle('');
    setDialogConfirmationContent('');
    setCurrentConfirmationRow(null);
  };

  const handleActivateClick = (row) => {
    const { file_name, file_format } = row || {};
    const docName = `${file_name}.${file_format}`;
    setDialogConfirmationTitle('Add Document to Document Explorer');
    setDialogConfirmationContent(
      `Are you sure you want to add this document (${docName}) to the Document Explorer?`
    );
    setOpenConfirmationDialog(true);
  };

  const handleDeactivateClick = (row) => {
    const { file_name, file_format } = row || {};
    const docName = `${file_name}.${file_format}`;
    setDialogConfirmationTitle('Remove Document from Document Explorer');
    setDialogConfirmationContent(
      `Are you sure you want to remove this document (${docName}) from the Document Explorer?`
    );
    setOpenConfirmationDialog(true);
  };

  const handleActivatePortfolioClick = (row) => {
    const { file_name, file_format } = row || {};
    const docName = `${file_name}.${file_format}`;
    setDialogConfirmationTitle('Add Document to Portfolio - Company Tracker');
    setDialogConfirmationContent(
      `Are you sure you want to add this document (${docName}) to the Portfolio - Company Tracker?`
    );
    setOpenConfirmationDialog(true);
  };

  const handleDeactivatePortfolioClick = (row) => {
    const { file_name, file_format } = row || {};
    const docName = `${file_name}.${file_format}`;
    setDialogConfirmationTitle('Remove Document from Portfolio - Company Tracker');
    setDialogConfirmationContent(
      `Are you sure you want to remove this document (${docName}) from the Portfolio - Company Tracker?`
    );
    setOpenConfirmationDialog(true);
  };

  const onChangeFileStatus = (row) => {
    if (row) {
      setCurrentConfirmationRow(row);
      setConfirmationAction(ConfirmationActionType.DOC_EXPLORER);
      if (row?.visibility?.doc_explorer) {
        handleDeactivateClick(row);
      } else {
        handleActivateClick(row);
      }
    }
  };

  const onChangeFileStatusPortfolio = (row) => {
    if (row) {
      setCurrentConfirmationRow(row);
      setConfirmationAction(ConfirmationActionType.COMPANY_PORTFOLIO);
      if (row?.visibility?.company_portfolio) {
        handleDeactivatePortfolioClick(row);
      } else {
        handleActivatePortfolioClick(row);
      }
    }
  };

  // const handleConfirmAction = async () => {
  //   if (currentConfirmationRow && currentConfirmationRow.doc_id) {
  //     try {
  //       setLoading(true);
  //       const input_source =
  //         currentConfirmationRow.input_source &&
  //         currentConfirmationRow.input_source === 'doc_library'
  //           ? 'doc_explorer'
  //           : 'doc_library';
  //       await putApi(`${ingestionUrl}/v1/document/${currentConfirmationRow.doc_id}`, {
  //         input_source
  //       });
  //       await getDocFromDocLib();
  //       handleConfirmationClose();
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //     }
  //   }
  // };

  // const handlePortfolioConfirmAction = async () => {
  //   if (currentConfirmationRow && currentConfirmationRow.doc_id) {
  //     try {
  //       setLoading(true);
  //       const input_source =
  //         currentConfirmationRow.input_source &&
  //         currentConfirmationRow.input_source === 'doc_library'
  //           ? 'doc_explorer'
  //           : 'doc_library';
  //       await putApi(`${ingestionUrl}/v2/document/${currentConfirmationRow.doc_id}`, {
  //         input_source
  //       });
  //       await getDocFromDocLib();
  //       handleConfirmationClose();
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //     }
  //   }
  // };

  const handleConfirmAction = async () => {
    if (currentConfirmationRow && currentConfirmationRow.doc_id) {
      try {
        setLoadingTableChange(true);
        const visibility = {
          ...currentConfirmationRow.visibility,
          doc_explorer: !currentConfirmationRow.visibility.doc_explorer
        };
        await putApi(`${ingestionUrl}/v1/document/${currentConfirmationRow.doc_id}`, {
          visibility
        });
        await getDocFromDocLib();
        handleConfirmationClose();
        setLoading(false);
      } catch (error) {
        setLoadingTableChange(false);
      } finally {
        setLoadingTableChange(false);
      }
    }
  };

  const handlePortfolioConfirmAction = async () => {
    if (currentConfirmationRow && currentConfirmationRow.doc_id) {
      try {
        setLoadingTableChange(true);
        const visibility = {
          ...currentConfirmationRow.visibility,
          company_portfolio: !currentConfirmationRow.visibility.company_portfolio
        };
        await putApi(`${ingestionUrl}/v1/document/${currentConfirmationRow.doc_id}`, {
          visibility
        });
        await getDocFromDocLib();
        handleConfirmationClose();
        setLoading(false);
      } catch (error) {
        setLoadingTableChange(false);
      } finally {
        setLoadingTableChange(false);
      }
    }
  };

  const handleCloseDeletePopUp = () => {
    setOpenDeletePopUp(false);
    setDeleteDocinfo(null);
  };

  useEffect(() => {
    // Call getDocFromDocLib whenever refreshFlag changes
    getDocFromDocLib();
  }, [refreshFlag]);

  const updateSortOrder = (columnName) => {
    setPageNumber(1);
    let currentSortOrder = '';
    const newSortState = sortState.map((x) => {
      if (x.columnName === columnName) {
        const sortOrderForColumn = x.order === '' ? 'Asc' : x.order === 'Asc' ? 'Desc' : 'Asc';
        x.order = sortOrderForColumn;
        currentSortOrder = sortOrderForColumn;
      } else {
        x.order = '';
      }
      return { ...x };
    });
    setSortOrder(currentSortOrder);
    setSortBy(columnName);
    setSortState(newSortState);
  };

  const getDocFromDocLib = async () => {
    try {
      setLoading(true);
      setIngestionData([]);
      setIngestionDataTags([]);
      let queryStr = `page_size=${pageSize}&page_number=${pageNumber}&input_source=doc_library`;
      queryStr += sortBy ? `&sort_by=${sortBy}` : '';
      queryStr += sortBy && sortOrder ? `&sort_order=${sortOrder}` : '';
      if (cin && Array.isArray(cin)) {
        const cinKeys = cin.map((item) => item.key).join(',');
        queryStr += `&cin_list=${cinKeys}`;
      }
      //queryStr += checkAssignFilter('cin_list', cin);
      queryStr += checkAssignFilter('themes_list', cinSector);
      queryStr += checkAssignFilter('query', names);
      queryStr += checkAssignFilter('query', searchDocName);
      queryStr += checkAssignFilter('status', filterStatus);
      const ingestionData = await getApi(`${ingestionUrl}/v1/document?${queryStr}`);
      const { data } = ingestionData?.data || {};
      let { items = [], metadata = {} } = data || {};
      const {
        total_records = totalRecords,
        page_number = pageNumber,
        page_size = pageSize
      } = metadata;

      const pageChangeFlag = (page_number - 1) * pageSize >= total_records;
      if (page_number > 1 && pageChangeFlag) {
        setPageNumber(page_number - 1);
        return;
      }
      items = items.filter(
        (item) =>
          item.association_types &&
          Array.isArray(item.association_types) &&
          item.association_types.length
      );
      setPageNumber(page_number);
      setPageSize(page_size);
      setTotalRecords(total_records);
      setIngestionData([...items]);
      setIngestionDataTags([...items]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setOpenDeletePopUp(deleteDocumentInfo ? true : false);
  }, [deleteDocumentInfo]);

  const checkAssignFilter = (filterName, filter) => {
    return filter && filter.length > 0
      ? `&${filterName}=${filter.map((x) => x.key).join(',')}`
      : '';
  };

  // const getDocFromDocLib = async () => {
  useEffect(() => {
    getDocFromDocLib();
  }, [pageNumber, sortOrder, sortBy, pageSize, cin, cinSector, filterStatus, names, searchDocName]);

  const handleDelete = async () => {
    const docid = deleteDocumentInfo.doc_id;
    setErrorMessage('');
    setLoading(true);
    try {
      await deleteApi(`${ingestionUrl}/v1/document/${docid}`);
      handleCloseDeletePopUp();
      setSuccessMessage(t('docLib.deleteSuccess'));
      setTimeout(() => {
        setSuccessMessage('');
      }, 10000);
      getDocFromDocLib();
    } catch (err) {
      console.log(err);
      setErrorMessage(t('docLib.deleteError'));
      setTimeout(() => {
        setErrorMessage('');
      }, 10000);
    }
    setLoading(false);
  };

  const updatedTotalPages = Math.floor((totalRecords - 1) / [pageSize]) + 1;

  const companyDDProps = {
    value: null,
    options: [{ key: '', value: t('tearSheet.searchBarPlaceholder') }, ...allCompanies],
    callbackFn: () => {},
    label: t('tearSheet.searchBarPlaceholder'),
    placeholder: t('tearSheet.searchBarPlaceholder')
  };

  const pageSizeProps = {
    value: pageSize,
    options: [
      { key: 5, value: 5 },
      { key: 10, value: 10 },
      { key: 15, value: 15 }
    ],
    callbackFn: (v) => {
      setPageNumber(1);
      setPageSize(v);
    },
    label: '',
    placeholder: ''
  };

  const CompanyFilter = () => {
    return (
      <div className={classes.companyFilterPd}>
        <GetDropDown dropdownProps={companyDDProps} />
      </div>
    );
  };

  const handleAutocompleteChange = (value, fn) => {
    fn(value);
  };

  const searchCompProps = {
    handleChange: (e, v) => {
      setPageNumber(1);
      handleAutocompleteChange(v, setCin);
    },
    options: allCompanies,
    selectedValues: cin,
    setSelectedValues: setCin,
    placeholder: 'Select Company',
    hasMore,
    setPage,
    setInputValue: setInputValue,
    data: allCompanies
  };

  console.log(searchCompProps);

  const searchSectorProps = {
    handleChange: (e, v) => {
      setPageNumber(1);
      handleAutocompleteChange(v, setCinSector);
    },
    options: allSectors,
    selectedValues: cinSector,
    placeholder: 'Select Theme'
  };

  const searchStatusProps = {
    handleChange: (e, v) => {
      setPageNumber(1);
      handleAutocompleteChange(v, setFilterStatus);
    },
    options: statusOptions,
    selectedValues: filterStatus,
    placeholder: t('docLib.selectStatus')
  };

  const [docNameInputValue, setDocNameInputValue] = useState('');
  const debouncedInputValue = useDebounce(docNameInputValue, 500);

  const searchDocNameProps = {
    handleChange: (e, v) => {
      let value;
      if (v === '') {
        value = [];
      } else {
        value = [{ key: v, value: v, styleClass: 'queue' }];
      }
      setDocNameInputValue(value);
    },
    options: allDocNames,
    selectedValues: searchDocName,
    placeholder: 'File Name'
  };

  useEffect(() => {
    setPageNumber(1);
    handleAutocompleteChange(debouncedInputValue, setSearchDocName);
  }, [debouncedInputValue]);

  // const searchNameProps = {
  //   handleChange: (e, v) => {
  //     setPageNumber(1);
  //     handleAutocompleteChange(v, setNames);
  //   },
  //   options: allDocNames,
  //   selectedValues: names,
  //   placeholder: 'File Name'
  // };

  // const searchTagsProps = {
  //   handleChange: (e, v) => {
  //     setPageNumber(1);
  //     handleAutocompleteChange(v, setFilterTags);
  //   },
  //   options: [
  //     { key: 'Company', value: 'Company' },
  //     { key: 'Themes', value: 'Themes' },
  //     // { key: 'Sector', value: 'Sector' },
  //     { key: 'Miscellaneous', value: 'Miscellaneous' }
  //   ],
  //   selectedValues: filterTags,
  //   chip: true,
  //   placeholder: t('docLib.selectTags')
  // };

  // const autoCompleteControls = [
  //   searchTagsProps,
  //   searchNameProps,
  //   searchCompProps,
  //   searchSectorProps,
  //   searchStatusProps
  // ];

  const updateFilter = (currentFilters, fn, key) => {
    const filterData = currentFilters.filter((x) => x.key !== key);
    fn(filterData);
  };

  const filterRemove = (filterName, currentFilter) => {
    const key = currentFilter.key;
    switch (filterName) {
      case 'Tags':
        updateFilter(filterTags, setFilterTags, key);
        break;
      case 'File':
        updateFilter(names, setNames, key);
        break;
      case 'File Name':
        updateFilter(names, setSearchDocName, key);
        break;
      case 'Company':
        updateFilter(cin, setCin, key);
        break;
      case 'Themes':
        updateFilter(cinSector, setCinSector, key);
        break;
      case 'Status':
        updateFilter(filterStatus, setFilterStatus, key);
        break;
    }
  };

  const handleClearAll = () => {
    setCin([]);
    setNames([]);
    setSearchDocName([]);
    setCinSector([]);
    setFilterStatus([]);
    setFilterTags([]);
  };

  const filters = [
    { filter: filterTags, fieldName: 'Tags', method: filterRemove },
    { filter: cin, fieldName: 'Company', method: filterRemove },
    { filter: names, fieldName: 'File', method: filterRemove },
    { filter: cinSector, fieldName: 'Themes', method: filterRemove },
    // { filter: cinSector, fieldName: 'Sector', method: filterRemove },
    { filter: filterStatus, fieldName: 'Status', method: filterRemove },
    { filter: searchDocName, fieldName: 'File Name', method: filterRemove }
  ];
  const isFilterShow = filters.find((x) => x.filter.length > 0);

  const columnHeaders = columnHeadersList().map((header) => ({
    ...header,
    sort: header.sort ? updateSortOrder : null,
    styleClass: header.styleClass ? classes[header.styleClass] : undefined
  }));

  const fileTagsProps = {
    allCompanies: allCompanies,
    allDocNames: allDocNames,
    allSectors: allSectors,
    setIngestionDataTags: setIngestionDataTags,
    allIngestionData: allIngestionData,
    allIngestionDataTags: allIngestionDataTags
  };
  const fileRowComps = [
    {
      styleClass: classes.fileNameCell,
      comp: FileNameCellComp
    },
    {
      comp: ({ row }) => (
        // <Checkbox
        //   className={classes.checkCls}
        //   checked={row?.input_source === 'doc_explorer'}
        //   onChange={() => {
        //     onChangeFileStatus(row);
        //   }}
        // />
        <Checkbox
          className={classes.checkCls}
          checked={row?.visibility?.doc_explorer}
          onChange={() => {
            onChangeFileStatus(row);
          }}
        />
      )
    },
    {
      comp: ({ row }) => (
        // <Checkbox
        //   className={classes.checkCls}
        //   checked={row?.input_source === 'portfolio'}
        //   onChange={() => {
        //     onChangeFileStatusPortfolio(row);
        //   }}
        // />
        <Checkbox
          className={classes.checkCls}
          checked={row?.visibility?.company_portfolio}
          onChange={() => {
            onChangeFileStatusPortfolio(row);
          }}
        />
      )
    },
    {
      comp: FileStatusCellComp
    },
    {
      comp: ({ row }) => <FileTagsCellComp row={row} {...fileTagsProps} />
    },
    {
      comp: FileProcessedAtCellComp
    },
    {
      styleClass: classes.fileSizeCell,
      comp: FileSizeCellComp
    },
    {
      comp: FileFormatCellComp
    },
    {
      styleClass: `${classes.stickyColumn} ${classes.deleteCell}`,
      comp: ({ row }) => <FileDeleteCellComp setDeleteDocinfo={setDeleteDocinfo} row={row} />
    }
  ];

  const manageTableProps = {
    columnHeaders: columnHeaders,
    sortState: sortState,
    loading: loading
      ? {
          colCnt: 7,
          rowCnt: 7
        }
      : null,
    emptyTable: {
      comp: NoFilesUploadedTableComp,
      colCnt: 10
    },
    tableData: allIngestionData,
    tableRowComps: fileRowComps
  };

  return (
    <>
      <ErrorToast errorMessage={errorMessage} />
      <SuccessToast successMessage={successMessage} />
      <LoadingPopover open={loadingTableChange} />
      <CustomDialog
        title=""
        open={openCompanyFilter}
        save={() => {}}
        handleClose={() => {
          setOpenCompanyFilter(false);
        }}
        Content={CompanyFilter}
      />
      <DeleteDialog
        openState={openDeletePopUp}
        handleClose={handleCloseDeletePopUp}
        text="File"
        trigger={handleDelete}
      />

      {/* <ConfirmationPopOver
        openState={openConfirmationDialog}
        handleClose={handleConfirmationClose}
        trigger={handleConfirmAction}
        text={dialogConfirmationTitle}
        content={dialogConfirmationContent}
      /> */}

      <ConfirmationPopOver
        openState={openConfirmationDialog}
        handleClose={handleConfirmationClose}
        trigger={
          confirmationAction === ConfirmationActionType.COMPANY_PORTFOLIO
            ? handlePortfolioConfirmAction
            : handleConfirmAction
        }
        text={dialogConfirmationTitle}
        content={dialogConfirmationContent}
      />
      <Grid container style={{ paddingBottom: '5px' }}>
        <Grid item xs={12} sm={3} style={{ padding: '0px 3px' }}>
          <SearchTextComp {...searchDocNameProps} className={classes.autoCompleteForm} />
        </Grid>
        <Grid item xs={12} sm={3} style={{ padding: '0px 3px' }}>
          <div className={classes.root}>
            <AutocompleteComponent
              setInputValue={setInputValue}
              isSingleSelect={false}
              data={allCompanies}
              hasMore={hasMore}
              page={page}
              setPage={setPage}
              placeholder="Select Company"
              selectedItems={cin}
              setSelectedItems={setCin}
              property={{ key: 'key', value: 'value' }}
              searchIcon={false}
              label={null}
              styleClass={`${classes.option}`}
              // onInputChange={(event, value) => {
              //   setInputValue(value);
              //   setPage(1);
              //   getCompanySearchData(value, 1);
              // }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={3} style={{ padding: '0px 3px' }}>
          <SearchComp {...searchSectorProps} className={classes.autoCompleteForm} />
        </Grid>
        <Grid item xs={12} sm={3} style={{ padding: '0px 3px' }}>
          <SearchComp {...searchStatusProps} className={classes.autoCompleteForm} />
        </Grid>
      </Grid>
      {/* <Grid container style={{ padding: '0px 0px 10px 0px' }}>
        {autoCompleteControls.map((x, i) => {
          if (i === 0) return <></>;
          if (i === 1) return <></>;
          return (
            <Grid item xs={12} sm={4} key={i} style={{ padding: '0px 3px' }}>
              <SearchComp {...x} className={classes.autoCompleteForm} />
            </Grid>
          );
        })}
      </Grid> */}
      <div>
        <div>
          {isFilterShow ? (
            <>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  overflowX: 'auto'
                }}
              >
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0px 10px 0px 10px'
                  }}
                >
                  {t('appliedFilter.showing')}
                </Typography>
                {filters.map((x, i) => (
                  <div key={i} style={{ display: 'flex' }}>
                    {x.filter && x.filter.length > 0 && (
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: '14px',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {x.fieldName}
                      </Typography>
                    )}
                    {x.filter &&
                      x.filter.length > 0 &&
                      x.filter.map((value) => (
                        <Chip
                          key={value.key}
                          label={value.value}
                          style={{
                            borderRadius: '2px',
                            margin: '7px 3px',
                            backgroundColor: '#E8F0FE',
                            color: '#004CFF'
                          }}
                          onDelete={() => {
                            filterRemove(x.fieldName, value);
                          }}
                          deleteIcon={<CloseIcon />}
                        />
                      ))}
                  </div>
                ))}
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#004CFF',
                    cursor: 'pointer'
                  }}
                  onClick={handleClearAll}
                >
                  {t('appliedFilter.clearAll')}
                </Typography>
              </div>
            </>
          ) : (
            <div></div>
          )}
        </div>
        <TableComp {...manageTableProps} />

        <div
          className={classes.componentFooter}
          style={{
            opacity: loading ? 0.4 : 1,
            pointerEvents: loading ? 'none' : 'auto'
          }}
        >
          <div>
            <Pagination
              className={classes.pagination}
              shape="rounded"
              count={updatedTotalPages}
              onChange={(e, value) => {
                setPageNumber(value);
              }}
              size={xSmallScreen ? 'small' : 'medium'}
              page={pageNumber}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <Typography
                style={{
                  fontFamily: 'Satoshi',
                  fontSize: '14px',
                  color: '#171717',
                  fontWeight: '500',
                  paddingRight: '10px'
                }}
              >
                Per page
              </Typography>
            </div>
            <div style={{ width: '55px' }}>
              <GetDropDown dropdownProps={pageSizeProps} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocLibList;
