import { makeStyles, Typography } from '@material-ui/core';
import { Box } from '@mui/material';

const useStyles = makeStyles(() => ({
  mainBox: {
    overflowY: 'auto'
  },
  section: {
    background: '#F7F9FD',
    border: '1px solid #E4EBFC',
    borderRadius: '8px',
    padding: '20px 20px 12px 20px',
    marginBottom: '10px'
  },
  section2: {
    marginTop: '20px',
    paddingLeft: '8px'
  },
  sectionHeading: {
    color: '#171717',
    fontSize: '14px',
    fontWeight: '600',
    marginBottom: '8px'
  },
  sectionPara: {
    color: '#171717',
    fontSize: '14px',
    fontWeight: '400',
    marginBottom: '8px'
  },
  sectionList: {
    margin: '0px 0px 8px 0px',
    padding: '0px 0px 0px 20px'
  },
  sectionListItem: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#171717'
  }
}));
const UserAgreementContent = () => {
  const classes = useStyles();
  return (
    <Box className={classes.mainBox}>
      <Box className={classes.section}>
        <Typography className={classes.sectionHeading} style={{ textAlign: 'center' }}>
          SUBSCRIPTION AGREEMENT
        </Typography>
        <Typography className={classes.sectionHeading} style={{ textAlign: 'center' }}>
          THIS SOFTWARE AS A SERVICE (SAAS) SUBSCRIPTION AGREEMENT (Agreement) is between
        </Typography>
        <Typography className={classes.sectionHeading} style={{ textAlign: 'center' }}>
          Your Organization (hereinafter referred to as Client){' '}
        </Typography>
        <Typography className={classes.sectionHeading} style={{ textAlign: 'center' }}>
          AND
        </Typography>
        <Typography className={classes.sectionPara} style={{ textAlign: 'center' }}>
          <strong>BLOKTREK</strong> Inc, a company duly incorporated under the laws of Delaware, USA
          and having its registered office at 6 Brians Way, Princeton Jct, NJ 08550 (hereinafter
          referred to as <strong>BlokTrek</strong>).
        </Typography>
        <Typography className={classes.sectionPara}>
          This agreement governs enrollment in and use of the Services by you, your Organization,
          and anyone given access to the Services.
        </Typography>
        <Typography className={classes.sectionPara}>
          You are entering into this Agreement on behalf of your Organization and not in your
          individual capacity. By using your Organization-assigned email address to enroll in the
          Services, you represent that you:
        </Typography>
        <Box component="ol" className={classes.sectionList}>
          <Typography component="li" className={classes.sectionListItem}>
            have authority to enter into this Agreement on behalf of your Organization; and
          </Typography>
          <Typography component="li" className={classes.sectionListItem}>
            will comply with your Organization's policies relevant to enrollment, purchase, and use
            of the Services.
          </Typography>
        </Box>
        <Typography className={classes.sectionPara}>
          Licenses for the Services are owned and controlled by your Organization. This is true even
          if you provide your personal payment information when enrolling in the Services. Your
          Organization has full control over the Services and may cancel, reassign, or otherwise
          restrict access to the Services at any time.
        </Typography>
        <Typography className={classes.sectionPara}>
          <strong>WHEREAS</strong>, BlokTrek has developed “PrivateBlok” software as a service
          offering and related services which can be made available to subscribers for the purpose
          of analysis and presentation;
        </Typography>
        <Typography className={classes.sectionPara}>
          <strong>WHEREAS</strong>, the Client wants to use BlokTrek’s “PrivateBlok” platform in its
          business operations;
        </Typography>
        <Typography className={classes.sectionPara}>
          <strong>WHEREAS</strong>, BlokTrek agrees to provide the same to the Client, subject to
          the terms and conditions of this Agreement.
        </Typography>
        <Typography className={classes.sectionPara}>
          <strong>NOW, THEREFORE</strong>, in consideration of the mutual covenants and promises
          herein set forth, the parties hereby agree as follows:
        </Typography>
        <Typography variant="h6" className={classes.sectionHeading}>
          1. Definitions
        </Typography>
        <Typography className={classes.sectionPara}>
          In this Agreement, the following words and phrases shall have the following respective
          meanings, unless the context otherwise requires:
        </Typography>

        <Typography className={classes.sectionPara}>
          a. <strong>“Affiliate”</strong> With respect to either party means, any other person
          directly or indirectly Controlling, Controlled by, or under direct or indirect common
          Control with such party. “Control”, “Controlled”, or “Controlling” shall mean, with
          respect to any person, any circumstances in which such person is controlled by another
          person by virtue of the latter person controlling the composition of the Board of
          Directors or owning the largest or controlling percentage of the voting securities of such
          person or by way of contractual relationship or otherwise.
        </Typography>

        <Typography className={classes.sectionPara}>
          b. <strong>“Agreement”</strong> Shall mean and include this Software as a Service
          Subscription Agreement, and its Schedules, any other deed, document, addendum, email
          communication or writing mutually accepted and executed by the parties and includes any
          variation or modification made to this Agreement in writing.
        </Typography>

        <Typography className={classes.sectionPara}>
          c. <strong>“Agreement Date”</strong> Shall mean the date on which this Agreement is duly
          signed and executed by the authorized representatives of the parties respectively, and
          from which date this Agreement and its obligations become binding upon the parties hereto.
        </Typography>

        <Typography className={classes.sectionPara}>
          d. <strong>“Applicable Laws”</strong> Shall mean all applicable laws, rules, regulations,
          orders, ordinances, protocols, codifications, guidelines, policies, notices, directions
          and judgments or other similarly mandatory requirements of any statutory/regulatory/local
          authority or body of India.
        </Typography>

        <Typography className={classes.sectionPara}>
          e. <strong>“Authorized Users”</strong> Are those personnel of the Client who are
          authorized to access and use the Services on the Client’s behalf under this Agreement.
        </Typography>

        <Typography className={classes.sectionPara}>
          f. <strong>"Confidential Information"</strong> means the terms of this Agreement and any
          and all other information disclosed by either party <strong>(“Discloser")</strong> whether
          relating to its past, present or future business activities and including, without
          limitation, the following types of information and other information of a similar nature,
          whether or not set forth in writing (a) non-public and proprietary information and data,
          such as technical or business data, documents, knowledge, samples, prototypes, emails,
          communication in any form whatsoever, software, test results, discoveries, ideas,
          concepts, papers, software in various stages of development, designs, drawings,
          specifications, techniques, models, prototypes, data, source code, object code,
          documentation, manuals, diagrams, reports, flow charts, schematics, employee details,
          scope and activities of any research, process, procedures, functions, “know-how”, patent,
          patent application (complete or pending), trademark, copyright or other intellectual
          property rights, marketing techniques and materials, marketing and development plans,
          technical, business, manufacturing, marketing, strategy, volumes, services rendered or
          availed, operational or customer or supplier names and other information including
          information related to other projects of Discloser or its subsidiaries, its Affiliates or
          its business partners, customers, price lists, pricing policies and financial or business
          information, (b) any information disclosed to the other Party{' '}
          <strong>("Recipient")</strong> by any third party under obligations of confidentiality to
          Discloser and/or the results of the provisions of the products or the Services under this
          Agreement, and (c) any information relating to Discloser's directors, officers, employees
          or its expressly authorized representatives or agents, (d) all data/ reports/
          presentations/ correspondence/ evaluation/ analysis/ assessment/ observations/ email/ or
          any deed/ documents/ writing disclosed by Discloser shall be protected hereunder and be
          treated as Confidential Information. All information meeting the foregoing definition of
          Confidential Information which is provided for the Services under this Agreement, whether
          provided in any form, by visual display or learning by way of sight or by orally, by
          electronic media or on magnetic or other media, or in the form of samples, models,
          computer programs or otherwise shall be considered to be Confidential Information for this
          Agreement, regardless of whether such information is specifically marked or designated as
          “confidential”.
        </Typography>
        <Typography className={classes.sectionPara}>
          g. <strong>"Data"</strong> means all data, content, and Confidential Information owned,
          held, used or created by or on behalf of the Client that is stored using, or inputted
          into, the Services.
        </Typography>
        <Typography className={classes.sectionPara}>
          h. <strong>"Force Majeure Event"</strong> shall mean any event or circumstance or a
          combination of events and circumstances referred to herein, which or any consequences of
          which materially and adversely affects the performance by either party{' '}
          <strong>(“Affected Party")</strong> of its obligations under this Agreement and which are
          beyond the reasonable control of the Affected Party and which event or circumstance could
          not be mitigated by the exercise of ordinary prudence, reasonable skill and diligence
          including but not limited to the following events or occurrences and the effects thereof:
          internet shutdown, power failure, labour scarcity, Acts of God or public enemy, flood,
          earthquake, storm, cyclone, tornado, hurricane, lightning, fire, explosion, epidemic or
          pandemic, embargoes, riot or civil disturbances, act of terrorism, sabotage, malicious
          damage, strikes, blockades, lock out or other industrial disputes or change of law which
          may lead to disruption of agreed services as under this Agreement or specific Statement of
          Works.
        </Typography>
        <Typography className={classes.sectionPara}>
          i. <strong>"Intellectual Property Rights”</strong> means all intellectual property rights
          owned by either party, including without limitation copyrights, trademarks, service marks,
          internet domain names, designs, trade secrets, know-how, patents, patent applications,
          relating to any inventions, products, software, methodology or technology business name,
          goodwill, database rights and all other intellectual property rights in each case whether
          registered or unregistered and including all applications and rights to apply for and be
          granted, renewals or extensions of, and rights to claim priority from, such rights and all
          similar or equivalent rights or forms of protection which subsist in any part of the
          world.
        </Typography>
        <Typography className={classes.sectionPara}>
          j. <strong>"PrivateBlok Software"</strong> is the software owned by BlokTrek (and its
          licensors, if any) that is used to provide the SaaS Services.
        </Typography>
        <Typography className={classes.sectionPara}>
          k. <strong>"Other Services"</strong> any service excluding SaaS Service, as described, and
          any further services that BlokTrek agrees to provide to the Client under the Agreement.{' '}
          <strong>“SaaS Service"</strong> refers to PrivateBlok Software which is a cloud based,
          mobile enabled, AI powered solution where various stakeholders within the client
          organisation could meaningfully interact to conduct analysis or generate reports.
        </Typography>
        <Typography className={classes.sectionPara}>
          l. <strong>“Services"</strong> means SaaS Services and Other Services
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>2. Services.</Typography>

        <Typography className={classes.sectionPara}>
          a. “<strong>Services</strong>” BlokTrek grants to the Client a non-exclusive,
          non-transferable, non-assignable, non-sublicensable right to permit the Authorized User(s)
          to use the Services during the Term of this Agreement solely for the Client's internal
          business operations, as described.
        </Typography>

        <Typography className={classes.sectionPara}>
          b. “<strong>Additional Services</strong>” At the request of the Client and subject to
          Client paying the applicable fees, BlokTrek may agree to provide to the Client additional{' '}
          <strong>Other Services</strong> on the terms of this Agreement.
        </Typography>

        <Typography className={classes.sectionPara}>
          c. “<strong>No Exclusivity</strong>” Client acknowledges that nothing in this Agreement
          obliges BlokTrek to devote all or substantially all of its time or attention to the
          Services and that nothing shall restrict or prevent BlokTrek from entering into agreements
          with other persons concerning the provision of similar services.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>
          3. <strong>Client Obligations.</strong> Client agrees to the following terms and
          conditions:
        </Typography>

        <Typography className={classes.sectionPara}>
          a. <strong>Cooperation.</strong> The Client shall provide the necessary cooperation and
          information as may be required by BlokTrek to render the Services under this Agreement. In
          the event of any delays in the Client's provision of such cooperation, the Parties may,
          based on mutual discussion, adjust any agreed timetable or delivery schedule as is
          reasonably necessary;
        </Typography>

        <Typography className={classes.sectionPara}>
          b. <strong>Access Conditions.</strong> When accessing the Services, the Client and its
          personnel must (i) not impersonate another person or misrepresent authorisation to act on
          behalf of others or BlokTrek; (ii) correctly identify the sender of all electronic
          transmissions; (iii) not attempt to view, access or copy, reproduce, reverse engineer,
          decompile any material or data other than that which the Client and/or its personnel is
          authorised to access to the extent necessary to use the Services in accordance with this
          Agreement; and (iv) not use the Services in a manner, nor transmit, input or store any
          Data, that breaches any third party right or is objectionable, incorrect or misleading.
        </Typography>

        <Typography className={classes.sectionPara}>
          c. <strong>Maintenance.</strong> Client shall be responsible for obtaining and maintaining
          any equipment and ancillary services needed to connect to, access or otherwise use the
          Services, including, without limitation, modems, hardware, servers, software, operating
          systems, networking, web servers and the like (collectively, <strong>“Equipment”</strong>
          ). Client shall also be responsible for maintaining the security of the Equipment, Client
          account, passwords (including but not limited to administrative and user passwords) and
          files, and for all uses of the Client account or the Equipment with or without Client’s
          knowledge or consent.
        </Typography>

        <Typography className={classes.sectionPara}>
          d. <strong>Access to BlokTrek.</strong> Client will provide BlokTrek with timely access to
          Client’s <strong>Confidential Information</strong>, facilities, <strong>Equipment</strong>{' '}
          and environment to perform the implementation and delivery of the Services as contemplated
          in this Agreement, including, without limitation, data, security access, information and
          software interfaces to Client’s applications.
        </Typography>

        <Typography className={classes.sectionPara}>
          e. <strong>Unauthorized Access.</strong> Client shall use all reasonable endeavors to
          prevent any unauthorized access to, or use of, the Services and, in the event of any such
          unauthorized access or use, promptly notify BlokTrek.
        </Typography>

        <Typography className={classes.sectionPara}>
          f. <strong>Restrictions.</strong> The Client shall not:
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          i. Attempt to copy, modify, decompile, reproduce, create derivative works from, frame,
          mirror, republish, download, license, sell, rent, lease, transfer, assign, distribute,
          display, disclose or otherwise commercially exploit or make the Services available to any
          third party except the Authorized Users;
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          ii. Attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to
          human-perceivable form all or any part of the software used for the Services;
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          iii. Access all or any part of the Services in order to build a product or service which
          competes with the Services;
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          iv. Use the Services to provide services to third parties.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>4. DATA.</Typography>

        <Typography className={classes.sectionPara}>
          a. <strong>Access to Data.</strong> Client acknowledges that BlokTrek or its personnel may
          require access to the Data for the performance of this Agreement.
        </Typography>

        <Typography className={classes.sectionPara}>
          b. <strong>Analytical Data.</strong> Client acknowledges and agrees that BlokTrek may use
          Data and information about Client’s and the Client’s end users’ use of the Services to
          generate anonymised and aggregated statistical and analytical data (
          <strong>“Analytical Data”</strong>) for (i) BlokTrek’s internal research and product
          development; (ii) to conduct statistical analysis and identify trends and insights.
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          i. BlokTrek’s rights under this Section shall survive termination or expiry of this
          Agreement; and
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          ii. Any right (including Intellectual Property Rights) in Analytical Data is and remains
          BlokTrek’s property.
        </Typography>

        <Typography className={classes.sectionPara}>
          c. <strong>Authorisations.</strong> Client is responsible for procuring all licences,
          authorisations and consents required for Client, and its authorized personnel to use,
          store and input Data into, and process and distribute Data through, the Services.
        </Typography>

        <Typography className={classes.sectionPara}>
          d. <strong>Data Backup.</strong> Client agrees to keep a back-up copy of all Data uploaded
          by it onto the SaaS Service.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>5. FEES.</Typography>
        <Typography className={classes.sectionPara}>
          a. <strong>Fee.</strong> The total fee for the use of the Services is set forth as agreed
          upon and communicated separately in an email or any other written communication.
        </Typography>
        <Typography className={classes.sectionPara}>
          b. <strong>Payment.</strong> Payment is due as set forth and is non-refundable. Payment
          made later than the due date will accrue interest from the date due to the date paid at
          the lesser rate of 1% per month or the maximum allowed by applicable law. If a payment is
          late, BlokTrek shall be entitled to suspend performance of the Services and, at its
          option, terminate the Agreement on written notice. Any such suspension of Services shall
          not be considered a breach of this Agreement.
        </Typography>
        <Typography className={classes.sectionPara}>
          c. <strong>Early Termination Charges.</strong> If BlokTrek terminates the Services
          pursuant to Client’s failure to timely pay any Fees under Section 5(b), then Client will
          pay BlokTrek early termination charges equivalent to unpaid Fees for the remaining term of
          such terminated Services and the balance of any unpaid non-recurring charges associated
          with such terminated Services. Client agrees that the actual damages in the event of such
          termination would be difficult or impossible to ascertain, and that such termination
          charges are intended, therefore, to establish liquidated damages for such early
          termination and are not intended as a penalty.
        </Typography>
        <Typography className={classes.sectionPara}>
          d. <strong>Right to Change.</strong> The Parties may change the Fees or applicable charges
          during the Term of this Agreement, upon mutual discussion and written agreement.
        </Typography>
        <Typography className={classes.sectionPara}>
          e. <strong>Disputed Billing.</strong> If Client, in good faith, believes that BlokTrek has
          billed Client incorrectly, Client must contact BlokTrek no later than ten (10) days after
          the closing date on the first billing statement in which the error or problem appeared, in
          order to receive an adjustment or credit. If Client fails to do so, Client is deemed to
          have waived its right to dispute the billing statement and the billing statement will be
          deemed accurate and valid.
        </Typography>
        <Typography className={classes.sectionPara}>
          f. <strong>Taxes.</strong> Fees paid by Client to BlokTrek are exclusive of applicable
          taxes. Client will pay and bear the liability for any taxes associated with the delivery
          of all Services, including sales, use, excise, and goods and services taxes (GST). If
          Client is required to withhold taxes, Client will furnish BlokTrek receipts substantiating
          such payment. If BlokTrek is required to remit any tax or duty on behalf or for the
          account of Client, Client will reimburse BlokTrek within thirty (30) days after BlokTrek
          notifies Client in writing of such remittance. Client will provide a valid tax exemption
          certificate in advance of any remittance otherwise required to be made by BlokTrek on
          behalf or for the account of Client, where such certificate is applicable.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>
          6. Bloktrek Representations And Warranties.
        </Typography>

        <Typography className={classes.sectionPara}>
          a. <strong>Warranties.</strong> BlokTrek hereby represents, warrants, covenants and
          assures the Client that:
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          i. BlokTrek has the necessary authority and approval for consummation of the transaction
          and to enter into and execute this Agreement.
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          ii. BlokTrek is not barred/prohibited under any Applicable Law/statutory order to enter
          into this Agreement.
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          iii. BlokTrek does not have any conflict of interest in entering into this Agreement with
          any third party contracts or in breach or violation of such third party contracts.
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          iv. Services shall be performed in accordance with generally accepted industry standards.
          Upon receipt of written notice of breach of this warranty, BlokTrek’s sole obligation is
          to undertake corrective measures.
        </Typography>

        <Typography className={classes.sectionPara}>
          b. <strong>Exclusions.</strong> The warranties under this Section 5 exclude
          non-performance issues that result from third-party hardware or firmware malfunction or
          defect; software not developed by BlokTrek; incorrect data or incorrect procedures used or
          provided by Client or a third party; changes to Client’s environment; or defects, delays,
          interruptions, service failures or other problems inherent in use of the internet and
          electronic communications or other systems which are outside the reasonable control of
          BlokTrek. The parties hereby agree that in the event there are non-performance issues
          outside of BlokTrek's reasonable control, they shall mutually discuss and agree on the
          cost to be incurred for rectification of non-performance. This warranty shall immediately
          cease if Client or any third party modifies any portion of the Services and/or modifies
          Client’s system.
        </Typography>

        <Typography className={classes.sectionPara}>
          c. <strong>Disclaimer.</strong> EXCEPT AS EXPRESSLY DESCRIBED IN THIS WARRANTY SECTION,
          BLOKTREK MAKES NO WARRANTY OF ANY KIND. THE SERVICES ARE PROVIDED “AS IS” AND BLOKTREK
          DISCLAIMS AND EXCLUDES ALL OTHER EXPRESS, IMPLIED, AND STATUTORY WARRANTIES,
          REPRESENTATIONS, AND CONDITIONS WITH RESPECT TO SERVICES AND DELIVERABLES, INCLUDING THE
          IMPLIED WARRANTIES OF MERCHANTABILITY, GOOD TITLE, NON-INFRINGEMENT, AND FITNESS FOR A
          PARTICULAR PURPOSE. BlokTrek does not warrant that the Services provided will be without
          defect or error.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>7. INTELLECTUAL PROPERTY RIGHTS.</Typography>

        <Typography className={classes.sectionPara}>
          a. <strong>Ownership.</strong> BlokTrek (and/or its licensors) own all rights, title and
          interest, including all Intellectual Property Rights, in any code, documentation, look and
          feel or other materials developed (including modifications and improvements), used and/or
          delivered by BlokTrek in the performance of the Services under this Agreement. The Client
          owns all rights, title and interest, including all Intellectual Property Rights, of the
          specific tearsheet templates, reports, documentation generated by BlokTrek for the Client
          on their specification in the performance of the Services under this Agreement. The Client
          shall have the right to download and receive such reports/deliverables in formats as it
          may require. This Agreement does not have any impact on the ownership of any pre-existing
          materials of BlokTrek.
        </Typography>

        <Typography className={classes.sectionPara}>
          b. <strong>Right to Client.</strong> Subject to payment of all applicable{' '}
          <strong>Fees</strong> for Services provided to Client, BlokTrek grants to Client a
          nonexclusive, nontransferable, limited right to access and use the Services during the
          Term of this Agreement. All proprietary rights notices must be faithfully reproduced and
          included on all copies (including any modifications or adaptations allowed by this
          Agreement). The Parties hereby agree that any and all BlokTrek’s Properties delivered to
          the Client by BlokTrek shall be used solely by the Client (including its Affiliates) for
          its own internal purposes. The Client shall not, directly or indirectly, sell, transfer,
          license, or otherwise make available any BlokTrek Properties to any third party without
          the prior written consent of BlokTrek.
        </Typography>

        <Typography className={classes.sectionPara}>
          c. <strong>Feedback.</strong> BlokTrek may freely use or exploit Feedback in connection
          with any of its products or services and the Client shall not have any Intellectual
          Property Rights in any developments arising from the Feedback.
        </Typography>

        <Typography className={classes.sectionPara}>
          d. <strong>License of Client Intellectual Property Rights.</strong> Client grants to
          BlokTrek, solely for BlokTrek’s provision of the Services, a license during the Term to
          use any Intellectual Property Rights, including any software, owned by or licensed to
          Client by third parties and that is necessary for providing the Services to Client and
          otherwise performing its obligations under this Agreement. With respect to any
          Intellectual Property Rights and software used by BlokTrek to provide the Services, Client
          represents and warrants that: (a) Client is either the owner of such Intellectual Property
          Rights or software or is authorized by its owner to include it under this Agreement; and
          (b) BlokTrek has the right during the Term to use such Intellectual Property Rights and
          software for the purpose of providing the Services to Client as contemplated by this
          Agreement.
        </Typography>

        <Typography className={classes.sectionPara}>
          e. <strong>Ownership of Pre-Existing Intellectual Property.</strong> Subject to the terms
          contained herein, each Party will retain exclusive interest in and ownership of any
          Pre-Existing Intellectual Property including, but not limited to, any intellectual
          property developed prior to this Agreement or outside the scope of this Agreement. For the
          purpose of this provision, the term “Pre-Existing Intellectual Property” means any
          proprietary methodologies, tools, models, software, procedures, documentation, know-how,
          processes, trade secrets, inventions, or works of authorship that have already been
          conceived or developed by BlokTrek and/or the Client before BlokTrek renders any Services
          under this Agreement.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>8. CONFIDENTIAL INFORMATION.</Typography>

        <Typography className={classes.sectionPara}>
          a. <strong>Confidential Information.</strong> Recipient agrees that any and all
          information disclosed by Discloser under this Agreement or prior thereto leading up to
          this Agreement shall be treated as Confidential Information regardless of whether such
          information is specifically marked or designated as “Confidential” or “Proprietary”, and
          that the same shall not be disclosed to any person or third party except to the
          Recipient’s directors, officers, employees, consultants, agents, advisors and bankers
          (each, a <strong>“Permitted Recipient”</strong>) on a need to know basis, who shall each
          be legally or contractually bound to keep it confidential in terms of this Agreement.
        </Typography>

        <Typography className={classes.sectionPara}>
          b. <strong>Recipient Warranties.</strong> Without limiting the above, Recipient further
          represents, warrants, covenants, agrees and undertakes:
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          i. To use the Discloser’s Confidential Information only for the purposes of fulfilling its
          obligations under this Agreement.
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          ii. Not to disclose (directly or indirectly) the Confidential Information that is or has
          been or will be made available to a third party except for the Permitted Recipient.
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          iii. To limit the access to the Confidential Information solely to Permitted Recipients as
          described herein, who have reason to require access to the Confidential Information for
          the Services to be rendered and on a limited need to know basis only.
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          iv. To ensure that each such Permitted Recipient is advised about the confidentiality
          obligations under this Agreement and strictly observes the restrictions as to use and
          disclosure contained herein; notwithstanding any such agreement on the part of a Permitted
          Recipient, Recipient shall be liable for any breach of this Agreement by such Permitted
          Recipient and shall take all reasonable measures to restrain them from prohibited or
          unauthorized use of the Confidential Information.
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          v. In consideration of the disclosure and release of the Confidential Information by
          Discloser to Recipient, Recipient hereby agrees to use, and ensure that it uses, the same
          measures and/or procedures as it does for its own Confidential Information to hold and
          keep in confidence all such Confidential Information and comply with the terms of this
          Agreement.
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          vi. Except for the purposes of and as authorized under this Agreement, the Recipient shall
          ensure that the Confidential Information will not be copied, reproduced, or transmitted by
          any means whatsoever for any purposes other than rendering the Services.
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          vii. Recipient hereby agrees that it will promptly return to Discloser any or all such
          Confidential Information together with all copies or reproductions thereof (and cease all
          use of the same) upon written request by Discloser at any time. Alternatively, at the
          written request of the Discloser, Recipient shall promptly ensure the destruction of any
          or all Confidential Information together with all copies or reproductions thereof.
          Provided that Recipient may retain such Confidential Information only as required by
          applicable law, regulation, or documented professional archival policy or as otherwise
          authorized by Discloser. Any Confidential Information so retained shall at all times
          remain subject to the terms and conditions of this Agreement, and this confidentiality
          obligation shall be binding on Recipient in perpetuity.
        </Typography>

        <Typography className={classes.sectionPara}>
          c. <strong>Information provided by Client.</strong> In performing the Services, BlokTrek
          will use and rely primarily on information available from public sources and the
          Confidential Information provided by Client, and Client warrants that it is authorized to
          provide BlokTrek with such Confidential Information for BlokTrek’s use in connection with
          this Agreement and that BlokTrek will have no obligation to independently verify such
          information.
        </Typography>

        <Typography className={classes.sectionPara}>
          d. <strong>Breach.</strong> The Recipient acknowledges that any breach of the obligations
          of confidentiality contained in this Agreement would result in irreparable harm, injury,
          loss, and damage to the Discloser. Accordingly, the Recipient agrees that in the event of
          a breach of the terms of this Agreement, the Discloser shall be entitled to obtain any
          legal and/or equitable relief including an injunction or specific performance to prevent
          any further or continuing breach. The Recipient shall indemnify and keep the Discloser
          indemnified for any breach of confidentiality attributable to the Recipient as per the
          terms of this Agreement.
        </Typography>

        <Typography className={classes.sectionPara}>
          e. <strong>Exclusions.</strong> Confidential Information will not include any information
          that is (i) already in possession of Recipient without an obligation of confidence; (ii)
          independently developed by Recipient; (iii) becomes publicly available without breach of
          this Agreement; (iv) rightfully received by the Recipient from a third party without an
          obligation of confidence; or (v) released for disclosure by the Discloser with its written
          consent.
        </Typography>

        <Typography className={classes.sectionPara}>
          f. <strong>Exception.</strong> When disclosure is required by law in connection with a
          legal proceeding or governmental authority, Recipient may disclose Confidential
          Information provided that the Recipient (i) gives Discloser prompt notice of the order;
          (ii) limits the scope of disclosure to what is reasonably required by the applicable
          authority; and (iii) provides Discloser with an opportunity to challenge the release of
          the Confidential Information or obtain a protective order prior to disclosure.
        </Typography>

        <Typography className={classes.sectionPara}>
          g. <strong>Feedback.</strong> To the extent Client provides any suggestion, idea,
          enhancement requests, recommendations or comments (<strong>“Feedback”</strong>) to
          BlokTrek, such Feedback will not be considered Confidential Information and BlokTrek will
          have the unrestricted right to use, disclose, publish or otherwise exploit any Feedback
          without any compensation to Client.
        </Typography>

        <Typography className={classes.sectionPara}>
          h. <strong>Survival.</strong> This Section 7 shall survive the termination of this
          Agreement.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>9. INDEMNITY</Typography>

        <Typography className={classes.sectionPara}>
          a. <strong>Client Indemnity.</strong> Client shall indemnify and hold harmless BlokTrek,
          its Affiliates, and each of their respective officers, directors, members, agents, and
          employees from and against any and all claims, demands, liabilities, obligations, losses,
          damages, penalties, and fines of any kind and nature whatsoever (including reasonable
          attorneys’ fees) brought by a third party under any theory of legal liability arising out
          of or related to the actual use of the Services in violation of this Agreement or
          applicable law and/or any breach by Client of any of its obligations under this Agreement
          and/or arising from personal injury or tangible property damage which is determined by a
          court to be caused by the negligence, violation of confidentiality provisions, or willful
          misconduct of the Client or its authorized employees relating to this Agreement.
        </Typography>

        <Typography className={classes.sectionPara}>
          b. <strong>BlokTrek Indemnity.</strong> Subject to the limitations in Section 11, BlokTrek
          will defend and indemnify Client against any third party claim with respect to a
          Deliverable that it infringes upon such third party’s rights under a copyright, trademark,
          or patent or misappropriates the subject matter of such third party's trade secret, under
          the laws of the country in which Client receives the Service (
          <strong>“IP Infringement Claim”</strong>) provided that: (i) Client promptly notifies
          BlokTrek in writing of an IP Infringement Claim such that BlokTrek is not materially
          prejudiced by any delay in such notification; (ii) BlokTrek has sole control of the
          defense and all related settlement negotiations; and (iii) Client provides BlokTrek with
          reasonable assistance in defending the IP Infringement Claim. Subject to the limitations
          in Section 11, BlokTrek will pay any damages, costs, and expenses finally awarded (or
          agreed to by settlement) for any such IP Infringement Claim. If Client desires separate
          legal representation in any IP Infringement Claim, Client will be responsible for the
          costs and fees of Client’s separate counsel.
        </Typography>

        <Typography className={classes.sectionPara}>
          c. Client agrees to indemnify BlokTrek against any direct liability, claim, proceeding,
          cost, expense (including the actual legal fees charged by BlokTrek’s solicitors) and loss
          of any kind arising from any actual claim by a third party that any Data infringes the
          rights of that third party (including Intellectual Property Rights and privacy rights) or
          that the Data is objectionable, incorrect, or misleading. BlokTrek agrees to indemnify the
          Client against any direct liability, claim, proceeding, cost, expense (including the
          actual legal fees charged by Client’s solicitors) and loss of any kind arising from any
          actual claim by a third party in the event any data used by BlokTrek for providing Other
          Services (excluding Data provided by Client) was not legally permitted to be used or was
          not in the public domain and such data infringes the rights of that third party (including
          Intellectual Property Rights and privacy rights) or that such data is objectionable,
          incorrect, or misleading.
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          i. <strong>Remedy.</strong> Should any Service provided by BlokTrek under this Agreement
          become, or in BlokTrek's opinion is likely to become, the subject of infringement of any
          copyright, patent, trademark, or misappropriation of any trade secret, BlokTrek’s sole
          obligation and Client’s exclusive remedy under this Section shall be, at BlokTrek’s option
          and expense, either to procure for Client the right to continue using the Service, to
          replace or modify the Service so that it becomes non-infringing, or to grant Client a
          refund of the amounts paid by Client.
        </Typography>

        <Typography className={classes.sectionPara} style={{ marginLeft: '1rem' }}>
          ii. <strong>Disclaimer.</strong> BlokTrek shall have no responsibility for infringement,
          including obligations of indemnification under this Section, to the extent the
          infringement results from (i) compliance with Client's designs or instructions, (ii) a
          modification not authorized in writing by BlokTrek, (iii) use or combination with
          third-party software, equipment, or data, (iv) non-licensed use, (v) third-party software
          provided under this Agreement, or (vi) open source technology incorporated in or provided
          with the Services.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>10. LIMITATION OF LIABILITY.</Typography>

        <Typography className={classes.sectionPara}>
          a. A PARTY’S AGGREGATE LIABILITY FOR ANY CLAIM OF A SIMILAR NATURE ARISING UNDER OR
          RELATING TO THIS AGREEMENT SHALL BE LIMITED TO DIRECT DAMAGES AND SHALL NOT EXCEED THE
          AMOUNT ACTUALLY PAID BY THE CLIENT DURING THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE
          DATE OF THE APPLICABLE CLAIM.
        </Typography>

        <Typography className={classes.sectionPara}>
          b. <strong>DISCLAIMER.</strong> A PARTY SHALL NOT BE LIABLE TO THE OTHER PARTY FOR ANY
          INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES, (INCLUDING LOSS OF PROFITS OR
          BUSINESS) ARISING UNDER OR RELATING TO THIS AGREEMENT, EVEN IF THE OTHER PARTY HAS BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </Typography>

        <Typography className={classes.sectionPara}>
          c. <strong>Limitation of Action.</strong> No action arising out of this Agreement,
          regardless of the form of action, may be brought by a Party against the other Party more
          than one year after the action accrued.
        </Typography>

        <Typography className={classes.sectionPara}>
          d. <strong>Nature of Services.</strong> The Services shall not be deemed as investment,
          legal, tax, accounting or other regulated advice. BlokTrek does not supplant Client’s
          management or other decision-making bodies and does not guarantee results. Client remains
          solely responsible for its decisions, actions, use of the Services and compliance with
          applicable laws, rules and regulations. Client agrees to pay for any costs, including
          attorney fees, BlokTrek incurs as a result of its participation as a non-party in any
          legal, regulatory, administrative or other proceeding relating to the Services.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>11. FORCE MAJEURE.</Typography>

        <Typography className={classes.sectionPara}>
          a. <strong>Notice upon occurrence.</strong> Upon the occurrence of any Force Majeure
          Event, the Affected Party shall, as soon as possible and not later than thirty (30) days
          after the occurrence of such an event, notify in writing the non-affected Party,
          specifying (i) the nature of the event, (ii) the effect of the event on the Affected
          Party’s performance, and (iii) the estimated duration of the event.
        </Typography>

        <Typography className={classes.sectionPara}>
          b. <strong>Notice upon cessation.</strong> The Affected Party shall notify the
          non-affected Party promptly (and in any case not later than three (3) days) upon the
          cessation of the Force Majeure Event.
        </Typography>

        <Typography className={classes.sectionPara}>
          c. <strong>Suspension of obligations.</strong> On the occurrence of a Force Majeure Event,
          the performance of the obligations under this Agreement affected by such event for the
          Affected Party shall be temporarily suspended. The performance of those obligations not
          affected by the Force Majeure Event shall continue.
        </Typography>

        <Typography className={classes.sectionPara}>
          d. <strong>Mitigation.</strong> The Affected Party shall make all commercially reasonable
          efforts to mitigate the effects of the Force Majeure Event on the performance of its
          obligations under this Agreement.
        </Typography>

        <Typography className={classes.sectionPara}>
          e. <strong>Joint decision.</strong> In case the Force Majeure Event continues for a period
          of more than thirty (30) days, the Parties shall jointly decide the future course of
          action and shall be entitled to either terminate this Agreement or mutually decide the
          further course of action.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>
          12. GOVERNING LAW, JURISDICTION & DISPUTE RESOLUTION.
        </Typography>

        <Typography className={classes.sectionPara}>
          a. <strong>Governing Law.</strong> This Agreement including Schedules, and any amendments
          or addenda in writing thereto, shall be governed by and construed in accordance with the
          Applicable Laws of India.
        </Typography>

        <Typography className={classes.sectionPara}>
          b. <strong>Jurisdiction.</strong> The parties hereby irrevocably and unconditionally
          submit to the sole and exclusive jurisdiction of the competent courts of Bengaluru, which
          shall try and entertain all disputes arising between the parties hereto.
        </Typography>

        <Typography className={classes.sectionPara}>
          c. <strong>Amicable Resolution.</strong> Any disputes, or differences which may arise out
          of, in relation to, or in connection with this Agreement or its interpretation,
          non-performance of obligations, or breach thereof (<strong>“Disputes”</strong>) shall, at
          the first instance, be resolved amicably through mutual discussion between the parties
          within a period of thirty (30) days of such Dispute.
        </Typography>

        <Typography className={classes.sectionPara}>
          d. <strong>Arbitration.</strong> In the event the parties fail to amicably resolve the
          Dispute within the aforesaid period, such Dispute may be referred for Institutional
          Arbitration to a sole arbitrator appointed by the Parties in the Bangalore International
          Mediation Arbitration and Conciliation Centre (BIMACC), under the BIMACC Rules of
          Arbitration. The award of the arbitrator shall be final and binding on the parties hereto,
          and the arbitration shall be conducted in accordance with the provisions of the
          Arbitration & Conciliation Act, 1996. The arbitration shall be conducted in the English
          language and the seat of arbitration shall be in Bengaluru.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography className={classes.sectionHeading}>13. GENERAL PROVISIONS.</Typography>

        <Typography className={classes.sectionPara}>
          a. <strong>Notice.</strong> Unless otherwise agreed to by the parties, all notices shall
          be deemed effective when made in writing and received by either (i) registered mail, (ii)
          certified mail, return receipt requested, or (iii) email, addressed and sent to the
          receiving party’s address specified in the introductory paragraph to this Agreement.
        </Typography>

        <Typography className={classes.sectionPara}>
          b. <strong>Independent Contractor.</strong> Nothing in this Agreement, and no course of
          dealing between the parties, shall be construed to create an employment or agency
          relationship or a partnership between a party and the other party or the other party’s
          employees or agents. Each party shall be solely responsible for payment of its employees’
          salaries (including withholding of income taxes and social security), workers
          compensation, and all other employment benefits.
        </Typography>

        <Typography className={classes.sectionPara}>
          c. <strong>Third Party Beneficiaries.</strong> Unless otherwise agreed to between the
          parties in writing, this Agreement is made for the sole benefit of the parties, and no
          other person shall have any rights or remedies by reason of this Agreement against any of
          the parties or shall be considered to be third-party beneficiaries of this Agreement in
          any way.
        </Typography>

        <Typography className={classes.sectionPara}>
          d. <strong>Cumulative Remedies.</strong> All rights and remedies of either party herein
          are cumulative of each other and of every other right or remedy such party may otherwise
          have at law or in equity, and the exercise of one or more rights or remedies shall not
          prejudice or impair the concurrent or subsequent exercise of other rights or remedies.
        </Typography>

        <Typography className={classes.sectionPara}>
          e. <strong>Severability.</strong> If any provision or portion of this Agreement shall be
          held by a court of competent jurisdiction to be illegal, invalid, or unenforceable, the
          remaining provisions or portions shall remain in full force and effect.
        </Typography>

        <Typography className={classes.sectionPara}>
          f. <strong>Waiver.</strong> No waiver of any Agreement right shall be effective unless in
          writing signed by an authorized representative of the waiving party. No waiver of a right
          arising from any breach or failure to perform shall be deemed a waiver of any future
          right.
        </Typography>

        <Typography className={classes.sectionPara}>
          g. <strong>Binding Effect/Assignment.</strong> This Agreement is binding upon the parties’
          respective representatives, successors, and assigns. Neither party shall transfer or
          assign this Agreement without the prior written consent of the other party.
          Notwithstanding the foregoing, BlokTrek may assign this Agreement in its entirety, with
          prior written notice to the Client, to its Affiliate or in connection with a merger,
          acquisition, corporate reorganization, or sale of all or substantially all of its assets.
        </Typography>

        <Typography className={classes.sectionPara}>
          h. <strong>Subcontracting Services.</strong> BlokTrek may subcontract any portion of the
          Services to a third-party contractor, provided written consent of Client is obtained
          within 3 (three) days from the subcontracting to any such party, to ensure that the terms
          of this Agreement are met. It is hereby clarified that BlokTrek would at all times be
          responsible for the acts and omissions of such subcontractors at all times.
        </Typography>

        <Typography className={classes.sectionPara}>
          i. <strong>Use of Client Name.</strong> BlokTrek may use and publish Client's name and
          logos in its client lists, lists of referrals for other clients (or potential clients),
          and in other promotional information, including, but not limited to, press releases,
          brochures, reports, letters, white papers, and electronic media such as e-mail or Web
          pages.
        </Typography>

        <Typography className={classes.sectionPara}>
          j. <strong>Entire Agreement.</strong> This Agreement is the entire agreement between the
          parties with respect to the subject matter and supersedes any prior agreement or
          communications between the parties relative thereto, whether written or oral. This
          Agreement may be modified only by a written addendum or change order signed by authorized
          signatories of both parties. The terms of any invoice, purchase order, or similar document
          will not modify this Agreement.
        </Typography>

        <Typography className={classes.sectionPara}>
          k. <strong>Signature, Counterparty, and Delivery.</strong> This Agreement may be signed
          electronically and may be signed in any number of counterparts (including counterparts by
          scanned or electronic signature), and each counterpart will be deemed an original; taken
          together, all counterparts will be deemed to constitute one and the same instrument.
          Delivery of a printed counterpart (whether or not the counterpart was signed
          electronically) or electronic delivery (including by email transmission or transmission
          over an electronic signature platform) of an executed counterpart of this Agreement is as
          valid, enforceable, and binding as if the signatures were upon the same instrument and
          delivered in person.
        </Typography>
      </Box>
    </Box>
  );
};

export default UserAgreementContent;
