import React from 'react';
import { useSelector } from 'react-redux';
import QueryMainFilter from './queryMainFilter';
import FilterMainFilter from './filterMainFilter';

const ScreenerMainSearch = () => {
  const toggleScreening = useSelector((state) => state.screenerReducer.toggleScreening);
  const selectedScreenerTab = useSelector((state) => state.screenerReducer.selectedScreenerTab);

  return (
    <>
      {toggleScreening &&
        (selectedScreenerTab === 'custom' ? (
          <QueryMainFilter />
        ) : selectedScreenerTab === 'filter' ? (
          <FilterMainFilter />
        ) : null)}
    </>
  );
};

export default ScreenerMainSearch;
