import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Paper, Typography } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// import CustomTextField from '../../../atoms/textfield';
import SecondaryButton from '../../../atoms/buttons/secondaryBtn';
// import DeleteButton from '../../../atoms/buttons/deleteBtn';
import CustomButton from '../../../atoms/buttons/button';
import ErrorToast from '../../../atoms/errorToast';
// import { Add, DeleteForever, Save } from '@material-ui/icons';
import { Add } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import TemplateSectionCard from './templateSectionCard';
import CustomDialog from './dialog';
import {
  setCurrentKpiReport,
  setOpenReportDialog
} from '../../../../redux/pages/portfolioTracker/action';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { validateReportData } from '../../../../utility/report_validation';
import { getReportSummaryByCin, saveKPIReport } from '../services';
import { setGlobalSuccessMessage } from '../../action';
import { setLoadingPortfolioTracker } from '../../../../redux/pages/portfolioTracker/action';
import { dispatchHideShowMessage } from '../../../../helpers';

const useStyles = makeStyles((theme) => ({
  tearSheet: {
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: '16px !important',
    padding: '16px',
    maxWidth: '90%', // Adjust percentage as needed
    margin: '0 auto' // Center the Paper if necessary
  },
  formLevelOne: {
    display: 'flex',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  keyDiv: {
    width: '20%',
    marginTop: '5px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  valueDiv: {
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      margin: '8px 0px',
      width: '100%'
    }
  },
  keyTypography: {
    fontSize: '16px',
    fontWeight: '550',
    overflowWrap: 'break-word'
  },
  sectionsDiv: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  sectionCardDiv: {
    width: '94%',
    margin: '8px auto',
    [theme.breakpoints.down('sm')]: {
      margin: '8px 0px',
      width: '100%'
    }
  },
  addSectionBtn: {
    margin: '8px 3%',
    [theme.breakpoints.down('sm')]: {
      margin: '8px 0'
    }
  },
  mainActionBtns: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: '3%'
  }
}));

// A static template with a static array for question groups (sections)

const MAX_AGE = 60 * 60 * 24 * 1000;

const StaticTearSheetTemplate = () => {
  const classes = useStyles();
  const timeoutRef = useRef(null);
  const { t } = useTranslation();
  const [sectionsCopied, setSectionsCopied] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [pasteEnabled, setPasteEnabled] = useState(false);

  const { openReportDialog, currentKpiReport, currentWatchlistCompany } = useSelector(
    (state) => state.portfolioTrackerReducer
  );

  const { company: currentCompany } = currentWatchlistCompany || {};

  // const [currentKpiReport, setCurrentKpiReport] = useState(staticTemplate);
  // const currentCompanyId = currentCompany?.cin;

  // useEffect(() => {
  //   if (currentCompanyId && currentCompanyId !== currentKpiReport.entity_list[0].entity_id) {
  //     setCurrentKpiReport((prevData) => ({
  //       ...prevData,
  //       entity_list: [{ ...prevData.entity_list[0], entity_id: currentCompanyId }]
  //     }));
  //   }
  // }, [currentCompanyId]);
  const dispatch = useDispatch();

  // Initialize the copied flags when template changes
  useEffect(() => {
    setSectionsCopied(Array(currentKpiReport.question_groups.length).fill(false));
  }, [currentKpiReport]);

  // Copy, Paste & LocalStorage cleanup functions
  const handleSectionCopy = (section, sectionIndex) => {
    localStorage.setItem(
      'section',
      JSON.stringify({
        timestamp: new Date().getTime(),
        data: { ...section }
      })
    );
    setSectionsCopied(() => {
      const updated = Array(currentKpiReport.question_groups.length).fill(false);
      updated[sectionIndex] = true;
      return updated;
    });
    cleanupLocalStorage('section');
    setTimeout(() => {
      setSectionsCopied(Array(currentKpiReport.question_groups.length).fill(false));
    }, 5000);
  };

  const handleSectionPaste = (sectionIndex) => {
    let copiedSection = localStorage.getItem('section');
    if (!copiedSection) return;
    copiedSection = JSON.parse(copiedSection);
    const now = new Date().getTime();
    if (now - copiedSection.timestamp > MAX_AGE) {
      localStorage.removeItem('section');
      return;
    }
    handleSectionChange(sectionIndex, copiedSection.data);
  };

  const cleanupLocalStorage = (key) => {
    const itemStr = localStorage.getItem(key);
    if (itemStr) {
      const item = JSON.parse(itemStr);
      const now = new Date().getTime();
      if (now - item.timestamp > MAX_AGE) {
        localStorage.removeItem(key);
      } else {
        setPasteEnabled(true);
      }
    }
  };

  useEffect(() => {
    cleanupLocalStorage('section');
    const interval = setInterval(() => {
      cleanupLocalStorage('section');
    }, 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  // Template change handlers
  const handleTemplateChange = (key, value) => {
    dispatch(setCurrentKpiReport({ ...currentKpiReport, [key]: value }));
  };

  const handleSectionChange = (index, value) => {
    const updatedSections = [...currentKpiReport.question_groups];
    updatedSections[index] = value;
    handleTemplateChange('question_groups', updatedSections);
  };

  const handleDeleteSection = (index) => {
    const updatedSections = [...currentKpiReport.question_groups];
    updatedSections.splice(index, 1);
    setSectionsCopied((prev) => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
    handleTemplateChange('question_groups', updatedSections);
  };

  const handleAddSection = () => {
    const defaultSection = {
      name: 'New Section',
      questions: [{ question: '', type: 'query', question_type: '' }]
    };

    const updatedSections = [...currentKpiReport.question_groups, defaultSection];
    setSectionsCopied((prev) => [...prev, false]);
    handleTemplateChange('question_groups', updatedSections);
  };

  const handleSectionsReorder = (result) => {
    if (!result.destination) return;
    const reorderedSections = Array.from(currentKpiReport.question_groups);
    const [removed] = reorderedSections.splice(result.source.index, 1);
    reorderedSections.splice(result.destination.index, 0, removed);

    const updatedCopied = Array.from(sectionsCopied);
    const [removedCopied] = updatedCopied.splice(result.source.index, 1);
    updatedCopied.splice(result.destination.index, 0, removedCopied);
    setSectionsCopied(updatedCopied);
    handleTemplateChange('question_groups', reorderedSections);
  };

  const handleQuestionsReorder = (sectionIndex, result) => {
    if (!result.destination) return;
    const reorderedQuestions = Array.from(currentKpiReport.question_groups[sectionIndex].questions);
    const [removed] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, removed);
    handleTemplateChange('question_groups', [
      ...currentKpiReport.question_groups.slice(0, sectionIndex),
      { ...currentKpiReport.question_groups[sectionIndex], questions: reorderedQuestions },
      ...currentKpiReport.question_groups.slice(sectionIndex + 1)
    ]);
  };

  const [errorObject, setErrorObject] = useState({});
  console.log(typeof setErrorMessage, typeof errorObject);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [currentCompany]);

  const generateReportData = ({ report_id, questionGroups, metaData, response }) => {
    return {
      report_id,
      report_name: 'KPI',
      entity_list: [
        {
          entity_type: 'company',
          entity_id: currentCompany?.cin
        }
      ],
      question_groups: questionGroups,
      metaData,
      response
    };
  };

  const isReportProgressOrQueue = (status) => {
    return status && (status === t('tearSheet.inProgress') || status === t('tearSheet.inQueue'));
  };

  const refreshReportData = async (data, isRecurrsive = false, prevCin = null) => {
    const report_id = data?.data?.report_id[0];
    const currentCompCin = prevCin ? prevCin : currentCompany?.cin;

    clearTimeout(timeoutRef.current);
    if (report_id) {
      const reportData = (await getReportSummaryByCin(report_id, currentCompany?.cin)) || {};
      const questionGroups = reportData?.data?.data?.question_groups || [];
      const metaData = reportData?.data?.data?.metadata || null;
      const current_report_id = metaData?.report_id || '';
      if (current_report_id) {
        const response = reportData?.data?.data.response || null;
        const status = metaData?.report_status;

        if (isReportProgressOrQueue(status)) {
          if (!isRecurrsive) {
            dispatch(
              setCurrentKpiReport(
                generateReportData({ report_id, questionGroups, metaData, response })
              )
            );
          }
          timeoutRef.current = setTimeout(() => {
            refreshReportData(data, true, currentCompCin);
          }, 10000);
        } else {
          dispatch(
            setCurrentKpiReport(
              generateReportData({ report_id, questionGroups, metaData, response })
            )
          );
        }
      }
    }
  };

  // useEffect(() => {
  //   const status = currentKpiReport?.metaData?.report_status;
  //   const report_id = currentKpiReport?.report_id;
  //   clearTimeout(timeoutRef.current);
  //   if (status && report_id && isReportProgressOrQueue(status)) {
  //     refreshReportData({
  //       data: {
  //         report_id: [report_id]
  //       }
  //     });
  //   }
  // }, [currentKpiReport]);

  const onSaveKPIReport = async () => {
    if (validateReportData(currentKpiReport, setErrorObject)) {
      try {
        dispatch(setOpenReportDialog(false));
        dispatch(setLoadingPortfolioTracker(true));
        const data = await saveKPIReport(currentKpiReport);
        console.log(typeof data);
        await refreshReportData(data);
        dispatchHideShowMessage(
          dispatch,
          `KPI report saved successfully`,
          setGlobalSuccessMessage,
          3000
        );
        dispatch(setLoadingPortfolioTracker(false));
      } catch (error) {
        dispatch(setLoadingPortfolioTracker(false));
      }
    }
  };

  return (
    <>
      {/* {JSON.stringify(currentKpiReport)} */}
      <CustomDialog
        title={
          <Typography
            style={{
              fontSize: '20px',
              fontWeight: 700,
              fontFamily: 'Satoshi'
              //paddingLeft: '20px'
            }}
          >
            {currentKpiReport.report_id ? 'Edit KPI' : 'Create KPI'}
          </Typography>
        }
        open={openReportDialog}
        save={() => {}}
        handleClose={() => {
          dispatch(setOpenReportDialog(false));
        }}
      >
        <Paper className={classes.tearSheet}>
          <ErrorToast errorMessage={errorMessage} />
          <div>
            {/* Template Name */}
            {/* <div style={{ paddingBottom: '20px', display: 'none' }} className={classes.formLevelOne}>
                        <div className={classes.keyDiv}>
                            <Typography className={classes.keyTypography}>Report Name</Typography>
                        </div>
                        <div className={classes.valueDiv}>
                            <CustomTextField
                            value={template.report_name}
                            handleChange={(value) => handleTemplateChange('report_name', value)}
                            placeholder={t('tearSheet.templateNamePlaceholder') || 'Enter template name'}
                            disabled={true}
                            />
                        </div>
                        </div> */}

            {/* Template Description */}
            {/* <div style={{ paddingBottom: '20px' }} className={classes.formLevelOne}>
            <div className={classes.keyDiv}>
                <Typography className={classes.keyTypography}>
                    {t('tearSheet.templateDescriptionLabel') || 'Template Description'}
                </Typography>
            </div>
            <div className={classes.valueDiv}>
                <CustomTextField
                    value={template.description}
                    handleChange={(value) => handleTemplateChange('description', value)}
                    placeholder={t('tearSheet.templateDescriptionPlaceholder') || 'Enter description'}
                />
            </div>
        </div> */}

            {/* Sections Header */}
            {/* <div className={classes.formLevelOne}>
              <div className={classes.keyDiv}>
                <Typography className={classes.keyTypography}>Sections</Typography>
              </div>
            </div> */}

            {/* Drag & Drop Sections */}
            <div className={classes.sectionsDiv}>
              <DragDropContext onDragEnd={handleSectionsReorder}>
                <Droppable droppableId="sections">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {currentKpiReport.question_groups.map((section, index) => (
                        <Draggable
                          key={`section-${index}`}
                          draggableId={`section-${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={classes.sectionCardDiv}
                              style={{ ...provided.draggableProps.style }}
                            >
                              <TemplateSectionCard
                                sectionIndex={index}
                                section={section}
                                handleSectionChange={(value) => handleSectionChange(index, value)}
                                handleDeleteSection={() => handleDeleteSection(index)}
                                copied={sectionsCopied[index]}
                                handleSectionCopy={() => handleSectionCopy(section, index)}
                                handleSectionPaste={() => handleSectionPaste(index)}
                                dragHandleProps={provided.dragHandleProps}
                                pasteEnabled={pasteEnabled}
                                copyEnabled={true}
                                errorObject={errorObject}
                                currentKpiReport={currentKpiReport}
                                index={index}
                                onQuestionsReorder={handleQuestionsReorder}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            {/* Add Section Button */}
            <div className={classes.addSectionBtn}>
              <SecondaryButton
                StartIcon={Add}
                btnText={t('tearSheet.addSection') || 'Add Section'}
                handleClick={handleAddSection}
              />
            </div>

            {/* Main Action Buttons */}
            <div className={classes.mainActionBtns}>
              <div style={{ width: '15%' }}>
                <CustomButton
                  btnText={`Generate KPI`}
                  // StartIcon={Save}
                  handleClick={onSaveKPIReport}
                />
              </div>
            </div>
          </div>
        </Paper>
      </CustomDialog>
    </>
  );
};

export default StaticTearSheetTemplate;
