import { postApi, getApi } from '../../../services/token';
import { setScreenerData } from '../../../redux/pages/screener/action';
import { setTableDataErrorMessage } from '../../../redux/pages/screener/action';

export const getScreenrTableData =
  (dispatch, screenerData, selectedTabData, selectedTabDataIndex) => async (body, params) => {
    const timeout = 300000;
    const timeoutErrorMessage = 'Request timed out. Please try again later.';

    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => {
        reject(new Error(timeoutErrorMessage));
      }, timeout);
    });

    try {
      let updatedTabData = { ...selectedTabData };
      updatedTabData = {
        ...updatedTabData,
        isLoading: true
      };
      const updatedScreenerData = screenerData.map((item, i) =>
        i === selectedTabDataIndex ? updatedTabData : item
      );

      dispatch(setScreenerData(updatedScreenerData));

      const screenrTableData = await Promise.race([
        postApi(
          `${process.env.REACT_APP_API_BASE_LB}/server/screener/query/response${params}`,
          body
        ),
        timeoutPromise
      ]);

      return screenrTableData;
    } catch (error) {
      const errorMessage =
        error.message === timeoutErrorMessage
          ? timeoutErrorMessage
          : 'Something went wrong. Please try again later.';

      let updatedTabData = { ...selectedTabData };
      updatedTabData = {
        ...updatedTabData,
        isLoading: false,
        tableData: []
      };
      const updatedScreenerData = screenerData.map((item, i) =>
        i === selectedTabDataIndex ? updatedTabData : item
      );

      dispatch(setScreenerData(updatedScreenerData));

      dispatch(setScreenerData(updatedScreenerData));
      dispatch(setTableDataErrorMessage(errorMessage));
      setTimeout(() => {
        dispatch(setTableDataErrorMessage(''));
      }, 3000);
      console.error(error);
    }
  };

export const getFilterConfig = async (tab) => {
  const companyUrl = process.env.REACT_APP_COMPANY_API;
  try {
    const res = await getApi(`${companyUrl}/v1/screener/filter?entity_type=${tab}`);
    const filterdata = res.data.data.filter;
    const filterDataConfig = {
      filters: {
        ...filterdata
      },
      selectedFilters: {}
    };
    return { ...filterDataConfig };
  } catch (err) {
    console.log(err);
    return null;
  }
};
