import { getApi } from '../../../services/token';
import { useEffect, useState } from 'react';
import AutocompleteComponent from '../../atoms/multiSearchableAutocomplete';
import { useDebounce } from '../../../hooks/useDebounce';
import { createKeyForSearch } from '../../screener/helper';
import { useSelector, useDispatch } from 'react-redux';
import { setCompanSearchData } from '../../../redux/pages/common/company/action';
import { Typography } from '@material-ui/core';

const COMPANY_API_URL = process.env.REACT_APP_COMPANY_API;

const isDefaultOption = (option) => {
  return (
    option.entity_type === 'No options' &&
    option.entity_name === 'No options' &&
    option.entity_id === 'custom_entity_id' &&
    option.logo === ''
  );
};

const SearchCompany = ({
  setLoading,
  setCompanySelectedContext,
  classes,
  handleExploreCompaniesClick,
  handleQuestionClickDashbaord
}) => {
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItem] = useState(null);
  const isSingleSelect = true;
  //   const [isModified, setIsModified] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const debouncedInputValue = useDebounce(inputValue, 300);
  const [isFetchDataLoading, setIsFetchDataLoading] = useState(false);

  const storeOptions = useSelector((state) => state.companyReducer.data);

  useEffect(() => {
    if (selectedItems) {
      const { entity_id: cin, entity_name: name } = selectedItems;
      setCompanySelectedContext({
        cin,
        name
      });
    }
  }, [selectedItems]);

  const fetchData = async (searchText, pageNumber = 1) => {
    try {
      setLoading(true);
      setIsFetchDataLoading(true);
      const queryParams = new URLSearchParams({
        entity_types: 'company',
        page_size: 500,
        page_number: pageNumber,
        search_text: searchText || ''
      });

      const currentKey = createKeyForSearch(queryParams);

      let newOptions = [];
      let totalRecords = 0;

      const storeOptionsData = storeOptions[currentKey];

      if (storeOptionsData) {
        newOptions = storeOptionsData.newOptions || [];
        totalRecords = storeOptionsData.total_records || 0;
      } else {
        const response = await getApi(
          `${COMPANY_API_URL}/v1/entity/search?${queryParams.toString()}`
        );

        newOptions = response.data?.data?.items || [];
        totalRecords = response.data?.data?.metadata?.total_records || 0;
        dispatch(
          setCompanSearchData({
            key: currentKey,
            data: {
              newOptions,
              total_records: totalRecords
            }
          })
        );
      }

      setTimeout(() => {
        if (pageNumber === 1) {
          setData(newOptions);
        } else {
          setData((prev) => [...prev, ...newOptions]);
        }
        const newData = pageNumber === 1 ? newOptions : [...data, ...newOptions];
        setHasMore(newData.length < totalRecords);
        setIsFetchDataLoading(false);
        setLoading(false);
      }, 0);
    } catch (error) {
      setIsFetchDataLoading(false);
      setLoading(false);
      console.error('Fetching data failed', error);
    }
  };

  const onInputChange = (event, newValue) => {
    console.log(typeof newValue, typeof event);
  };

  // Fetch data when user types in the input field
  useEffect(() => {
    fetchData(debouncedInputValue, page);
  }, [debouncedInputValue, page]);

  useEffect(() => {
    if (inputValue !== '') {
      setPage(1);
      setData([]);
    }
  }, [debouncedInputValue]);

  const onSelectedItems = async (selectedOption) => {
    // setIsModified(false);

    if (selectedOption && isDefaultOption(selectedOption)) {
      return;
    }

    setSelectedItem(selectedOption);
  };

  const defaultOptionJsx = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <div className={classes.flexContainer}>
          <Typography className={classes.optionTypo}>What are you looking for?</Typography>
          <div className={classes.exploreCompanyWrapper}>
            <Typography
              className={classes.exploreCompanyText}
              onMouseDown={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleExploreCompaniesClick(event);
              }}
            >
              Explore Companies
            </Typography>
          </div>
        </div>
        <div>
          <Typography
            className={classes.optionTypo}
            onMouseDown={(event) => {
              event.preventDefault();
              event.stopPropagation();
              handleQuestionClickDashbaord(event, inputValue);
            }}
          >
            <strong>Ask Byz - </strong>"{inputValue}"
          </Typography>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div
        onKeyDown={(e) => {
          if (
            process.env.REACT_APP_ENABLE_ENTER_DASHBOARD === 'true' &&
            e.key === 'Enter' &&
            Array.isArray(data) &&
            data.length > 0 &&
            data[0].entity_name.toLowerCase() === debouncedInputValue.toLowerCase()
          ) {
            const enteredCompany = {
              entity_id: data[0].entity_id,
              entity_name: data[0].entity_name
            };
            setSelectedItem(enteredCompany);
            document.querySelector('.MuiAutocomplete-popupIndicator')?.click();
            setTimeout(() => {
              setSelectedItem(null);
            }, 1000);
          } else if (
            process.env.REACT_APP_ENABLE_ENTER_DASHBOARD === 'true' &&
            e.key === 'Enter' &&
            debouncedInputValue &&
            //data.length === 0 &&
            !isFetchDataLoading
          ) {
            handleQuestionClickDashbaord(e, inputValue);
          }
        }}
      >
        <AutocompleteComponent
          data={data}
          hasMore={hasMore}
          setInputValue={setInputValue}
          onInputChange={onInputChange}
          page={page}
          setPage={setPage}
          selectedItems={selectedItems}
          setSelectedItems={onSelectedItems}
          property={{ key: 'entity_id', value: 'entity_name', type: 'entity_type' }}
          isSingleSelect={isSingleSelect}
          label=""
          placeholder="Search for a company or prompt Ask Byz"
          disabled={false}
          defaultOptionJsx={defaultOptionJsx}
          noOptionsText={defaultOptionJsx}
        />
      </div>
    </>
  );
};

export default SearchCompany;
