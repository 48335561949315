import { SETCOMPANYSEARCHDATA } from '../../../action.types';

const INITIAL_STATE = {
  data: {}
};

const companyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETCOMPANYSEARCHDATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.data.key]: action.data.data
        }
      };

    default:
      return state;
  }
};

export default companyReducer;
