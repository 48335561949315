import { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  autoCompletRoot: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input': {
      height: '7px !important'
    }
  }
}));

const SearchTextComp = ({ className = '', selectedValues, handleChange = () => {} }) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (selectedValues.length === 0) {
      setInputValue('');
    }
  }, [selectedValues]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
    }
  };

  return (
    <>
      <form noValidate className={className}>
        <TextField
          classes={{ root: classes.autoCompletRoot }} // Apply styles correctly
          placeholder="Search Documents"
          variant="outlined"
          value={inputValue}
          onChange={(event) => {
            setInputValue(event.target.value);
            handleChange(event, event.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
      </form>
    </>
  );
};

export default SearchTextComp;
