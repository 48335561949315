import {
  SETINTIALSTATEFOLDERDC,
  SETFOLDERDATADC,
  SETDOCEXPLORERLOADINGDC,
  SETGLOBALLOADINGDC,
  SETSELECTEDDOCCOMPARE,
  SETALLMESSAGESDCOM,
  SETLOADINGDCOM,
  SETLOADINGANSWERSTREAMDCOM,
  SETSTOPRESPONSEDCOM,
  SETCHATSCROLLDCOM,
  SETCHATSEARCHINPUTDCOM
} from '../../action.types';

export const setIntialStateFolderDc = (data) => {
  return {
    type: SETINTIALSTATEFOLDERDC,
    data
  };
};

export const setFolderDataDc = (data) => {
  return {
    type: SETFOLDERDATADC,
    data
  };
};

export const setDocexplorerLoadingDc = (data) => {
  return {
    type: SETDOCEXPLORERLOADINGDC,
    data
  };
};

export const setGlobalLoadingDc = (data) => {
  return {
    type: SETGLOBALLOADINGDC,
    data
  };
};

export const setSelectedDocCompare = (data) => {
  return {
    type: SETSELECTEDDOCCOMPARE,
    data
  };
};

export const setAllMessagesDcom = (data) => {
  return {
    type: SETALLMESSAGESDCOM,
    data
  };
};

export const setLoadingDCOM = (data) => {
  return {
    type: SETLOADINGDCOM,
    data
  };
};

export const setLoadingAnswerStreamDCOM = (data) => {
  return {
    type: SETLOADINGANSWERSTREAMDCOM,
    data
  };
};

export const setStopResponseDCOM = (data) => {
  return {
    type: SETSTOPRESPONSEDCOM,
    data
  };
};

export const setChatScrollDCOM = (data) => {
  return {
    type: SETCHATSCROLLDCOM,
    data
  };
};

export const setChatSearchInputDCOM = (data) => {
  return {
    type: SETCHATSEARCHINPUTDCOM,
    data
  };
};
