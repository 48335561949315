import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import CompanyThemeSearch from './companyThemeSearch';
import CompanyThemeSearchV1 from './companyThemeSearch-v1';
import ReportDropDown from './reportDropDown';
import { getApi } from '../../../../services/token';
import CustomButton from '../../../atoms/buttons/button';
import { hideShowMessage } from '../../../../helpers';
import { useTranslation } from 'react-i18next';
import ThemeDialog from '../theme/themeDialog';
import DeleteTheme from '../theme/deleteTheme';

const enableV1Search = process.env.REACT_APP_ENABLE_CUSTOM_REPORT_V1_SEARCH === 'true';

const useStyles = makeStyles((theme) => ({
  btnWidth: {
    // width: '46.9%!important',
    // height: '96%',
    height: '48px',
    width: '150px',
    [theme.breakpoints.down('md')]: {
      width: '120px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '60px'
    },
    borderRadius: '4px',
    transition: 'opacity 0.3s',
    margin: '0',
    background:
      'var(--Gradients-Gradient4, linear-gradient(270deg, #CDF1F3 0%, #CDDBFF 100%))!important',
    '& p:first-child': {
      color: 'var(--Neutral-Colors-Color-800, var(--grey7, #171717))',
      fontFamily: 'Satoshi',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal'
    }
  },
  '@media print': {
    btnWidth: {
      visibility: 'hidden'
    }
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  select: {
    '& .MuiSelect-icon': {
      fontSize: '35px'
    },
    height: '48px'
  },
  inputLabel: {
    lineHeight: '1 !important',
    overflow: 'visible !important'
  },
  templateSelectForm: {
    height: '48px',
    width: '340px',
    [theme.breakpoints.down('md')]: {
      margin: '0px !important',
      width: '280px'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px !important',
      width: '108px'
    }
  },
  dialogTitle: {
    fontFamily: 'Satoshi !important',
    fontWeight: '700 !important',
    fontSize: '20px !important',
    margin: '0 !important',
    padding: '0 !important',
    lineHeight: '1.2 !important',
    color: '#171717 !important'
  }
}));
const TearSheetSearchBar = ({ tearSheetSearchBarProps }) => {
  const {
    location,
    navigate,
    updateParamsInURL,
    searchParams,
    onSelectReportId,
    COMPANY_API_URL,
    resetSummary,
    loading,
    setLoading,
    setErrorMessage,
    setSuccessMessage,
    selectedFilter,
    getLimits
  } = tearSheetSearchBarProps;

  const { t } = useTranslation();

  const queryParamName = 'search';
  const report_success = searchParams.get('success');

  const query_search = searchParams.get(queryParamName);
  const entity_type = searchParams.get('entity_type') || 'company';
  const query_report_id = searchParams.get('report_id');
  const query_definition_type = searchParams.get('definition_type');

  const [selectedItem, setSelectedItem] = useState(null);

  const [activeEntityType, setActiveEntityType] = useState('company');

  const [openThemeDialog, setOpenThemeDialog] = useState(false);
  const [closeCompThemeSearch, setCloseCompThemeSearch] = useState(false);

  const [themeData, setThemeData] = useState(null);

  const onReportSucess = async () => {
    await getLimits();
    if (report_success) {
      if (
        report_success === 'create' ||
        report_success === 'edit' ||
        report_success === 'template-library'
      ) {
        hideShowMessage(t('tearSheet.InProg'), setSuccessMessage);
      } else if (report_success === 'report-deleted') {
        hideShowMessage(t('tearSheet.reportDeleted'), setSuccessMessage);
      } else if (report_success === 'theme-deleted') {
        hideShowMessage(t('tearSheet.themeDeleted'), setSuccessMessage);
      }
      updateParamsInURL({
        navigate,
        location,
        removeParams: ['success']
      });
    }
  };

  useEffect(() => {
    onReportSucess();
  }, [report_success]);

  useEffect(() => {
    if (selectedFilter) setActiveEntityType(selectedFilter);
  }, [selectedFilter]);

  const [reportData, setReportData] = useState([]);
  const [reportId, setReportId] = useState(query_report_id || '');

  // redux s
  useEffect(() => {
    if (query_report_id && query_report_id != reportId) {
      setReportId(query_report_id);
    }
  }, [query_report_id]);
  // redux e

  const handleReportChange = (e) => {
    const report_id = e.target.value;
    setReportId(report_id);

    if (report_id) {
      updateParamsInURL({
        navigate,
        location,
        addParams: { report_id: report_id }
      });
    }
  };

  useEffect(() => {
    if (selectedItem && reportId) {
      onSelectReportId(selectedItem, reportId);
    } else {
      resetSummary();
    }
  }, [selectedItem, reportId]);

  const onTemplateLib = () => {
    if (selectedItem?.entity_id)
      updateParamsInURL({
        navigate,
        location,
        addParams: { entity_type, entity_id: selectedItem?.entity_id, action: 'template-library' }
      });
  };

  const getReports = async (selectedCompany) => {
    if (selectedCompany?.entity_id) {
      try {
        setLoading(true);
        const reportData = await getApi(
          `${COMPANY_API_URL}/v2/report?entity_type=${entity_type}&entity_id=${selectedCompany.entity_id}`
        );
        setReportData(reportData.data.data || []);
      } catch (error) {
        hideShowMessage(
          'Error occurred while retrieving options for the report. Please try again.',
          setErrorMessage
        );
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!query_report_id) setReportId('');

    if (!(selectedItem && query_search)) {
      setReportData([]);
    }
  }, [query_search, selectedItem, query_report_id]);

  const classes = useStyles();

  const handleOpenThemeDialog = () => {
    setOpenThemeDialog(true);
  };

  const handleCloseThemeDialog = () => {
    setOpenThemeDialog(false);
    setCloseCompThemeSearch(true);
    setThemeData(null);
  };

  const getSimilarEntities = async (search_text, activeEntityType) => {
    try {
      if (activeEntityType === 'theme' && search_text) {
        const queryParams = new URLSearchParams({
          theme_phrase: search_text
        });
        const { data } = await getApi(
          `${COMPANY_API_URL}/v1/fts/similarity-theme-search?${queryParams.toString()}`
        );
        return data?.data || [];
      }
      return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const onEditTheme = async (opt) => {
    setLoading(true);
    try {
      const { data } = await getApi(`${COMPANY_API_URL}/v1/fts/sub-theme/${opt.entity_id}`);
      const themeData = data?.data;
      setThemeData(themeData);
      handleOpenThemeDialog();
      setLoading(false);
    } catch (error) {
      hideShowMessage(
        'Error occurred while retrieving theme data. Please try again.',
        setErrorMessage
      );
      setLoading(false);
    }
  };

  const [openThemeDelete, setOpenThemeDelete] = useState(false);
  const [currentThemeData, setCurrentThemeData] = useState(null);

  const onDeleteTheme = (opt) => {
    setCurrentThemeData(opt);
    setOpenThemeDelete(true);
  };

  const handleThemeDeleteClose = () => {
    setOpenThemeDelete(false);
    setCurrentThemeData(null);
    setCloseCompThemeSearch(true);
  };

  return (
    <>
      {openThemeDelete && (
        <DeleteTheme
          themeName={currentThemeData?.entity_name || ''}
          open={openThemeDelete}
          handleClose={handleThemeDeleteClose}
          entityType={currentThemeData?.entity_type || ''}
          themeId={currentThemeData?.entity_id || ''}
          setErrorMessage={setErrorMessage}
          setLoading={setLoading}
        />
      )}
      {openThemeDialog && (
        <ThemeDialog
          title={
            <Typography className={classes.dialogTitle}>
              {themeData ? 'Edit theme' : 'Add New Theme'}
            </Typography>
          }
          open={openThemeDialog}
          handleClose={handleCloseThemeDialog}
          btnText={themeData ? 'Update theme' : 'Add Theme'}
          getSimilarEntities={getSimilarEntities}
          navigate={navigate}
          location={location}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          themeData={themeData}
          setThemeData={setThemeData}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xs={3.5}>
          {enableV1Search ? (
            <CompanyThemeSearchV1
              {...tearSheetSearchBarProps}
              getReports={getReports}
              COMPANY_API_URL={COMPANY_API_URL}
              queryParamName={queryParamName}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              activeEntityType={activeEntityType}
              query_definition_type={query_definition_type}
              setOpenThemeDialog={setOpenThemeDialog}
              getSimilarEntities={getSimilarEntities}
              onEditTheme={onEditTheme}
              onDeleteTheme={onDeleteTheme}
              closeCompThemeSearch={closeCompThemeSearch}
              setCloseCompThemeSearch={setCloseCompThemeSearch}
            />
          ) : (
            <CompanyThemeSearch
              {...tearSheetSearchBarProps}
              getReports={getReports}
              COMPANY_API_URL={COMPANY_API_URL}
              queryParamName={queryParamName}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              activeEntityType={activeEntityType}
              query_definition_type={query_definition_type}
              setOpenThemeDialog={setOpenThemeDialog}
              getSimilarEntities={getSimilarEntities}
              onEditTheme={onEditTheme}
              onDeleteTheme={onDeleteTheme}
              closeCompThemeSearch={closeCompThemeSearch}
              setCloseCompThemeSearch={setCloseCompThemeSearch}
            />
          )}
        </Grid>
        <Grid item xs={4.5}>
          <ReportDropDown
            {...tearSheetSearchBarProps}
            reportData={reportData}
            setReportId={setReportId}
            reportId={reportId}
            handleReportChange={handleReportChange}
            selectedItem={selectedItem}
            getReports={getReports}
            query_report_id={query_report_id}
            styleClass={`${classes.select} ${classes.inputLabel} ${classes.templateSelectForm}`}
          />
        </Grid>
        <Grid item xs={2}>
          <CustomButton
            btnText={`Template Library`}
            handleClick={selectedItem?.entity_id ? onTemplateLib : undefined}
            styleClass={classes.btnWidth + ` ${!selectedItem?.entity_id ? classes.disabled : ''}`}
          />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </>
  );
};

export default TearSheetSearchBar;
