import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { postApi, putApi, getApi } from '../../services/token';
import { processNameFunc } from '../shared/utils/usernameFunc';
import AutocompleteComponent from '../atoms/multiSearchableAutocomplete';
import SearchComp from '../atoms/searchComp';
import { makeStyles } from '@material-ui/core/styles';
import LoadingPopover from '../shared/loadingPopover/loadingPopover';
import ErrorToast from '../atoms/errorToast';
import SuccessToast from '../atoms/successToast';
import { Button, Grid } from '@mui/material';
import { Menu, Skeleton } from '@mui/material';
// import CustomChip from '../atoms/chip';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as FileUploadIcon } from '../../components/icons/FileUploadIcon.svg';
import { ReactComponent as FileRemoveIcon } from '../../components/icons/FileRemoveIcon.svg';
import { ReactComponent as LinkIcon } from '../../components/icons/Link.svg';
import { ReactComponent as SortDirectionDown } from '../../components/icons/SortDirectionDown.svg';
import { ReactComponent as SortDirectionUp } from '../../components/icons/SortDirectionUp.svg';
import { ReactComponent as GmailAdd } from '../../components/icons/GmailAdd.svg';
import { ReactComponent as OutlookAdd } from '../../components/icons/OutlookAdd.svg';
import { ReactComponent as OutlookIcon } from '../../components/icons/OutlookIcon.svg';
import { ReactComponent as GmailIcon } from '../../components/icons/GmailIcon.svg';
import { v4 as uuidv4 } from 'uuid';
import Checkbox from '@mui/material/Checkbox';
import { Tooltip } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomSelect from '../atoms/select';
import BoxContentExplorer from './box';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@material-ui/core/Box';
import getGDriveToken from '../../helpers/service/googleDriveUpload';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-input::placeholder': {
      color: '#a1a1a1',
      opacity: 1
    }
  },
  autoComplete: {
    width: '20%',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      margin: '15px 0 23px 0'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '40%'
    },
    float: 'right'
  },
  uploadText: {
    fontFamily: 'Satoshi',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'center'
  },
  uploadHeadderFiles: {
    fontFamily: 'Satoshi',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'left'
  },
  uploadFilesName: {
    fontFamily: 'Satoshi',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    textAlign: 'left',
    wordBreak: 'break-all',
    color: '#171717',
    paddingTop: '16px'
  },
  uploadHeadderTags: {
    fontFamily: 'Satoshi',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'center'
  },
  uploadHeadderDelete: {
    fontFamily: 'Satoshi',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'right'
  },
  uploadCheckBoxTest: {
    fontFamily: 'Satoshi',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '18.9px',
    textAlign: 'left'
  },
  uploadButton: {
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    height: '41px',
    borderRadius: '4px',
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: '40px',
    paddingRight: '40px'
  },
  cancleButton: {
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    height: '41px',
    borderRadius: '4px',
    background: 'linear-gradient(to right, #797F8D, #797F8D)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: '40px',
    paddingRight: '40px',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: '#FFFFFF',
      zIndex: -1,
      transition: '200ms'
    }
  },
  fileGrid: {
    padding: '60px 0px 0px 0px'
  },
  uploadBox: {
    height: '160px'
  },
  removeFile: {
    fontFamily: 'Satoshi',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'right',
    padding: '16px 5px 5px 0px'
  },
  uploadHeadderTagsMain: {
    fontFamily: 'Satoshi',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '32.4px',
    textAlign: 'center',
    padding: '16px 5px 5px 0px'
  },
  boxPopoverTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '18px !important',
    fontWeight: '500 !important',
    color: '#000000B2 !important'
  },
  linkEmailBtn: {
    marginLeft: 'auto',
    position: 'relative',
    height: '36px',
    width: '150px',
    zIndex: 1,
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    textTransform: 'none !important',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1.5px',
      right: '1.5px',
      top: '1.5px',
      bottom: '1.5px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  linkEmailBtnTxt: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    fontWeight: '700 !important',
    color: '#171717 !important',
    padding: '0px 10px',
    gap: '6px'
  },
  linkedEmail: {
    fontFamily: 'Satoshi',
    color: '#373737',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18.9px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px'
  },
  linkedEmailStop: {
    fontFamily: 'Satoshi',
    color: '#004CFF',
    fontSize: '12px',
    fontWeight: '700',
    cursor: 'not-allowed',
    opacity: '0.5'
  }
}));

const UploadFile = ({ propsData, hasMore, setPage, setInputValue, page }) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOrg, setIsLoadingOrg] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [org, setOrg] = useState(null);
  const [openBoxState, setOpenBoxState] = useState(false);
  const [boxToken, setBoxToken] = useState(null);
  const [vendorUserId, setVendorUserId] = useState(null);
  const [linkedEmail, setLinkedEmail] = useState([]);
  const [isLoadingEmails, setIsLoadingEmails] = useState(false);
  const folderId = '0';
  const ingestionUrl = process.env.REACT_APP_INGESTION_API_URL;
  const location = useLocation();

  const classes = useStyles();
  const url = process.env.REACT_APP_INGESTION_API_URL;
  const { allCompanies, allSectors } = propsData;
  const baseUrl = process.env.REACT_APP_API_BASE;
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const successTimer = setTimeout(() => {
      setSuccessMessage(null);
    }, 7000);

    const errorTimer = setTimeout(() => {
      setErrorMessage(null);
    }, 7000);

    return () => {
      clearTimeout(successTimer);
      clearTimeout(errorTimer);
    };
  }, [successMessage, errorMessage]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('openBox') === 'true') {
      //handleBoxOpenPopup();
      params.delete('openBox');
      const newUrl = params.toString()
        ? `${location.pathname}?${params.toString()}`
        : location.pathname;
      window.history.replaceState(null, '', newUrl);
    } else if (params.get('openSync') === 'true') {
      setSuccessMessage('Link email commpleted');
      params.delete('openSync');
      const newUrl = params.toString()
        ? `${location.pathname}?${params.toString()}`
        : location.pathname;
      window.history.replaceState(null, '', newUrl);
    }
  }, [location]);

  const handleRemoveFile = (uuid) => {
    const updatedFiles = files.filter((file) => file.uuid !== uuid);
    setFiles(updatedFiles);
  };

  const getLinkedEmails = async () => {
    setIsLoadingEmails(true);
    try {
      const response = await getApi(
        `${process.env.REACT_APP_INGESTION_API_URL}/v1/supplier-emails`
      );
      setLinkedEmail(response.data.data);
      // setLinkedEmail({
      //   gmail: {
      //     supplier: [{ email: 'email17654345678976543.com' }, { email: 'email2@random.com' }],
      //     distributor: [{ email: 'distributor1@acmecorp.com' }]
      //   },
      //   outlook: {
      //     supplier: [{ email: 'email3@xyz.com' }, { email: 'email3@xyz.com' }],
      //     distributor: [{ email: 'email3@xyz.com' }]
      //   }
      // });
    } catch (error) {
      console.error('Error fetching linked emails:', error);
    } finally {
      setIsLoadingEmails(false);
    }
  };

  useEffect(() => {
    const getOrg = async () => {
      setIsLoadingOrg(true);
      const response = await getApi(`${process.env.REACT_APP_API}/v1/user`);
      const { org } = processNameFunc(response);
      setOrg(org);
      setIsLoadingOrg(false);
    };
    getOrg();
    getLinkedEmails();
  }, []);

  const handleCheckboxChange = (uuid, associationType) => {
    const updatedFiles = files.map((file) => {
      if (file.uuid === uuid) {
        const updatedFile = { ...file };
        const index = updatedFile.association_types.indexOf(associationType);
        if (index !== -1) {
          updatedFile.association_types.splice(index, 1);
        } else {
          updatedFile.association_types[0] = associationType;
        }

        return updatedFile;
      }
      return file;
    });
    setFiles(updatedFiles);
  };

  const handleUpload = async () => {
    try {
      setIsLoading(true);
      const payload = createPayload();

      const response = await postApi(`${url}/v1/document/ingestion`, payload);

      const uploadUrls = response.data.data.upload_urls;

      for (const filename in uploadUrls) {
        const uploadKey = uploadUrls[filename].upload_key;

        const fileIndex = files.findIndex((file) => file.name === filename);
        const uploadPromises = [];
        if (fileIndex !== -1) {
          const file = files[fileIndex].file;
          const formData = new FormData();
          formData.append('file', file); // Use a fixed key ('file')

          const uploadPromise = putApi(uploadKey, file, {
            headers: {
              skipAuth: 'true',
              'Content-Type': file.type // Explicit MIME type
            }
          }).catch(() => {
            setErrorMessage((prev) => `${prev}Error uploading file ${filename}.\n`);
          });

          uploadPromises.push(uploadPromise);
        }
      }
      navigate('/document-upload/manage');
      setSuccessMessage('Files uploaded successfully.');
      setIsLoading(false); // Hide loader
      setFiles([]);
    } catch (error) {
      setErrorMessage(error.response.data.error.detail);
      setIsLoading(false); // Hide loader
    }
  };

  const createPayload = () => {
    const payload = {
      upload_type: 'file',
      file_details: files.map((file) => {
        let association_types = file.association_types;
        let size = file.size;
        const source_link = file.name;
        let association_details = {};
        // const document_type = file.document_type;
        const document_type = 'unclassified';
        if (file.association_types.includes('webLinks')) {
          association_types = ['no_association_type'];
        } else {
          if (
            file.sectorSelectedValues &&
            file.sectorSelectedValues.length > 0 &&
            file.association_types.includes('themes')
          ) {
            association_details['themes_list'] = file.sectorSelectedValues.map(
              (sector) => sector.key
            );
          }

          if (
            file.companySelectedValues &&
            file.companySelectedValues.length > 0 &&
            file.association_types.includes('company')
          ) {
            association_details['cin_list'] = file.companySelectedValues.map(
              (company) => company.key
            );
          }
        }
        return {
          association_types,
          size,
          source_link,
          association_details,
          document_type
        };
      })
    };
    return payload;
  };

  const validateFileTags = () => {
    const missingDocumentTypeFiles = files.filter((file) => !file.document_type);
    const invalidTagAssociationFiles = files.filter((file) => {
      if (file.association_types.includes('miscellaneous') || file.document_type === 'webLinks') {
        return false;
      } else if (file.association_types.includes('company')) {
        return file.companySelectedValues.length === 0;
      } else if (file.association_types.includes('themes')) {
        return file.sectorSelectedValues.length === 0;
      }
      return true;
    });

    if (missingDocumentTypeFiles.length === 0 && invalidTagAssociationFiles.length === 0) {
      handleUpload();
    } else {
      let errorMessage = '';
      if (missingDocumentTypeFiles.length > 0) {
        const missingDocumentTypeFileNames = missingDocumentTypeFiles.map((file) => file.name);
        errorMessage += `Please associate file type to ${missingDocumentTypeFileNames.join(
          ', '
        )}. `;
      } else if (invalidTagAssociationFiles.length > 0) {
        const invalidTagAssociationFileNames = invalidTagAssociationFiles.map((file) => file.name);
        errorMessage += `Please associate valid tags to ${invalidTagAssociationFileNames.join(
          ', '
        )}.`;
      }
      setErrorMessage(errorMessage);
    }
  };

  const onDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      name: file.name,
      size: file.size,
      type: file.type,
      lastModified: file.lastModified,
      uuid: uuidv4(),
      association_types: [],
      companySelectedValues: [],
      document_type: 'reports',
      sectorSelectedValues: [],
      preview: URL.createObjectURL(file),
      file: file
    }));
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, application/pdf',
    onDrop
  });

  const handleDeleteCompanyChip = (value, uuid) => {
    const updatedFiles = files.map((file) => {
      if (file.uuid === uuid) {
        const indexToRemove = file.companySelectedValues.findIndex((item) => item.key === value);
        if (indexToRemove !== -1) {
          const updatedValues = [...file.companySelectedValues];
          updatedValues.splice(indexToRemove, 1);
          return { ...file, companySelectedValues: updatedValues };
        }
      }
      return file;
    });
    setFiles(updatedFiles);
  };

  const handleDeleteSectorChip = (value, uuid) => {
    const updatedFiles = files.map((file) => {
      if (file.uuid === uuid) {
        const indexToRemove = file.sectorSelectedValues.findIndex((item) => item.key === value);
        if (indexToRemove !== -1) {
          const updatedValues = [...file.sectorSelectedValues];
          updatedValues.splice(indexToRemove, 1);
          return { ...file, sectorSelectedValues: updatedValues };
        }
      }
      return file;
    });
    setFiles(updatedFiles);
  };

  const handleCompanyChange = (event, value, uuid) => {
    const updatedFiles = files.map((file) => {
      if (file.uuid === uuid) {
        return { ...file, companySelectedValues: value };
      }
      return file;
    });
    setFiles(updatedFiles);
  };

  const handleSectorChange = (event, value, uuid) => {
    const updatedFiles = files.map((file) => {
      if (file.uuid === uuid) {
        return { ...file, sectorSelectedValues: value };
      }
      return file;
    });
    setFiles(updatedFiles);
  };

  const searchCompProps = (uuid) => ({
    setInputValue: setInputValue,
    isSingleSelect: false,
    data: allCompanies,
    hasMore: hasMore,
    page: page,
    setPage: setPage,
    placeholder: 'Select Company',
    selectedItems: files.find((file) => file.uuid === uuid)?.companySelectedValues || [],
    setSelectedItems: (newValue) => {
      const updatedFiles = files.map((file) => {
        if (file.uuid === uuid) {
          return { ...file, companySelectedValues: newValue };
        }
        return file;
      });
      setFiles(updatedFiles);
    },
    label: null,
    search: false
  });

  const searchSectorProps = (uuid) => ({
    handleChange: (event, value) => handleSectorChange(event, value, uuid),
    options: allSectors,
    selectedValues: files.find((file) => file.uuid === uuid)?.sectorSelectedValues || [],
    placeholder: 'Select Themes',
    chip: true,
    handleDeleteChip: (value) => handleDeleteSectorChip(value, uuid)
  });

  const handleFileTypeChange = (fileId, value) => {
    const updatedFiles = files.map((file) => {
      if (file.uuid === fileId) {
        if (value === 'webLinks') {
          handleCheckboxChange(file.uuid, 'webLinks');
        }
        return { ...file, document_type: value };
      }
      return file;
    });
    setFiles(updatedFiles);
  };

  const fileTypeOption = [
    {
      key: 'reports',
      value: 'Reports'
    },
    {
      key: 'presentation',
      value: 'Presentation'
    },
    {
      key: 'spreadsheet',
      value: 'Spreadsheet'
    },
    ...(org === 'PrivateBlok Ltd' || org === 'PrivateBlok'
      ? [
          {
            key: 'webLinks',
            value: 'Web Links'
          }
        ]
      : [])
  ];

  const getBoxToken = async () => {
    setIsLoading(true);
    try {
      const boxToken = await getApi(
        `${baseUrl}/ingestion/v1/get-user-auth-state/?vendor=${'box'}&service=storage`
      );
      const token = boxToken.data.data.access_token;
      const is_authorised = boxToken.data.data.is_authorised;
      const vendor_user_id = boxToken.data.data.vendor_user_id;
      if (is_authorised === true) {
        setBoxToken(token);
        setVendorUserId(vendor_user_id);
        setOpenBoxState(true);
        setIsLoading(false);
      } else {
        window.open(boxToken.data.data.redirect_url);
        setIsLoading(false);
      }
    } catch {
      setOpenBoxState(true);
      setBoxToken(null);
      setVendorUserId(null);
      setIsLoading(false);
    }
  };

  const handleBoxOpenPopup = async () => {
    await getBoxToken();
  };

  const handleClosBoxState = async () => {
    setOpenBoxState(false);
    try {
      await postApi(`${ingestionUrl}/v1/unauthorise-user`);
    } catch {
      setOpenBoxState(false);
    }
  };

  const handleShareBoxItems = async (payload) => {
    const body = {
      items: payload.items,
      vendor_user_id: vendorUserId,
      vendor: 'box',
      input_source: 'doc_library'
    };
    setIsLoading(true);
    try {
      await postApi(`${ingestionUrl}/v1/webhook/vendor-upload`, body);
      setSuccessMessage('Files shared successfully. Ingestion is queued.');
      setBoxToken(null);
      setIsLoading(false);
      setTimeout(() => setSuccessMessage(''), 7000);
      await handleClosBoxState();
    } catch {
      setErrorMessage('An error occurred. Please try again later.');
      setBoxToken(null);
      setIsLoading(false);
      setTimeout(() => setErrorMessage(''), 7000);
      await handleClosBoxState();
    }
  };

  console.log(handleDeleteCompanyChip, handleCompanyChange);

  return (
    <>
      <LoadingPopover open={isLoading} />
      <ErrorToast errorMessage={errorMessage} />
      <SuccessToast successMessage={successMessage} />
      <Dialog open={openBoxState} fullWidth={true} maxWidth="lg">
        <Box width="100%">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '20px 20px 0px 20px'
            }}
          >
            <div>
              <Typography className={classes.boxPopoverTxt}>Share Files</Typography>
            </div>
            <div>
              <CloseIcon style={{ cursor: 'pointer' }} onClick={handleClosBoxState} />
            </div>
          </div>
          <DialogContent style={{ padding: '24px' }}>
            <BoxContentExplorer
              folderId={folderId}
              accessToken={boxToken}
              handleShareBoxItems={(payload) => handleShareBoxItems(payload)}
            />
          </DialogContent>
        </Box>
      </Dialog>
      <Grid
        container
        style={{ height: 'calc(100vh - 240px)', overflowY: 'auto', padding: '5px 4px 0px 0px' }}
      >
        <Grid className={classes.uploadBox} item xs={12} sm={12} md={12} lg={12}>
          <div
            style={{
              border: '2px dashed #0276E8',
              borderRadius: '8px',
              padding: '20px',
              textAlign: 'center',
              height: '160px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
              // gap: '10px'
            }}
          >
            <FileUploadIcon />
            <Typography className={classes.uploadText} style={{ cursor: 'default' }}>
              Drag & Drop here or browse files from{' '}
            </Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                position: 'relative',
                paddingTop: '20px'
              }}
            >
              {/* Centered Section */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)'
                }}
              >
                <div>
                  <span {...getRootProps()} style={{ color: '#004CFF', cursor: 'pointer' }}>
                    <input {...getInputProps()} />
                    Your Computer
                  </span>
                </div>
                <div
                  style={{
                    width: '1.5px',
                    height: '24px',
                    backgroundColor: '#B8CCF6',
                    margin: '0 10px',
                    borderRadius: '8px'
                  }}
                ></div>
                <div>
                  <img
                    src="/images/icons/box-logo.png"
                    alt="Box Logo"
                    style={{ cursor: 'pointer', height: '40px' }}
                    onClick={handleBoxOpenPopup}
                  />
                </div>
                <div
                  style={{
                    width: '1.5px',
                    height: '24px',
                    backgroundColor: '#B8CCF6',
                    margin: '0 10px',
                    borderRadius: '8px'
                  }}
                ></div>
                <div>
                  <img
                    src="/images/icons/gdrive-logo.png"
                    alt="G-Drive Logo"
                    style={{ cursor: 'pointer', height: '32px' }}
                    onClick={() =>
                      getGDriveToken('doc_library', 'storage', setIsLoading, dispatch, '')
                    }
                  />
                </div>
              </div>

              {/* Right-aligned Button */}
              <div
                style={{
                  marginLeft: 'auto',
                  display: process.env.REACT_APP_ENABLE_LINKEMAIL ? 'flex' : 'none'
                }}
              >
                <Button className={classes.linkEmailBtn} onClick={handleClick} disableRipple>
                  <Typography className={classes.linkEmailBtnTxt}>
                    <LinkIcon /> Link Email {open ? <SortDirectionUp /> : <SortDirectionDown />}
                  </Typography>
                </Button>
              </div>
              <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <div style={{ padding: '8px 8px 0px 8px' }}>
                  {isLoadingEmails ? (
                    <Skeleton variant="text" width={150} height={30} />
                  ) : (
                    <>
                      {Object.keys(linkedEmail).map((vendor, index) => (
                        <div key={index}>
                          {linkedEmail[vendor].supplier.map((supplierEmail, supplierIndex) => (
                            <div
                              key={supplierIndex}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '10px'
                              }}
                            >
                              <Typography className={classes.linkedEmail}>
                                {vendor === 'gmail' && <GmailIcon />}
                                {vendor === 'outlook' && <OutlookIcon />}
                                {supplierEmail.email}
                              </Typography>
                              <Typography className={classes.linkedEmailStop}>Stop Sync</Typography>
                            </div>
                          ))}
                        </div>
                      ))}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          paddingTop: '20px'
                        }}
                      >
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            getGDriveToken('email_sync', 'mail', setIsLoading, dispatch, '')
                          }
                        >
                          <GmailAdd />
                        </div>
                        <div>
                          <div style={{ opacity: 0.5, cursor: 'not-allowed' }}>
                            <OutlookAdd />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Menu>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {files.length > 0 && (
            <>
              <Grid className={classes.fileGrid} container>
                <Grid item xs={3.3}>
                  <Typography className={classes.uploadHeadderFiles}>
                    {' '}
                    <strong>Files</strong>
                  </Typography>
                </Grid>
                <Grid className={classes.uploadHeadderTags} item xs={8.5}>
                  <strong>Tags</strong>
                </Grid>
                <Grid className={classes.uploadHeadderDelete} item xs={0.2} />
                {files.map((file) => (
                  <>
                    <Grid item xs={3.3} style={{ padding: '0px 0px 5px 0px' }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className={classes.uploadFilesName}>{file.name}</Typography>
                        </Grid>
                        <Grid item xs={4.2} style={{ padding: '0px 2px', display: 'none' }}>
                          <CustomSelect
                            value={file.document_type}
                            options={fileTypeOption}
                            handleChange={(value) => handleFileTypeChange(file.uuid, value)}
                            label={'File Type'}
                            placeholder={'File Type'}
                            disabled={isLoadingOrg}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className={classes.uploadHeadderTagsMain} item xs={8.5}>
                      <Grid
                        container
                        style={{
                          background: '#F7F7FC',
                          opacity: file.document_type === 'webLinks' ? 0.3 : 1
                        }}
                      >
                        <Grid item xs={4.6}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                              size="small"
                              style={{
                                color: '#004CFF',
                                pointerEvents: file.document_type === 'webLinks' ? 'none' : 'auto'
                              }}
                              value={'company'}
                              checked={!!file.association_types?.includes('company')}
                              onChange={() => handleCheckboxChange(file.uuid, 'company')}
                            />
                            <Typography className={classes.uploadCheckBoxTest}>
                              Companies
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: file.association_types.includes('company') ? 'grid' : 'none',
                              backgroundColor: '#FFFFFF'
                            }}
                          >
                            <div className={classes.root}>
                              <AutocompleteComponent {...searchCompProps(file.uuid)} />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={0.15} />
                        <Grid item xs={4.6}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                              size="small"
                              style={{
                                color: '#004CFF',
                                pointerEvents: file.document_type === 'webLinks' ? 'none' : 'auto'
                              }}
                              value={'themes'}
                              checked={!!file.association_types?.includes('themes')}
                              onChange={() => handleCheckboxChange(file.uuid, 'themes')}
                            />
                            <Typography className={classes.uploadCheckBoxTest}>Themes</Typography>
                          </div>
                          <div
                            style={{
                              display: file.association_types.includes('themes') ? 'grid' : 'none',
                              backgroundColor: '#FFFFFF'
                            }}
                          >
                            <SearchComp {...searchSectorProps(file.uuid)} />
                          </div>
                        </Grid>
                        <Grid item xs={0.15} />
                        <Grid item xs={2.5}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                              size="small"
                              style={{
                                color: '#004CFF',
                                pointerEvents: file.document_type === 'webLinks' ? 'none' : 'auto'
                              }}
                              value={'miscellaneous'}
                              checked={!!file.association_types?.includes('miscellaneous')}
                              onChange={() => handleCheckboxChange(file.uuid, 'miscellaneous')}
                            />
                            <Typography className={classes.uploadCheckBoxTest}>
                              Miscellaneous
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className={classes.removeFile} item xs={0.2}>
                      <Tooltip title={'File will be removed from upload'}>
                        <FileRemoveIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleRemoveFile(file.uuid)}
                        />
                      </Tooltip>
                    </Grid>
                  </>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {files.length > 0 && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}>
          <Button
            className={classes.cancleButton}
            style={{ width: '80px', height: '30px' }}
            onClick={() => {
              setFiles([]);
            }}
          >
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 400,
                color: '#08070D',
                textTransform: 'none',
                fontFamily: 'Satoshi'
              }}
            >
              Cancel
            </Typography>
          </Button>
          <div style={{ paddingLeft: '10px' }}></div>
          <Button
            className={classes.uploadButton}
            onClick={() => {
              validateFileTags();
            }}
            style={{ width: '80px', height: '30px' }}
          >
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 400,
                color: '#FFFFFF',
                textTransform: 'none',
                fontFamily: 'Satoshi'
              }}
            >
              Upload
            </Typography>
          </Button>
        </div>
      )}
    </>
  );
};

export default UploadFile;
