import { FormControl, MenuItem, Select, InputLabel, makeStyles } from '@material-ui/core';
import InputError from './inputError';

const useStyles = makeStyles(() => ({
  root: {
    '& fieldset': {
      borderRadius: '3px',
      border: '1px solid #E3EBFD !important'
    },
    '& .MuiInputBase-input': {
      padding: '9px',
      color: 'rgba(0, 0, 0, 0.87) !important'
    }
  },
  customClass: {
    backgroundColor: 'transparent'
  },
  lable: {
    padding: '0px',
    position: 'relative',
    top: '8px',
    left: '14px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  dropdownList: {
    '& ul': {
      padding: '0px'
    }
  },
  placeholder: { display: 'none', color: 'rgba(0, 0, 0, 0.54)' },
  errorStyle: {
    '& fieldset': {
      border: '1px solid #ed5b55 !important',
      '& legend': {
        color: '#eb5b55'
      }
    }
  }
}));

const CustomSelect = ({
  label = '',
  handleChange = () => {},
  options = [],
  value = '',
  placeholder = '',
  errorMessage = '',
  disabled = null,
  className = '',
  placeholderClassName = ''
}) => {
  const classes = useStyles();
  const labelConditional = label ? { label } : {};
  return (
    <FormControl fullWidth className={`${classes.templateSelectForm} ${className}`}>
      <InputLabel className={classes.lable}>{label}</InputLabel>
      <Select
        fullWidth
        variant="outlined"
        {...labelConditional}
        className={`${classes.root} ${classes.customClass} ${
          errorMessage && classes.errorStyle
        } ${className}`}
        value={value ? value : 'placeHolder'}
        onChange={(event) => handleChange(event.target.value)}
        MenuProps={{
          PaperProps: {
            className: classes.dropdownList
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
        disabled={disabled}
      >
        <MenuItem value="placeHolder" className={`${classes.placeholder}`}>
          <span className={placeholderClassName}>{placeholder}</span>
        </MenuItem>
        {options &&
          Array.isArray(options) &&
          options?.map((option, index) => (
            <MenuItem key={index} value={option.key}>
              <span>{option.value}</span>
            </MenuItem>
          ))}
      </Select>
      <InputError errorMessage={errorMessage} />
    </FormControl>
  );
};

export default CustomSelect;
