import { Box, Typography, makeStyles } from '@material-ui/core';

import { ReactComponent as RestorePage } from '../../../icons/refreshReport.svg';
// import { ReactComponent as Print } from '../../../icons/printReport.svg';
import { ReactComponent as DeleteBinIcon } from '../../../icons/DeleteBinIcon.svg';
import { ReactComponent as EditIcon } from '../../../icons/edit.svg';

import { useTranslation } from 'react-i18next';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import ErrorToast from '../../../atoms/errorToast';
import { Info } from '@material-ui/icons';
// If you have a custom print icon, otherwise you can reuse Print
import PrintReport from '@mui/icons-material/Print';

const useStyles = makeStyles(() => ({
  tearSheetHeader: {
    display: 'flex',
    marginBottom: '10px'
  },
  printButton: {
    display: 'flex',
    marginLeft: 'auto', // pushes the button to the right
    alignItems: 'center'
  },
  '@media print': {
    printButton: {
      visibility: 'hidden'
    }
  },
  infoBox: {
    display: 'flex',
    alignItems: 'center'
  },
  infoDiv: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 6px',
    // background: '#cccccc',
    // borderRadius: '5px',
    marginRight: '15px',
    'border-left': '3px solid var(--SystemColor-blue400, #3473EE)',
    background: 'var(--SystemColor-blue100, #F4F8FF)',
    'box-shadow': '0px 0px 8px 0px rgba(0, 0, 0, 0.10)'
  },
  infoIcon: {
    marginRight: '4px',
    fontSize: '21px',
    color: '#1d1d1d'
  },
  infoTypo: {
    color: '#1d1d1d'
  }
}));

const Message = ({ classes, msg }) => {
  return (
    <Box className={classes.infoBox}>
      <div className={classes.infoDiv}>
        <Info className={classes.infoIcon} />
        <Typography className={classes.infoTypo}>{msg}</Typography>
      </div>
    </Box>
  );
};

const TearSheetHeader = ({
  // tearSheetData,
  errorMessage,
  // New props for updated icons behavior:
  showLoader,
  generated_from_latest_template,
  shareReport,
  triggerLatest,
  handleEdit,
  handleDelete = () => {}
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.tearSheetHeader}>
      {/* {tearSheetData?.metadata?.logo && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '60px'
          }}
        >
          <img
            src={tearSheetData?.metadata?.logo}
            alt="Logo"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain'
            }}
          />
        </div>
      )} */}
      <div
        style={{
          fontFamily: 'Satoshi',
          //paddingLeft: '15px',
          fontWeight: '600',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <p
          style={{
            fontFamily: 'Satoshi',
            fontSize: '16px',
            fontWeight: '600'
          }}
        >
          KPI
        </p>
      </div>
      {/* {tearSheetData?.response && Object.keys(tearSheetData?.response).length > 0 && ( */}
      <div className={classes.printButton}>
        {showLoader ? (
          <>
            {/* <Message classes={classes} msg={t('tearSheet.InProg')} /> */}
            <IconButton>
              {/* <Tooltip title={t('tearSheet.inProcessToolTip')}> */}
              <Tooltip title={t('tearSheet.InProg')}>
                <CircularProgress size={24} />
              </Tooltip>
            </IconButton>
          </>
        ) : (
          <>
            {generated_from_latest_template === false && (
              <Message classes={classes} msg={t('tearSheet.notLatest')} />
            )}
            {shareReport && <Message classes={classes} msg={t('tearSheet.nonEditable')} />}
            {!shareReport && (
              <IconButton onClick={triggerLatest} style={{ display: 'none' }}>
                <Tooltip title={t('tearSheet.RefreshToolTip')}>
                  <RestorePage />
                </Tooltip>
              </IconButton>
            )}
          </>
        )}
        {!showLoader && (
          <>
            <IconButton onClick={handleEdit}>
              <EditIcon />
            </IconButton>
            <div style={{ display: 'none' }}>
              {!shareReport && (
                <IconButton onClick={handleDelete}>
                  <DeleteBinIcon />
                </IconButton>
              )}
              <IconButton style={{ marginLeft: '10px' }} onClick={() => window.print()}>
                <PrintReport />
              </IconButton>
            </div>
          </>
        )}
        <ErrorToast errorMessage={errorMessage} />
      </div>
    </div>
  );
};

export default TearSheetHeader;
