import { configureStore } from '@reduxjs/toolkit';
import reducer from './rootReducer';

const currentEnv = process.env.REACT_APP_ENV_NAME;
const isLocal = currentEnv === 'local';

const onLocalConfigureStore = () => {
  return configureStore(
    {
      reducer
    },
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
};

const onNonLocalConfigureStore = () => {
  return configureStore({
    reducer
  });
};

const onConfigureStore = () => (isLocal ? onLocalConfigureStore() : onNonLocalConfigureStore());

const store = onConfigureStore();

export default store;
