// import React from 'react';
import { ListItemButton, Typography } from '@mui/material';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as DownArrow } from '../../../components/icons/DownArrow.svg';
import { ReactComponent as SideArrow } from '../../../components/icons/SideArrow.svg';

const useStyles = makeStyles(() => ({
  listItem: {
    borderBottom: '1px solid #F0F0F0',
    padding: '5px 10px !important',
    minHeight: '40px'
  },
  selected: {
    background:
      'var(--Gradients-Gradient4, linear-gradient(270deg, #CDF1F3 0%, #CDDBFF 100%)) !important'
  },
  expandIcon: {
    transition: 'transform 0.3s ease-in-out!important',
    fontSize: '16px!important',
    padding: '0px 0',
    paddingRight: '5px'
  },
  expandIconOpen: {
    transform: 'rotate(0deg)'
  },
  expandIconClosed: {
    transform: 'rotate(-90deg)'
  },
  text: {
    fontFamily: 'Satoshi !important',
    color: '#373737',
    fontSize: '14px !important'
  },
  selectedText: {
    fontWeight: 700,
    color: 'pink !important',
    'background-clip': 'text !important',
    'background-image':
      'linear-gradient(to right, rgb(0, 76, 255) 0%, rgb(6, 188, 193) 100%) !important',
    '-webkit-text-fill-color': 'transparent !important'
  },
  unselectedText: {
    fontWeight: '400 !important'
  },
  activeMenu: {
    background:
      'var(--Gradients-Gradient4, linear-gradient(270deg, #CDF1F3 0%, #CDDBFF 100%)) !important'
  },
  bold: {
    fontWeight: '700 !important'
  }
}));

const CustomListItemButton = ({
  expanded,
  onClick = () => {},
  text,
  showIcon = false,
  style = {},
  textStyle = {},
  children,
  customClass = '',
  isChildMenuSelected = false,
  ...props
}) => {
  const classes = useStyles();
  return (
    <ListItemButton
      disableRipple
      onClick={onClick}
      style={{ ...style }}
      className={`${classes.listItem} ${isChildMenuSelected ? classes.activeMenu : ''} ${
        expanded ? classes.selected : ''
      } ${customClass}`}
      {...props}
    >
      {showIcon &&
        // <ExpandMoreIcon
        //   className={`${classes.expandIcon} ${
        //     expanded ? classes.expandIconOpen : classes.expandIconClosed
        //   }`}
        // />
        (expanded ? (
          <DownArrow className={classes.expandIcon} />
        ) : (
          <SideArrow className={classes.expandIcon} />
        ))}
      <Typography
        className={`${classes.text} ${expanded ? classes.bold : classes.unselectedText} ${
          isChildMenuSelected ? classes.selectedText : classes.unselectedText
        }`}
        style={textStyle}
      >
        {text}
      </Typography>
      {children}
    </ListItemButton>
  );
};

export default CustomListItemButton;
