import { Paper, Tooltip, Typography, makeStyles } from '@material-ui/core';
import CustomTextField from '../../../atoms/textfield';
import { Autocomplete, IconButton } from '@mui/material';
import { Add, Close, DragIndicator } from '@material-ui/icons';
import CustomSelect from '../../../atoms/select';
import TertiaryButton from '../../../atoms/buttons/tertiaryBtn';
import { useTranslation } from 'react-i18next';
// import InputError from '../../../atoms/inputError';
import { ContentCopy, ContentPaste } from '@mui/icons-material';
import { questionTypes, questionLib } from '../../tearSheet-V1/tearSheetTemplateMocks';
import ErrorSpan from '../../../atoms/errorSpan';
import { useSearchParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
//import useState from 'react';

const useStyles = makeStyles(() => ({
  mainDiv: {
    padding: '14px',
    position: 'relative',
    marginBottom: '10px'
  },
  sectionLevelOne: {
    display: 'flex',
    width: '100%'
  },
  closeSectionBtn: {
    position: 'absolute',
    top: '0',
    right: '0'
  },
  keyDiv: {
    width: '14%',
    margin: '18px 0px 0px'
  },
  textDiv: {
    width: '56%',
    margin: '16px 0px 0px'
  },
  keyTypography: {
    fontSize: '16px',
    marginTop: '4px'
  },
  questionsDiv: {
    display: 'flex',
    flexDirection: 'column',
    margin: '16px 0px 0px'
  },
  selectDiv: {
    width: '14%',
    marginLeft: '2%'
  },
  closeQuestionDiv: {
    width: '10%',
    '& button': {
      padding: '3px',
      marginLeft: '5px',
      margin: '16px 0px 0px'
    }
  },
  addQuestionBtn: {
    margin: '0 14% 0 auto'
  },
  dragDiv: {
    position: 'absolute',
    top: '-4px',
    justifyContent: 'center',
    display: 'flex',
    width: '100%'
  },
  dragQDiv: {
    width: '10%',
    paddingBottom: '7px',
    marginLeft: '7px',
    margin: '16px 0px 0px',
    display: 'flex',
    justifyContent: 'start'
  },
  dragIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    transform: 'rotate(90deg)',
    cursor: 'all-scroll !important'
  },
  questionAutocomplete: {
    '& .MuiInputBase-input': {
      height: '7px !important'
    }
  }
}));

//const [copied, setCopied] = useState([]);
const TemplateSectionCard = ({
  obj,
  handleChange,
  isDiabledAll,
  errorObject
  //copied,
  //setCopied
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const selectedFilter = searchParams.get('entity_type') || 'company';
  const initial = selectedFilter === 'theme' ? 'Theme' : 'Company';
  const updateSection = (index, prop, value) => {
    const questionGroups = [...obj.question_groups];
    questionGroups[index][prop] = value;
    handleChange('question_groups', questionGroups);
  };
  const MAX_AGE = 60 * 60 * 24 * 1000;
  const handleAddQuestion = (i) => {
    const questionGroups = [...obj.question_groups];
    questionGroups[i].questions.push({
      question: '',
      type: 'query',
      question_type: ''
    });
    handleChange('question_groups', questionGroups);
  };

  const updateQuestion = (pindex, index, prop, value) => {
    const questionGroups = [...obj.question_groups];
    questionGroups[pindex].questions[index][prop] = value;
    handleChange('question_groups', questionGroups);
  };

  const removeQuestion = (pindex, index) => {
    const questionGroups = [...obj.question_groups];
    if (questionGroups[pindex].questions.length > 1) {
      questionGroups[pindex].questions = questionGroups[pindex].questions.filter(
        (x, ind) => index !== ind
      );
      handleChange('question_groups', questionGroups);
    }
  };

  const removeSection = (i) => {
    let questionGroups = [...obj.question_groups];
    if (questionGroups.length > 1) {
      questionGroups = questionGroups.filter((x, ind) => i !== ind);
      handleChange('question_groups', questionGroups);
    }
  };
  const handleSectionCopy = (i) => {
    const copiedSection = { ...obj.question_groups[i] }; // Clone the section at index i
    //console.log(obj.question_groups);
    localStorage.setItem(
      'section',
      JSON.stringify({
        timestamp: new Date().getTime(),
        data: copiedSection
      })
    );
    //let updatedcopied = [...copied]; // Create a copy of the array
    //updatedcopied[i] = true; // Update the 3rd item (index 2)
    //setCopied(updatedcopied);
    //console.log(updatedcopied);
    //setTimeout(() => {
    //let resetCopied = [...updatedcopied]; // Copy the updated array
    //resetCopied[i] = false; // Reset only the i-th index
    //setCopied(resetCopied);
    //}, 5000);
  };
  const handleSectionPaste = (i) => {
    const questionGroups = [...obj.question_groups];
    const copiedSectionStr = localStorage.getItem('section');
    if (!copiedSectionStr) return;
    const copiedSection = JSON.parse(copiedSectionStr);
    const now = new Date().getTime();
    if (now - copiedSection.timestamp < MAX_AGE) {
      questionGroups[i] = { ...copiedSection.data };
      handleChange('question_groups', questionGroups);
    } else {
      localStorage.removeItem('section');
    }
  };

  const handleDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) return;

    // For section drag
    if (type === 'section') {
      const sections = Array.from(obj.question_groups);
      const [movedSection] = sections.splice(source.index, 1);
      sections.splice(destination.index, 0, movedSection);
      handleChange('question_groups', sections);
    }

    // For question drag
    if (type === 'question') {
      const sectionIndex = draggableId.split('-')[1];
      const section = obj.question_groups[sectionIndex];
      const questions = Array.from(section.questions);

      const [movedQuestion] = questions.splice(source.index, 1);
      questions.splice(destination.index, 0, movedQuestion);

      const updatedSections = [...obj.question_groups];
      updatedSections[sectionIndex] = {
        ...updatedSections[sectionIndex],
        questions
      };

      handleChange('question_groups', updatedSections); // Update the state
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="sectionsList" type="section">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {obj?.question_groups &&
              obj?.question_groups.map((section, i) => {
                const currentSection =
                  (errorObject?.question_groups && errorObject?.question_groups[i]) || null;

                return (
                  <Draggable key={i} draggableId={`section-${i}`} index={i}>
                    {(provided) => (
                      <Paper
                        fullwidth
                        className={classes.mainDiv}
                        elevation={3}
                        ref={provided.innerRef}
                        {...provided.draggableProps} // Applied to the Paper
                      >
                        <div
                          className={classes.dragDiv}
                          ref={provided.innerRef}
                          {...provided.dragHandleProps} // Applied only to DragIndicator
                        >
                          <span className={classes.dragIcon}>
                            <DragIndicator />
                          </span>
                        </div>
                        <div className={classes.closeSectionBtn}>
                          <Tooltip title={t('Copy Section')}>
                            <IconButton onClick={() => handleSectionCopy(i)}>
                              {<ContentCopy />}
                            </IconButton>
                          </Tooltip>
                          {/* <Tooltip title={copied[i] ? t('Section Copied!') : t('Copy Section')}>
                            <IconButton onClick={() => handleSectionCopy(i)}>
                              {copied[i] ? <Check style={{ color: '#22A428' }} /> : <ContentCopy />}
                            </IconButton>
                          </Tooltip> */}
                          <Tooltip title={t('Paste Section')}>
                            <IconButton onClick={() => handleSectionPaste(i)}>
                              <ContentPaste />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remove Section">
                            <IconButton onClick={() => removeSection(i)}>
                              <Close />
                            </IconButton>
                          </Tooltip>
                        </div>

                        <div className={classes.sectionLevelOne}>
                          <div className={classes.keyDiv}>
                            <Typography className={classes.keyTypography}>Heading</Typography>
                          </div>
                          <div className={classes.textDiv}>
                            <CustomTextField
                              disabled={isDiabledAll}
                              placeholder="Section Heading Placeholder"
                              value={section.name}
                              handleChange={(value) => updateSection(i, 'name', value)}
                            />
                            <ErrorSpan value={currentSection?.name} />
                          </div>
                        </div>
                        <Droppable droppableId={`questions-${i}`} type="question">
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              {section?.questions &&
                                section.questions.map((quest, ind) => {
                                  const currentQuestion = currentSection?.questions[ind] || null;
                                  const defaultKey =
                                    questionTypes.find((option) => option.value === initial)?.key ||
                                    '';
                                  if (!quest?.question_type && defaultKey) {
                                    updateQuestion(i, ind, 'question_type', defaultKey);
                                  }
                                  return (
                                    <Draggable
                                      key={ind}
                                      draggableId={`question-${i}-${ind}`}
                                      index={ind}
                                    >
                                      {(provided) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps}>
                                          <div className={classes.sectionLevelOne}>
                                            <div className={classes.keyDiv}>
                                              <Typography className={classes.keyTypography}>
                                                Question
                                              </Typography>
                                            </div>
                                            <div className={classes.textDiv}>
                                              <Autocomplete
                                                freeSolo
                                                className={classes.questionAutocomplete}
                                                value={quest?.question}
                                                options={questionLib.map(
                                                  (option) => option.question
                                                )}
                                                onInputChange={(event, value) =>
                                                  updateQuestion(i, ind, 'question', value)
                                                }
                                                renderInput={(params) => (
                                                  <form noValidate>
                                                    <CustomTextField
                                                      params={params}
                                                      placeholder={t(
                                                        'tearSheet.questionPlaceholder'
                                                      )}
                                                      label="Byz Question"
                                                      errorMessage={''}
                                                    />
                                                  </form>
                                                )}
                                                disabled={isDiabledAll}
                                                disableClearable
                                                autoHighlight
                                                autoComplete
                                              />
                                              <ErrorSpan value={currentQuestion?.question} />
                                            </div>
                                            <div className={classes.selectDiv}>
                                              <CustomSelect
                                                value={quest?.question_type || defaultKey}
                                                options={questionTypes}
                                                handleChange={(value) =>
                                                  updateQuestion(i, ind, 'question_type', value)
                                                }
                                                label={t('tearSheet.questionTypeLabel')}
                                                placeholder={
                                                  selectedFilter === 'theme' ? 'Theme' : 'Company'
                                                }
                                                errorMessage={''}
                                                disabled={isDiabledAll}
                                              />
                                              <ErrorSpan value={currentQuestion?.question_type} />
                                            </div>
                                            <div
                                              className={classes.dragQDiv}
                                              ref={provided.innerRef}
                                              {...provided.dragHandleProps}
                                            >
                                              <span className={classes.dragIcon}>
                                                <DragIndicator />
                                              </span>
                                            </div>
                                            <div className={classes.closeQuestionDiv}>
                                              <IconButton
                                                disabled={isDiabledAll}
                                                onClick={() => removeQuestion(i, ind)}
                                              >
                                                <Close />
                                              </IconButton>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                              {provided.placeholder}
                              <div className={classes.sectionLevelOne}>
                                <div className={classes.addQuestionBtn}>
                                  <TertiaryButton
                                    disabled={isDiabledAll}
                                    handleClick={() => handleAddQuestion(i)}
                                    btnText={t('tearSheet.addQuestionBtn')}
                                    StartIcon={Add}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </Droppable>
                      </Paper>
                    )}
                  </Draggable>
                );
              })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TemplateSectionCard;
