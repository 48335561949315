import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Chip
} from '@mui/material';
import { Typography, makeStyles } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import CustomTextField from '../../../atoms/textfield';
import MultiCompanyThemeSearch from './multiCompanyThemeSearch';
import { updateParamsInURL, hideShowMessage } from '../../../../helpers';
import { postApi, putApi } from '../../../../services/token';
import ErrorSpan from '../../../atoms/errorSpan';
const companyUrl = process.env.REACT_APP_COMPANY_API;

const useStyles = makeStyles(() => ({
  mainDiv: {
    borderRadius: '16px !important',
    height: '566px',
    width: '500px'
  },
  autocompleteInput: {
    height: '4px !important'
  },
  closeBtn: {
    position: 'absolute !important',
    right: '10px',
    top: '10px',
    cursor: 'pointer'
  },
  saveBtn: {
    background: 'linear-gradient(90deg,#004CFF,#06BCC1)',
    textTransform: 'none!important',
    fontFamily: 'Satoshi!important',
    fontSize: '16px!important',
    width: '32%!important'
  },
  dialogClass: {
    borderRadius: '16px !important',
    height: 'auto',
    width: '500px'
  },
  dialogMain: {
    display: 'flex',
    'flex-direction': 'column',
    gap: '0px'
  },
  fieldContainer: {
    minHeight: '68px',
    marginBottom: '24px',
    '& .MuiOutlinedInput-root': {
      height: '48px !important'
    },
    '& .MuiInputBase-root': {
      height: '48px !important'
    },
    '& .MuiAutocomplete-inputRoot': {
      height: '48px !important'
    },
    '& .MuiTextField-root': {
      height: '48px !important'
    },
    '& input': {
      height: '16px !important'
    }
  },
  searchCompanyContainer: {
    minHeight: '68px',
    marginTop: '-20px',
    '& .MuiOutlinedInput-root': {
      height: '48px !important'
    },
    '& .MuiInputBase-root': {
      height: '48px !important'
    },
    '& .MuiAutocomplete-inputRoot': {
      height: '48px !important'
    },
    '& .MuiTextField-root': {
      height: '48px !important'
    },
    '& input': {
      height: '16px !important'
    }
  },
  fieldLabel: {
    marginBottom: '4px',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  errorMessage: {
    color: 'red'
  },
  scrollContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  scrollBarEffect: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    padding: '10px',
    overflowX: 'hidden', // Hide scrollbar by default
    paddingBottom: '5px',
    '&:hover': {
      overflowX: 'auto',
      paddingBottom: '0px'
    }
  },
  textItem: {
    marginRight: '20px',
    fontSize: '14px',
    textAlign: 'center',
    display: 'inline-block'
  },
  chipLabel: {
    padding: '6px !important',
    height: 'auto !important',
    'justify-content': 'center!important',
    'align-items': 'center!important',
    borderRadius: '2px!important',
    margin: '2px 3px!important',
    color: '#004CFF!important',
    backgroundColor: '#EAF1FE!important',
    '& span': {
      display: 'block!important',
      'white-space': 'normal !important'
    }
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 30px 30px'
  },
  themeNameContainer: {
    minHeight: '68px',
    marginBottom: '24px',
    '& .MuiOutlinedInput-root': {
      height: '48px !important'
    },
    '& .MuiInputBase-root': {
      height: '48px !important'
    },
    '& .MuiAutocomplete-inputRoot': {
      height: '48px !important'
    },
    '& .MuiTextField-root': {
      height: '48px !important'
    },
    '& input': {
      height: '16px !important'
    }
  }
}));

const ThemeDialog = ({
  title = '',
  open = false,
  btnText = 'save',
  handleClose = () => {},
  getSimilarEntities,
  navigate,
  location,
  setSuccessMessage,
  setErrorMessage,
  themeData,
  setLoading,
  loading
}) => {
  const classes = useStyles();
  const [themeId, setThemeId] = useState('');
  const [entityType, setEntityType] = useState('');
  const [themeName, setthemeName] = useState('');
  const [category, setCategory] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [isModified, setIsModified] = useState(true);

  const [similarDats, setSimilarData] = useState([]);

  const [submitTemplate, seSubmitTemplate] = useState(false);

  useEffect(() => {
    if (themeData && isModified) {
      // Destructure and compare themeData fields to avoid unnecessary updates
      const { companies_associated, entity_name, theme, entity_id, entity_type } = themeData || {};

      // Check if the data in state differs from themeData to prevent unnecessary updates
      const isCompaniesChanged =
        companies_associated &&
        companies_associated.length !== companies.length &&
        !companies_associated.every((c, index) => c.entity_id === companies[index]?.entity_id);

      if (isCompaniesChanged) {
        setCompanies(
          companies_associated && companies_associated.length > 0 ? companies_associated : []
        );
      }

      if (entity_name !== themeName) {
        setthemeName(entity_name ? entity_name : '');
      }

      if (theme?.entity_id !== category?.entity_id) {
        setCategory(theme ? theme : null);
      }

      setThemeId(entity_id ? entity_id : '');
      setEntityType(entity_type ? entity_type : '');
    }
  }, [themeData, companies, themeName, category]);

  const resetTemplateForm = () => {
    setthemeName('');
    setCategory(null);
    setCompanies([]);
    setSimilarData([]);
    seSubmitTemplate(false);
  };

  const validate = () => {
    let isValid = true;

    if (!themeName || themeName.trim() === '') {
      isValid = false;
    }

    if (!category || !category.entity_id) {
      isValid = false;
    }

    if (!companies || companies.length === 0 || !companies.every((c) => c.entity_id)) {
      isValid = false;
    }

    return isValid;
  };

  const onUpdate = async () => {
    try {
      setLoading(true);
      let obj = {
        companies_associated: companies,
        name: themeName,
        is_enabled: true
      };

      await putApi(`${companyUrl}/v1/fts/${themeId}?entity_type=${entityType}`, obj);
      hideShowMessage('Theme Saved!', setSuccessMessage);
      resetTemplateForm();
      handleClose();
      setLoading(false);
    } catch (error) {
      hideShowMessage('Error occurred while saving the theme. Please try again.', setErrorMessage);
      setLoading(false);
    }
  };

  const onSave = async () => {
    seSubmitTemplate(true);
    if (validate()) {
      if (themeId) {
        onUpdate();
        return;
      }
      try {
        setLoading(true);
        await postApi(`${companyUrl}/v1/fts/sub-theme`, {
          companies_associated: companies,
          sub_theme: themeName,
          theme_id: category.entity_id
        });
        hideShowMessage('Theme Saved!', setSuccessMessage);
        resetTemplateForm();
        handleClose();
        setLoading(false);
      } catch (error) {
        hideShowMessage(
          'Error occurred while saving the theme. Please try again.',
          setErrorMessage
        );
        setLoading(false);
      }
    }
    // await save(themeName, templateDesc, resetTemplateForm);
  };

  const onSelectedCompany = (value) => {
    setCompanies(value);
  };

  const onSelectedCategory = (value) => {
    setCategory(value);
  };

  const getSimilarThemeEntities = async (text) => {
    if (text) {
      const data = await getSimilarEntities(text, 'theme');
      setSimilarData(data && data.length > 0 ? data : []);
    }
  };

  return (
    <Dialog open={open} classes={{ paper: classes.dialogClass }}>
      <IconButton
        className={classes.closeBtn}
        disableRipple
        onClick={() => {
          resetTemplateForm();
          handleClose();
        }}
      >
        <Close />
      </IconButton>
      <DialogTitle style={{ margin: '10px 0px 0px' }}>
        <Typography variant="h5">{title}</Typography>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '5px' }}>
        <div className={classes.dialogMain}>
          <div className={classes.themeNameContainer}>
            <Typography className={classes.fieldLabel}>Theme Name</Typography>
            <CustomTextField
              value={themeName}
              handleChange={(value) => {
                setthemeName(value);
              }}
              placeholder="Theme Name"
            />
            {submitTemplate && !themeName && <ErrorSpan value="Required *" />}
          </div>
          <div className={classes.fieldContainer}>
            <MultiCompanyThemeSearch
              isSingleSelect={true}
              entity_types="theme"
              onSelectedValue={onSelectedCategory}
              onApiCall={getSimilarThemeEntities}
              label="Search Category"
              placeholder="Search Category"
              selectedVal={category}
              disabled={!!themeId}
              setIsModified={setIsModified}
            />
            {submitTemplate && !category && <ErrorSpan value="Required *" />}
          </div>
          <div className={classes.searchCompanyContainer}>
            <MultiCompanyThemeSearch
              entity_types="company"
              onSelectedValue={onSelectedCompany}
              label="Search Companies"
              placeholder="Search Companies"
              selectedVal={companies}
              setIsModified={setIsModified}
            />
            {submitTemplate && !(companies && companies.length > 0) && (
              <ErrorSpan value="Required *" />
            )}
          </div>
          {similarDats && similarDats.length > 0 && (
            <div className={classes.scrollContainer}>
              <div>
                <span className={classes.textItem}>Did you mean?</span>
              </div>
              <div className={classes.scrollBarEffect}>
                {similarDats.map((x, i) => {
                  return (
                    <Chip
                      key={i}
                      label={x.entity_name}
                      className={classes.chipLabel}
                      onClick={() => {
                        updateParamsInURL({
                          navigate,
                          location,
                          addParams: { search: x.entity_name },
                          removeParams: ['report_id']
                        });
                        resetTemplateForm();
                        handleClose();
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.saveBtn}
          onClick={onSave}
          disabled={loading}
        >
          {btnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ThemeDialog;
