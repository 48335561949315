import {
  SETPORTFOLIOINTIALSTATEFOLDER,
  SETPORTFOLIOFOLDERDATA,
  SETPORTFOLIODOCEXPLORERLOADING,
  SETPORTFOLIOGLOBALLOADING,
  SETPORTFOLIOSELECTEDDOCEXPLORER,
  SET_WATCHLISTS,
  SET_EXPANDED_WATCHLIST,
  SET_WATCHLIST_COMPANIES,
  SET_LOADING_COMPANIES,
  SET_CURRENT_COMPANY,
  SETPORTFOLIOACTIVESCREENERTAB,
  SETPORTFOLIOSCREENERTAB,
  SETOPENREPORTDIALOG,
  SETCURRENTREPORT,
  SETKPIACTIVETAB,
  SETALLMESSAGESPT,
  SETLOADINGPORTFOLIOTRACKER,
  SETLOADINGPT,
  SETLOADINGANSWERSTREAMPT,
  SETSTOPRESPONSEPT,
  SETCHATSCROLLPT,
  SETCHATSEARCHINPUTPT
} from '../../action.types';

const staticReportData = {
  report_id: null,
  report_name: 'KPI',
  entity_list: [
    {
      entity_type: 'company',
      entity_id: ''
    }
  ],
  question_groups: [
    {
      name: 'Section 1',
      questions: [{ question: '', type: 'query', question_type: '' }]
    }
  ],
  metaData: null,
  response: null
};

const INITIAL_STATE = {
  portfolioFolderData: [],
  portfolioDocExplorerloading: false,
  portfolioGlobalLoading: false,
  portfolioSelectedDocExplorer: [],
  watchlists: [],
  expanded: [],
  companiesData: {},
  loadingCompanies: {},
  currentWatchlistCompany: null,
  portfolioScreenerTabs: [
    { key: 'internal', value: 'Internal' }
    // { key: 'external', value: 'External' }
  ],
  portfolioScreenerTab: 'internal',
  openReportDialog: false,
  currentKpiReport: staticReportData,
  activeKPITab: '',
  allMessagesPT: [],
  loadingPortfolioTracker: false,
  loadingDE: false,
  loadingAnswerStreamPT: false,
  stopResponsePT: false,
  chatScrollPT: false,
  chatsearchInputPT: ''
};
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETPORTFOLIOFOLDERDATA:
      return {
        ...state,
        portfolioFolderData: action.data
      };

    case SETPORTFOLIODOCEXPLORERLOADING:
      return {
        ...state,
        portfolioDocExplorerloading: action.data
      };
    case SETPORTFOLIOGLOBALLOADING:
      return {
        ...state,
        portfolioGlobalLoading: action.data
      };
    case SETPORTFOLIOSELECTEDDOCEXPLORER:
      return {
        ...state,
        portfolioSelectedDocExplorer: action.data
      };
    case SETPORTFOLIOINTIALSTATEFOLDER:
      return {
        ...state,
        portfolioFolderData: [],
        portfolioDocExplorerloading: false,
        portfolioGlobalLoading: false,
        portfolioSelectedDocExplorer: []
      };
    case SETPORTFOLIOACTIVESCREENERTAB:
      return {
        ...state,
        portfolioScreenerTabs: action.data
      };
    case SETPORTFOLIOSCREENERTAB:
      return {
        ...state,
        portfolioScreenerTab: action.data
      };
    case SET_WATCHLISTS:
      return { ...state, watchlists: action.payload };
    case SET_EXPANDED_WATCHLIST:
      return { ...state, expanded: action.payload };
    case SET_WATCHLIST_COMPANIES:
      return {
        ...state,
        companiesData: {
          ...state.companiesData,
          [action.payload.watchlistId]: action.payload.companies
        }
      };
    case SET_LOADING_COMPANIES:
      return {
        ...state,
        loadingCompanies: {
          ...state.loadingCompanies,
          [action.payload.watchlistId]: action.payload.isLoading
        }
      };
    case SET_CURRENT_COMPANY:
      return {
        ...state,
        currentKpiReport: action.currentKpiReportData,
        currentWatchlistCompany: action.payload
      };
    case SETOPENREPORTDIALOG:
      return {
        ...state,
        openReportDialog: action.data
      };
    case SETCURRENTREPORT:
      return {
        ...state,
        currentKpiReport: action.data
      };
    case SETKPIACTIVETAB:
      return {
        ...state,
        activeKPITab: action.data
      };
    case SETALLMESSAGESPT:
      return {
        ...state,
        allMessagesPT: action.data
      };
    case SETLOADINGPORTFOLIOTRACKER:
      return {
        ...state,
        loadingPortfolioTracker: action.data
      };
    case SETLOADINGPT:
      return {
        ...state,
        loadingDE: action.data
      };
    case SETLOADINGANSWERSTREAMPT:
      return {
        ...state,
        loadingAnswerStreamPT: action.data
      };
    case SETSTOPRESPONSEPT:
      return {
        ...state,
        stopResponsePT: action.data
      };
    case SETCHATSCROLLPT:
      return {
        ...state,
        chatScrollPT: action.data
      };
    case SETCHATSEARCHINPUTPT:
      return {
        ...state,
        chatsearchInputPT: action.data
      };

    default:
      return state;
  }
};

export default reducer;
