import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CheckRoute = ({ setCurrentActiveMenu }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const routeMappings = {
    askbyz: t('tab.AskBot'),
    'report?entity_type=company': t('tearSheet.customReportsCompanyHeadder'),
    'report?entity_type=theme': t('tearSheet.customReportsThemeHeadder'),
    report: t('tearSheet.customReportsHeadder'),
    tearsheet: t('tearSheet.heading'),
    'doc-explorer': t('headder.docExplorer'),
    'explore-all-companies': t('headder.exploreCompanies'),
    history: t('headder.History'),
    'document-upload/manage': t('headder.docLibrary'),
    'my-templates': t('headder.templateLibrary'),
    template: t('tearSheet.heading'),
    tracker: t('headder.portfolioHeadder'),
    'tracker-1': t('tracker.heading'),
    'help-center': t('headder.helpCenter'),
    about: 'Company',
    dashboard: t('headder.dashBoard')
  };

  useEffect(() => {
    let newValue = '';
    const pathname = location.pathname + location.search; // Include search params in the check
    for (const [key, value] of Object.entries(routeMappings)) {
      if (pathname === `/${key}`) {
        // Exact match for pathname and query string
        newValue = value;
        break;
      } else if (pathname.includes(key)) {
        newValue = value;
      }
    }
    setCurrentActiveMenu(newValue);
  }, [location, routeMappings, setCurrentActiveMenu]);

  return null;
};

export default CheckRoute;
