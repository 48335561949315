import {
  SETINTIALSTATEFOLDERDC,
  SETFOLDERDATADC,
  SETDOCEXPLORERLOADINGDC,
  SETGLOBALLOADINGDC,
  SETSELECTEDDOCCOMPARE,
  SETALLMESSAGESDCOM,
  SETLOADINGDCOM,
  SETLOADINGANSWERSTREAMDCOM,
  SETSTOPRESPONSEDCOM,
  SETCHATSCROLLDCOM,
  SETCHATSEARCHINPUTDCOM
} from '../../action.types';

const INITIAL_STATE = {
  folderDataDc: [],
  docExplorerloadingDc: false,
  globalLoadingDc: false,
  selectedDocCompare: [],
  allMessagesDCom: [],
  loadingDE: false,
  loadingAnswerStreamDCOM: false,
  stopResponseDCOM: false,
  chatScrollDCOM: false,
  chatsearchInputDCOM: ''
};
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETFOLDERDATADC:
      return {
        ...state,
        folderDataDc: action.data
      };

    case SETDOCEXPLORERLOADINGDC:
      return {
        ...state,
        docExplorerloadingDc: action.data
      };
    case SETGLOBALLOADINGDC:
      return {
        ...state,
        globalLoadingDc: action.data
      };
    case SETSELECTEDDOCCOMPARE:
      return {
        ...state,
        selectedDocCompare: action.data
      };
    case SETALLMESSAGESDCOM:
      return {
        ...state,
        allMessagesDCom: action.data
      };
    case SETINTIALSTATEFOLDERDC:
      return { ...INITIAL_STATE };
    case SETLOADINGDCOM:
      return {
        ...state,
        loadingDE: action.data
      };
    case SETLOADINGANSWERSTREAMDCOM:
      return {
        ...state,
        loadingAnswerStreamDCOM: action.data
      };
    case SETSTOPRESPONSEDCOM:
      return {
        ...state,
        stopResponseDCOM: action.data
      };
    case SETCHATSCROLLDCOM:
      return {
        ...state,
        chatScrollDCOM: action.data
      };
    case SETCHATSEARCHINPUTDCOM:
      return {
        ...state,
        chatsearchInputDCOM: action.data
      };

    default:
      return state;
  }
};

export default reducer;
