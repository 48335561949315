import { selecteEntitesFilterToDisplayMap } from '../../../../constants/tearSheet/tearSheet';
import { MenuItem, makeStyles, IconButton } from '@material-ui/core';
import { FormControl, InputLabel, Select } from '@mui/material';
import EntityTypeFilterTabs from '../../../atoms/entitySearch/entityTypeFilterTabs';
import { ReactComponent as DeleteBinIcon } from '../../../icons/DeleteBinIcon.svg';
import { ReactComponent as EditIcon } from '../../../icons/edit.svg';
import { useState, useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  templateSelectForm: {
    width: '305px',
    [theme.breakpoints.down('sm')]: {
      margin: '0px !important',
      width: '280px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '10px !important'
    }
  },
  inputLabel: {
    fontFamily: 'Satoshi !important',
    color: '#000000 !important',
    opacity: 0.6
  },
  '@media print': {
    inputLabel: {
      visibility: 'hidden'
    }
  },
  select: {
    backgroundColor: '#FFFFFF',
    height: '47px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& fieldset': {
      borderWidth: '1px !important',
      borderRadius: '3px',
      borderColor: '#E3EBFD !important'
    },
    '& ul.css-6hp17o-MuiList-root-MuiMenu-list': {
      padding: '0px'
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Satoshi !important'
    }
  },
  publicDataText: {
    fontSize: '14px',
    margin: '0px 0px 1px 8px',
    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover $actionIcons': {
      display: 'inline-flex'
    }
  },
  actionIcons: {
    display: 'none',
    '&:hover': {
      display: 'inline-flex'
    }
  }
}));

const ReportDropDown = ({
  reportData,
  reportId,
  handleReportChange,
  updateParamsInURL,
  navigate,
  location,
  selectedItem,
  handleReportDeleteOpen,
  getReports,
  query_report_id,
  styleClass
}) => {
  const [selectedFilter, setSelectedFilter] = useState('');
  const classes = useStyles();

  const handleEdit = (event, report_id) => {
    event.stopPropagation();
    navigateToPage('edit', report_id);
  };

  const handleDelete = (report) => {
    handleReportDeleteOpen(selectedItem?.entity_id, report.report_id, report.report_name);
  };

  const navigateToPage = (action, report_id = null) => {
    const adParams = { action, entity_id: selectedItem?.entity_id };
    updateParamsInURL({
      navigate,
      location,
      addParams: action === 'edit' && report_id ? { ...adParams, report_id } : adParams
    });
  };

  const onFilterChange = (filter) => {
    setSelectedFilter(filter);
    if (filter === 'create_new') {
      navigateToPage('create');
    }
  };

  useEffect(() => {
    if (!query_report_id && selectedItem?.entity_id && reportData && reportData.length > 0) {
      getReports({ entity_id: selectedItem?.entity_id });
    }
  }, [query_report_id]);

  return (
    <>
      <FormControl className={`${classes.templateSelectForm} ${styleClass}`}>
        <InputLabel className={`${classes.inputLabel} ${styleClass}`}>Report</InputLabel>
        <Select
          label="Report"
          className={`${classes.select} ${styleClass}`}
          value={reportId || ''}
          onChange={handleReportChange}
          style={{ fontFamily: 'Satoshi' }}
          disabled={!selectedItem?.entity_id}
        >
          <EntityTypeFilterTabs
            tabsOptions={selecteEntitesFilterToDisplayMap}
            setFilterTab={onFilterChange}
            selectedTab={selectedFilter}
          />
          {reportData &&
            reportData.length > 0 &&
            reportData.map((report, index) => (
              <MenuItem key={index} value={report.report_id} className={classes.menuItem}>
                <span>{report.report_name}</span>
                <div className={classes.actionIcons}>
                  <IconButton
                    onClick={(event) => {
                      handleEdit(event, report.report_id);
                    }}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                  {!report.share_report && (
                    <IconButton
                      onClick={() => {
                        handleDelete(report);
                      }}
                      size="small"
                    >
                      <DeleteBinIcon />
                    </IconButton>
                  )}
                </div>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default ReportDropDown;
