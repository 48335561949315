import React, { useState, useEffect } from 'react';
import { Paper, TextField, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { Autocomplete, IconButton } from '@mui/material';
import { Add, Check, Close, DragIndicator } from '@material-ui/icons';
import CustomTextField from '../../../atoms/textfield';
import CustomSelect from '../../../atoms/select';
import TertiaryButton from '../../../atoms/buttons/tertiaryBtn';
import InputError from '../../../atoms/inputError';
import { ContentCopy, ContentPaste } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ErrorSpan from '../../../atoms/errorSpan';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const defaultQuestion = {
  question: '',
  type: 'query',
  question_type: '',
  static_field: ''
};

const questionLib = [
  {
    question: 'Give me description of the company.',
    type: 'query',
    question_type: 'companies',
    static_field: ''
  },
  {
    question: 'Give me the valuation details of the company.',
    type: 'static',
    question_type: 'companies',
    static_field: 'Valuation'
  },
  {
    question: 'Give me the annual revenue details of the company.',
    type: 'static',
    question_type: 'companies',
    static_field: 'Annual Revenue'
  },
  {
    question: 'Tell me about business model of the company in detail.',
    type: 'query',
    question_type: 'companies',
    static_field: ''
  },
  {
    question: 'Tell me about product description or product portfolio of the company.',
    type: 'query',
    question_type: 'companies',
    static_field: ''
  },
  {
    question: 'Tell me about various investment stages of the company.',
    type: 'static',
    question_type: 'companies',
    static_field: 'Round Construct'
  },
  {
    question: 'Tell me about founders of the company.',
    type: 'static',
    question_type: 'companies',
    static_field: 'Key People'
  },
  {
    question: 'Tell me about competitors of the company.',
    type: 'static',
    question_type: 'companies',
    static_field: 'Competitors'
  },
  {
    question: 'Give me risks associated with the company in terms of business and investments.',
    type: 'query',
    question_type: 'companies',
    static_field: ''
  },
  {
    question: 'Tell me about traction for business model of company.',
    type: 'query',
    question_type: 'companies',
    static_field: ''
  },
  {
    question: 'Give me the consolidated profit and loss statement for the company.',
    type: 'static',
    question_type: 'companies',
    static_field: 'Profit and Loss'
  },
  {
    question: 'Give me the total funding raised by the company.',
    type: 'static',
    question_type: 'companies',
    static_field: 'Total Funding Raised'
  }
  // ...other questions
];

const questionTypes = [
  { key: 'companies', value: 'Company' },
  { key: 'people', value: 'People' },
  { key: 'sector', value: 'Theme' },
  { key: 'screener', value: 'Screener' },
  { key: 'fund', value: 'Fund' }
];

const useStyles = makeStyles(() => ({
  mainDiv: {
    padding: '14px',
    position: 'relative'
  },
  sectionLevelOne: {
    display: 'flex',
    width: '100%'
  },
  closeSectionBtn: {
    position: 'absolute',
    top: '0',
    right: '0'
  },
  keyDiv: {
    width: '14%',
    margin: '18px 0px 0px'
  },
  textDiv: {
    width: '56%',
    margin: '16px 0px 0px'
  },
  keyTypography: {
    fontSize: '16px',
    marginTop: '4px'
  },
  questionsDiv: {
    display: 'flex',
    flexDirection: 'column',
    margin: '16px 0px 0px'
  },
  selectDiv: {
    width: '14%',
    marginLeft: '2%'
  },
  closeQuestionDiv: {
    '& button': {
      padding: '7px',
      marginLeft: '5px',
      margin: '16px 0px 0px'
    }
  },
  addQuestionBtn: {
    margin: '0 14% 0 auto'
  },
  dragDiv: {
    position: 'absolute',
    top: '-4px',
    justifyContent: 'center',
    display: 'flex',
    width: '100%'
  },
  dragQDiv: {
    width: '10%',
    marginLeft: '7px',
    margin: '16px 0px 0px',
    display: 'flex',
    justifyContent: 'start'
  },
  dragIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    transform: 'rotate(90deg)',
    cursor: 'all-scroll'
  },
  questionAutocomplete: {
    '& .MuiInputBase-input': {
      height: '7px !important'
    }
  },
  autoCompleteTextroot: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #004CFF !important',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '3px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input': {
      height: '1px !important',
      color: 'rgba(0, 0, 0, 0.87) !important'
    },
    '& .MuiInputLabel-formControl': {
      top: '-10px',
      color: 'rgba(0, 0, 0, 0.54)'
    },
    '& .MuiInputLabel-shrink': {
      top: '0'
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: '0px'
    }
  }
}));

// Controlled TemplateSectionCard: expects section data & update callbacks from parent.
const TemplateSectionCard = ({
  disabled = false,
  dragHandleProps,
  section,
  handleSectionChange,
  handleDeleteSection,
  copyEnabled = true,
  errorObject = errorObject,
  currentKpiReport = currentKpiReport,
  index = index,
  onQuestionsReorder
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  // Local states only for copy/paste UI feedback
  const [copied, setCopied] = useState(false);
  const [pasteEnabled, setPasteEnabled] = useState(false);
  const MAX_AGE = 60 * 60 * 24 * 1000; // 24 hours

  useEffect(() => {
    const itemStr = localStorage.getItem('section');
    if (itemStr) {
      const item = JSON.parse(itemStr);
      const now = new Date().getTime();
      if (now - item.timestamp < MAX_AGE) {
        setPasteEnabled(true);
      } else {
        localStorage.removeItem('section');
      }
    }
  }, []);

  const handleSectionCopy = () => {
    localStorage.setItem(
      'section',
      JSON.stringify({
        timestamp: new Date().getTime(),
        data: section
      })
    );
    setCopied(true);
    setTimeout(() => setCopied(false), 5000);
  };

  const handleSectionPaste = () => {
    const copiedSectionStr = localStorage.getItem('section');
    if (!copiedSectionStr) return;
    const copiedSection = JSON.parse(copiedSectionStr);
    const now = new Date().getTime();
    if (now - copiedSection.timestamp < MAX_AGE) {
      handleSectionChange(copiedSection.data);
    } else {
      localStorage.removeItem('section');
    }
  };

  const handleHeadingChange = (value) => {
    handleSectionChange({ ...section, name: value });
  };

  const handleQuestionChange = (questionIndex, newQuestion) => {
    const updatedQuestions = [...section.questions];
    updatedQuestions[questionIndex] = {
      ...updatedQuestions[questionIndex],
      question: newQuestion
    };
    // const libQuestion = questionLib.find((q) => q.question === newQuestion);
    // if (libQuestion) {
    //   updatedQuestions[questionIndex] = {
    //     ...updatedQuestions[questionIndex],
    //     question: libQuestion.question,
    //     question_type: libQuestion.type,
    //     static_field: libQuestion.static_field
    //   };
    // } else {
    //   updatedQuestions[questionIndex] = {
    //     ...updatedQuestions[questionIndex],
    //     question: newQuestion
    //   };
    // }
    handleSectionChange({ ...section, questions: updatedQuestions });
  };

  const handleQuestionTypeChange = (questionIndex, newType) => {
    const updatedQuestions = [...section.questions];
    updatedQuestions[questionIndex] = {
      ...updatedQuestions[questionIndex],
      question_type: newType
    };
    handleSectionChange({ ...section, questions: updatedQuestions });
  };

  const handleDeleteQuestion = (questionIndex) => {
    const updatedQuestions = section.questions.filter((_, index) => index !== questionIndex);
    handleSectionChange({ ...section, questions: updatedQuestions });
  };

  const handleAddQuestion = () => {
    handleSectionChange({ ...section, questions: [...section.questions, defaultQuestion] });
  };

  const handleQuestionsReorderInternal = (result) => {
    if (!result.destination) return;
    const reorderedQuestions = Array.from(section.questions);
    const [removed] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, removed);
    handleSectionChange({ ...section, questions: reorderedQuestions });
    if (onQuestionsReorder) {
      onQuestionsReorder(index, result);
    }
  };

  return (
    <Paper fullwidth className={classes.mainDiv} elevation={3}>
      {!disabled && (
        <div className={classes.dragDiv}>
          {/* Spread the dragHandleProps to enable dragging */}
          <span {...dragHandleProps} className={classes.dragIcon}>
            <DragIndicator />
          </span>
        </div>
      )}
      <div className={classes.closeSectionBtn}>
        {copyEnabled && (
          <Tooltip title={copied ? t('Section Copied!') : t('Copy Section')}>
            <IconButton onClick={handleSectionCopy}>
              {copied ? <Check style={{ color: '#22A428' }} /> : <ContentCopy />}
            </IconButton>
          </Tooltip>
        )}
        {!disabled && (
          <Tooltip title={t('Paste Section')}>
            <IconButton onClick={handleSectionPaste} disabled={!pasteEnabled}>
              <ContentPaste />
            </IconButton>
          </Tooltip>
        )}
        {!disabled && (
          <Tooltip title={t('Remove Section')}>
            <IconButton onClick={handleDeleteSection}>
              <Close />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className={classes.sectionLevelOne}>
        <div className={classes.keyDiv}>
          <Typography className={classes.keyTypography}>
            {t('tearSheet.sectionHeadingLabel') || 'Section Heading'}
          </Typography>
        </div>
        <div className={classes.textDiv}>
          <CustomTextField
            value={section.name}
            handleChange={handleHeadingChange}
            placeholder={t('tearSheet.sectionHeadingPlaceholder') || 'Enter section heading'}
            disabled={disabled}
          />
          <ErrorSpan value={errorObject?.question_groups?.[index]?.name || ''} />
        </div>
      </div>
      {section.questions.length === 0 && (
        <div style={{ marginTop: '8px' }}>
          <InputError
            errorMessage={
              t('At least one question is required') || 'At least one question is required'
            }
          />
        </div>
      )}
      {section.questions.length > 0 && (
        <DragDropContext onDragEnd={handleQuestionsReorderInternal}>
          <Droppable droppableId={`questions-${index}`} direction="vertical">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={classes.questionsDiv}
              >
                {section.questions.map((questionObj, questionIndex) => (
                  <Draggable
                    key={questionIndex}
                    draggableId={`question-${questionIndex}`}
                    index={questionIndex}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={classes.sectionLevelOne}
                        style={{ ...provided.draggableProps.style }}
                      >
                        {/* Question Content */}
                        <div className={classes.keyDiv}>
                          <Typography className={classes.keyTypography}>
                            {`${t('tearSheet.questionLabel') || 'Question'} ${questionIndex + 1}`}
                          </Typography>
                        </div>
                        <div className={classes.textDiv}>
                          <Autocomplete
                            freeSolo
                            className={classes.questionAutocomplete}
                            value={questionObj.question}
                            options={questionLib.map((option) => option.question)}
                            onInputChange={(event, value) => {
                              handleQuestionChange(questionIndex, value);
                            }}
                            renderInput={(params) => (
                              <form noValidate>
                                <TextField
                                  {...params}
                                  label="Question"
                                  variant="outlined"
                                  className={`${classes.autoCompleteTextroot}`}
                                />
                              </form>
                            )}
                            disabled={disabled}
                            disableClearable
                            autoHighlight
                            autoComplete
                          />
                          <ErrorSpan
                            value={
                              errorObject?.question_groups?.[index]?.questions?.[questionIndex]
                                ?.question || ''
                            }
                          />
                        </div>
                        <div className={classes.selectDiv}>
                          <CustomSelect
                            value={questionObj.question_type}
                            options={questionTypes}
                            handleChange={(value) => handleQuestionTypeChange(questionIndex, value)}
                            label={t('tearSheet.questionTypeLabel') || 'Question Type'}
                            placeholder={
                              t('tearSheet.questionTypePlaceholder') || 'Select question type'
                            }
                            disabled={disabled}
                          />
                          <ErrorSpan
                            value={
                              errorObject?.question_groups?.[index]?.questions?.[questionIndex]
                                ?.question_type || ''
                            }
                          />
                        </div>
                        <div
                          className={classes.dragQDiv}
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                        >
                          <span className={classes.dragIcon}>
                            <DragIndicator />
                          </span>
                        </div>
                        {!disabled && (
                          <div className={classes.closeQuestionDiv}>
                            <IconButton onClick={() => handleDeleteQuestion(questionIndex)}>
                              <Close />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {!disabled && (
        <div className={classes.sectionLevelOne}>
          <div className={classes.addQuestionBtn}>
            <TertiaryButton
              handleClick={handleAddQuestion}
              btnText={t('tearSheet.addQuestionBtn') || 'Add Question'}
              StartIcon={Add}
            />
          </div>
        </div>
      )}
    </Paper>
  );
};

export default TemplateSectionCard;
