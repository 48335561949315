import React, { useEffect, useState, useContext, useRef } from 'react';
import RemainingCredits from '../../../atoms/creditCount';
import { getApi } from '../../../../services/token';
import useStyles from './styles';
import Title from './Title';
import InputComponent from './Input';
import Chat from './chatbox';
import { v4 as uuidv4 } from 'uuid';
import { AuthContext } from '../../../auth/contexts/authContext';
import { useSelector } from 'react-redux';
import {
  setAllMessagesDE,
  setChatSearchInputDE,
  setLoadingAnswerStreamDE,
  setLoadingDE,
  setStopResponseDE
  // setSuggestedDeQuestions
} from '../../action';
import { postApi, streamApi } from '../../../../services/token';
import { currentTime } from '../../utils/formatedTime';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getRandomStrings } from '../../../../helpers';

const PAYMENT_API_URL = process.env.REACT_APP_PAYMENT_API_URL;
const chatQueryURL = process.env.REACT_APP_CHAT_SERVER_API;
const chatQueryErrorURL = `${process.env.REACT_APP_API}/chat-query-error`;
// const baseUrl = process.env.REACT_APP_API_BASE_LB;

const DocExplorerChat = ({ checkIFqueryRunning }) => {
  const classes = useStyles();
  const [credit, setCredit] = useState(null);
  const { t } = useTranslation();

  const uuid = useSelector((state) => state.tokenReducer.uuID);
  const allMessagesDE = useSelector((state) => state.tokenReducer.allMessagesDE);
  const selectedDocExp = useSelector((state) => state.docExplorerReducer.selectedDocExplorer);
  const loadingAnswerStreamDE = useSelector((state) => state.tokenReducer.loadingAnswerStreamDE);
  const suggestedDeQuestions = useSelector((state) => state.tokenReducer.suggestedDeQuestions);

  const dispatch = useDispatch();
  const auth = useContext(AuthContext);
  const stopResponseInetialRef = useRef(false);
  const stopFlagRef = useRef(false);
  const [stopResponseInetial, setStopResponseInetial] = useState(false);

  const handleStopResponseInetial = () => {
    stopResponseInetialRef.current = true;
  };

  const handleStopResponse = () => {
    stopFlagRef.current = true;
    dispatch(setLoadingAnswerStreamDE(false));
    dispatch(setLoadingDE(false));
    dispatch(setStopResponseDE(false));
  };

  console.log(stopResponseInetial, handleStopResponseInetial);

  const handleQuestionClick = (event, inputValue) => {
    event.preventDefault();
    if (checkIFqueryRunning()) {
      return;
    }
    const qid = uuidv4();
    if (inputValue === 'Summary' || inputValue === 'Key Insights') {
      fetchDataForSummaryKeyInsights(qid, inputValue, false);
    } else {
      dispatch(setLoadingAnswerStreamDE(true));
      dispatch(setLoadingDE(true));
      dispatch(setStopResponseDE(true));
      fetchData(qid, inputValue, false);
    }
  };

  const fetchDataForSummaryKeyInsights = async (qID, questionQuery, regenerateResponse) => {
    console.log(regenerateResponse);
    const newMessage = {
      question: questionQuery,
      question_time: currentTime(),
      messageId: qID
    };

    const chatResp =
      questionQuery === 'Summary'
        ? suggestedDeQuestions.summary
        : suggestedDeQuestions.key_insights;

    try {
      // Use the response directly from `aa`
      const updatedMessage = {
        answer: chatResp,
        like: false,
        unlike: false,
        answer_time: currentTime()
      };

      dispatch(
        setAllMessagesDE({
          ...allMessagesDE,
          [qID]: { ...newMessage, ...updatedMessage }
        })
      );

      // Optionally handle follow-up questions (if applicable)
      try {
        const followUpQuestionsData = await getSuggestedQuestions();
        const show_more_flag = followUpQuestionsData?.data?.data?.show_more_flag;
        const followUpQuestions = {
          follow_up_q: followUpQuestionsData || [],
          disclaimer: followUpQuestionsData?.data?.data?.disclaimer,
          showMore: false
        };
        if (show_more_flag) {
          const showMore = {
            showMore: true,
            disclaimer: followUpQuestionsData?.data?.data?.disclaimer
          };
          dispatch(
            setAllMessagesDE({
              ...allMessagesDE,
              [qID]: { ...newMessage, ...updatedMessage, ...showMore }
            })
          );
        } else {
          dispatch(
            setAllMessagesDE({
              ...allMessagesDE,
              [qID]: { ...newMessage, ...updatedMessage, ...followUpQuestions }
            })
          );
        }
      } catch (error) {
        console.error('Error fetching follow-up questions:', error);
      }
    } catch (error) {
      console.error('Error processing response:', error);

      const updatedMessage = {
        answer: t('askBot.chatFailMsg'),
        like: false,
        unlike: false,
        answer_time: currentTime()
      };

      dispatch(
        setAllMessagesDE({
          ...allMessagesDE,
          [qID]: { ...newMessage, ...updatedMessage }
        })
      );
    }
  };

  const getTokenVal = async () => {
    const session = await auth.getSession();
    const currentJwtToken = session?.idToken.jwtToken;
    return currentJwtToken;
  };

  const getSuggestedQuestions = async () => {
    return getRandomStrings(suggestedDeQuestions.questions, 5);
    // try {
    //   const docids =
    //     selectedDocExp && selectedDocExp.length > 0 ? selectedDocExp.map((x) => x.doc_id) : [];
    //   dispatch(setSuggestedDeQuestions({ questions: [], loading: true }));
    //   let ingestionData = await postApi(`${baseUrl}/server/suggested-questions`, {
    //     doc_ids: docids
    //   });

    //   dispatch(setSuggestedDeQuestions({ questions: ingestionData.data, loading: false }));
    //   return ingestionData.data || [];
    // } catch (error) {
    //   dispatch(setSuggestedDeQuestions({ questions: [], loading: false }));
    //   return [];
    // }
  };

  const fetchData = async (qID, questionQuery, regenerateResponse) => {
    console.log(regenerateResponse);
    const apiQid = uuidv4();
    const docids =
      selectedDocExp && selectedDocExp.length > 0 ? selectedDocExp.map((x) => x.id) : [];
    let body = {
      query: questionQuery,
      session_id: uuid,
      streaming: true,
      question_id: apiQid,
      company_name: '',
      cin: '',
      question_type: '',
      request_identifier: 'DocExplorer'
    };

    if (docids && docids.length > 0) {
      body['doc_ids'] = docids;
    }

    const newMessage = {
      question: questionQuery,
      question_time: currentTime(),
      messageId: qID
    };

    const currentTokenVal = await getTokenVal();

    dispatch(
      setAllMessagesDE({ ...allMessagesDE, [qID]: { ...allMessagesDE[qID], ...newMessage } })
    );

    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        const timeoutError = new Error('chat Query 30 seconds time out');
        timeoutError.code = 110;
        reject(timeoutError);
      }, 30000);
    });

    let stopButtonInterval;

    const stopButtonPromise = new Promise((resolve, reject) => {
      stopButtonInterval = setInterval(() => {
        if (stopResponseInetialRef.current === true) {
          setStopResponseInetial(false);
          clearInterval(stopButtonInterval); // Clear interval if condition met
          const stopButtonPromiseErr = new Error('user stop button trigger');
          reject(stopButtonPromiseErr);
        }
      }, 200);
    });

    const chatQueryPromise = Promise.race([
      streamApi(chatQueryURL, currentTokenVal, { ...body }),
      timeoutPromise,
      stopButtonPromise
    ]);

    let accumulatedData = '';
    let updatedMessage;
    chatQueryPromise
      .then(async ({ result }) => {
        dispatch(setLoadingDE(false));
        // dispatch(setShowCompanyButton(false));
        const followUpQuestionsAPI = getSuggestedQuestions();
        const reader = result.getReader();
        const decoder = new TextDecoder();
        const processChunk = async (chunk) => {
          dispatch(setStopResponseDE(true));
          setStopResponseInetial(false);
          let testDecodedChunkIfEmpty = decoder.decode(chunk.value, { stream: true });
          if (chunk.done && accumulatedData === '' && testDecodedChunkIfEmpty === '') {
            updatedMessage = {
              creditsExpired: t('askBot.chatFailMsg')
            };

            dispatch(
              setAllMessagesDE({
                ...allMessagesDE,
                [qID]: { ...newMessage, ...updatedMessage }
              })
            );
            dispatch(setStopResponseDE(false));
            dispatch(setLoadingAnswerStreamDE(false));
            dispatch(setLoadingDE(false));
            dispatch(setChatSearchInputDE(''));

            let errorBody = {
              session_id: uuid,
              question_id: body.question_id,
              query: body.query,
              error_msg: '500 error 200 response with empty body',
              error_code: 500
            };
            postApi(chatQueryErrorURL, errorBody);
          } else if (chunk.done) {
            stopFlagRef.current = false;
            // const followUpQuestionsData = await followUpQuestionsAPI;
            try {
              const followUpQuestionsData = await followUpQuestionsAPI;
              const show_more_flag = followUpQuestionsData?.data?.data?.show_more_flag;
              const followUpQuestions = {
                follow_up_q: followUpQuestionsData || [],
                disclaimer: followUpQuestionsData?.data?.data?.disclaimer,
                showMore: false
              };
              if (show_more_flag) {
                const showMore = {
                  showMore: true,
                  disclaimer: followUpQuestionsData?.data?.data?.disclaimer
                };
                dispatch(
                  setAllMessagesDE({
                    ...allMessagesDE,
                    [qID]: { ...newMessage, ...updatedMessage, ...showMore }
                  })
                );
              } else {
                dispatch(
                  setAllMessagesDE({
                    ...allMessagesDE,
                    [qID]: { ...newMessage, ...updatedMessage, ...followUpQuestions }
                  })
                );
              }
            } catch (error) {
              dispatch(
                setAllMessagesDE({
                  ...allMessagesDE,
                  [qID]: { ...newMessage, ...updatedMessage }
                })
              );
            }
            dispatch(setStopResponseDE(false));
            // dispatch(setShowCompanyButton(true));
            dispatch(setLoadingAnswerStreamDE(false));
            dispatch(setChatSearchInputDE(''));
            return;
          } else if (stopFlagRef.current) {
            stopFlagRef.current = false;
            dispatch(setStopResponseDE(false));
            // dispatch(setRegenerateResponse(true));
            // // dispatch(setShowCompanyButton(true));
            dispatch(setLoadingAnswerStreamDE(false));
            dispatch(setChatSearchInputDE(''));
            return;
          } else {
            let decodedChunk = decoder.decode(chunk.value, { stream: true });
            accumulatedData += decodedChunk;
            updatedMessage = {
              answer: accumulatedData,
              like: false,
              unlike: false,
              answer_time: currentTime()
            };

            dispatch(
              setAllMessagesDE({
                ...allMessagesDE,
                [qID]: { ...newMessage, ...updatedMessage }
              })
            );
            return reader.read().then(processChunk);
          }
        };
        reader.read().then(async (chunk) => {
          await processChunk(chunk);
          // checkCreditAndHideExpiryPopup();
          // await getRemainingCredits(true);
        });
      })
      .catch(async (error) => {
        console.log(error);
        let errorBody = {
          session_id: uuid,
          question_id: body.question_id,
          query: body.query,
          error_msg: error.message,
          error_code: error.code
        };
        setStopResponseInetial(false);
        dispatch(setStopResponseDE(false));
        if (error.code === 110) {
          // Handle API timeout here
          dispatch(setLoadingAnswerStreamDE(false));
          dispatch(setLoadingDE(false));
          const updatedMessage = {
            answer: t('askBot.chatTimeOutMsg'),
            like: false,
            unlike: false,
            answer_time: currentTime()
          };
          dispatch(
            setAllMessagesDE({
              ...allMessagesDE,
              [qID]: { ...newMessage, ...updatedMessage }
            })
          );
          dispatch(setChatSearchInputDE(''));
          // dispatch(setRegenerateResponse(true));
        } else {
          const errorMessage =
            error?.message &&
            (() => {
              try {
                return JSON.parse(error.message);
              } catch (e) {
                return error.message;
              }
            })();
          if (error.message === 'Failed to fetch') {
            updatedMessage = {
              answer: t('askBot.chatTimeOutMsg'),
              like: false,
              unlike: false,
              answer_time: currentTime()
            };
          } else if (
            errorMessage.status === 401 ||
            errorMessage.status === 403 ||
            errorMessage.status === 404 ||
            errorMessage.status === 501
          ) {
            updatedMessage = {
              creditsExpired:
                errorMessage.text === null ? t('askBot.chatFailMsg') : errorMessage.text
            };
          } else {
            updatedMessage = {
              creditsExpired: t('askBot.chatFailMsg')
            };
          }
          dispatch(
            setAllMessagesDE({
              ...allMessagesDE,
              [qID]: { ...newMessage, ...updatedMessage }
            })
          );
          dispatch(setLoadingDE(false));
          dispatch(setLoadingAnswerStreamDE(false));
          dispatch(setChatSearchInputDE(''));
          // dispatch(setRegenerateResponse(true));
        }
        await postApi(chatQueryErrorURL, errorBody);
        // checkCreditAndHideExpiryPopup();
        // await getRemainingCredits(true);
      });
  };

  const getCreditsData = async () => {
    const { data } = await getApi(`${PAYMENT_API_URL}/user/limits`);
    const limitsData = data?.data;
    const currentCredit =
      (limitsData?.limits &&
        limitsData?.limits.length > 0 &&
        limitsData.limits.find((x) => x.type === 'QNA')?.remaining_limits) ||
      0;
    setCredit(currentCredit);
  };

  useEffect(() => {
    if (!loadingAnswerStreamDE) getCreditsData();
  }, [loadingAnswerStreamDE]);

  return (
    <div className={classes.mainChatGridContainer}>
      <Title title="headder.docExplorer" />
      <Chat
        handleQuestionClick={handleQuestionClick}
        checkIFqueryRunning={checkIFqueryRunning}
        handleStopResponse={handleStopResponse}
      />
      <InputComponent
        handleQuestionClick={handleQuestionClick}
        checkIFqueryRunning={checkIFqueryRunning}
      />
      {credit != null && <RemainingCredits credit={credit} />}
    </div>
  );
};

export default React.memo(DocExplorerChat);
