import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setPortfolioScreenertab } from '../../../../../redux/pages/portfolioTracker/action';

function PortfolioTabs() {
  const dispatch = useDispatch();

  const portfolioScreenerTabs = useSelector(
    (state) => state.portfolioTrackerReducer.portfolioScreenerTabs
  );
  const portfolioScreenerTab = useSelector(
    (state) => state.portfolioTrackerReducer.portfolioScreenerTab
  );

  const handleTabChange = (event, newValue) => {
    dispatch(setPortfolioScreenertab(newValue));
  };

  return (
    <>
      <div style={{ padding: '0px 0px 0px 16px' }}>
        <Tabs
          value={portfolioScreenerTab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            sx: {
              bgcolor:
                portfolioScreenerTab ===
                portfolioScreenerTabs.find((filter) => filter.key === portfolioScreenerTab)?.key
                  ? '#004CFF !important'
                  : '#767676 !important',
              height: '1px'
            }
          }}
          //style={{ padding: '0px' }}
        >
          {portfolioScreenerTabs.map((filter) => (
            <Tab
              key={filter.key}
              label={filter.value}
              value={filter.key}
              sx={{
                textTransform: 'none',
                fontFamily: 'Satoshi',
                fontWeight: 'bold',
                fontSize: '14px',
                minWidth: '60px',
                padding: '10px 8px 0px 8px !important',
                color:
                  portfolioScreenerTab === filter.key ? '#004CFF !important' : '#767676 !important'
              }}
            />
          ))}
        </Tabs>
      </div>
    </>
  );
}

export default PortfolioTabs;
