import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import DocExplorerFiles from './Files';
import DocExplorerChat from './Chat';
import ErrorToast from '../../atoms/errorToast';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hideShowMessage } from '../../../helpers';
import SuccessToast from '../../atoms/successToast';
//import LoadingPopover from '../loadingPopover/loadingPopover';
import Title from './Chat/Title';
import Watchlist from './watchlist';
import StaticTearSheetTemplate from './kpi';
// import { setLoadingPT } from '../../../redux/pages/portfolioTracker/action';

const useStyles = makeStyles((theme) => ({
  mainGridContainer: {
    padding: '0px 20px 10px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 80px'
    }
  }
}));

const isSeparateState = !(process.env.REACT_APP_GLOBAL_STATE === 'true');
// const updateAllMessageAction = isSeparateState ? setLoadingPT : setAllMessagesDE;

const PortfolioTracker = () => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const loadingDE = useSelector((state) =>
    isSeparateState ? state.portfolioTrackerReducer.loadingDE : state.tokenReducer.loadingDE
  );
  const loadingAnswerStreamDE = useSelector((state) =>
    isSeparateState
      ? state.portfolioTrackerReducer.loadingAnswerStreamPT
      : state.tokenReducer.loadingAnswerStreamDE
  );
  const portfolioScreenerTab = useSelector(
    (state) => state.portfolioTrackerReducer.portfolioScreenerTab
  );
  // const globalLoadingDc = useSelector(
  //   (state) => state.portfolioTrackerReducer.portfolioGlobalLoading
  // );
  const enablePortfolio = process.env.REACT_APP_ENABLE_PORTFOLIO === 'true';
  const navigate = useNavigate();
  //alert(globalLoadingDc);
  useEffect(() => {
    if (!enablePortfolio) {
      navigate('/home');
    }
  }, []);

  const showErrorMessage = (msg) => {
    hideShowMessage(msg, setErrorMessage, 3000);
  };

  const showSuccessMessage = (msg) => {
    hideShowMessage(msg, setSuccessMessage, 3000);
  };

  const checkIFqueryRunning = () => {
    if (loadingDE || loadingAnswerStreamDE) {
      showErrorMessage('Your query is being processed. Please wait for a response.');
      return true;
    }
    return false;
  };
  //<LoadingPopover open={globalLoadingDc} />
  const loadingPortfolioTracker = useSelector(
    (state) => state.portfolioTrackerReducer.loadingPortfolioTracker
  );
  return (
    <>
      <StaticTearSheetTemplate />
      <ErrorToast errorMessage={errorMessage} />
      <SuccessToast successMessage={successMessage} />
      <div style={{ minHeight: 'calc(100vh - 120px)' }}>
        <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
          <div style={{ padding: '12px 20px 0px 24px' }}>
            <Title title="Portfolio - Company Tracker" />
          </div>
          <Grid container className={classes.mainGridContainer}>
            <Grid
              item
              xs={2.5}
              style={{
                paddingRight: '20px',
                opacity: loadingPortfolioTracker ? '0.5' : '1',
                pointerEvents: loadingPortfolioTracker ? 'none' : 'auto'
              }}
            >
              <Watchlist />
            </Grid>
            <Grid
              item
              xs={portfolioScreenerTab === 'external' ? 9.5 : 6.7}
              style={{
                paddingRight: '20px',
                opacity: loadingPortfolioTracker ? '0.5' : '1',
                pointerEvents: loadingPortfolioTracker ? 'none' : 'auto'
              }}
            >
              <DocExplorerChat checkIFqueryRunning={checkIFqueryRunning} />
            </Grid>
            {portfolioScreenerTab !== 'external' && (
              <Grid
                item
                xs={2.8}
                style={{
                  paddingRight: '20px',
                  opacity: loadingPortfolioTracker ? '0.5' : '1',
                  pointerEvents: loadingPortfolioTracker ? 'none' : 'auto'
                }}
              >
                <DocExplorerFiles
                  showErrorMessage={showErrorMessage}
                  showSuccessMessage={showSuccessMessage}
                  checkIFqueryRunning={checkIFqueryRunning}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default PortfolioTracker;
