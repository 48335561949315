import { Box, Button, Dialog, DialogContent, IconButton } from '@mui/material';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  boxRelative: {
    position: 'relative'
  },
  headerPt: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  headerCh1: {
    '& img': {
      width: '47px',
      height: '33px'
    }
  },
  closeBtn: {
    position: 'absolute !important',
    right: '10px',
    padding: '10px',
    top: '8px',
    cursor: 'pointer',
    '& svg': {
      height: '24px',
      width: '24px'
    }
  },
  dialogTitle: {
    fontSize: '20px',
    fontFamily: 'Satoshi',
    fontWeight: '700',
    'margin-top': '-6px',
    'padding-left': '20px!important',
    color: '#171717',
    borderRadius: '8px'
  },
  dialogContentTitle: {
    'font-size': '16px',
    marginBottom: '20px',
    fontFamily: 'Satoshi',
    fontWeight: 400,
    padding: '0 46px'
  },
  dialogFooterDiv: {
    display: 'flex',
    justifyContent: 'center',
    'border-top': '1px solid #E6E8ED',
    'padding-top': '20px'
  },
  dialogFooterButton: {
    gap: '10px',
    padding: '10px 20px',
    border: 'none',
    textTransform: 'none',
    height: '33px',
    color: '#FFFFFF!important',
    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)'
  }
}));

const ConfirmationPopOver = ({
  content,
  openState = false,
  text,
  handleClose = () => {},
  trigger = () => {}
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={openState} fullWidth={true} maxWidth="sm">
      <Box p={0} width="100%" className={classes.boxRelative}>
        <br />
        <div className={classes.headerPt}>
          {/* <div className={classes.headerCh1}>
            <img src="/images/icons/deleteIcon.svg" alt="PrivateBlok-Logo" className="logo" />
          </div> */}
          <div>
            <Typography className={classes.dialogTitle}>{text}</Typography>
          </div>
          <div>
            <IconButton
              className={classes.closeBtn}
              onClick={() => {
                handleClose();
              }}
            >
              <Close />
            </IconButton>
          </div>
        </div>
        <DialogContent style={{ padding: '24px 0 0 0' }}>
          <>
            <Typography align="center" className={classes.dialogContentTitle}>
              {content ? content : t('dialog.confirmation')}
            </Typography>
            <div className={classes.dialogFooterDiv}>
              <Button
                variant="outlined"
                className={classes.dialogFooterButton}
                onClick={() => {
                  trigger();
                }}
              >
                {t('askBot.feedbackSave')}
              </Button>
            </div>
            <br />
          </>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ConfirmationPopOver;
