import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const Android12Switch = styled(Switch)(({ theme }) => ({
  transform: 'scale(0.75)',
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: '#AEAEAE',
    transition: theme.transitions.create(['background-color'], {
      duration: 200
    })
  },
  '& .Mui-checked + .MuiSwitch-track': {
    background: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    opacity: '1 !important'
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    color: '#FFFFFF'
  }
}));

export default function ToggleSwitchComponent({ value, onChange }) {
  return (
    <FormGroup>
      <FormControlLabel
        control={<Android12Switch checked={value} onChange={(e) => onChange(e.target.checked)} />}
        label=""
      />
    </FormGroup>
  );
}
