import { Box, Divider, Chip } from '@mui/material';
import { Button, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mainBox: {
    padding: '4px 14px 4px 14px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  tabBox: {
    borderRadius: '4px',
    display: 'flex',
    padding: '2px 10px',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #E4EBFC',
    background: '#FFF',
    marginRight: '6px',
    minWidth: '10px',
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      '& p': {
        color: 'rgba(23, 23, 23, 0.30) !important'
      }
    }
  },
  selectedTabBox: {
    background: '#004CFF !important',
    border: 'none!important'
  },
  tabTypo: {
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 600
  },
  selectedTabTypo: {
    color: '#ffffff!important'
  },
  breakLine: {
    background: '#EDEDED',
    height: '1px',
    marginTop: '8px !important'
  },
  pd2: {
    padding: '2px'
  },
  chipLabel: {
    padding: '0 !important',
    height: 'auto !important',
    'justify-content': 'center!important',
    'align-items': 'center!important',
    '& span': {
      // wordBreak: 'break-word!important',
      display: 'block!important',
      'white-space': 'normal !important'
    }
  },
  chipContainer: {
    display: 'flex',
    width: '100%',
    'justify-content': 'space-between',
    'overflow-x': 'hidden',
    transition: 'overflow-x 0.3s ease',
    '&:hover': {
      'overflow-x': 'auto'
    }
  },
  similarThemeTxt: {
    margin: '0 0 0 18px',
    color: '#373737',
    'font-family': 'Satoshi',
    'font-size': '14px',
    'font-style': 'normal',
    'font-weight': 700,
    'line-height': '24px'
  },
  defaultColorTab: {
    color: '#171717'
  },
  btn: {
    color: '#004CFF'
  },
  noBdBg: {
    border: 'none!important',
    background: 'none!important'
  }
}));

const EntityTypeFilterTabs = (props) => {
  const {
    tabsOptions,
    setFilterTab,
    selectedTab,
    showChips = false,
    chipsData = [],
    onSelectEntity = () => {},
    defaultOptionJsx = ''
  } = props;
  const classes = useStyles();
  const handleTabChange = (key) => {
    setFilterTab(key);
  };

  return (
    <div {...props}>
      <Box className={classes.mainBox}>{defaultOptionJsx && defaultOptionJsx}</Box>
      {tabsOptions && tabsOptions.length > 0 ? (
        <>
          <Box className={classes.mainBox}>
            {tabsOptions.map((option) => (
              <>
                {option.isText ? (
                  <Typography className={`${classes.tabTypo} ${classes.pd2}`}>
                    {option.value}
                  </Typography>
                ) : (
                  <Button
                    variant="outlined"
                    className={`${option?.isButton ? classes.noBdBg : ''} ${classes.tabBox} ${
                      selectedTab === option.key && classes.selectedTabBox
                    }`}
                    key={option.key}
                    onClick={() => handleTabChange(option?.key)}
                    disabled={option.disabled}
                  >
                    <Typography
                      className={`${option?.isButton ? classes.btn : classes.defaultColorTab} ${
                        classes.tabTypo
                      } ${selectedTab === option.key && classes.selectedTabTypo}`}
                    >
                      {option.value}
                    </Typography>
                  </Button>
                )}
              </>
            ))}
          </Box>
          <Divider className={classes.breakLine} />
          {showChips && chipsData && chipsData.length > 0 && (
            <>
              <p className={classes.similarThemeTxt}>Similar theme</p>
              <Box className={classes.mainBox}>
                <div className={classes.chipContainer}>
                  {chipsData.map((x, i) => {
                    return (
                      <Chip
                        key={i}
                        label={x.entity_name}
                        className={classes.chipLabel}
                        style={{
                          borderRadius: '2px',
                          margin: '2px 3px',
                          color: '#004CFF',
                          backgroundColor: '#EAF1FE'
                        }}
                        onClick={() => {
                          onSelectEntity(x);
                        }}
                      />
                    );
                  })}
                </div>
              </Box>
              <Divider className={classes.breakLine} />
            </>
          )}
          {props.children && props.children}
        </>
      ) : (
        <>{props.children && props.children}</>
      )}
    </div>
  );
};

export default EntityTypeFilterTabs;
