import React from 'react';
import { useSelector } from 'react-redux';
import FilterBox from './filterBox';

const FilterMainFilter = () => {
  const toggleScreening = useSelector((state) => state.screenerReducer.toggleScreening);

  return (
    <>
      {toggleScreening && (
        <div style={{ display: 'flex', gap: '20px' }}>
          <div style={{ flex: 1 }}>
            <FilterBox />
          </div>
        </div>
      )}
    </>
  );
};

export default FilterMainFilter;
