export const SETGRIDCONFIG = 'SETGRIDCONFIG';
export const SETCOLCONFIG = 'SETCOLCONFIG';
export const SETFILTER = 'SETFILTER';
export const SETINPUTFILTER = 'SETINPUTFILTER';
export const SETPREVIOUSFILTER = 'SETPREVIOUSFILTER';
export const SETUUID = 'SETUUID';
export const SETUSERMESSAGE = 'SETUSERMESSAGE';
export const SETRESPMESSAGE = 'SETRESPMESSAGE';
export const SETCOMPANYDETAILS = 'SETCOMPANYDETAILS';
export const SETCOMPANYDASHBOARDDETAILS = 'SETCOMPANYDASHBOARDDETAILS';
export const SETHOMETABS = 'SETHOMETABS';
export const SETCOMPANYNAME = 'SETCOMPANYNAME';
export const SETCOMPANYCIN = 'SETCOMPANYCIN';
export const SETCOMPANYAPICONTEXT = 'SETCOMPANYAPICONTEXT';
export const SETCOMPANYMENUCLICKED = 'SETCOMPANYMENUCLICKED';
export const SETISCOMPANYDATAVISIBLE = 'SETISCOMPANYDATAVISIBLE';
export const SETLOADING = 'SETLOADING';
export const SETLOADINGDE = 'SETLOADINGDE';
export const SETLOADINGANSWERSTREAM = 'SETLOADINGANSWERSTREAM';
export const SETLOADINGANSWERSTREAMDE = 'SETLOADINGANSWERSTREAMDE';
export const SETSHOWCOMPANYBUTTON = 'SETSHOWCOMPANYBUTTON';
export const SETISCOMPANYDATAPOPULATED = 'SETISCOMPANYDATAPOPULATED';
export const SETALLMESSAGES = 'SETALLMESSAGES';
export const SETALLMESSAGESDE = 'SETALLMESSAGESDE';
export const SETCHATSCROLL = 'SETCHATSCROLL';
export const SETCHATSCROLLDE = 'SETCHATSCROLLDE';
export const SETCHATSEARCHINPUT = 'SETCHATSEARCHINPUT';
export const SETCHATSEARCHINPUTDE = 'SETCHATSEARCHINPUTDE';
export const SHOWCOMPANYPAGE = 'SHOWCOMPANYPAGE';
export const UPDATEPREVIOUSROUTE = 'UPDATEPREVIOUSROUTE';
export const SETASKBOTWIDTH = 'SETASKBOTWIDTH';
export const SETSTOPRESPONSE = 'SETSTOPRESPONSE';
export const SETSTOPRESPONSEDE = 'SETSTOPRESPONSEDE';
export const RESETSTATE = 'RESETSTATE';
export const SETREGENERATERESPONSE = 'SETREGENERATERESPONSE';
export const SETQUESTIONTABS = 'SETQUESTIONTABS';
export const SETEXPLOREPAGINATION = 'SETEXPLOREPAGINATION';
export const SETNEWS = 'SETNEWS';
export const SETDASHBOARDNEWS = 'SETDASHBOARDNEWS';
export const SETISFIRSTTIMEUSER = 'SETISFIRSTTIMEUSER';
export const SETISSTEPFINISHED = 'SETISSTEPFINISHED';
export const SETWHITELABELDETAILS = 'SETWHITELABELDETAILS';
export const SETCURRENTSTEP = 'SETCURRENTSTEP';
export const SETSHOWTOURGUIDE = 'SETSHOWTOURGUIDE';
export const ENDTOURGUIDE = 'ENDTOURGUIDE';
export const SETTEARSHEETKEYS = 'SETTEARSHEETKEYS';
export const SETTEARSHEETENTITIESOPTIONS = 'SETTEARSHEETENTITIESOPTIONS';
export const SETTEARSHEETTEMPLATEOPTIONS = 'SETTEARSHEETTEMPLATEOPTIONS';
export const SETTEARSHEETDATA = 'SETTEARSHEETDATA';
export const SETMCADATA = 'SETMCADATA';
export const SETDASHBOARDQUESTION = 'SETDASHBOARDQUESTION';
export const SETCOMPANYLISTDETIALS = 'SETCOMPANYLISTDETIALS';
export const SETSELECTEDDOCEXP = 'SETSELECTEDDOCEXP';
export const SETDETAGS = 'SETDETAGS';
export const SETSUGGESTEDDEQUESTIONS = 'SETSUGGESTEDDEQUESTIONS';
export const SETTEARSHEETURL = 'SETTEARSHEETURL';
export const SETINTIALSTATESCREENER = 'SETINTIALSTATESCREENER';
export const SETSCREENERTABS = 'SETSCREENERTABS';
export const SETACTIVESCREENERTAB = 'SETACTIVESCREENERTAB';
export const SETTOGGLESCREANING = 'SETTOGGLESCREANING';
export const SETSCREENERDATA = 'SETSCREENERDATA';
export const SETDOCCOMPARESTART = 'SETDOCCOMPARESTART';
export const SETTABLEDATAERRORMESSAGE = 'SETTABLEDATAERRORMESSAGE';
export const SETINTIALSTATEFOLDER = 'SETINTIALSTATEFOLDER';
export const SETFOLDERDATA = 'SETFOLDERDATA';
export const SETDOCEXPLORERLOADING = 'SETDOCEXPLORERLOADING';
export const SETGLOBALLOADING = 'SETGLOBALLOADING';
export const SETGLOBALSUCCESSMESSAGE = 'SETGLOBALSUCCESSMESSAGE';
export const SETGLOBALERRORMESSAGE = 'SETGLOBALERRORMESSAGE';
export const SETINTIALSTATEFOLDERDC = 'SETINTIALSTATEFOLDERDC';
export const SETFOLDERDATADC = 'SETFOLDERDATADC';
export const SETDOCEXPLORERLOADINGDC = 'SETDOCEXPLORERLOADINGDC';
export const SETGLOBALLOADINGDC = 'SETGLOBALLOADINGDC';
export const SETSELECTEDDOCEXPLORER = 'SETSELECTEDDOCEXPLORER';
export const SETSELECTEDDOCCOMPARE = 'SETSELECTEDDOCCOMPARE';
export const SETGLOBALLOADER = 'SETGLOBALLOADER';
export const SETSELECTEDTABFILTERTYPE = 'SETSELECTEDTABFILTERTYPE';
export const SETGLOBALINFOMESSAGE = 'SETGLOBALINFOMESSAGE';
export const SETCOMPANYSEARCHDATA = 'SETCOMPANYSEARCHDATA';
export const SETTHEMESEARCHDATA = 'SETTHEMESEARCHDATA';
export const SETPORTFOLIOINTIALSTATEFOLDER = 'SETPORTFOLIOINTIALSTATEFOLDER';
export const SETPORTFOLIOFOLDERDATA = 'SETPORTFOLIOFOLDERDATA';
export const SETPORTFOLIODOCEXPLORERLOADING = 'SETPORTFOLIODOCEXPLORERLOADING';
export const SETPORTFOLIOGLOBALLOADING = 'SETPORTFOLIOGLOBALLOADING';
export const SETPORTFOLIOSELECTEDDOCEXPLORER = 'SETPORTFOLIOSELECTEDDOCEXPLORER';

export const SET_WATCHLISTS = 'SET_WATCHLISTS';
export const SET_EXPANDED_WATCHLIST = 'SET_EXPANDED_WATCHLIST';
export const SET_WATCHLIST_COMPANIES = 'SET_WATCHLIST_COMPANIES';
export const SET_LOADING_COMPANIES = 'SET_LOADING_COMPANIES';
export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY';
export const SETPORTFOLIOACTIVESCREENERTAB = 'SETPORTFOLIOACTIVESCREENERTAB';
export const SETPORTFOLIOSCREENERTAB = 'SETPORTFOLIOSCREENERTAB';
export const SETOPENREPORTDIALOG = 'SETOPENREPORTDIALOG';
export const SETCURRENTREPORT = 'SETCURRENTREPORT';
export const SETKPIACTIVETAB = 'SETKPIACTIVETAB';
export const SETALLMESSAGESPT = 'SETALLMESSAGESPT';
export const SETALLMESSAGESDCOM = 'SETALLMESSAGESDCOM';
export const SETLOADINGPORTFOLIOTRACKER = 'SETLOADINGPORTFOLIOTRACKER';
export const SETLOADINGPT = 'SETLOADINGPT';
export const SETLOADINGANSWERSTREAMPT = 'SETLOADINGANSWERSTREAMPT';
export const SETSTOPRESPONSEPT = 'SETSTOPRESPONSEPT';
export const SETCHATSCROLLPT = 'SETCHATSCROLLPT';
export const SETCHATSEARCHINPUTPT = 'SETCHATSEARCHINPUTPT';
export const SETLOADINGDCOM = 'SETLOADINGDCOM';
export const SETLOADINGANSWERSTREAMDCOM = 'SETLOADINGANSWERSTREAMDCOM';
export const SETSTOPRESPONSEDCOM = 'SETSTOPRESPONSEDCOM';
export const SETCHATSCROLLDCOM = 'SETCHATSCROLLDCOM';
export const SETCHATSEARCHINPUTDCOM = 'SETCHATSEARCHINPUTDCOM';
