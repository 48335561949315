import React, { useEffect, useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { getApi, postApi } from '../../../services/token';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { IconButton, useMediaQuery } from '@material-ui/core';
import { ReactComponent as SeeMore } from '../../../components/icons/SeeMore.svg';
import { ReactComponent as PostQuestion } from '../../../components/icons/PrivateBlokLogoChat.svg';
import { ReactComponent as ClockIcon } from '../../../components/icons/ClockIcon.svg';
import { Button, Skeleton, Card, Pagination, Box, Paper } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { AuthContext } from '../../../components/auth/contexts/authContext';
import { getNameFunc } from '../../shared/utils/usernameFunc';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Link } from 'react-router-dom';
import { formatedTime } from '../utils/formatedTime';
import { MenuList, MenuItem, Divider, MenuItem as DropdownItem, Select } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  icon: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  table: {
    borderCollapse: 'collapse'
  },
  th: {
    border: '1px solid #CFCFCF',
    padding: '5px',
    textAlign: 'center',
    backgroundColor: '#FFFFFF'
  },
  td: {
    border: '1px solid #CFCFCF',
    padding: '5px',
    backgroundColor: '#FFFFFF'
  },
  alternateCell: {
    backgroundColor: '#f6f7fe'
  },
  historyGrid: {
    paddingLeft: '50px',
    paddingRight: '50px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px',
      paddingRight: '10px'
    }
  },
  timeText: {
    fontSize: '14px',
    // paddingLeft: '32px',
    paddingBottom: '2px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingBottom: '6px',
      fontSize: '12px'
    }
  },
  botHistoryText: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  markdownTxt: {
    fontSize: '14px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  },
  questionText: {
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  },
  historyAccordianTime: {
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  historyAccordianTxt: {
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  },
  accordionSpace: {
    paddingTop: '10px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '7px'
    }
  },
  byzClickable: {
    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textDecoration: 'none',
    margin: '0px 4px'
  },
  pagination: {
    '@media (max-width: 320px)': {
      '& button': {
        minWidth: '20px'
      }
    }
  },
  borderStyle: {
    marginLeft: 'auto',
    position: 'relative',
    zIndex: 1,
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    textTransform: 'none',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1.5px',
      right: '1.5px',
      top: '1.5px',
      bottom: '1.5px',
      borderRadius: '12px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  dividerStyle: {
    backgroundColor: '#FFFFFF',
    height: '5px',
    padding: '0px 16px'
  },
  listItemTextStyle: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    color: '#373737 !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important'
    }
  },
  listItemTextSelected: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    fontWeight: '700 !important',
    color: '#373737 !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important'
    }
  },
  selectItem: {
    border: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  boldSelectedItem: {
    fontWeight: 'bold',
    fontFamily: 'Satoshi'
  },
  menuPaper: {
    boxShadow: 'none'
  }
}));

const History = ({ matches }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [historySession, setHistorySession] = useState([]);
  const [showAllSessionsButton, setShowAllSessionsButton] = useState(false);
  const [showMoreButtons, setShowMoreButtons] = useState({});
  const [expandedAccordions, setExpandedAccordions] = useState([]);
  const [accordionsData, setAccordionsData] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [nameChar, setNameChar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [historyIndex, setHistoryIndex] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const auth = useContext(AuthContext);
  const url = process.env.REACT_APP_API;
  let pageLimit = 10;
  let questionsLimit = parseInt(process.env.REACT_APP_HISTORY_PAGE_SIZE, 10);
  let emptyArr = new Array(questionsLimit).fill(null);
  const [nameVal, setNameVal] = useState('');

  const getSessionData = async () => {
    const nextPageNum = pageNum + 1;
    if (nextPageNum <= 1) {
      setLoading(true);
    }
    const url1 = year
      ? `${url}/user/conversation/history?page_limit=${pageLimit}&page_number=${nextPageNum}&year=${year}`
      : `${url}/user/conversation/history?page_limit=${pageLimit}&page_number=${nextPageNum}&month=${month}`;
    try {
      const companyListData = await getApi(url1);
      if (companyListData && companyListData.data && companyListData.data.data) {
        const newData = companyListData.data.data;
        setShowAllSessionsButton(true);
        if (newData.length < 10) {
          setShowAllSessionsButton(false);
        }
        if (nextPageNum === 1) {
          setHistorySession(newData);
        } else {
          setHistorySession((prevData) => [...prevData, ...newData]);
        }
        setPageNum(nextPageNum);
        setLoading(false);
      }
    } catch (error) {
      setHistorySession([]);
      setLoading(false);
      console.error('Error fetching company list data:', error);
    }
  };

  const getTimelineData = async () => {
    try {
      const timelineApi = await getApi(`${url}/user/conversation/timeline`);
      setHistoryIndex(timelineApi.data.data);
      if (timelineApi) {
        const data = timelineApi.data.data;
        const currentMonthObj = data.find((item) => item.is_current_month);

        if (currentMonthObj && currentMonthObj.month) {
          // Set the month to the current month
          setMonth(currentMonthObj.month);
        } else {
          // Find the first object with a month
          const firstMonthObj = data.find((item) => item.month);
          if (firstMonthObj) {
            // Set the month to the first month found
            setMonth(firstMonthObj.month);
          } else {
            // No objects have a month, set the year to the first year found
            setYear(data[0].year);
          }
        }
      }
    } catch (error) {
      setHistorySession([]);
      setLoading(false);
      console.error('Error fetching timeline data:', error);
      // You can add additional error handling logic here if needed
    }
  };

  // useEffect to call getSessionData when month/year is updated
  useEffect(() => {
    const updateData = async () => {
      if (month !== null || year !== null) {
        getSessionData();
      }
    };
    updateData();
  }, [month, year]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getTimelineData();
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleShowAllSessions = async () => {
    getSessionData();
  };

  const isAccordionExpanded = (accordionId) => {
    return expandedAccordions.includes(accordionId);
  };

  const toggleAccordion = (accordionId) => {
    if (isAccordionExpanded(accordionId)) {
      setExpandedAccordions((prevExpanded) => prevExpanded.filter((id) => id !== accordionId));
    } else {
      setExpandedAccordions((prevExpanded) => [...prevExpanded, accordionId]);
      if (!(accordionId in accordionsData)) {
        handlePageChange(event, accordionId, 1);
      }
    }
  };

  const handlePageChange = async (event, _id, pageNum) => {
    setTimeout(() => {
      const ele = document.getElementById(_id);
      ele.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 100);
    setShowMoreButtons((prev) => ({
      ...prev,
      [_id]: {
        ...prev[_id],
        ['currPage']: pageNum,
        ['loading']: false
      }
    }));
    if (!(accordionsData[_id] && accordionsData[_id][pageNum - 1][0])) {
      const response = await postApi(`${url}/user/conversation/${_id}`, {
        page_limit: questionsLimit,
        page_number: pageNum
      });
      const pageData = response?.data?.data;
      let updatedAccordionsData = { ...accordionsData };
      let totalQuestions = pageData?.metadata?.total;
      let totalPages = Math.floor((totalQuestions - 1) / questionsLimit) + 1;
      if (pageNum == 1) {
        let lastArr = new Array(totalQuestions - (totalPages - 1) * questionsLimit).fill(null);
        updatedAccordionsData[_id] = totalPages > 1 ? new Array(totalPages - 1).fill(emptyArr) : [];
        updatedAccordionsData[_id].push(lastArr);
      }
      updatedAccordionsData[_id][pageNum - 1] = pageData[_id];
      setAccordionsData(updatedAccordionsData);
      setShowMoreButtons((prev) => ({
        ...prev,
        [_id]: {
          currPage: pageNum,
          totalPages: totalPages,
          loading: true
        }
      }));
    } else {
      setShowMoreButtons((prev) => ({
        ...prev,
        [_id]: {
          ...prev[_id],
          ['currPage']: pageNum,
          ['loading']: true
        }
      }));
    }
  };
  useEffect(() => {
    const getName = async () => {
      const { nameVal, nameCharVar } = await getNameFunc(auth);
      setNameChar(nameCharVar);
      setNameVal(nameVal);
    };
    getName();
  }, []);
  let alternate = false;

  const renderTd = (props) => {
    const { children } = props;
    alternate = !alternate;
    const className = alternate ? classes.alternateCell : '';
    return <td className={`${classes.td} ${className}`}>{children}</td>;
  };
  const renderSubHeading = ({ children }) => {
    return (
      <p style={{ paddingTop: '5px' }}>
        <strong>{children}</strong>
      </p>
    );
  };

  const renderLink = ({ href, children, title }) => {
    if (href.startsWith('##')) {
      return (
        <span
          title={title}
          style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} // Look like a link
          onClick={(e) => e.preventDefault()} // Prevent click action
        >
          {children}
        </span>
      );
    }

    return (
      <a href={href} target="_blank" rel="noopener noreferrer" title={title}>
        {children}
      </a>
    );
  };

  const renderImage = ({ src, alt }) => (
    <img src={src} alt={alt} style={{ maxWidth: '100%', height: 'auto' }} />
  );

  const historyHeight = matches ? 'calc(100vh - 174px)' : 'calc(100vh - 234px)';

  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleClick = (index, item) => {
    setSelectedIndex(index);
    setPageNum(0);
    if (item.month) {
      setMonth(item.month);
      setYear(null);
    } else {
      setYear(item.year);
      setMonth(null);
    }
  };

  return (
    <>
      {matches ? <div style={{ height: '20px' }} /> : null}
      <Grid
        container
        style={{
          backgroundColor: '#FFFFFF',
          borderRadius: '24px',
          maxWidth: '1440px',
          margin: '0 auto'
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            height: '20px'
          }}
        />
        <Grid
          container
          xs={12}
          style={{
            height: historyHeight,
            overflowY: 'auto'
          }}
          className={classes.historyGrid}
        >
          <Grid
            item
            // xs={12}
            // md={2}
            style={{
              padding: '5px',
              width: isSmallScreen ? '100%' : '170px'
            }}
          >
            <Paper
              sx={{
                boxShadow: 'none',
                borderRadius: '13px'
              }}
              className={classes.borderStyle}
            >
              {isXSmallScreen ? (
                <Select
                  value={selectedIndex}
                  onClick={(event) => {
                    const index = event.target.value;
                    const selectedItem = historyIndex ? historyIndex[index] : null;
                    handleClick(index, selectedItem);
                  }}
                  className={classes.selectItem}
                  fullWidth
                  renderValue={(selected) => (
                    <span className={classes.boldSelectedItem}>
                      {historyIndex && historyIndex[selected]
                        ? historyIndex[selected].month
                          ? historyIndex[selected].is_current_month
                            ? 'This month'
                            : historyIndex[selected].month
                          : historyIndex[selected].year
                        : ''}
                    </span>
                  )}
                >
                  {historyIndex === null ? (
                    <>
                      {Array.from({ length: 1 }).map((_, index) => (
                        <div key={index}>
                          <Card
                            sx={{
                              maxWidth: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              backgroundColor: '#FAFAFA',
                              borderRadius: '8px',
                              border: '1px solid #E8E8E8'
                            }}
                            elevation={0}
                          >
                            <Skeleton
                              animation="wave"
                              height={10}
                              width="20%"
                              style={{ margin: 20 }}
                            />
                            <Skeleton
                              animation="wave"
                              height={10}
                              width="70%"
                              style={{ marginBottom: 10, marginLeft: 20, marginRight: 6 }}
                            />
                          </Card>
                          <br />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {historyIndex?.map((item, index) => (
                        <DropdownItem key={index} value={index}>
                          <Typography
                            className={
                              index === selectedIndex
                                ? classes.listItemTextSelected
                                : classes.listItemTextStyle
                            }
                          >
                            {item.month
                              ? item.is_current_month
                                ? 'This month'
                                : item.month
                              : item.year}
                          </Typography>
                        </DropdownItem>
                      ))}
                    </>
                  )}
                </Select>
              ) : (
                <MenuList style={{ padding: '5px' }}>
                  {historyIndex === null ? (
                    <>
                      {Array.from({ length: 3 }).map((_, index) => (
                        <div key={index}>
                          <Card
                            sx={{
                              maxWidth: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              backgroundColor: '#FAFAFA',
                              borderRadius: '8px',
                              border: '1px solid #E8E8E8'
                            }}
                            elevation={0}
                          >
                            <Skeleton
                              animation="wave"
                              height={10}
                              width="20%"
                              style={{ margin: 20 }}
                            />
                            <Skeleton
                              animation="wave"
                              height={10}
                              width="70%"
                              style={{ marginBottom: 10, marginLeft: 20, marginRight: 6 }}
                            />
                          </Card>
                          <br />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {historyIndex?.map((item, index) => (
                        <>
                          <MenuItem
                            key={index}
                            style={{
                              backgroundColor: '#FFFFFF',
                              height: '44px'
                            }}
                            onClick={() => handleClick(index, item)}
                            disableRipple
                          >
                            <Typography
                              className={
                                index === selectedIndex
                                  ? classes.listItemTextSelected
                                  : classes.listItemTextStyle
                              }
                            >
                              {item.month
                                ? item.is_current_month
                                  ? 'This month'
                                  : item.month
                                : item.year}
                            </Typography>
                          </MenuItem>
                          {index < historyIndex.length - 1 && (
                            <div className={classes.dividerStyle}>
                              <Divider />
                            </div>
                          )}
                        </>
                      ))}
                    </>
                  )}
                </MenuList>
              )}
            </Paper>
          </Grid>
          {/* <Grid item xs={12} md={10} style={{ padding: '0px 4px' }}> */}
          <Grid
            item
            style={{
              width: isSmallScreen ? '100%' : 'calc(100% - 170px)',
              padding: '5px'
            }}
          >
            {loading ? (
              <>
                {Array.from({ length: 10 }).map((_, index) => (
                  <div key={index}>
                    <Card
                      sx={{
                        maxWidth: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#FAFAFA',
                        borderRadius: '8px',
                        border: '1px solid #E8E8E8'
                      }}
                      elevation={0}
                    >
                      <Skeleton animation="wave" height={10} width="20%" style={{ margin: 20 }} />
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="70%"
                        style={{ marginBottom: 10, marginLeft: 20, marginRight: 6 }}
                      />
                    </Card>
                    <br />
                  </div>
                ))}
              </>
            ) : (
              <>
                <div>
                  {historySession.map(({ _id, timestamp, first_question }) => (
                    <div key={_id} id={_id}>
                      <Accordion
                        expanded={isAccordionExpanded(_id)}
                        onChange={() => toggleAccordion(_id)}
                        style={{
                          backgroundColor: '#FAFAFA',
                          borderRadius: '8px',
                          border: '1px solid #E8E8E8'
                        }}
                        elevation={0}
                      >
                        <AccordionSummary
                          expandIcon={<SeeMore />}
                          aria-controls="panel1a-content"
                          style={{ minHeight: '66px', display: 'flex' }}
                          id="panel1a-header"
                        >
                          <div>
                            <Typography
                              style={{
                                color: '#1D1D1D',
                                fontWeight: 500
                              }}
                              className={classes.historyAccordianTime}
                            >
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <ClockIcon
                                  style={{
                                    padding: '0px 6px 0px 0px',
                                    height: '18px',
                                    width: '18px'
                                  }}
                                />
                                {formatedTime(timestamp, true)}
                              </div>
                            </Typography>
                            <Typography
                              style={{
                                color: '#1D1D1D',
                                fontWeight: 700
                              }}
                              className={classes.historyAccordianTxt}
                            >
                              {first_question}
                            </Typography>
                          </div>
                        </AccordionSummary>

                        <AccordionDetails style={{ overflowX: 'auto', paddingTop: '0px' }} id={_id}>
                          {showMoreButtons[_id]?.loading && (
                            <>
                              {accordionsData[_id] &&
                                accordionsData[_id][showMoreButtons[_id].currPage - 1]?.map(
                                  (item, index) => (
                                    <>
                                      <div
                                        style={{
                                          padding: '5px 0px'
                                        }}
                                        key={item.question_id}
                                      >
                                        <div style={{ display: index === 0 ? 'none' : undefined }}>
                                          <div style={{ display: 'flex', padding: '6px 0px' }}>
                                            <Typography
                                              style={{
                                                color: '#1D1D1D',
                                                fontWeight: 600,
                                                lineHeight: '18.9px'
                                              }}
                                              className={classes.timeText}
                                            >
                                              <div
                                                style={{ display: 'flex', alignItems: 'center' }}
                                              >
                                                <ClockIcon
                                                  style={{
                                                    padding: '0px 6px 2px 3px'
                                                  }}
                                                />
                                                {formatedTime(item.received_timestamp, true)}
                                              </div>
                                            </Typography>
                                          </div>
                                          <div style={{ display: 'flex' }}>
                                            <IconButton
                                              style={{ padding: '0px' }}
                                              className={classes.icon}
                                              disableRipple
                                            >
                                              <Avatar
                                                sx={{
                                                  height: '24px',
                                                  width: '24px',
                                                  border: '1px solid #7DA3FD',
                                                  backgroundColor: '#E3EBFD',
                                                  color: '#0C0C0C',
                                                  fontSize: '10px'
                                                }}
                                                style={{
                                                  fontFamily: 'Satoshi',
                                                  fontWeight: 'bold'
                                                }}
                                              >
                                                {nameChar}
                                              </Avatar>
                                            </IconButton>
                                            <Typography
                                              style={{
                                                color: '#1D1D1D',
                                                fontWeight: 600,
                                                lineHeight: '24.3px',
                                                whiteSpace: 'pre-wrap',
                                                paddingLeft: '6px'
                                              }}
                                              className={classes.questionText}
                                            >
                                              {item.question}
                                            </Typography>
                                          </div>
                                        </div>
                                        <div className={classes.botHistoryText}>
                                          {!isSmallScreen && (
                                            <IconButton
                                              style={{
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                                padding: !item.answer
                                                  ? '5px 0px 0px 0px'
                                                  : '22px 7px 0px 0px'
                                              }}
                                              className={classes.icon}
                                              disableRipple
                                            >
                                              <PostQuestion />
                                            </IconButton>
                                          )}
                                          <Typography
                                            style={{
                                              color: '#1D1D1D',
                                              fontWeight: 400
                                            }}
                                            className={classes.markdownTxt}
                                          >
                                            <ReactMarkdown
                                              remarkPlugins={[remarkGfm]}
                                              components={{
                                                h3: renderSubHeading,
                                                table: ({ children }) => (
                                                  <table className={classes.table}>
                                                    {children}
                                                  </table>
                                                ),
                                                th: ({ children }) => (
                                                  <th className={classes.th}>{children}</th>
                                                ),
                                                td: renderTd,
                                                a: renderLink,
                                                img: renderImage
                                              }}
                                            >
                                              {item.answer}
                                            </ReactMarkdown>
                                          </Typography>
                                        </div>
                                      </div>
                                    </>
                                  )
                                )}
                            </>
                          )}
                          {!showMoreButtons[_id]?.loading && (
                            <Grid sx={{ flexGrow: 1, paddingTop: '10.5px' }}>
                              <Card
                                sx={{
                                  maxWidth: '100%',
                                  height: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  border: '1px solid #F0F0F0',
                                  borderRadius: '10px',
                                  marginLeft: '7.5px',
                                  marginRight: '7.5px',
                                  boxShadow: '0px 2px 4px 0px #0000000D'
                                }}
                                style={{ borderColor: '#F0F0F0', borderWidth: '1px' }}
                                elevation={0}
                              >
                                <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                  <Skeleton
                                    variant="circular"
                                    animation="wave"
                                    style={{ margin: 10 }}
                                    width={30}
                                    height={30}
                                  />
                                  <Skeleton
                                    variant="rectangle"
                                    animation="wave"
                                    style={{ margin: 10 }}
                                    width="40%"
                                    height={10}
                                  />
                                </Grid>
                                <Grid style={{ display: 'flex', alignItems: 'start' }}>
                                  <Skeleton
                                    variant="square"
                                    animation="wave"
                                    style={{ margin: 12 }}
                                    width={25}
                                    height={25}
                                  />
                                  <div style={{ width: '100%' }}>
                                    <Skeleton
                                      variant="rectangle"
                                      animation="wave"
                                      style={{ margin: 10 }}
                                      width="90%"
                                      height={10}
                                    />
                                    <Skeleton
                                      variant="rectangle"
                                      animation="wave"
                                      style={{ margin: 10 }}
                                      width="80%"
                                      height={10}
                                    />
                                    <Skeleton
                                      variant="rectangle"
                                      animation="wave"
                                      style={{ margin: 10 }}
                                      width="40%"
                                      height={10}
                                    />
                                  </div>
                                </Grid>
                                <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                  <Skeleton
                                    variant="circular"
                                    animation="wave"
                                    style={{ margin: 10 }}
                                    width={30}
                                    height={30}
                                  />
                                  <Skeleton
                                    variant="rectangle"
                                    animation="wave"
                                    style={{ margin: 10 }}
                                    width="30%"
                                    height={10}
                                  />
                                </Grid>
                                <Grid style={{ display: 'flex', alignItems: 'start' }}>
                                  <Skeleton
                                    variant="square"
                                    animation="wave"
                                    style={{ margin: 12 }}
                                    width={25}
                                    height={25}
                                  />
                                  <div style={{ width: '100%' }}>
                                    <Skeleton
                                      variant="rectangle"
                                      animation="wave"
                                      style={{ margin: 10 }}
                                      width="70%"
                                      height={10}
                                    />
                                    <Skeleton
                                      variant="rectangle"
                                      animation="wave"
                                      style={{ margin: 10 }}
                                      width="85%"
                                      height={10}
                                    />
                                    <Skeleton
                                      variant="rectangle"
                                      animation="wave"
                                      style={{ margin: 10 }}
                                      width="20%"
                                      height={10}
                                    />
                                  </div>
                                </Grid>
                              </Card>
                            </Grid>
                          )}
                          {showMoreButtons[_id]?.totalPages > 1 && (
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingTop: '4px'
                              }}
                            >
                              <Pagination
                                count={showMoreButtons[_id]?.totalPages}
                                onChange={(e, val) => handlePageChange(e, _id, val)}
                                shape="rounded"
                                size={isXSmallScreen ? 'small' : 'medium'}
                                className={classes.pagination}
                              ></Pagination>
                            </Grid>
                          )}
                        </AccordionDetails>
                      </Accordion>
                      <div className={classes.accordionSpace}></div>
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    display: 'flex',
                    height: '40px',
                    justifyContent: 'center',
                    paddingTop: '0px'
                  }}
                >
                  {showAllSessionsButton && (
                    <Button style={{ textTransform: 'none' }} onClick={handleShowAllSessions}>
                      <Typography
                        style={{ fontFamily: 'Satoshi', fontWeight: 'bold', fontSize: '12px' }}
                      >
                        {t('history.seeMoreSessions')}
                      </Typography>
                      <SeeMore style={{ paddingLeft: '5px' }} />
                    </Button>
                  )}
                </div>
                {historySession.length == 0 && (
                  <Box textAlign="center">
                    <Typography variant="h6">{`${t(
                      'history.newUserHeading'
                    )}${nameVal}`}</Typography>
                    <Typography style={{ marginTop: '10px' }}>
                      {t('history.newUserLeftDesc')}
                      <Link
                        to="/askbyz"
                        className={classes.byzClickable}
                        onClick={() => window.scroll(0, 0)}
                      >
                        {t('history.Byz')}
                      </Link>
                      {t('history.newUserRightDesc')}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {matches ? <div style={{ height: '10px' }} /> : null}
    </>
  );
};

export default History;
