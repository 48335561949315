import UserProfile from '../components/profile/userProfile';
import Detail from '../components/company/detail';
import 'font-awesome/css/font-awesome.min.css';
import { Routes, Route } from 'react-router-dom';

import SignIn from '../components/auth/signIn';
// import SignUp from '../components/auth/signUp'; //signup is set to none for as we want to prevent signup
import VerifyCode from '../components/auth/verify';
import RequestCode from '../components/auth/requestCode';
import ForgotPassword from '../components/auth/forgotPassword';

import { Navigate } from 'react-router-dom';
// import UnProtectedRoute from '../routes/unProtectedRoute';
import ProtectedRoute from '../routes/protectedRoute';
import PropTypes from 'prop-types';
import MainPage from '../components/shared/mainPage/mainPage';
// import Summary from '../components/shared/summary';
import HomePage from '../components/shared/homePage/homePage';
import HomePageCopy from '../components/shared/homePagecopy/homePage';
import Portfolio from '../components/shared/Portfolio/portfolio';
import Watchlist from '../components/shared/Watchlist';
// import Pricing from '../components/pricing/pricing';
import Pricing from '../components/pricingDemo/pricing';
import About from '../components/about';
import AddCompany from '../components/company/addCompany';
import { useTranslation } from 'react-i18next';
import VerifyEmail from '../components/auth/verifyEmail';
import TearSheetMainPage from '../components/shared/tearSheet/tearSheetMainPage';
import DocLib from '../components/docLib';
import DocViewer from '../components/shared/docViewer';
import HelpCenter from '../components/helpCenter/helpCenter';
import TermsAndConditions from '../components/termsAndCondition';
import Privacy from '../components/privacy';
import DashBoard from '../components/shared/dashBoard/dashboard';
import SignUp from '../components/auth/signUp/signUp';
import PressRelese from '../components/shared/pressRelese';
import MyTemplates from '../components/shared/myTemplate/myTemplate';
import DocExplorer from '../components/shared/doc-explorer';
import DocComparison from '../components/shared/docComparison';
import Screener from '../components/screener';
import ScreenerV1 from '../components/screenerV1';
import PortfolioTracker from '../components/shared/portfolioTracker';
import SignUpV1 from '../components/auth/signUp/signUp-V1';

const CustomRoutes = ({ prevRoute }) => {
  const { t } = useTranslation();
  const newHomepage = process.env.REACT_APP_ENABLE_NEW_HOMEPAGE;
  const docExplorer = process.env.REACT_APP_ENABLE_DOC_EXPLORER;
  const isTearSheetEnabled = process.env.REACT_APP_ENABLE_TEARSHEET === 'true';
  const isPeopleFilterEnabled = process.env.REACT_APP_ENABLE_PEOPLE_FILTER === 'true';
  const enablePhoneForSignUpAuth = process.env.REACT_APP_ENABLE_PHONE_FOR_SIGNUP_AUTH === 'true';
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" replace />} />{' '}
      {/* <Route path="/" element={<UnProtectedRoute />}> */}
      <Route path="/signin" element={<SignIn prevRoute={prevRoute} />} />
      {enablePhoneForSignUpAuth ? (
        <Route path="/signup" element={<SignUpV1 />} />
      ) : (
        <Route path="/signup" element={<SignUp />} />
      )}
      {enablePhoneForSignUpAuth ? (
        <Route path="/signupfortrial" element={<SignUpV1 />} />
      ) : (
        <Route path="/signupfortrial" element={<SignUp />} />
      )}
      {/* <Route path="/verify" element={<VerifyCode />} /> */}
      <Route path="/requestcode" element={<RequestCode />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      {/* </Route> */}
      <Route path="/companies" element={<Detail />} />
      <Route path="/verify" element={<VerifyCode />} />
      {/* commenting out for demos */}
      {process.env.REACT_APP_ENABLE_PRICING && process.env.REACT_APP_ENABLE_PRICING === 'true' && (
        <Route path="/pricing" element={<Pricing />} />
      )}
      <Route path="/about" element={<About />} />
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/askbyz" element={<MainPage currentRoute={t('tab.AskBot')} />} />
        <Route path="/history" element={<MainPage currentRoute={t('tab.History')} />} />
        <Route
          path="/explore-all-companies"
          element={<MainPage currentRoute={t('tab.ExploreAllCompanies')} />}
        />
        {/* <Route path="/summary" element={<Summary />} /> */}
        <Route path="/upload-company-document" element={<AddCompany />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/tracker" element={<Portfolio />} />
        <Route path="/document-upload/upload" element={<DocLib isupload={true} />} />
        <Route path="/document-upload/manage" element={<DocLib isupload={false} />} />
        <Route path="/dashboard" element={<DashBoard />} />
        <Route path="/portfolio-tracker" element={<PortfolioTracker />} />
        {docExplorer && <Route path="/doc-explorer" element={<DocExplorer />} />}
        <Route path="/doc-comparison" element={<DocComparison />} />
        {isPeopleFilterEnabled === true ? (
          <Route path="/screener" element={<ScreenerV1 />} />
        ) : (
          <Route path="/screener" element={<Screener />} />
        )}
        <Route path="/watchlist-company" element={<Watchlist />} />
        <Route
          path="/tearsheet"
          element={<TearSheetMainPage currentRoute={t('tearSheet.tearsheetRoute')} />}
        />
        <Route
          path="/report"
          element={<TearSheetMainPage currentRoute={t('tearSheet.tearsheetRoute')} />}
        />
        {/* <Route
          path="/template"
          element={<TearSheetMainPage currentRoute={t('tearSheet.templateRoute')} />}
        /> */}
        <Route path="/doc-viewer" element={<DocViewer />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        {isTearSheetEnabled && <Route path="/my-templates" element={<MyTemplates />} />}
      </Route>
      {newHomepage ? (
        <Route path="/home" element={<HomePage />} />
      ) : (
        <Route path="/home" element={<HomePageCopy />} />
      )}
      <Route path="/press-release" element={<PressRelese />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="help-center" element={<HelpCenter />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default CustomRoutes;

CustomRoutes.propTypes = {
  prevRoute: PropTypes.any
};
