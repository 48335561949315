export const createFilterPayload = (input) => {
  try {
    if (typeof input !== 'object' || input === null) {
      return null;
    }

    const output = { filter: {} };

    for (const key in input) {
      if (!input[key] || typeof input[key] !== 'object') {
        return null;
      }

      const filterType = input[key].type;

      if (!filterType || typeof filterType !== 'string') {
        return null;
      }

      if (filterType === 'dropdown') {
        if (!Array.isArray(input[key].selected) || input[key].selected.length === 0) {
          return null;
        }

        output.filter[key] = {
          type: filterType,
          value: input[key].selected[0].key || null
        };
      } else if (filterType === 'multi-select') {
        if (!Array.isArray(input[key].selected)) {
          return null;
        }

        output.filter[key] = {
          type: filterType,
          value: input[key].selected
            .map((item) => {
              if (!item.key) {
                return null;
              }
              return item.key;
            })
            .filter(Boolean)
        };
      } else if (filterType === 'range') {
        // if (
        //   typeof input[key].min_value !== 'number' ||
        //   typeof input[key].max_value !== 'number' ||
        //   typeof input[key].unit !== 'string'
        // ) {
        //   return null;
        // }

        output.filter[key] = {
          type: 'range',
          min_value: input[key]?.min_value,
          max_value: input[key]?.max_value,
          unit: input[key]?.unit
        };
      } else {
        return null;
      }
    }

    return output;
  } catch (error) {
    return null;
  }
};

export const getSelectedTabData = (useSelector = null) => {
  if (typeof useSelector === 'function') {
    const screenerData = useSelector((state) => state.screenerReducer.screenerData);
    const selectedScreenerTab = useSelector((state) => state.screenerReducer.selectedScreenerTab);
    const selectedTabFilterType = useSelector(
      (state) => state.screenerReducer.selectedTabFilterType
    );
    const selectedTabDataIndex = screenerData.findIndex(
      (item) => item.entityType === selectedScreenerTab && item.filterType === selectedTabFilterType
    );
    const selectedTabData = screenerData[selectedTabDataIndex];
    return {
      screenerData,
      selectedScreenerTab,
      selectedTabFilterType,
      selectedTabData,
      selectedTabDataIndex
    };
  }
};

export const createKeyForSearch = (queryParams) => {
  if (queryParams) return decodeURIComponent(queryParams.toString());

  return '';
};
