import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { setGlobalErrorMessage } from '../../action';
import {
  setFolderDataDc,
  setDocexplorerLoadingDc
} from '../../../../redux/pages/docCompare/action';
import { CircularProgress } from '@material-ui/core';
import { TreeView, TreeItem } from '@material-ui/lab';
import { ReactComponent as FolderIcon } from '../../../../components/icons/FolderIcon.svg';
import { ReactComponent as FolderIconBox } from '../../../../components/icons/FolderIconBox.svg';
import { ReactComponent as DeleteBinIcon } from '../../../icons/DeleteBinIcon.svg';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { updateFolderDataWithMovedItem, updateFolderDataToRoot } from './helper';
import { dispatchHideShowMessage } from '../../../../helpers';
import { getFolderData, moveFolder, moveFile } from '../services';
import FileDeleteDialog from './folderManager/deleteFileDialog';
import FolderActionsMenu from './folderManager/folderActionsMenu';
import { setSelectedDocCompare } from '../../../../redux/pages/docCompare/action';

const useStyles = makeStyles(() => ({
  fileStyle: {
    fontFamily: 'Satoshi',
    fontSize: '14px',
    color: '#373737',
    padding: '3px 0px'
  },
  fileLinkStyle: {
    fontFamily: 'Satoshi',
    fontSize: '14px',
    color: '#373737',
    textDecoration: 'none',
    '&:hover': {
      color: 'blue'
    }
  },
  fileLinkisDisabledStyle: {
    fontFamily: 'Satoshi',
    fontSize: '14px',
    color: '#373737',
    textDecoration: 'none'
  },
  deleteIcon: {
    visibility: 'hidden',
    cursor: 'pointer',
    padding: '5px 0px 5px 5px',
    '&:hover': {
      visibility: 'visible'
    }
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    '&:hover $deleteIcon': {
      visibility: 'visible'
    }
  },
  treeItemLabel: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontFamily: 'Satoshi',
    fontSize: '14px',
    color: '#373737',
    fontWeight: 400
  },
  rootBoxLabel: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontFamily: 'Satoshi',
    fontSize: '14px',
    color: '#373737',
    fontWeight: 400
  },
  folderContainer: {
    '&:hover $folderActions': {
      display: 'block'
    }
  },
  folderActions: {
    display: 'none'
  }
}));

const FolderExplorer = ({ handleCheckboxChange }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const folderData = useSelector((state) => state.docCompareReducer.folderDataDc);
  const docExplorerloading = useSelector((state) => state.docCompareReducer.docExplorerloadingDc);
  const selectedDocCompare = useSelector((state) => state.docCompareReducer.selectedDocCompare);

  const [draggedItem, setDraggedItem] = useState(null);
  // const [selectedDocExp, setSelectedDocExp] = useState([]);
  const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
  const [deleteFileData, setDeleteFileData] = useState(null);

  const handleOpenDeletePopUp = () => {
    setOpenDeletePopUp(true);
  };

  const handleDeleteClick = () => {
    handleOpenDeletePopUp();
  };

  const handleRootDrop = async (event) => {
    event.preventDefault();
    if (!draggedItem) return;
    dispatch(setDocexplorerLoadingDc(true));
    try {
      let updatedFolderData;

      if (draggedItem.parent_folder_id === null) return; // Already in the root folder

      if (draggedItem.node_type === 'folder') {
        await moveFolder(draggedItem.id, { parent_folder_id: '' }); // Move to root
        updatedFolderData = updateFolderDataToRoot(folderData, draggedItem);
      } else if (draggedItem.node_type === 'file') {
        await moveFile(draggedItem.id, { folder_id: '' }); // Move to root
        updatedFolderData = updateFolderDataToRoot(folderData, draggedItem);
      }

      if (updatedFolderData) {
        dispatch(setFolderDataDc(updatedFolderData));
      }
    } catch (error) {
      dispatchHideShowMessage(
        dispatch,
        error.message || 'Something went wrong, please try again later',
        setGlobalErrorMessage,
        3000
      );
    } finally {
      setDraggedItem(null);
      dispatch(setDocexplorerLoadingDc(false));
    }
  };

  const fetchFolderChildren = async (folderId) => {
    try {
      dispatch(setDocexplorerLoadingDc(true));
      const childrenData = await getFolderData(folderId);
      const updatedFolderData = updateFolderChildren(folderData, folderId, childrenData || []);
      dispatch(setFolderDataDc(updatedFolderData));
    } catch (error) {
      const updatedFolderData = updateFolderChildren(folderData, folderId, []);
      dispatch(setFolderDataDc(updatedFolderData));
      dispatchHideShowMessage(
        dispatch,
        error.message || 'Something went wrong , please try again later',
        setGlobalErrorMessage,
        3000
      );
    } finally {
      dispatch(setDocexplorerLoadingDc(false));
    }
  };

  const handleFolderDrop = async (event, targetFolder) => {
    event.preventDefault();
    if (!draggedItem || draggedItem.id === targetFolder?.id) return;
    try {
      dispatch(setDocexplorerLoadingDc(true));
      let updatedFolderData;

      if (draggedItem.node_type === 'folder') {
        await moveFolder(draggedItem.id, { parent_folder_id: targetFolder.id });
        updatedFolderData = updateFolderDataWithMovedItem(folderData, targetFolder.id, draggedItem);
      } else if (draggedItem.node_type === 'file') {
        await moveFile(draggedItem.id, { folder_id: targetFolder.id });
        updatedFolderData = updateFolderDataWithMovedItem(folderData, targetFolder.id, draggedItem);
      }

      if (updatedFolderData) {
        dispatch(setFolderDataDc(updatedFolderData));
      }
    } catch (error) {
      dispatchHideShowMessage(
        dispatch,
        error.message || 'Something went wrong, please try again later',
        setGlobalErrorMessage,
        3000
      );
    } finally {
      setDraggedItem(null);
      dispatch(setDocexplorerLoadingDc(false));
    }
  };

  const handleDragStart = (event, item) => {
    event.stopPropagation();
    setDraggedItem(item);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChange = (doc) => {
    const isSelected = selectedDocCompare.some((d) => d.id === doc.id);

    // Deselect the doc or add it to the selection
    const updatedSelectedDocExp = isSelected
      ? selectedDocCompare.filter((d) => d.id !== doc.id)
      : [...selectedDocCompare, doc];

    // If attempting to select and exceeding the limit, show a message and return
    const selectionConfig = process.env.REACT_APP_DOCUMENT_SELECTION_DC || 4;
    if (!isSelected && updatedSelectedDocExp.length > selectionConfig) {
      dispatchHideShowMessage(
        dispatch,
        `You can select a maximum of ${selectionConfig} documents. Please deselect one to choose another.`,
        setGlobalErrorMessage,
        3000
      );
      return;
    }

    // setSelectedDocExp(updatedSelectedDocExp);
    const updatedSelectedDocs = updatedSelectedDocExp.map((doc) => ({
      id: doc?.id,
      file_name: `${doc?.name}.${doc?.details?.file_format}`,
      tags: doc?.details?.tags
    }));

    dispatch(setSelectedDocCompare(updatedSelectedDocs));
    handleCheckboxChange();
  };

  const updateFolderChildren = (folders, folderId, children) => {
    return folders.map((folder) => {
      if (folder.id === folderId && folder.node_type === 'folder') {
        return { ...folder, children };
      }
      if (folder.node_type === 'folder' && folder.children) {
        return { ...folder, children: updateFolderChildren(folder.children, folderId, children) };
      }
      return folder;
    });
  };

  const renderFile = (file) => {
    const isFailed = file?.details?.status === 'FAILED';
    const isDisabled = !(file?.details?.status === 'INGESTED');
    const tooltipMessage = isFailed
      ? `${file.name}.${file?.details?.file_format} - Failed to process`
      : isDisabled
      ? `${file.name}.${file?.details?.file_format} File is being processed`
      : `${file.name}.${file?.details?.file_format}`;

    return (
      <div
        key={file.id}
        draggable={!isDisabled}
        onDragStart={(event) => !isDisabled && handleDragStart(event, file)}
        className={classes.fileContainer}
      >
        <input
          type="checkbox"
          checked={selectedDocCompare.some((d) => d.id === file.id)}
          onChange={() => !isDisabled && handleChange(file)}
          name="radio_doc_expo"
          disabled={isDisabled}
          style={{
            backgroundColor: selectedDocCompare.some((d) => d.id === file.id)
              ? '#004CFF'
              : '#FFFFFF',
            height: '14px',
            width: '14px',
            border: '1px solid #004CFF',
            borderRadius: '50%',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            margin: 0,
            padding: 0,
            flexShrink: 0,
            marginRight: '8px'
          }}
        />
        <Tooltip title={tooltipMessage} arrow>
          <Typography
            className={classes.fileStyle}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            <a
              href={file?.details?.source_link}
              target="_blank"
              rel="noreferrer"
              className={isDisabled ? classes.fileLinkisDisabledStyle : classes.fileLinkStyle}
              style={{ color: isFailed ? 'red' : 'inherit' }}
            >
              {file.name}.{file?.details?.file_format}
            </a>
          </Typography>
        </Tooltip>
        <div className={classes.deleteIcon}>
          <Tooltip title={'Delete document'} placement="top">
            <DeleteBinIcon
              onClick={(e) => {
                e.stopPropagation();
                setDeleteFileData(file);
                handleDeleteClick();
              }}
              style={{ cursor: 'pointer' }}
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderFolder = (folder) => (
    <TreeItem
      key={folder.id}
      nodeId={folder.id}
      label={
        <div
          draggable
          onDragStart={(event) => handleDragStart(event, folder)} // Allow dragging folders
          onDragOver={handleDragOver} // Allow drop events on folders
          onDrop={(event) => handleFolderDrop(event, folder)} // Handle dropping items into this folder
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: 'Satoshi',
            fontSize: '14px',
            color: '#373737',
            fontWeight: '400',
            padding: '8px',
            cursor: 'grab' // Indicate draggable element
          }}
          className={classes.folderContainer}
        >
          <Tooltip title={folder.name} arrow placement="top-start">
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {folder.name}
            </div>
          </Tooltip>
          <div
            onClick={(e) => e.stopPropagation()}
            onMouseEnter={(e) => e.stopPropagation()}
            onMouseLeave={(e) => e.stopPropagation()}
          >
            <FolderActionsMenu parentFolderData={folder} className={classes.folderActions} />
          </div>
        </div>
      }
      icon={
        folder?.details?.input_source === 'box' ? (
          <FolderIconBox
            onClick={(e) => {
              e.stopPropagation();
              if (folder?.details?.source_link) {
                window.open(folder.details.source_link, '_blank');
              }
            }}
            style={{
              transform: 'scale(1.3)'
            }}
          />
        ) : (
          <FolderIcon
            style={{
              transform: 'scale(1.3)'
            }}
          />
        )
      }
      onClick={() => {
        if (folder.node_type === 'folder' && !folder.children) {
          fetchFolderChildren(folder.id);
        }
      }}
    >
      {folder.node_type === 'folder' && !folder.children ? (
        <TreeItem nodeId={`${folder.id}-loading`} label={<CircularProgress size={20} />} />
      ) : folder.children && folder.children.length === 0 ? (
        <TreeItem
          nodeId={`${folder.id}-empty`}
          label={<Typography className={classes.treeItemLabel}>No file/folder</Typography>}
        />
      ) : (
        folder.children && renderFolders(folder.children)
      )}
    </TreeItem>
  );

  const renderFolders = (folders) => {
    return folders.map((folder) => {
      if (folder.node_type === 'file') {
        return renderFile(folder);
      }
      if (folder.node_type === 'folder') {
        return renderFolder(folder);
      }
      return null;
    });
  };

  return (
    <>
      <FileDeleteDialog
        openDeletePopUp={openDeletePopUp}
        setOpenDeletePopUp={setOpenDeletePopUp}
        setDeleteFileData={setDeleteFileData}
        file={deleteFileData}
        folderData={folderData}
      />
      <div
        style={{
          opacity: docExplorerloading ? 0.5 : 1,
          pointerEvents: docExplorerloading ? 'none' : 'auto'
        }}
      >
        {folderData.length === 0 && docExplorerloading ? (
          <CircularProgress />
        ) : (
          <>
            <TreeView
              onDragOver={(event) => handleDragOver(event)}
              onDrop={(event) => handleRootDrop(event)}
              style={{
                border: '1px dashed gray',
                padding: '2px',
                position: 'relative',
                backgroundColor: '#FFF'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <Typography className={classes.rootBoxLabel}>Drop files here</Typography>
              </div>
            </TreeView>
            <div
              style={{
                height: 'calc(100vh - 380px)',
                overflowY: 'auto'
              }}
            >
              <TreeView>
                <div style={{ padding: '10px' }}>
                  {renderFolders(folderData)} {/* Render the folders */}
                </div>
              </TreeView>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FolderExplorer;
