import {
  SETINTIALSTATESCREENER,
  SETSCREENERTABS,
  SETACTIVESCREENERTAB,
  SETTOGGLESCREANING,
  SETSCREENERDATA,
  SETTABLEDATAERRORMESSAGE,
  SETSELECTEDTABFILTERTYPE
} from '../../action.types';

const isPeopleFilterEnabled = process.env.REACT_APP_ENABLE_PEOPLE_FILTER === 'true';

const INITIAL_STATE = isPeopleFilterEnabled
  ? {
      screenerTabs: [
        { key: 'custom', value: 'Query' },
        { key: 'filter', value: 'Filter' }
      ],
      selectedScreenerTab: 'custom',
      selectedTabFilterType: 'company',
      toggleScreening: true,
      screenerFilterTypes: [
        { key: 'company', value: 'Company' }
        // { key: 'fund', value: 'Fund' },
        // { key: 'person', value: 'People' }
      ],
      errorMessage: '',
      screenerData: [
        {
          filterType: 'custom',
          queryText: '',
          tableData: [],
          isLoading: false,
          pageNumber: 1,
          pageSize: 30,
          question_id: ''
        },
        {
          filterType: 'filter',
          entityType: 'company',
          filterGeneratedQuery: '',
          intialFilterConfig: null,
          tableData: [],
          isLoading: false,
          pageNumber: 1,
          pageSize: 30,
          question_id: ''
        },
        {
          filterType: 'filter',
          entityType: 'person',
          filterGeneratedQuery: '',
          intialFilterConfig: null,
          tableData: [],
          isLoading: false,
          pageNumber: 1,
          pageSize: 30,
          question_id: ''
        }
      ]
    }
  : {
      screenerTabs: [
        { key: 'company', value: 'Company' },
        // { key: 'fund', value: 'Fund' },
        { key: 'person', value: 'People' }
      ],
      selectedScreenerTab: 'company',
      selectedTabFilterType: 'custom',
      toggleScreening: true,
      screenerFilterTypes: [
        { key: 'custom', value: 'Query' },
        { key: 'filter', value: 'Filter' }
      ],
      errorMessage: '',
      screenerData: [
        {
          active: true,
          entityType: 'company',
          filterType: 'custom',
          queryText: '',
          tableData: [],
          isLoading: false,
          exampleQueries: [
            `Give a list of Indian Series A companies in the fintech sector.`,
            `Give a list of Series B companies in the beauty and personal care sector that focus on Tier 2 and Tier 3 cities.`,
            `Give a list of all SaaS companies founded by alumni of IIT or IIM.`
          ],
          pageNumber: 1,
          pageSize: 30,
          question_id: ''
        },
        {
          active: false,
          entityType: 'fund',
          filterType: 'custom',
          queryText: '',
          tableData: [],
          isLoading: false,
          exampleQueries: [
            'Which are the industrializing Machine learning Tech FUNDS.',
            'FUNDS based out of Bangalore.',
            'Which are the industrializing Machine learning Tech FUNDS.',
            'FUNDS based out of Bangalore.'
          ],
          pageNumber: 1,
          pageSize: 30,
          question_id: ''
        },
        {
          active: false,
          entityType: 'person',
          filterType: 'custom',
          queryText: '',
          tableData: [],
          isLoading: false,
          exampleQueries: [
            `List all people with relationships with Turtlemint.`,
            `Get the list of names of people from India whose employment history includes the keyword 'director'`,
            `List the angel investors of Lobb.`
          ],
          pageNumber: 1,
          pageSize: 30,
          question_id: ''
        },
        {
          active: true,
          entityType: 'company',
          filterType: 'filter',
          filterGeneratedQuery: '',
          intialFilterConfig: null,
          tableData: [],
          isLoading: false,
          pageNumber: 1,
          pageSize: 30,
          question_id: ''
        },
        {
          active: false,
          entityType: 'fund',
          filterType: 'filter',
          filterGeneratedQuery: '',
          intialFilterConfig: null,
          tableData: [],
          isLoading: false,
          pageNumber: 1,
          pageSize: 30,
          question_id: ''
        },
        {
          active: false,
          entityType: 'person',
          filterType: 'filter',
          filterGeneratedQuery: '',
          intialFilterConfig: null,
          tableData: [],
          isLoading: false,
          pageNumber: 1,
          pageSize: 30,
          question_id: ''
        }
      ]
    };

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETSCREENERTABS:
      return {
        ...state,
        screenerTabs: action.data
      };

    case SETACTIVESCREENERTAB:
      return {
        ...state,
        selectedScreenerTab: action.data
      };

    case SETTOGGLESCREANING:
      return {
        ...state,
        toggleScreening: action.data
      };

    case SETSCREENERDATA:
      return {
        ...state,
        screenerData: action.data
      };

    case SETTABLEDATAERRORMESSAGE:
      return {
        ...state,
        errorMessage: action.data
      };
    case SETSELECTEDTABFILTERTYPE:
      return {
        ...state,
        selectedTabFilterType: action.data
      };

    case SETINTIALSTATESCREENER:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
