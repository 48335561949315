import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postApi, putApi } from '../../../services/token';
import {
  Box,
  Button,
  Dialog,
  Grid,
  LinearProgress,
  TextField,
  Typography,
  IconButton
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import { ReactComponent as NoWebWarning } from '../../../components/icons/NoWebWarning.svg';
import { ReactComponent as AddCompanyHeading } from '../../../components/icons/AddCompanyHeading.svg';
import { useEffect } from 'react';
import { dispatchHideShowMessage } from '../../../helpers';
import { useDispatch } from 'react-redux';
import { setGlobalErrorMessage } from '../action';
import ErrorSpan from '../../atoms/errorSpan';

const url = process.env.REACT_APP_COMPANY_API;
const useStyles = makeStyles((theme) => ({
  errorColor: {
    verticalAlign: 'top !important'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  submitCompayGrid: {
    paddingLeft: '120px',
    paddingRight: '120px',
    paddingTop: '72px',
    paddingBottom: '72px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '20px',
      paddingBottom: '20px'
    }
  },
  container: {
    width: '552px',
    height: '42px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    paddingRight: '20px',
    paddingLeft: '20px',
    display: 'flex',
    alignItems: 'center',
    //marginBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      margin: '8px 0px',
      width: '100%'
    }
  },
  textField2: {
    fontFamily: 'Satoshi !important',
    lineHeight: '100%',
    letterSpacing: '0%',
    width: '100%',
    paddingBottom: '16px'
  },
  requestComanyTest1: {
    fontSize: '36px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '26px'
    }
  },
  requestComanyTest2: {
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    }
  },
  lineBreakBlog: {
    height: '2px',
    background: 'linear-gradient(90deg, #004CFF 0%, #06BCC1 100%)',
    opacity: 1,
    width: '100%'
  },
  rootText: {
    '& .MuiInputBase-input': {
      backgroundColor: '#E9F0FD !important'
    }
  },
  notchedOutline: {
    borderWidth: '1px',
    borderRadius: '3px',
    borderColor: '#E3EBFD !important'
  },
  tryButton: {
    width: '93px',
    height: '38px',
    textTransform: 'none',
    background: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      fontFamily: 'Satoshi',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#616161 !important',
        border: '1px solid #ccc'
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#616161 !important',
        border: '1px solid #ccc'
      }
    }
  },
  inputProps: {
    minHeight: '42px !important',
    borderColor: '#ccc',
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
      height: '42px',
      padding: '10px 14px',
      '& input': {
        height: '22px',
        fontWeight: '00',
        fontSize: '16px',
        fontFamily: 'Satoshi',
        fontColor: '#171717 !important'
      }
    }
  },
  inputLabelProps: {
    //color: '#171717 !important',
    fontWeight: '400',
    fontSize: '16px',
    fontFamily: 'Satoshi',
    '&.Mui-focused': {
      color: '#171717 !important'
    }
  }
}));
const initialBody = {
  name: '',
  message: 'I am unable to find this company. Please add it to your platform.',
  website: ''
};
const RequestCompanyPopover = ({
  displayPopoverSubmitCopany,
  setDisplayPopoverSubmitCopany,
  setSuccessMessage,
  updateWesbiteCompanyName,
  updateWesbiteCompanyCin
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDashboard = updateWesbiteCompanyCin ? true : false;
  const [loadingSubmitCompany, setLoadingSubmitCompany] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [websiteError, setWebsiteError] = useState(false);
  //   const [comment, setComment] = useState('');
  //   const [companyNameRequest, setCompanyNameRequest] = useState('');
  const [body, setBody] = useState(JSON.parse(JSON.stringify(initialBody)));
  const submitCompanyRequest = async () => {
    if (!body.name) {
      setSuccessMessage(false);
      return;
    }
    setLoadingSubmitCompany(true);
    try {
      if (isDashboard) {
        const UpdateCompanyRequestInput = {
          website: body.website,
          cin: updateWesbiteCompanyCin
        };
        await putApi(`${url}/v1/company/request`, UpdateCompanyRequestInput);
      } else {
        const CreateCompanyRequestInput = {
          message: body.message,
          website: body.website,
          name: body.name
        };
        await postApi(`${url}/v1/company/request`, CreateCompanyRequestInput);
      }
      setBody(JSON.parse(JSON.stringify(initialBody)));
      setSuccessMessage(true);
      handleClose();
      setTimeout(() => {
        setSuccessMessage(false);
      }, 10000);
    } catch (error) {
      var customErrorMessage;
      if (error?.response?.data?.error?.message === 'FORBIDDEN') {
        customErrorMessage = 'You do not have access to update the company';
      } else if (error?.response?.data?.error?.message === 'BAD_REQUEST') {
        customErrorMessage = 'Company with the given name/website already exists in database';
      }
      dispatchHideShowMessage(
        dispatch,
        customErrorMessage || 'An error occurred. Please try again later.',
        setGlobalErrorMessage,
        6000
      );
      setSuccessMessage(false);
    }
    setLoadingSubmitCompany(false);
  };
  //   const handlecommentChange = (event) => {
  //     setComment(event.target.value);
  //     setSuccessMessage(false);
  //     setErrorRequestCompany('');
  //   };
  //   const handleCompanyNameChange = (event) => {
  //     setCompanyNameRequest(event.target.value);
  //     setSuccessMessage(false);
  //     setErrorRequestCompany('');
  //   };
  const handleChange = (key, value) => {
    setSuccessMessage(false);
    setBody((prev) => ({
      ...prev,
      [key]: value
    }));
  };
  const handleClose = () => {
    //setSuccessMessage(false);
    setDisplayPopoverSubmitCopany(false);
    setNameError(false);
    setWebsiteError(false);
    setBody(JSON.parse(JSON.stringify(initialBody)));
  };
  const handleSubmit = () => {
    if (!body.name) {
      setNameError(true);
      return;
    }
    setNameError(false);
    setWebsiteError(false);
    submitCompanyRequest();
  };
  useEffect(() => {
    if (updateWesbiteCompanyName) {
      setBody((prev) => ({
        ...prev,
        name: updateWesbiteCompanyName
      }));
    }
  }, [updateWesbiteCompanyName]);
  return (
    <Dialog
      open={displayPopoverSubmitCopany}
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      classes={{ paper: classes.dialogPaper }}
      fullWidth={true}
      maxWidth="sm"
    >
      <Grid
        container
        style={{
          background: '#FFFFFF',
          padding: '20px',
          gap: '10px'
        }}
        className={classes.submitCompayGrid}
      >
        {/* Dialog Header */}
        <div style={{ marginBottom: '10px', display: 'flex', gap: '10px' }}>
          <AddCompanyHeading style={{ width: '27px', height: '27px' }} />
          <Typography
            style={{
              width: '100%',
              height: '27px',
              fontFamily: 'Satoshi',
              fontWeight: 700,
              fontSize: '20px',
              lineHeight: '100%',
              letterSpacing: '0%',
              paddingTop: '5px'
            }}
          >
            {isDashboard ? 'Update Company' : 'Add Company'}
          </Typography>
          <div
            style={{
              position: 'absolute',
              right: 14,
              top: 16,
              cursor: 'pointer'
            }}
          >
            <IconButton
              onClick={() => {
                handleClose();
              }}
            >
              <Close />
            </IconButton>
          </div>
        </div>
        {/* Form Fields */}
        {/* <div style={{ maxWidth: '560px !important' }}> */}
        <div style={{ width: '100%' }}>
          <TextField
            fullWidth
            id="outlined-required"
            size="small"
            label={
              <span>
                Company Name <span style={{ color: '#C44545' }}>*</span>
              </span>
            }
            onChange={(e) => {
              handleChange('name', e.target.value);
              if (e.target.value.trim() !== '') setNameError(false);
            }}
            value={body.name}
            className={classes.textField}
            InputProps={{ className: classes.inputProps }}
            InputLabelProps={{ className: classes.inputLabelProps }}
            sx={{ marginBottom: nameError ? '0px' : '22px' }}
          />
          <ErrorSpan value={nameError && 'Company Name is required'} />
        </div>
        <div style={{ width: '100%' }}>
          <TextField
            fullWidth
            label={<span>Website</span>}
            onChange={(e) => {
              const value = e.target.value.trim();
              handleChange('website', e.target.value);
              // Regular expression for basic URL validation
              const urlPattern = /^(https?:\/\/)?(www\.)?([\w-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/;
              if (value === '' || urlPattern.test(value)) {
                setWebsiteError(false);
              } else {
                setWebsiteError(true);
              }
            }}
            value={body.website}
            id="outlined-required"
            size="small"
            className={classes.textField}
            InputProps={{ className: classes.inputProps }}
            InputLabelProps={{ className: classes.inputLabelProps }}
            sx={{ marginBottom: websiteError ? '0px' : '22px' }}
          />
          <ErrorSpan
            sx={{ VerticalAlign: 'top !important' }}
            value={websiteError && 'Invalid website'}
          />
        </div>
        <div style={{ width: '100%' }}>
          <TextField
            defaultValue={
              isDashboard
                ? 'Please update the above information for this company.'
                : 'I am unable to find this company. Please add it to your platform.'
            }
            label="Message"
            onChange={(e) => handleChange('message', e.target.value)}
            fullWidth
            id="outlined-required"
            size="small"
            className={classes.textField}
            InputProps={{ className: classes.inputProps }}
            InputLabelProps={{ className: classes.inputLabelProps }}
          />
        </div>
        <div
          style={{
            height: '19px',
            //background: '#373737',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Satoshi',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '100%',
            letterSpacing: '0%',
            fontColor: '#373737'
            //padding: '0 10px' // Ensures text doesn't touch edges
          }}
        >
          <NoWebWarning style={{ marginRight: '5px' }} />
          Without valid website no data/news collection will occur.
        </div>
        {/* Submit Button */}
        <Box
          width="100%"
          //marginTop={1}
          marginBottom="0px !importat "
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            color="primary"
            variant="contained"
            className={classes.tryButton}
            disabled={loadingSubmitCompany}
            style={{
              background: loadingSubmitCompany ? '#ccc' : ''
            }}
            onClick={handleSubmit}
          >
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 400,
                color: '#FFFFFF',
                textTransform: 'none'
              }}
            >
              {isDashboard ? 'Update' : t('homepage.Submit')}
            </Typography>
          </Button>
        </Box>

        <div style={{ height: '1px' }}>{loadingSubmitCompany && <LinearProgress />}</div>
      </Grid>
    </Dialog>
  );
};
export default RequestCompanyPopover;
