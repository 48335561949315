import React, { useState } from 'react';
import { ReactComponent as AddIcon } from '../../../../icons/Add.svg';
import { Grid } from '@material-ui/core';
import SaveFolderName from '../folderManager/index.js';
import { createNewFolder, getFolderData } from '../../services/index.js';
import { useDispatch } from 'react-redux';
import { setFolderData } from '../../../../../redux/pages/docExplorer/action.js';
import CustomSelect from '../../../../atoms/select.js';
import { makeStyles } from '@material-ui/core/styles';
import CustomTooltip from '../../../../atoms/customTooltip/index.js';
import { mergeFolderData } from '../helper.js';
import { useSelector } from 'react-redux';
import {
  setGlobalErrorMessage,
  setGlobalLoader,
  setGlobalSuccessMessage
} from '../../../action.js';
import { dispatchHideShowMessage } from '../../../../../helpers/index.js';

const useStyles = makeStyles(() => ({
  spanCls: {
    color: '#5E5E5E',
    'font-family': 'Satoshi',
    'font-size': '12px',
    'font-style': 'normal',
    'font-weight': 700,
    'line-height': 'normal'
  },
  customSelectSmall: {
    '& .MuiOutlinedInput-root': {
      height: '20px !important',
      width: '110px !important'
    },
    '& .MuiSelect-select': {
      padding: '6px 14px !important',
      minHeight: '20px !important'
    }
  },
  placeholderClassName: {
    color: '#373737 !important',
    fontFamily: 'Satoshi !important',
    fontSize: '12px !important',
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    lineHeight: 'normal !important'
  }
}));

const FileHeader = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const folderData = useSelector((state) => state.docExplorerReducer.folderData);

  const refreshFolder = async () => {
    const currentFolderData = await getFolderData(null);
    const updatedFolderData = mergeFolderData(folderData, currentFolderData);
    dispatch(setFolderData(updatedFolderData));
  };

  const save = async (folder_name) => {
    try {
      if (folder_name) {
        dispatch(setGlobalLoader(true));
        const body = {
          parent_folder_id: '',
          folder_name
        };
        await createNewFolder(body);
        await refreshFolder();
        dispatch(setGlobalLoader(false));
        dispatchHideShowMessage(
          dispatch,
          'Folder created successfully.',
          setGlobalSuccessMessage,
          3000
        );
      }
    } catch (error) {
      dispatch(setGlobalLoader(false));
      dispatchHideShowMessage(
        dispatch,
        error?.response?.data?.error?.detail || 'Something went wrong',
        setGlobalErrorMessage,
        3000
      );
    }
  };

  return (
    <>
      <SaveFolderName open={open} setOpen={setOpen} title="Create New Folder" save={save} />
      <div style={{ marginBottom: '10px' }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={3}
            style={{ padding: '5px 8px 8px 8px', opacity: '0.3', cursor: 'not-allowed' }}
          >
            <span className={classes.spanCls}>Sort by: </span>
          </Grid>
          <Grid item xs={7} style={{ padding: '8px 0px', opacity: '0.3' }}>
            <div style={{ cursor: 'not-allowed' }}>
              <CustomSelect
                value={null}
                options={[]}
                handleChange={() => {}}
                label={''}
                placeholder={'Date Added'}
                disabled={true}
                className={classes.customSelectSmall}
                placeholderClassName={classes.placeholderClassName}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <CustomTooltip title={'Create folder'} placement="top">
              <AddIcon onClick={() => setOpen(true)} />
            </CustomTooltip>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default FileHeader;
