import { getApi, postApi } from '../../services/token';
import { dispatchHideShowMessage } from '..';
import { setGlobalErrorMessage, setGlobalSuccessMessage } from '../../components/shared/action';

const baseUrl = process.env.REACT_APP_API_BASE;
const ingestionUrl = process.env.REACT_APP_INGESTION_API_URL;
const gDriveAppId = process.env.REACT_APP_GDRIVE_APP_ID;

// const handleClosGDriveState = async (dispatch) => {
//   try {
//     await postApi(`${ingestionUrl}/v1/unauthorise-user`);
//   } catch {
//     dispatchHideShowMessage(
//       dispatch,
//       'An error occurred. Please try again later.',
//       setGlobalErrorMessage,
//       30000
//     );
//   }
// };

const handleShareDriveItems = async (
  payload,
  vid,
  input_source_param,
  dispatch,
  setIsLoading,
  parent_folder_id,
  handleUploadPopup
) => {
  const body = {
    items: payload,
    vendor_user_id: vid,
    vendor: 'google',
    input_source: input_source_param,
    parent_folder_id: parent_folder_id
  };

  setIsLoading(true);
  try {
    await postApi(`${ingestionUrl}/v1/webhook/vendor-upload`, body);
    dispatchHideShowMessage(
      dispatch,
      'Files shared successfully. Ingestion is queued.',
      setGlobalSuccessMessage,
      20000
    );
    setIsLoading(false);
    if (input_source_param === 'doc_library') {
      window.location.assign('/document-upload/manage');
    }
    handleUploadPopup(true);
    setTimeout(() => dispatchHideShowMessage(dispatch, '', setGlobalSuccessMessage, 20000), 7000);
    //await handleClosGDriveState(dispatch);
  } catch (error) {
    dispatchHideShowMessage(
      dispatch,
      error?.response?.data?.error?.message || 'An error occurred. Please try again later.',
      setGlobalErrorMessage,
      20000
    );
    setIsLoading(false);
    //await handleClosGDriveState(dispatch);
  }
};

const openPicker = (
  ACCESS_TOKEN,
  vid,
  input_source_param,
  dispatch,
  setIsLoading,
  parent_folder_id,
  handleUploadPopup
) => {
  try {
    window.gapi.load('picker', () => {
      try {
        const view = new window.google.picker.View(window.google.picker.ViewId.DOCS);
        view.setMimeTypes('application/vnd.google-apps.folder,application/pdf');

        const picker = new window.google.picker.PickerBuilder()
          .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
          .setOAuthToken(ACCESS_TOKEN)
          .setAppId(gDriveAppId)
          .addView(view)
          .addView(new window.google.picker.DocsUploadView())
          .setCallback((data) => {
            if (data.action === window.google.picker.Action.PICKED) {
              const selectedFiles = data.docs.map((doc) => ({
                id: doc.id,
                type: doc.type
              }));
              handleShareDriveItems(
                selectedFiles,
                vid,
                input_source_param,
                dispatch,
                setIsLoading,
                parent_folder_id,
                handleUploadPopup
              );
            }
          })
          .build();

        picker.setVisible(true);
      } catch (error) {
        dispatchHideShowMessage(
          dispatch,
          'Error while building or displaying the Picker',
          setGlobalErrorMessage,
          20000
        );
      }
    });
  } catch (error) {
    dispatchHideShowMessage(
      dispatch,
      'Error loading Google Picker API',
      setGlobalErrorMessage,
      20000
    );
  }
};

const getGDriveToken = async (
  input_source_param,
  service_type,
  setIsLoading,
  dispatch,
  parent_folder_id,
  handleUploadPopup
) => {
  setIsLoading(true);
  try {
    const driveToken = await getApi(
      process.env.REACT_APP_ENABLE_LINKEMAIL
        ? `${baseUrl}/ingestion/v1/get-user-auth-state/?vendor=${'google'}&service=${service_type}&input_source=${input_source_param}`
        : `${baseUrl}/ingestion/v1/get-user-auth-state/?vendor=${'google'}&input_source=${input_source_param}`
    );
    const token = driveToken.data.data.access_token;
    const is_authorised = driveToken.data.data.is_authorised;
    const vendor_user_id = driveToken.data.data.vendor_user_id;
    if (is_authorised && service_type === 'storage') {
      openPicker(
        token,
        vendor_user_id,
        //input_source_param,
        input_source_param === 'doc_comparison' ? 'doc_explorer' : input_source_param,
        dispatch,
        setIsLoading,
        parent_folder_id,
        handleUploadPopup
      );
      setIsLoading(false);
    } else {
      window.open(driveToken.data.data.redirect_url);
      setIsLoading(false);
    }
  } catch {
    setIsLoading(false);
    dispatchHideShowMessage(
      dispatch,
      'Failed to get Google Drive token',
      setGlobalErrorMessage,
      20000
    );
  }
};

export default getGDriveToken;
