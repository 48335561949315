import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  screenerTxt: {
    fontFamily: 'Satoshi !important',
    fontWeight: '400 !important',
    fontSize: '22px !important',
    lineHeight: '36px !important'
  },
  selected: {
    height: '38px',
    padding: '8px 2px !important',
    borderRadius: '4px !important',
    border: '1px solid #E4EBFC !important',
    background: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    textTransform: 'none !important'
  },
  unSelected: {
    height: '38px',
    padding: '8px 2px !important',
    borderRadius: '4px !important',
    border: '1px solid #E7E7E7 !important',
    backgroundColor: '#FFFFFF !important',
    textTransform: 'none !important'
  },
  selectedText: {
    fontFamily: 'Satoshi !important',
    color: '#FFFFFF',
    fontSize: '16px !important',
    lineHeight: '24px !important',
    padding: '0px 24px',
    fontWeight: '700 !important'
  },
  unSelectedText: {
    fontFamily: 'Satoshi !important',
    color: '#767676',
    fontSize: '16px !important',
    lineHeight: '24px !important',
    padding: '0px 24px',
    fontWeight: '700 !important'
  },
  tglScreenerBtn: {
    height: '38px',
    padding: '0px 2px !important',
    borderRadius: '4px !important',
    border: '1px solid #767676 !important',
    backgroundColor: '#FFFFFF !important',
    textTransform: 'none !important'
  },
  tglScreenerTxt: {
    fontFamily: 'Satoshi !important',
    color: '#5E5E5E',
    fontSize: '16px !important',
    padding: '0px 7px 0px 12px',
    fontWeight: '500 !important'
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    height: '100px',
    background: 'linear-gradient(90deg,#EEF3FF,#EEFAFB)',
    overflow: 'hidden',
    borderWidth: '1px',
    border: '1px solid #E7E7E7',
    borderRadius: '4px'
  },
  notchedOutline: {
    border: 'none'
  },
  root: {
    height: 'auto', // Allow it to adjust the height based on content
    overflowY: 'auto', // Enable vertical scrolling
    overflowX: 'hidden' // Prevent horizontal overflow
  },
  noScrollbarVertical: {
    overflowY: 'hidden', // Hide scrollbar by default
    overflowX: 'hidden', // Hide scrollbar by default
    scrollbarGutter: 'stable both-edges',
    '&:hover': {
      overflowY: 'hidden',
      overflowX: 'auto' // Show scrollbar on hover
    }
  },
  noScrollbarHorizantal: {
    overflowY: 'hidden', // Hide scrollbar by default
    overflowX: 'hidden', // Hide scrollbar by default
    scrollbarGutter: 'stable both-edges',
    '&:hover': {
      overflowY: 'auto',
      overflowX: 'hidden' // Show scrollbar on hover
    }
  },
  noScrollbar: {
    overflowY: 'hidden', // Hide scrollbar by default
    overflowX: 'hidden', // Hide scrollbar by default
    scrollbarGutter: 'stable both-edges',
    '&:hover': {
      overflowY: 'auto',
      overflowX: 'auto' // Show scrollbar on hover
    }
  },
  reportBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  },
  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '32px'
  },
  templateName: {
    fontFamily: 'Satoshi !important',
    fontWeight: '500 !important',
    fontSize: '16px !important',
    color: '#373737',
    lineHeight: '18px !important'
  },
  saveQuery: {
    height: '38px',
    padding: '8px 18px !important',
    borderRadius: '4px !important',
    border: '1px solid #FFFFFF !important',
    background: 'linear-gradient(to right, #CDDBFF 0%, #CDF1F3 100%)',
    textTransform: 'none !important'
  },
  saveQueryTxt: {
    fontFamily: 'Satoshi !important',
    fontWeight: '700 !important',
    fontSize: '14px !important',
    color: '#373737',
    lineHeight: '18px !important'
  },
  resetQuery: {
    height: '38px',
    padding: '8px 18px !important',
    borderRadius: '4px !important',
    border: '1px solid #767676 !important',
    background: '#FFFFFF',
    textTransform: 'none !important'
  },
  resetQueryTxt: {
    fontFamily: 'Satoshi !important',
    fontWeight: '500 !important',
    fontSize: '16px !important',
    color: '#373737',
    lineHeight: '18px !important'
  },
  runQuery: {
    height: '38px',
    padding: '8px 18px !important',
    borderRadius: '4px !important',
    border: '1px solid #FFFFFF !important',
    background: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    textTransform: 'none !important'
  },
  runQueryTxt: {
    fontFamily: 'Satoshi !important',
    fontWeight: '500 !important',
    fontSize: '16px !important',
    color: '#FFFFFF',
    lineHeight: '18px !important'
  },
  addFiltersBtn: {
    position: 'relative',
    minHeight: '24px',
    zIndex: 1,
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    textTransform: 'none !important',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1.5px',
      right: '1.5px',
      top: '1.5px',
      bottom: '1.5px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  autoCompletRoot: {
    width: '100%',
    fontFamily: 'Satoshi !important',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '2px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '2px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '2px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input': {
      height: '18px !important'
    }
  },
  option: {
    fontSize: '14px',
    fontFamily: 'Satoshi !important',
    fontWeight: 400,
    color: '#555',
    '&[aria-selected="true"]': {
      backgroundColor: '#f0f8ff' // Light blue background when selected
    },
    '&:hover': {
      backgroundColor: '#f5f5f5' // Light grey background on hover
    }
  }
}));

export default useStyles;
