import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

import { useValidCode, useValidPassword } from '../../hooks/useAuthHooks';
import { Code, Password } from './authComponents';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { AuthContext } from './contexts/authContext';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const useStyles = makeStyles((theme) => ({
  hover: {
    '&:hover': { cursor: 'pointer' },
    color: '#004CFF'
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  forgotPwdGrid: {
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'start'
    }
  },
  forgotPwdPaper: {
    padding: 32,
    [theme.breakpoints.down('xs')]: {
      padding: 10
    }
  }
}));
const enablePhoneForSignUpAuth = process.env.REACT_APP_ENABLE_PHONE_FOR_SIGNUP_AUTH === 'true';
export default function ForgotPassword() {
  const { t } = useTranslation();
  const classes = useStyles();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isSmlMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const paperWidth = isDesktop ? '60%' : '100%';
  const gridHeight = isDesktop
    ? 'calc(100vh - 121px)'
    : isSmlMobile
    ? 'calc(100vh - 155px)'
    : 'calc(100vh - 121px)';
  const { state } = useLocation();
  const username = state.email;

  const { code, setCode, codeIsValid } = useValidCode('');
  const { password, setPassword, passwordIsValid } = useValidPassword('');
  const [error, setError] = useState('');

  const [checkPass, setCheckPass] = useState(false);

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid
  } = useValidPassword('');

  const isValid = !codeIsValid || code.length === 0 || !passwordIsValid || password.length === 0;
  console.log(isValid);
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const resetPassword = async () => {
    setCheckPass(false);
    setError(null);
    if (password !== passwordConfirm) {
      setCheckPass(true);
      setError(t('forgotPwd.pwdMessage'));
    } else {
      try {
        await authContext.forgotPassword(username, code, password);
        navigate('/signin', {
          state: { message: t('forgotPwd.stateMessage') }
        });
      } catch (err) {
        if (err instanceof Error) {
          setCheckPass(true);
          setError(err.message);
        }
      }
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        const btn = document.getElementById('changePassBtn');
        btn.click();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{
        minHeight: gridHeight
      }}
    >
      {!isMobile && ( // Conditionally render only on non-mobile screens
        <Grid item lg={5}>
          <Box
            sx={{
              background: 'linear-gradient(90deg,#004CFF,#06BCC1)',
              borderRadius: '3px',
              opacity: '1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: gridHeight
            }}
          >
            <img src="/images/PBLogo_White.svg" alt="PrivateBlok-Logo" className="logo" />
          </Box>
        </Grid>
      )}
      <Grid xs={12} sm={12} lg={7} container className={classes.forgotPwdGrid} item>
        <Paper elevation={0} className={classes.forgotPwdPaper} style={{ width: paperWidth }}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Box width="100%">
              <Typography
                style={{
                  fontSize: '24px',
                  lineHeight: '70px',
                  letterSpacing: '0px',
                  color: '#08070D'
                }}
              >
                {t('forgotPwd.changePwdTxt')}
              </Typography>

              <Typography style={{ fontSize: '16px', letterSpacing: '0px', color: '#0C0C0C' }}>
                {enablePhoneForSignUpAuth ? t('forgotPwd.textMsg') : t('forgotAccountPwd.textMsg')}
              </Typography>
              <br />
              <Code codeIsValid={codeIsValid} setCode={setCode} />
              <br />
              <br />
              <Password
                label={t('forgotPwd.pwdLabel')}
                password={password}
                passwordIsValid={passwordIsValid}
                setPassword={setPassword}
                validate={true}
              />
              <br />
              <br />
              <Password
                label={t('forgotPwd.cnfPwdLabel')}
                password={passwordConfirm}
                passwordIsValid={passwordConfirmIsValid}
                setPassword={setPasswordConfirm}
                validate={false}
              />
              <br />
              <br />
              {/* Error */}
              <>
                {checkPass ? (
                  <>
                    <Box
                      m={1}
                      style={{
                        background: '#FDF6F6',
                        borderRadius: '5px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <ErrorOutlineIcon
                        style={{
                          marginLeft: '13px', // Set margin left to 13px
                          marginRight: '5px', // Set margin right to 1px
                          color: '#EB5B55' // Set color of the icon to #3C962A
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: '14px',
                          color: '#EB5B55',
                          marginRight: '15px'
                        }}
                      >
                        {error}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <div></div>
                )}
              </>

              {/* Buttons */}
              <Button
                id="changePassBtn"
                color="primary"
                variant="contained"
                style={{
                  width: '100%',
                  background: 'linear-gradient(90deg,#004CFF,#06BCC1)',
                  height: '50px'
                }}
                onClick={resetPassword}
              >
                <Typography
                  style={{
                    fontSize: '18px',
                    fontWeight: 'medium',
                    color: '#FFFFFF',
                    textTransform: 'none'
                  }}
                >
                  {t('forgotPwd.changePwdBtn')}
                </Typography>
              </Button>
              <br />
              <Button
                style={{
                  width: '100%',
                  color: '#0C0C0C',
                  fontSize: '16px',
                  textTransform: 'none'
                }}
                className={classes.button}
                onClick={() => navigate('/home')}
              >
                {t('forgotPwd.cancelBtn')}
              </Button>
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
