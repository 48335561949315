import React from 'react';
import { Autocomplete, Checkbox, TextField, Chip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setScreenerData } from '../../../../redux/pages/screener/action';
import useStyles from '../../styles';

const FilterMultiSelect = ({ filterKey, currentTabData, currentTabDataIndex, screenerData }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  // Get the filter data from Redux (replace with actual selector)
  const filterData = currentTabData.intialFilterConfig?.filters[filterKey] || {};
  const selectedFilters =
    currentTabData.intialFilterConfig?.selectedFilters[filterKey]?.selected || [];

  const updateScreenerDataAtIndex = (index, updatedTabData) => {
    const updatedScreenerData = screenerData.map((item, i) =>
      i === index ? updatedTabData : item
    );
    dispatch(setScreenerData(updatedScreenerData));
  };

  const handleAddFilter = (filterKey, selected) => {
    const existingFilterConfig = currentTabData.intialFilterConfig || {
      filters: {},
      selectedFilters: {}
    };

    // Convert keys to full objects with key and label
    const updatedSelectedFilters = selected.map((key) => {
      return filterData.options.find((option) => option.key === key);
    });

    // Ensure we add new filters to the existing ones
    const existingSelected = existingFilterConfig.selectedFilters[filterKey]?.selected || [];

    // Add the updated selected filters to the existing ones, avoiding duplicates
    const finalSelectedFilters = [
      ...existingSelected,
      ...updatedSelectedFilters.filter(
        (newFilter) =>
          !existingSelected.some((existingFilter) => existingFilter.key === newFilter.key)
      )
    ];

    const updatedFilters = {
      ...existingFilterConfig.selectedFilters,
      [filterKey]: {
        type: existingFilterConfig.filters[filterKey].type,
        selected: finalSelectedFilters,
        display_name: existingFilterConfig.filters[filterKey]?.display_name
      }
    };

    const updatedFilterConfig = {
      ...existingFilterConfig,
      selectedFilters: updatedFilters
    };

    const updatedTabData = {
      ...currentTabData,
      intialFilterConfig: updatedFilterConfig
    };

    updateScreenerDataAtIndex(currentTabDataIndex, updatedTabData);
  };

  const handleRemoveFilter = (filterKey, selectedKeysToRemove) => {
    const existingFilterConfig = currentTabData.intialFilterConfig || {
      filters: {},
      selectedFilters: {}
    };

    // Get the current selected filters for the given filterKey
    const existingSelected = existingFilterConfig.selectedFilters[filterKey]?.selected || [];

    // Filter out the items whose keys are in the `selectedKeysToRemove` array
    const updatedSelectedFilters = existingSelected.filter(
      (existingFilter) => !selectedKeysToRemove.includes(existingFilter.key)
    );

    const updatedFilters = {
      ...existingFilterConfig.selectedFilters,
      [filterKey]: {
        type: existingFilterConfig.filters[filterKey]?.type,
        selected: updatedSelectedFilters,
        display_name: existingFilterConfig.filters[filterKey]?.display_name
      }
    };

    const updatedFilterConfig = {
      ...existingFilterConfig,
      selectedFilters: updatedFilters
    };

    const updatedTabData = {
      ...currentTabData,
      intialFilterConfig: updatedFilterConfig
    };

    updateScreenerDataAtIndex(currentTabDataIndex, updatedTabData);
  };

  const handleChange = (event, newValue) => {
    const selected = newValue.map((option) => option.key);

    // Compare the new selected values with the previous selected values
    const addedFilters = selected.filter(
      (key) => !selectedFilters.some((item) => item.key === key)
    );
    const removedFilters = selectedFilters.filter(
      (item) => !selected.some((key) => key === item.key)
    );

    if (addedFilters.length) {
      handleAddFilter(filterKey, addedFilters); // Add filters
    }

    if (removedFilters.length) {
      handleRemoveFilter(
        filterKey,
        removedFilters.map((item) => item.key)
      ); // Remove filters
    }
  };

  return (
    <Autocomplete
      multiple
      id={`autocomplete-${filterKey}`}
      options={filterData.options || []}
      className={classes.autoCompletRoot}
      value={selectedFilters}
      onChange={handleChange}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      disableCloseOnSelect
      noOptionsText="No options available" // Custom text if no options are available
      renderInput={(params) => (
        <TextField
          {...params}
          label={filterData.display_name}
          InputProps={{
            ...params.InputProps,
            placeholder: '' // Ensures no placeholder
          }}
          InputLabelProps={{
            shrink: true // Prevents the floating label from behaving like a placeholder
          }}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            label={option.label}
            className={classes.chip}
            style={{
              backgroundColor: '#e0e0e0',
              fontFamily: 'Satoshi',
              color: '#373737',
              background: '#EAF1FE',
              fontSize: '14px',
              borderRadius: '4px',
              padding: '2px 8px',
              fontWeight: 700
            }}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(props, option, state) => (
        <li {...props} className={classes.option}>
          <Checkbox checked={state.selected} />
          {option.label}
        </li>
      )}
    />
  );
};

export default FilterMultiSelect;
