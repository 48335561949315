import React from 'react';
import useStyles from '../styles';
import { useSelector } from 'react-redux';

const Heading = () => {
  const classes = useStyles();
  const { currentWatchlistCompany } = useSelector((state) => state.portfolioTrackerReducer);

  const { company: currentCompany } = currentWatchlistCompany || {};
  return (
    <div style={{ padding: '16px 16px 0 18px' }}>
      {currentCompany ? (
        <div>
          <div className={`${classes.helpingText} ${classes.companyHeadingText}`}>
            {currentCompany.name}
          </div>
        </div>
      ) : (
        <div className={classes.noCompanySelected}>
          <span>Please select a company to track</span>
        </div>
      )}
    </div>
  );
};

export default Heading;
