import React, { useEffect, useState } from 'react';
import TearSheetHeader from './header';
import TearSheetContent from './content';
import { useTranslation } from 'react-i18next';

const handleTearSheetTrigger = () => {
  console.log('Static tear sheet trigger executed');
};

const StaticReportSummary = ({ currentKpiReport, handleEdit }) => {
  const [currentKpiReportData, setCurrentKpiReportData] = useState(currentKpiReport);

  useEffect(() => {
    setCurrentKpiReportData(currentKpiReport);
  }, [currentKpiReport]);

  const staticErrorMessage = '';
  const { t } = useTranslation();
  const staticTearSheetData = {
    metadata: currentKpiReportData?.metadata,
    response: currentKpiReportData?.response
  };
  const tearSheetStatus = currentKpiReportData?.metaData?.report_status;
  const showLoader =
    tearSheetStatus === t('tearSheet.inProgress') || tearSheetStatus === t('tearSheet.inQueue');
  return (
    <div style={{ padding: '0 16px' }}>
      <TearSheetHeader
        tearSheetData={staticTearSheetData}
        handleTearSheetTrigger={handleTearSheetTrigger}
        errorMessage={staticErrorMessage}
        handleEdit={handleEdit}
        showLoader={showLoader}
      />
      <TearSheetContent tearSheetData={staticTearSheetData} />
    </div>
  );
};

export default StaticReportSummary;
