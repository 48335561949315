// import { Box, Slide, Snackbar, Typography, makeStyles } from '@material-ui/core';
// import { CheckCircleOutlined } from '@material-ui/icons';

// const useStyles = makeStyles(() => ({
//   box: {
//     display: 'inline-flex',
//     alignItems: 'center',
//     backgroundColor: '#F3FFF4',
//     padding: '5px 10px',
//     margin: '8px',
//     borderLeft: '3px solid #22A428',
//     boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.10)'
//   },
//   icon: {
//     marginRight: '8px',
//     color: '#22A428'
//   },
//   typo: {
//     fontSize: '13px',
//     color: '#171717',
//     fontWeight: '500'
//   }
// }));

// const SuccessToast = ({ successMessage }) => {
//   const classes = useStyles();
//   return (
//     successMessage && (
//       <Snackbar
//         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//         open={successMessage}
//         TransitionComponent={Slide}
//         TransitionProps={{
//           direction: 'down'
//         }}
//         autoHideDuration={6000}
//         style={{ marginTop: '65px' }}
//       >
//         <Box className={classes.box}>
//           <CheckCircleOutlined className={classes.icon} />
//           <Typography className={classes.typo}>{successMessage}</Typography>
//         </Box>
//       </Snackbar>
//     )
//   );
// };

// export default SuccessToast;
import { Box, Slide, Snackbar, Typography, makeStyles } from '@material-ui/core';
import { CheckCircleOutlined } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  box: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: '#F3FFF4',
    padding: '10px 14px',
    margin: '8px',
    borderLeft: '3px solid #22A428',
    boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.10)',
    maxWidth: '850px',
    width: 'max-content', // Ensures it takes the maximum width of heading and message
    minWidth: '200px', // Prevents it from becoming too small
    whiteSpace: 'pre-wrap', // Ensures proper text wrapping
    wordBreak: 'break-word' // Prevents text overflow
  },
  icon: {
    marginRight: '10px',
    color: '#22A428'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%' // Ensures text doesn't exceed max-width
  },
  heading: {
    fontFamily: 'Satoshi',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '18px',
    letterSpacing: '0%',
    color: '#171717',
    whiteSpace: 'nowrap', // Keeps heading in a single line unless it exceeds max-width
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  message: {
    fontSize: '14px',
    color: '#171717',
    fontWeight: '500',
    maxWidth: '100%' // Prevents overflow issues
  }
}));

const SuccessToast = ({ successMessage, successHeading }) => {
  const classes = useStyles();
  return (
    successMessage && (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(successMessage)}
        TransitionComponent={Slide}
        TransitionProps={{
          direction: 'down'
        }}
        autoHideDuration={6000}
        style={{ marginTop: '65px' }}
      >
        <Box className={classes.box}>
          <CheckCircleOutlined className={classes.icon} />
          <Box className={classes.content}>
            {successHeading && (
              <Typography className={classes.heading}>{successHeading}</Typography>
            )}
            <Typography className={classes.message}>{successMessage}</Typography>
          </Box>
        </Box>
      </Snackbar>
    )
  );
};

export default SuccessToast;
