import React, { useState, useEffect } from 'react';
import CustomMenu from '../../../../atoms/menu';
import { ReactComponent as DeleteBinIcon } from '../../../../icons/DeleteBinIcon.svg';
import { ReactComponent as EditIcon } from '../../../../icons/edit.svg';
import { ReactComponent as FileIcon } from '../../../../icons/file-up.svg';
import { ReactComponent as AddIcon } from '../../../../icons/Add.svg';
import SaveFolderName from '../folderManager/index.js';
import { createNewFolder, getFolderData, moveFolder } from '../../services/index.js';
import { useDispatch } from 'react-redux';
import {
  setGlobalLoadingDc,
  setFolderDataDc
} from '../../../../../redux/pages/docCompare/action.js';
import { useSelector } from 'react-redux';
import { renameLocalFolder, updateFolderChildren } from '../helper.js';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent } from '@mui/material';
import UploadDocExpFile from '../upload.js';
import FolderDeleteDialog from './deleteDialog.js';
import { deleteUrlParam, dispatchHideShowMessage } from '../../../../../helpers/index.js';
import { setGlobalErrorMessage, setGlobalSuccessMessage } from '../../../action.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  menuTextCls: {
    color: '#4D4D4D',
    'font-family': 'Satoshi',
    'font-size': '16px',
    'font-style': 'normal',
    'font-weight': 400,
    'line-height': '24px'
  }
}));

const MenuOptionWithIcon = ({ Icon, text, onClick, action }) => {
  const classes = useStyles();
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '0 6px' }} // 4px 8px
      onClick={() => {
        onClick(action);
      }}
    >
      <Icon />
      <span className={classes.menuTextCls}>{text}</span>
    </div>
  );
};

const FolderActionsMenu = ({ parentFolderData, className }) => {
  let navigate = useNavigate();
  const [isMenuOPen, setIsMenuOpen] = useState(false);
  const [openUploadState, setOpenUploadState] = useState(false);
  const {
    id: parentFolderId,
    name: parentFolderName,
    node_type = '',
    details = {}
  } = parentFolderData;
  const [open, setOpen] = useState(false);
  const [modelTitle, setModelTitle] = useState('Create new folder');
  const [currentAction, setCurrentAction] = useState('');
  const dispatch = useDispatch();
  const folderData = useSelector((state) => state.docCompareReducer.folderDataDc);
  const [currentFolderName, setCurrentFolderName] = useState('');

  const [openDeletePopUp, setOpenDeletePopUp] = useState(false);

  const isBoxFolder = node_type === 'folder' && details?.input_source === 'box';

  const handleOpenDeletePopUp = () => {
    setOpenDeletePopUp(true);
  };

  const handleDeleteClick = () => {
    handleOpenDeletePopUp();
  };

  const refreshFolder = async () => {
    const childrenData = await getFolderData(parentFolderId);
    const updatedFolderData = updateFolderChildren(folderData, parentFolderId, childrenData || []);
    dispatch(setFolderDataDc(updatedFolderData));
  };

  const handleClick = () => {
    setOpenUploadState(true);
  };

  const createFolder = async (inputValue) => {
    if (inputValue) {
      const body = {
        parent_folder_id: parentFolderId,
        folder_name: inputValue
      };
      await createNewFolder(body);
      await refreshFolder();
    }
  };

  const renameFolder = async (inputValue) => {
    if (inputValue && parentFolderId) {
      const body = {
        folder_name: inputValue
      };
      await moveFolder(parentFolderId, body);
      const newFolderdt = { ...parentFolderData, name: inputValue };

      const newFolderData = renameLocalFolder(folderData, parentFolderId, newFolderdt);

      dispatch(setFolderDataDc(newFolderData));
    }
  };

  const save = async (inputValue) => {
    try {
      dispatch(setGlobalLoadingDc(true));
      if (currentAction === 'create') {
        await createFolder(inputValue);
        dispatchHideShowMessage(
          dispatch,
          'Folder created successfully.',
          setGlobalSuccessMessage,
          3000
        );
      } else if (currentAction === 'rename') {
        await renameFolder(inputValue);
        dispatchHideShowMessage(
          dispatch,
          'Folder name updated successfully.',
          setGlobalSuccessMessage,
          3000
        );
      }
      dispatch(setGlobalLoadingDc(false));
    } catch (error) {
      dispatchHideShowMessage(
        dispatch,
        error?.response?.data?.error?.detail || 'Something went wrong',
        setGlobalErrorMessage,
        3000
      );
      dispatch(setGlobalLoadingDc(false));
    }
  };

  const menuOnClick = (action) => {
    setCurrentAction(action);
    setCurrentFolderName(action === 'rename' ? parentFolderName : '');

    switch (action) {
      case 'create':
        setModelTitle('Create New Folder');
        break;
      case 'rename':
        setModelTitle('Rename Folder');
        break;
      case 'upload':
        handleClick();
        navigate(`/doc-comparison?parent_folder_id=${parentFolderId}`);
        break;
      case 'delete':
        handleDeleteClick();
        break;
      default:
        console.log(action);
    }

    if (action === 'create' || action === 'rename') setOpen(true);
  };

  const menuOptions = [
    {
      label: (
        <MenuOptionWithIcon
          Icon={AddIcon}
          text="Create Folder"
          onClick={menuOnClick}
          action="create"
        />
      ),
      value: 'create',
      isHide: isBoxFolder
    },
    {
      label: (
        <MenuOptionWithIcon
          Icon={EditIcon}
          text="Rename Folder"
          onClick={menuOnClick}
          action="rename"
        />
      ),
      value: 'rename',
      isHide: isBoxFolder
    },
    {
      label: (
        <MenuOptionWithIcon
          Icon={DeleteBinIcon}
          text="Delete Folder"
          onClick={menuOnClick}
          action="delete"
        />
      ),
      value: 'delete'
    },
    {
      label: (
        <MenuOptionWithIcon
          Icon={FileIcon}
          text="Upload File"
          onClick={menuOnClick}
          action="upload"
        />
      ),
      value: 'upload',
      isHide: isBoxFolder
    }
  ];

  const handleOptionSelect = (option) => {
    console.log(`Selected option: ${option.label} (${option.value})`);
    // Add logic to handle the specific action
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const parent_folder_id = params.get('parent_folder_id');
    if (params.get('openBox') === 'true' && parent_folder_id) {
      handleClick();
      params.delete('openBox');
      params.delete('cin');
      const newUrl = params.toString()
        ? `${location.pathname}?${params.toString()}`
        : location.pathname;
      window.history.replaceState(null, '', newUrl);
    }
  }, [location]);

  const handleClosePopup = () => {
    setOpenUploadState(false);
    deleteUrlParam(location, 'parent_folder_id');
  };

  const handleUploadPopup = async (isUploaded = false) => {
    // setCachedPages({});
    // setPageNumber(1);
    // getDocFromDocLib(false);
    setOpenUploadState(false);
    deleteUrlParam(location, 'parent_folder_id');
    if (isUploaded) {
      await refreshFolder();
    }
    // handleRefresh();
  };

  const menuOptionsToBeShown =
    (menuOptions && menuOptions.length > 0 && menuOptions.filter((x) => !x.isHide)) || [];

  return (
    <>
      <div className={isMenuOPen ? '' : className}>
        {openDeletePopUp && (
          <FolderDeleteDialog
            openDeletePopUp={openDeletePopUp}
            setOpenDeletePopUp={setOpenDeletePopUp}
            parentFolderId={parentFolderId}
            folderData={folderData}
          />
        )}

        <Dialog open={openUploadState} fullWidth={true} maxWidth="lg">
          <DialogContent style={{ padding: '24px' }}>
            <UploadDocExpFile
              handleClosePopup={handleClosePopup}
              handleUploadPopup={handleUploadPopup}
              folder_id={parentFolderId}
            />
          </DialogContent>
        </Dialog>
        {open && (
          <SaveFolderName
            open={open}
            setOpen={setOpen}
            title={modelTitle}
            save={save}
            currentFolderName={currentFolderName}
          />
        )}
        <CustomMenu
          triggerElement={null}
          menuOptions={menuOptionsToBeShown}
          onOptionSelect={handleOptionSelect}
          setIsMenuOpen={setIsMenuOpen}
        />
      </div>
    </>
  );
};

export default FolderActionsMenu;
