import Company from './searchable/company';
import Theme from './searchable/theme';
import { useEffect, useState } from 'react';

const CompanyThemeSearch = (props) => {
  // const { activeEntityType } = props;
  const [entitytype, setEntitytype] = useState('');
  useEffect(() => {
    if (props.activeEntityType != entitytype) {
      setEntitytype(props.activeEntityType);
    }
  }, [props.activeEntityType]);

  return <>{entitytype === 'company' ? <Company {...props} /> : <Theme {...props} />}</>;
};

export default CompanyThemeSearch;
