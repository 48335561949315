import React from 'react';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { setScreenerData } from '../../../../redux/pages/screener/action';
import useStyles from '../../styles';

const FilterRangeSelect = ({ filterKey, currentTabData, currentTabDataIndex, screenerData }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Extract filter configuration and selected values
  const filterData = currentTabData.intialFilterConfig?.filters[filterKey] || {};
  const selectedFilters = currentTabData.intialFilterConfig?.selectedFilters[filterKey] || {
    type: 'range',
    min_value: null,
    max_value: null,
    unit: filterData.unit || 'INR'
  };

  const updateScreenerDataAtIndex = (index, updatedTabData) => {
    const updatedScreenerData = screenerData.map((item, i) =>
      i === index ? updatedTabData : item
    );
    dispatch(setScreenerData(updatedScreenerData));
  };

  const handleChange = (key, value) => {
    const existingFilterConfig = currentTabData.intialFilterConfig || {
      filters: {},
      selectedFilters: {}
    };

    const updatedSelectedFilters = {
      ...existingFilterConfig.selectedFilters[filterKey],
      type: existingFilterConfig.filters[filterKey]?.type,
      min_value: key === 'min' ? (value === '' ? null : Number(value)) : selectedFilters.min_value,
      max_value: key === 'max' ? (value === '' ? null : Number(value)) : selectedFilters.max_value,
      unit: filterData.unit || 'INR',
      display_name: existingFilterConfig.filters[filterKey]?.display_name
    };

    const updatedFilters = {
      ...existingFilterConfig.selectedFilters,
      [filterKey]: updatedSelectedFilters
    };

    const updatedFilterConfig = {
      ...existingFilterConfig,
      selectedFilters: updatedFilters
    };

    const updatedTabData = {
      ...currentTabData,
      intialFilterConfig: updatedFilterConfig
    };

    updateScreenerDataAtIndex(currentTabDataIndex, updatedTabData);
  };

  return (
    <div
      style={{
        display: 'flex',
        // alignItems: 'center',
        gap: '8px'
      }}
    >
      <div
        style={{
          height: '48px',
          border: '2px solid #EAF1FE',
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Typography
          style={{
            padding: '4px  22px',
            fontFamily: 'Satoshi',
            fontWeight: 400,
            fontSize: '16px',
            color: '#171717'
          }}
        >
          {filterData.unit}
        </Typography>
      </div>
      <TextField
        id={`min-${filterKey}`}
        style={{ width: '300px' }}
        className={classes.autoCompletRoot}
        label={`Min (${filterData.min_value})`}
        type="number"
        value={selectedFilters.min_value ?? ''}
        onChange={(e) => handleChange('min', e.target.value)} // Allow typing freely
        onBlur={(e) => {
          // Apply range check on blur
          const value = e.target.value;
          if (value < filterData.min_value) {
            handleChange('min', filterData.min_value);
          } else if (value > filterData.max_value) {
            handleChange('min', filterData.max_value);
          }
        }}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          min: filterData.min_value,
          max: filterData.max_value
        }}
        helperText={
          selectedFilters.min_value < filterData.min_value
            ? `Value must be at least ${filterData.min_value}`
            : selectedFilters.min_value > filterData.max_value
            ? `Value cannot exceed ${filterData.max_value}`
            : ''
        }
      />

      <TextField
        id={`max-${filterKey}`}
        style={{ width: '300px' }}
        label={`Max (${filterData.max_value})`}
        type="number"
        value={selectedFilters.max_value ?? ''}
        onChange={(e) => handleChange('max', e.target.value)} // Allow typing freely
        onBlur={(e) => {
          // Apply range check on blur
          const value = e.target.value;
          if (value < filterData.min_value) {
            handleChange('max', filterData.min_value);
          } else if (value > filterData.max_value) {
            handleChange('max', filterData.max_value);
          }
        }}
        className={classes.autoCompletRoot}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          min: filterData.min_value,
          max: filterData.max_value
        }}
        // helperText={
        //   selectedFilters.max_value < filterData.min_value
        //     ? `Value must be at least ${filterData.min_value}`
        //     : selectedFilters.max_value > filterData.max_value
        //     ? `Value cannot exceed ${filterData.max_value}`
        //     : ''
        // }
        helperText={`Value cannot exceed`}
      />
    </div>
  );
};

export default FilterRangeSelect;
