import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setScreenerData } from '../../../../redux/pages/screener/action';
import useStyles from '../../styles';

const FilterDropdown = ({ filterKey, currentTabData, currentTabDataIndex, screenerData }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const filterData = currentTabData.intialFilterConfig?.filters[filterKey] || {};
  const selectedFilter =
    currentTabData.intialFilterConfig?.selectedFilters[filterKey]?.selected[0] || null;

  const updateScreenerDataAtIndex = (index, updatedTabData) => {
    const updatedScreenerData = screenerData.map((item, i) =>
      i === index ? updatedTabData : item
    );
    dispatch(setScreenerData(updatedScreenerData));
  };

  const handleSelectFilter = (filterKey, selected) => {
    const existingFilterConfig = currentTabData.intialFilterConfig || {
      filters: {},
      selectedFilters: {}
    };

    const selectedOption = filterData.options.find((option) => option.key === selected);

    const updatedFilters = {
      ...existingFilterConfig.selectedFilters,
      [filterKey]: {
        type: existingFilterConfig.filters[filterKey]?.type,
        selected: selectedOption ? [selectedOption] : [],
        display_name: existingFilterConfig.filters[filterKey]?.display_name
      }
    };

    const updatedFilterConfig = {
      ...existingFilterConfig,
      selectedFilters: updatedFilters
    };

    const updatedTabData = {
      ...currentTabData,
      intialFilterConfig: updatedFilterConfig
    };

    updateScreenerDataAtIndex(currentTabDataIndex, updatedTabData);
  };

  const handleChange = (event, newValue) => {
    if (newValue) {
      handleSelectFilter(filterKey, newValue.key);
    } else {
      handleSelectFilter(filterKey, null);
    }
  };

  return (
    <Autocomplete
      id={`autocomplete-${filterKey}`}
      options={filterData.options || []}
      className={classes.autoCompletRoot}
      value={selectedFilter}
      onChange={handleChange}
      getOptionLabel={(option) => option.label || ''}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      noOptionsText="No options available"
      sx={{
        '& .MuiAutocomplete-input': {
          fontFamily: 'Satoshi'
        },
        '& .MuiAutocomplete-option': {
          fontFamily: 'Satoshi'
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={filterData.display_name}
          InputProps={{
            ...params.InputProps,
            placeholder: '' // Ensures no placeholder
          }}
          InputLabelProps={{
            shrink: true // Prevents the floating label from behaving like a placeholder
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} className={classes.option} style={{ padding: '10px' }}>
          {option.label}
        </li>
      )}
    />
  );
};

export default FilterDropdown;
