export const orgDetails = {
  org_8f4d8e3e667a4caf9ada2c3dd5336f0b: {
    sub_domain: 'fireside',
    org_logo_url: 'https://public.privateblok.ai/org_logo/fireside_logo.png'
  },
  org_09a9885671394d4ba89a6fab8fd7ebd6: {
    sub_domain: 'testing.dev',
    org_logo_url: 'https://public.privateblok.ai/org_logo/fireside_logo.png'
  }
};
