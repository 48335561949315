import { forwardRef, useImperativeHandle } from 'react';
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  LinearProgress,
  Button,
  Skeleton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getApi, postApi } from '../../../services/token';
import BusinessIcon from '@mui/icons-material/Business';
import { TextField, Typography, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ReactComponent as DashBoardSearchIcon } from '../../../components/icons/DashBoardSearchIcon.svg';
import { useNavigate } from 'react-router-dom';
import {
  setCompanyName,
  setCompanyCin,
  setCompanyDashBoardDetails,
  setHomeTabs,
  setDashBoardNews,
  setDashBoardQuestion,
  setCompanyListDetials
} from '../action';
import { useDispatch, useSelector } from 'react-redux';
import Marquee from 'react-fast-marquee';
import ErrorToast from '../../atoms/errorToast';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as HomeIcon } from '../../icons/HomeIcon.svg';
import SearchCompany from './searchCompany';

const isEnableV1 = process.env.REACT_APP_ENABLE_DASHBOARD_V1_SEARCH === 'true';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiAutocomplete-popupIndicator': {
      display: 'none'
    },
    '& .MuiOutlinedInput-root': {
      background: 'linear-gradient(to right, #F1F4FE 0%, #F2FBFC 100%)',
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#000000',
      opacity: 1
    },
    '& .MuiInputBase-input': {
      height: '10px !important',
      fontFamily: 'Satoshi !important'
    }
  },
  flexContainer: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  exploreCompanyWrapper: {
    paddingLeft: '200px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px'
    }
  },
  searchIcon: {
    marginRight: '4px',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    color: '#80a6ff'
  },
  inputLabel: {
    fontFamily: 'Satoshi !important',
    color: '#000000 !important',
    opacity: 0.6
  },
  optionTypo: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px',
    fontWeight: 400,
    margin: '4px 0px',
    color: '#373737 !important',
    cursor: 'pointer'
  },
  exploreCompanyText: {
    fontFamily: 'Satoshi !important',
    fontWeight: '700 !important',
    fontSize: '14px !important',
    color: '#004CFF !important',
    lineHeight: '22px !important',
    cursor: 'pointer'
  },
  dashBoardText: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px',
    fontWeight: 500,
    color: '#373737 !important',
    padding: '0px 24px'
  },
  dashBoardSelectedText: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px',
    fontWeight: 500,
    color: '#FFFFFF !important',
    padding: '0px 24px'
  },
  dashBoardTag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '42px',
    minWidth: '70px',
    background: '#FFFFFF',
    borderRadius: '24px',
    border: '1px solid #E7E7E7',
    cursor: 'pointer',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  dashBoardSelectedTag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '42px',
    minWidth: '70px',
    background: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
    borderRadius: '24px',
    cursor: 'pointer',
    whiteSpace: 'nowrap'
  },
  exploreCompanies: {
    marginTop: '10px',
    background: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`,
    position: 'relative',
    zIndex: 1,
    textTransform: 'none !important',
    height: '36px',
    borderRadius: '4px',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: '24px',
    paddingRight: '24px',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`,
      zIndex: -1,
      transition: '200ms'
    }
  },
  closeIcon: {
    position: 'relative',
    '& svg': {
      objectFit: 'cover',
      position: 'absolute',
      borderRadius: '50%',
      width: '8px',
      height: '8px',
      overflow: 'hidden',
      border: '1px solid #E8E8E8',
      padding: '4px',
      top: '-21px',
      left: '-13px',
      background: '#ffffff',
      '& path': {
        stroke: 'url(#defaultGradient)',
        transition: 'stroke 0.3s ease'
      },
      '&:hover path': {
        stroke: 'url(#hoverGradient)'
      }
    }
  },
  homeIcon: {
    padding: '8px',
    cursor: 'pointer',
    '& svg': {
      stroke: '#5E5E5E',
      transition: 'stroke 0.3s ease',
      '& path': {
        transition: 'stroke 0.3s ease',
        stroke: 'url(#defaultHomeGradient)'
      },
      '&:hover path': {
        stroke: 'url(#hoverHomeGradient)'
      }
    }
  }
}));

const DashBoardHeadder = forwardRef((props, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [companySelectedContext, setCompanySelectedContext] = useState({});
  const [loadingCompanyDashBoard, setLoadingCompanyDashBoard] = useState(false);
  const navigate = useNavigate();
  const [companyMarquee, setCompanyMarquee] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const companyUrl = process.env.REACT_APP_COMPANY_API;

  const companyDashBoardDetails = useSelector(
    (state) => state.tokenReducer.companyDashBoardDetails
  );
  const companyListDetials = useSelector((state) => state.tokenReducer.companyListDetials);

  function shallowEqual(objA, objB) {
    for (let key in objB) {
      if (!(key in objA)) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    const errorTimer = setTimeout(() => {
      setErrorMessage(null);
    }, 7000);

    return () => {
      clearTimeout(errorTimer);
    };
  }, [errorMessage]);

  const dashBoardNews = useSelector((state) => state.tokenReducer.dashBoardNews, shallowEqual);

  const handleCompanyByCin = async (cin) => {
    const body = {
      cin: cin,
      name: ''
    };
    setCompanySelectedContext(body);
  };

  const checkUrlParamsAndHandleCompany = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const cinParam = urlParams.get('cin');

    if (cinParam) {
      handleCompanyByCin(cinParam);
    }
  };

  useImperativeHandle(ref, () => ({
    handleCompanyByCin: (cin) => handleCompanyByCin(cin)
  }));

  useEffect(() => {
    checkUrlParamsAndHandleCompany();
  }, []);

  const handleMarqueCompanyClick = (cin, name) => {
    const body = {
      cin: cin,
      name: name
    };
    setCompanySelectedContext(body);
  };

  const getCompanyData = async () => {
    let listCompanyUrl = 'v1/company/all';
    const companyListData = await postApi(`${companyUrl}/${listCompanyUrl}`, {
      page_number: 1,
      page_limit: parseInt(process.env.REACT_APP_HOMEPAGE_LIST_COMPANIES_PAGE_SIZE, 10)
    });
    if (companyListData && companyListData.data) {
      const data = companyListData.data.data;
      setCompanyMarquee(data?.company_list);
    }
  };

  const getCompanySearchData = async () => {
    const companyListData = await getApi(`${companyUrl}/v1/entity/search`, {
      entity_types: 'company',
      page_size: 20000
    });
    dispatch(setCompanyListDetials(companyListData.data.data.items));
  };

  useEffect(() => {
    if (companyMarquee.length === 0) {
      getCompanyData();
    }
    if (isEnableV1 && companyListDetials === null) {
      getCompanySearchData();
    }
  }, []);

  const getWatchlistData = async (cin) => {
    const data = await getApi(`${process.env.REACT_APP_API}/v1/user/watchlist_update?cin=${cin}`);
    const watchlistData = data?.data?.data;
    return {
      in_watchlist: watchlistData?.in_watchlist,
      watchlists: watchlistData?.watchlists || []
    };
  };

  const getCompanyQuestions = async (cin) => {
    try {
      const getCompanyURL = `${process.env.REACT_APP_COMPANY_API}/v1/company/${cin}/most-asked-questions`;
      const companyListData = await getApi(getCompanyURL);
      return companyListData?.data?.data || null;
    } catch (error) {
      console.error('Error fetching company questions:', error);
      return null;
    }
  };

  useEffect(() => {
    if (Object.keys(companySelectedContext).length === 0) {
      return;
    } else {
      setLoadingCompanyDashBoard(true);
      const { cin, name } = companySelectedContext;
      setCompanySelectedContext({});
      const getCompanyURL = `${process.env.REACT_APP_COMPANY_API}/v1/company/${cin}`;
      const getCompanyPromise = getApi(getCompanyURL);
      // Start fetching company questions and watchlist data in parallel
      const companyQuestionsPromise = getCompanyQuestions(cin);
      const watchlistDataPromise = getWatchlistData(cin);
      getCompanyPromise
        .then(async (companyData) => {
          if (companyData?.data?.data?.base?.CIN) {
            // const companyQuestions = await getCompanyQuestions(cin);
            // const watchlistData = await getWatchlistData(cin);
            const [companyQuestionsResult, watchlistDataResult] = await Promise.allSettled([
              companyQuestionsPromise,
              watchlistDataPromise
            ]);
            const companyQuestions =
              companyQuestionsResult.status === 'fulfilled' ? companyQuestionsResult.value : null;
            const watchlistData =
              watchlistDataResult.status === 'fulfilled' ? watchlistDataResult.value : null;
            let updatedCompanyDetails = [...companyDashBoardDetails];
            updatedCompanyDetails = updatedCompanyDetails.map((item) => ({
              ...item,
              active: false
            }));
            const existingIndex = updatedCompanyDetails.findIndex(
              (obj) => obj.data.base.CIN === companyData.data.data.base.CIN
            );
            if (existingIndex !== -1) {
              updatedCompanyDetails[existingIndex].active = true;
              updatedCompanyDetails[existingIndex].watchlistData = watchlistData;
              dispatch(setCompanyName(name));
              dispatch(setCompanyCin(cin));
              dispatch(setCompanyDashBoardDetails([...updatedCompanyDetails]));
            } else {
              const newData = {
                active: true,
                data: companyData.data.data,
                watchlistData,
                userInterest: {
                  interest: '',
                  value: ''
                },
                companyQuestions,
                activeTab: 0
              };
              if (updatedCompanyDetails.length === 20) {
                updatedCompanyDetails.shift();
                updatedCompanyDetails.push(newData);
              } else {
                updatedCompanyDetails.push(newData);
              }
              dispatch(setCompanyName(name));
              dispatch(setCompanyCin(cin));
              dispatch(setCompanyDashBoardDetails([...updatedCompanyDetails]));
              setLoadingCompanyDashBoard(false);
            }
          } else {
            setErrorMessage(
              'Unable to retrieve company details for this company. Please try again later.'
            );
            throw new Error('Company details does not exist/contract does not work');
          }
        })
        .catch((error) => {
          console.log(error);
          setErrorMessage(
            'Unable to retrieve company details for this company. Please try again later.'
          );
          setLoadingCompanyDashBoard(false);
        });
    }
  }, [companySelectedContext]);

  const handleChange = (event, value) => {
    if (!value) {
      return;
    }
    const body = {
      cin: value?.entity_id,
      name: value?.entity_name
    };
    setCompanySelectedContext(body);
  };

  const handleQuestionClickDashbaord = (event, question) => {
    navigate('/askbyz');
    dispatch(setHomeTabs(t('tab.AskBot')));
    dispatch(setDashBoardQuestion(question));
  };

  const handleExploreCompaniesClick = (event) => {
    event.stopPropagation();
    dispatch(setHomeTabs(t('tab.ExploreAllCompanies')));
    navigate(`/explore-all-companies`);
  };

  const setActiveCompany = (currentCompany) => {
    let updatedCompanyDetails = [...companyDashBoardDetails];
    updatedCompanyDetails = updatedCompanyDetails.map((item) => ({ ...item, active: false }));

    const existingIndex = updatedCompanyDetails.findIndex(
      (obj) => obj.data.base.CIN === currentCompany.data.base.CIN
    );
    updatedCompanyDetails[existingIndex].active = true;
    dispatch(setCompanyDashBoardDetails([...updatedCompanyDetails]));
  };

  const dashBoardHome = () => {
    let updatedCompanyDetails = [...companyDashBoardDetails];
    updatedCompanyDetails = updatedCompanyDetails.map((item) => ({ ...item, active: false }));
    dispatch(setCompanyDashBoardDetails([...updatedCompanyDetails]));
  };

  const deleteDashboardCompanyTab = (cin) => {
    let updatedDashboardCompanyDetails = [...companyDashBoardDetails];
    let updatedDashboardNews = JSON.parse(JSON.stringify(dashBoardNews));
    delete updatedDashboardNews[cin];
    dispatch(setDashBoardNews(updatedDashboardNews));
    if (updatedDashboardCompanyDetails.length === 1) {
      dispatch(setCompanyDashBoardDetails([]));
      return;
    }
    const existingIndex = updatedDashboardCompanyDetails.findIndex(
      (obj) => obj.data.base.CIN === cin
    );

    if (updatedDashboardCompanyDetails[existingIndex].active === false) {
      updatedDashboardCompanyDetails.splice(existingIndex, 1);
    } else {
      if (existingIndex === 0) {
        updatedDashboardCompanyDetails = [
          ...updatedDashboardCompanyDetails.slice(0, existingIndex),
          { ...updatedDashboardCompanyDetails[existingIndex + 1], active: true },
          ...updatedDashboardCompanyDetails.slice(existingIndex + 2)
        ];
      } else {
        if (existingIndex === updatedDashboardCompanyDetails.length - 1) {
          updatedDashboardCompanyDetails = [
            ...updatedDashboardCompanyDetails.slice(0, existingIndex - 1),
            { ...updatedDashboardCompanyDetails[existingIndex - 1], active: true }
          ];
        } else {
          updatedDashboardCompanyDetails = [
            ...updatedDashboardCompanyDetails.slice(0, existingIndex),
            { ...updatedDashboardCompanyDetails[existingIndex + 1], active: true },
            ...updatedDashboardCompanyDetails.slice(existingIndex + 2)
          ];
        }
      }
    }
    dispatch(setCompanyDashBoardDetails([...updatedDashboardCompanyDetails]));
  };

  const currentActiveCompany =
    companyDashBoardDetails && companyDashBoardDetails.length > 0
      ? companyDashBoardDetails.find((x) => x.active === true) || null
      : null;
  return (
    <>
      <ErrorToast errorMessage={errorMessage} />
      <div style={{ height: '1px' }}>{loadingCompanyDashBoard && <LinearProgress />}</div>
      <div
        style={{
          padding: '16px 20px'
        }}
      >
        {isEnableV1 && companyListDetials === null ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            style={{ margin: 10 }}
            width="100%"
            height={45}
          />
        ) : (
          <>
            {isEnableV1 && companyListDetials && companyListDetials.length > 0 ? (
              <Autocomplete
                options={companyListDetials.slice(0, 10)}
                autoHighlight
                className={classes.root}
                getOptionLabel={(option) => option.entity_name || ''}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                noOptionsText={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div>
                      <div style={{ display: 'flex' }}>
                        <Typography className={classes.optionTypo}>
                          What are you looking for?
                        </Typography>
                        <div style={{ paddingLeft: '200px' }}>
                          <Typography
                            className={classes.exploreCompanyText}
                            onMouseDown={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              handleExploreCompaniesClick(event);
                            }}
                          >
                            Explore Companies
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <Typography
                          className={classes.optionTypo}
                          onMouseDown={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            handleQuestionClickDashbaord(event, searchValue);
                          }}
                        >
                          <strong>Ask Byz - </strong>"{searchValue}"
                        </Typography>
                      </div>
                    </div>
                  </div>
                }
                renderInput={(params) => (
                  <form noValidate onSubmit={(e) => e.preventDefault()}>
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={'Search for a company or prompt Ask Byz'}
                      // label={'Companies'}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <DashBoardSearchIcon className={classes.searchIcon} />
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabel
                        }
                      }}
                      autoComplete="off"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </form>
                )}
                renderOption={(option) => (
                  <Typography className={classes.optionTypo}>{option.entity_name}</Typography>
                )}
                onChange={(event, value) => handleChange(event, value)}
                ListboxComponent={(props) => (
                  <div>
                    {open && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '10px 20px 0px 20px'
                        }}
                      >
                        <div>
                          <div style={{ display: 'flex' }}>
                            <Typography className={classes.optionTypo}>
                              What are you looking for?
                            </Typography>
                            <div style={{ paddingLeft: '200px' }}>
                              <Typography
                                className={classes.exploreCompanyText}
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  handleExploreCompaniesClick(event);
                                }}
                              >
                                Explore Companies
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <Typography
                              className={classes.optionTypo}
                              onMouseDown={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleQuestionClickDashbaord(event, searchValue);
                              }}
                            >
                              <strong>Ask Byz - </strong>"{searchValue}"
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                    <div {...props} />
                  </div>
                )}
              />
            ) : (
              <SearchCompany
                classes={classes}
                setLoading={setLoadingCompanyDashBoard}
                setCompanySelectedContext={setCompanySelectedContext}
                handleExploreCompaniesClick={handleExploreCompaniesClick}
                handleQuestionClickDashbaord={handleQuestionClickDashbaord}
              />
            )}
          </>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          padding: '0px 20px 10px 20px',
          overflowX: 'auto'
        }}
      >
        {companyDashBoardDetails && companyDashBoardDetails.length > 0 && (
          <span
            className={classes.homeIcon}
            onClick={(event) => {
              event.stopPropagation();
              dashBoardHome();
            }}
          >
            <HomeIcon />
          </span>
        )}
        {companyDashBoardDetails &&
          companyDashBoardDetails.length > 0 &&
          companyDashBoardDetails.map((x, i) => {
            if (x.active == false)
              return (
                <div key={i} style={{ padding: '0px 4px' }}>
                  <div
                    className={classes.dashBoardTag}
                    onClick={() => {
                      setActiveCompany(x);
                    }}
                  >
                    <Typography className={classes.dashBoardText}>{x?.data?.base?.Name}</Typography>
                  </div>
                </div>
              );

            return (
              <div key={i} style={{ padding: '0px 4px' }}>
                <div className={classes.dashBoardSelectedTag}>
                  <Typography className={classes.dashBoardSelectedText}>
                    {x?.data?.base?.Name}
                  </Typography>
                  <span className={classes.closeIcon}>
                    <CloseIcon
                      onClick={(event) => {
                        event.stopPropagation();
                        deleteDashboardCompanyTab(x.data.base.CIN);
                      }}
                    />
                  </span>
                </div>
              </div>
            );
          })}
      </div>
      {currentActiveCompany === null && (
        <Grid
          style={{
            padding: '0px 20px'
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Marquee pauseOnHover={true}>
              {companyMarquee?.map(({ name, cin, images }) => (
                <>
                  <Card
                    sx={{
                      maxWidth: '100%',
                      height: '55px',
                      display: 'flex',
                      flexDirection: 'column',
                      border: '1px solid #F0F0F0',
                      margin: '7.5px',
                      borderRadius: '0.25rem',
                      '&:hover': { backgroundColor: '#EFF3FC' }
                    }}
                    style={{ borderColor: '#F0F0F0', borderWidth: '1px' }}
                    key={cin}
                    elevation={0}
                    onClick={() => handleMarqueCompanyClick(cin, name)}
                  >
                    <CardActionArea sx={{ flexGrow: 1, display: 'flex' }}>
                      {images && images.logo ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '50px',
                            height: '50px',
                            paddingLeft: '10px'
                          }}
                        >
                          <img
                            src={images.logo}
                            alt="Logo"
                            style={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain'
                            }}
                          />
                        </div>
                      ) : (
                        <BusinessIcon style={{ paddingLeft: '20px' }} />
                      )}
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography
                          style={{
                            fontFamily: 'Satoshi',
                            fontWeight: 'bold',
                            fontSize: '12px',
                            color: '#000000B2'
                          }}
                        >
                          {name}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </>
              ))}
            </Marquee>
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px 5px 5px 5px' }}
            >
              <Button
                className={classes.exploreCompanies}
                onClick={() => {
                  dispatch(setHomeTabs(t('tab.ExploreAllCompanies')));
                  navigate('/explore-all-companies');
                }}
              >
                <Typography
                  style={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#FFFFFF',
                    padding: '0px 20px'
                  }}
                >
                  Explore Companies
                </Typography>
              </Button>
            </div>
          </div>
        </Grid>
      )}
    </>
  );
});
DashBoardHeadder.displayName = 'DashBoardHeadder';
export default DashBoardHeadder;
