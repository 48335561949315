import {
  SETPORTFOLIOINTIALSTATEFOLDER,
  SETPORTFOLIOFOLDERDATA,
  SETPORTFOLIODOCEXPLORERLOADING,
  SETPORTFOLIOGLOBALLOADING,
  SETPORTFOLIOSELECTEDDOCEXPLORER,
  SET_WATCHLISTS,
  SET_EXPANDED_WATCHLIST,
  SET_WATCHLIST_COMPANIES,
  SET_LOADING_COMPANIES,
  SET_CURRENT_COMPANY,
  SETPORTFOLIOACTIVESCREENERTAB,
  SETPORTFOLIOSCREENERTAB,
  SETOPENREPORTDIALOG,
  SETCURRENTREPORT,
  SETKPIACTIVETAB,
  SETALLMESSAGESPT,
  SETLOADINGPORTFOLIOTRACKER,
  SETLOADINGPT,
  SETLOADINGANSWERSTREAMPT,
  SETSTOPRESPONSEPT,
  SETCHATSCROLLPT,
  SETCHATSEARCHINPUTPT
} from '../../action.types';

export const setPortfolioIntialStateFolder = (data) => {
  return {
    type: SETPORTFOLIOINTIALSTATEFOLDER,
    data
  };
};

export const setPortfolioFolderData = (data) => {
  return {
    type: SETPORTFOLIOFOLDERDATA,
    data
  };
};

export const setPortfolioDocExplorerloading = (data) => {
  return {
    type: SETPORTFOLIODOCEXPLORERLOADING,
    data
  };
};

export const setPortfolioGlobalLoading = (data) => {
  return {
    type: SETPORTFOLIOGLOBALLOADING,
    data
  };
};

export const setPortfolioSelectedDocExplorer = (data) => {
  return {
    type: SETPORTFOLIOSELECTEDDOCEXPLORER,
    data
  };
};

export const setPortfolioActiveScreenertab = (data) => {
  return {
    type: SETPORTFOLIOACTIVESCREENERTAB,
    data
  };
};

export const setPortfolioScreenertab = (data) => {
  return {
    type: SETPORTFOLIOSCREENERTAB,
    data
  };
};

export const setWatchlists = (data) => ({
  type: SET_WATCHLISTS,
  payload: data
});

export const setExpandedWatchlist = (expanded) => ({
  type: SET_EXPANDED_WATCHLIST,
  payload: expanded
});

export const setWatchlistCompanies = (watchlistId, companies) => ({
  type: SET_WATCHLIST_COMPANIES,
  payload: { watchlistId, companies }
});

export const setLoadingCompanies = (watchlistId, isLoading) => ({
  type: SET_LOADING_COMPANIES,
  payload: { watchlistId, isLoading }
});

export const setCurrentWatchlistCompany = (data, currentKpiReportData) => ({
  type: SET_CURRENT_COMPANY,
  payload: data,
  currentKpiReportData
});

export const setOpenReportDialog = (data) => {
  return {
    type: SETOPENREPORTDIALOG,
    data
  };
};

export const setKPIActiveTabs = (data) => {
  return {
    type: SETKPIACTIVETAB,
    data
  };
};

export const setCurrentKpiReport = (data) => {
  return {
    type: SETCURRENTREPORT,
    data
  };
};

export const setAllMessagesPT = (data) => {
  return {
    type: SETALLMESSAGESPT,
    data
  };
};

export const setLoadingPortfolioTracker = (data) => {
  return {
    type: SETLOADINGPORTFOLIOTRACKER,
    data
  };
};

export const setLoadingPT = (data) => {
  return {
    type: SETLOADINGPT,
    data
  };
};

export const setLoadingAnswerStreamPT = (data) => {
  return {
    type: SETLOADINGANSWERSTREAMPT,
    data
  };
};

export const setStopResponsePT = (data) => {
  return {
    type: SETSTOPRESPONSEPT,
    data
  };
};

export const setChatScrollPT = (data) => {
  return {
    type: SETCHATSCROLLPT,
    data
  };
};

export const setChatSearchInputPT = (data) => {
  return {
    type: SETCHATSEARCHINPUTPT,
    data
  };
};
