import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  mainChatGridContainer: {
    height: '100%'
  },
  docExplorerText: {
    paddingBottom: '10px',
    fontFamily: 'Satoshi',
    fontSize: '22px',
    fontWeight: '400',
    color: '#373737',
    '& span': {
      backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    },
    '& sup': {
      margin: '-4px 0 8px 3px',
      fontSize: '12px',
      fontWeight: 600,
      // WebkitBackgroundClip: 'text',
      // WebkitTextFillColor: 'transparent',
      // textDecoration: 'none'
      backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    }
  },
  inputContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    boxShadow: 'none',
    background: 'linear-gradient(90deg, #EEF3FF, #EEFAFB)',
    border: '1px solid #E7E7E7',
    borderRadius: '10px'
  },
  sourcesText: {
    top: '-6px',
    left: '10px',
    display: 'inline-flex',
    padding: '0px 4px',
    position: 'absolute',
    fontSize: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9,
    borderRadius: '4px',
    border: '1px solid var(--Border-Colors-Border3, #E7E7E7)',
    background: '#FFF'
  },
  textField: {
    '& .MuiInputBase-input': {
      height: '11px',
      background: 'linear-gradient(90deg, #EEF3FF, #EEFAFB)',
      borderRadius: '12px 0 0 12px',
      padding: '16px 9px'
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
      color: '#000000B2',
      fontWeight: 500
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#000000',
      opacity: 0.6,
      fontWeight: 400
    },
    '& .MuiOutlinedInput-adornedEnd': {
      background: 'linear-gradient(90deg,#EEF3FF,#EEFAFB)',
      borderRadius: '12px',
      paddingRight: '10px'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E7E7E7',
        borderRadius: '8px!important'
      },
      '&:hover fieldset': {
        borderColor: '#E7E7E7!important'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E7E7E7!important',
        boxShadow: 'none'
      }
    }
  },
  chatContainer: {
    height: 'calc(100vh - 326px)',
    borderRadius: '12px',
    background: '#FFF',
    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
    marginBottom: '16px',
    overflowY: 'hidden',
    padding: '0 0 16px 0',
    '&:hover': {
      overflowY: 'auto'
    }
  },
  noScrollbar: {
    overflowY: 'hidden',
    overflowX: 'hidden',
    scrollbarGutter: 'stable both-edges',
    '&:hover': {
      overflowY: 'auto',
      overflowX: 'auto'
    }
  },
  chipLabel: {
    borderRadius: '4px',
    border: '1px solid var(--Gradients-Gradient-main, #06BCC1)',
    background: 'linear-gradient(90deg, rgba(6, 188, 193, 0.10), rgba(0, 76, 255, 0.10))',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    '&:hover': {
      background: '#CCDBFF'
    },
    '& span': {
      fontFamily: 'Satoshi',
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '20px',
      color: '#373737'
    }
  },
  ovverrideChipLbl: {
    background: '#ffffff!important',
    border: '1px solid #E8E8E8'
  },
  defaultCursor: {
    cursor: 'default !important'
  },
  suggestedQues: {
    'margin-bottom': '10px',
    borderRadius: '4px',
    border: '1px solid #B3CDFA',
    background: '#EAF1FE',
    display: 'flex',
    alignItems: 'center',
    padding: '6px 0px',
    maxWidth: '100%',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    height: '100%',
    '&:hover': {
      background: '#CCDBFF'
    },
    'justify-content': 'flex-start',
    '& span': {
      fontStyle: 'normal',
      fontSize: '14px',
      fontWeight: '500t',
      lineHeight: '22px',
      whiteSpace: 'normal',
      overflowWrap: 'break-word',
      overflow: 'hidden'
    }
  },
  flexRow: {
    display: 'flex',
    gap: '8px'
  },
  flexRowTags: {
    display: 'flex',
    gap: '8px',
    maxWidth: '640px',
    overflowX: 'hidden',
    transition: 'overflow-x 0.2s ease-in-out',
    height: '41px',
    '&:hover': {
      overflowX: 'auto'
    }
  },
  chatMain: {
    display: 'flex',
    // gap: '30%',
    // 'margin-bottom': '18px',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    backgroundColor: '#fff',
    padding: '16px'
  },
  pdL20: {
    paddingLeft: '20%'
  },
  suggestedMain: {
    padding: '0 16px'
  },
  headingText: {
    fontFamily: 'Satoshi',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
    marginBottom: '10px'
  },
  helpingText: {
    color: '#5E5E5E',
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: 'Satoshi',
    fontSize: '12px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: 1.5
  },
  pdBt10: {
    'padding-bottom': '3px'
  },
  skeletonMain: {
    position: 'relative',
    margin: '4px',
    width: '100%',
    height: '32px',
    'margin-bottom': '10px'
  },
  skeletonCh2: {
    position: 'absolute!important',
    top: '50%!important',
    left: '50%!important',
    transform: 'translate(-50%, -50%)!important'
  },
  mgBt: {
    'margin-bottom': '22px'
  }
}));

export default useStyles;
