import { getApi, putApi, postApi, deleteApi } from '../../../../services/token';

const { REACT_APP_API: COMPANY_URL, REACT_APP_COMPANY_API: COMPANY_API_URL } = process.env;
export const getFolderData = async (folder_id = null, cin = '') => {
  try {
    const companyFilter = cin ? `&cin_list=${cin}` : '';
    const url =
      folder_id !== null
        ? `${process.env.REACT_APP_INGESTION_API_URL}/v2/document?input_source=company_portfolio&folder_id=${folder_id}${companyFilter}`
        : `${process.env.REACT_APP_INGESTION_API_URL}/v2/document?input_source=company_portfolio${companyFilter}&node_type=file`;

    const folderData = await getApi(url);

    if (!folderData?.data?.data?.items) {
      throw new Error('Something went wrong , please try again later');
    }

    return folderData.data.data.items;
  } catch (error) {
    // Check if backend provided error details and include them in the thrown error
    const backendError =
      error?.response?.data?.error?.detail || 'Something went wrong , please try again later';
    throw new Error(backendError);
  }
};

export const getSearchFileData = async (searchVal) => {
  try {
    const url = `${process.env.REACT_APP_INGESTION_API_URL}/v2/document?input_source=company_portfolio&query=${searchVal}`;
    const folderData = await getApi(url);
    return folderData.data.data.items;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteFolder = async (folder_id = null) => {
  try {
    const url = `${process.env.REACT_APP_INGESTION_API_URL}/v1/document/${folder_id}`;
    const data = await deleteApi(url);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createNewFolder = async (body) => {
  try {
    const url = `${process.env.REACT_APP_INGESTION_API_URL}/v1/folder`;
    const data = await postApi(url, body);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const moveFolder = async (folder_id, body) => {
  try {
    // const url = `${process.env.REACT_APP_INGESTION_API_URL}/v1/folder?folder_id=${folder_id}`;
    const url = `${process.env.REACT_APP_INGESTION_API_URL}/v1/folder/${folder_id}`;
    const data = await putApi(url, body);
    return data;
  } catch (error) {
    const backendError =
      error?.response?.data?.error?.detail ||
      error.message ||
      'Something went wrong while moving the folder. Please try again.';
    throw new Error(backendError);
  }
};

export const moveFile = async (folder_id, body) => {
  try {
    // const url = `${process.env.REACT_APP_INGESTION_API_URL}/v1/folder?folder_id=${folder_id}`;
    const url = `${process.env.REACT_APP_INGESTION_API_URL}/v1/document/${folder_id}`;
    await putApi(url, body);
  } catch (error) {
    const backendError =
      error?.response?.data?.error?.detail ||
      error.message ||
      'Something went wrong while moving the file. Please try again.';
    throw new Error(backendError);
  }
};

export const getWatchlists = async () => {
  try {
    const response = await getApi(`${COMPANY_URL}/v1/user/watchlist`);
    return response?.data?.data;
  } catch (err) {
    console.err(err);
  }
};

export const getCompaniesByWatchlistId = async (watchlistId) => {
  let response = await postApi(`${COMPANY_URL}/v1/user/watchlist/${watchlistId}`, {
    page_limit: 100
  });
  return response.data.data[0].companies;
};

export const saveKPIReport = async (obj) => {
  // cin
  const { report_id = '' } = obj;
  const updateUrl = report_id ? `/${report_id}` : '';
  const { data } = await postApi(`${COMPANY_API_URL}/v2/report${updateUrl}`, obj);
  return data;
};

export const getReportSummaryByCin = async (report_id, cin) => {
  const data = await getApi(
    `${COMPANY_API_URL}/v2/report/${report_id}?entity_id=${cin}&entity_type=company&report_name=KPI`
  );
  return data;
};

export const getReportIdByCin = async (cin) => {
  const data = await getApi(
    `${COMPANY_API_URL}/v2/report?entity_id=${cin}&entity_type=company&report_name=KPI`
  );
  const reportId = data?.data?.data[0]?.report_id || null;
  if (reportId) {
    return await getReportSummaryByCin(reportId, cin);
  }
};
