import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List, Typography, Collapse } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import CustomListItemButton from '../../../atoms/listItemButton';
import { getCompaniesByWatchlistId, getReportIdByCin, getWatchlists } from '../services';
import {
  setWatchlists,
  setExpandedWatchlist,
  setWatchlistCompanies,
  setLoadingCompanies,
  setCurrentWatchlistCompany,
  setKPIActiveTabs,
  setPortfolioSelectedDocExplorer,
  setAllMessagesPT,
  setPortfolioFolderData,
  setPortfolioIntialStateFolder
} from '../../../../redux/pages/portfolioTracker/action';
import { getApi } from '../../../../services/token';
import { setSuggestedDeQuestions, setAllMessagesDE } from '../../action';
import { setLoadingPortfolioTracker } from '../../../../redux/pages/portfolioTracker/action';
//import { useState } from 'react';
//import { setGlobalLoader } from '../../action';

const isSeparateState = true;
const updateAllMessageAction = isSeparateState ? setAllMessagesPT : setAllMessagesDE;

const useStyles = makeStyles(() => ({
  queriesHeading: {
    fontFamily: 'Satoshi !important',
    color: '#373737',
    fontSize: '16px !important',
    fontWeight: '700 !important',
    padding: '10px 16px'
  },
  mainDiv: {
    background: '#FFF',
    border: '1px solid rgb(231, 231, 231)',
    borderRadius: '8px'
  },
  listItemCls: {
    borderBottom: '1px solid #F0F0F0 !important',
    '& p': {
      paddingLeft: '16px!important'
    }
  }
}));

const staticReportData = {
  report_id: null,
  report_name: 'KPI',
  entity_list: [
    {
      entity_type: 'company',
      entity_id: ''
    }
  ],
  question_groups: [
    {
      name: 'Section 1',
      questions: [{ question: '', type: 'query', question_type: '' }]
    }
  ],
  metaData: null,
  response: null
};

const WatchlistsAccordion = () => {
  //const [isCompanyClicked, setIsCompanyClicked] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { watchlists, expanded, companiesData, loadingCompanies, currentWatchlistCompany } =
    useSelector((state) => state.portfolioTrackerReducer);
  const { company: currentCompany, watchlist: currentWatchlist } = currentWatchlistCompany || {};

  useEffect(() => {
    const fetchWatchlists = async () => {
      const data = await getWatchlists();
      dispatch(setWatchlists(data || []));
    };
    if (watchlists?.length === 0) fetchWatchlists();
  }, [dispatch]);

  const handleAccordionClick = useCallback(
    async (watchlistId) => {
      const newExpanded = expanded.includes(watchlistId)
        ? expanded.filter((id) => id !== watchlistId)
        : [watchlistId];
      dispatch(setExpandedWatchlist(newExpanded));

      if (!companiesData[watchlistId]) {
        dispatch(setLoadingCompanies(watchlistId, true));
        const companies = await getCompaniesByWatchlistId(watchlistId);
        dispatch(setWatchlistCompanies(watchlistId, companies || []));
        dispatch(setLoadingCompanies(watchlistId, false));
      }
    },
    [dispatch, expanded, companiesData]
  );

  const handleGetCompanyFiles = async (company) => {
    const companyCin = company?.cin || ''; //
    if (companyCin) {
      const companyFilter = companyCin ? `&cin_list=${companyCin}` : '';
      dispatch(setPortfolioIntialStateFolder());
      try {
        const url = `${process.env.REACT_APP_INGESTION_API_URL}/v2/document?input_source=company_portfolio${companyFilter}&node_type=file`;
        const folderData = await getApi(url);
        const initialData = folderData.data.data.items;
        dispatch(setPortfolioFolderData(initialData || []));
      } catch (error) {
        console.error('Error fetching initial folder data:', error);
      }
    }
  };

  const onSelectCompany = async (company, watchlist) => {
    try {
      //dispatch(setGlobalLoader(true));
      dispatch(setLoadingPortfolioTracker(true));
      //setIsCompanyClicked(true);
      const currentCompanyCin = company?.cin;
      let newKPIReportData = {
        ...staticReportData,
        entity_list: [{ ...staticReportData.entity_list[0], entity_id: currentCompanyCin }]
      };
      const { data } = (await getReportIdByCin(currentCompanyCin)) || {};
      const questionGroups = data?.data?.question_groups || [];
      const report_id = data?.data?.metadata?.report_id || '';
      const metaData = data?.data?.metadata || null;
      const response = data?.data.response || null;

      if (report_id) {
        newKPIReportData = {
          report_id,
          report_name: 'KPI',
          entity_list: [
            {
              entity_type: 'company',
              entity_id: currentCompanyCin
            }
          ],
          question_groups: questionGroups,
          metaData,
          response
        };
      }
      dispatch(setKPIActiveTabs(''));
      dispatch(setCurrentWatchlistCompany({ company, watchlist }, newKPIReportData));
      await handleGetCompanyFiles(company);
      dispatch(setLoadingPortfolioTracker(false));
      //setIsCompanyClicked(false);
      //dispatch(setGlobalLoader(false));
    } catch (error) {
      //dispatch(setGlobalLoader(false));
      dispatch(setLoadingPortfolioTracker(false));
    }
  };

  return (
    <div
      className={classes.mainDiv}
      // style={{
      //   opacity: isCompanyClicked ? '0.5' : '1',
      //   pointerEvents: isCompanyClicked ? 'none' : 'auto'
      // }}
    >
      <List disablePadding>
        <Typography className={classes.queriesHeading}>Watchlists</Typography>
        {watchlists?.map((watchlist) => {
          const isExpanded = expanded.includes(watchlist.watchlist_id);
          const companies = companiesData[watchlist.watchlist_id];
          const isLoading = loadingCompanies[watchlist.watchlist_id];

          return (
            <React.Fragment key={watchlist.watchlist_id}>
              <CustomListItemButton
                style={{ borderBottom: '1px solid #F0F0F0' }}
                expanded={isExpanded}
                onClick={() => handleAccordionClick(watchlist.watchlist_id)}
                text={watchlist.name}
                showIcon={true}
              />

              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <List disablePadding>
                  {isLoading ? (
                    <CustomListItemButton text="Loading..." customClass={classes.listItemCls} />
                  ) : Array.isArray(companies) && companies.length > 0 ? (
                    companies.map((company) => (
                      <>
                        <CustomListItemButton
                          key={company.cin || company.name}
                          text={company.name}
                          customClass={classes.listItemCls}
                          onClick={() => {
                            dispatch(
                              setSuggestedDeQuestions({
                                summary: '',
                                key_insights: '',
                                questions: [],
                                loading: false
                              })
                            );
                            dispatch(updateAllMessageAction([]));
                            dispatch(setPortfolioSelectedDocExplorer([]));
                            onSelectCompany(company, watchlist);
                          }}
                          isChildMenuSelected={
                            company.cin === currentCompany?.cin &&
                            currentWatchlist?.watchlist_id === watchlist.watchlist_id
                          }
                        />
                      </>
                    ))
                  ) : (
                    <CustomListItemButton
                      text="No Data Available"
                      customClass={classes.listItemCls}
                    />
                  )}
                </List>
              </Collapse>
            </React.Fragment>
          );
        })}
      </List>
    </div>
  );
};

export default WatchlistsAccordion;
