import {
  SETEXPLOREPAGINATION,
  SETGRIDCONFIG,
  SETNEWS,
  SETDASHBOARDNEWS,
  SETTEARSHEETDATA,
  SETTEARSHEETENTITIESOPTIONS,
  SETTEARSHEETKEYS,
  SETTEARSHEETTEMPLATEOPTIONS,
  SETWHITELABELDETAILS,
  SETMCADATA,
  SETSTOPRESPONSEDE,
  SETGLOBALSUCCESSMESSAGE,
  SETGLOBALINFOMESSAGE,
  SETGLOBALERRORMESSAGE,
  SETGLOBALLOADER
} from '../../redux/action.types';
import { SETCOLCONFIG } from '../../redux/action.types';
import { SETFILTER } from '../../redux/action.types';
import { SETINPUTFILTER } from '../../redux/action.types';
import { SETPREVIOUSFILTER } from '../../redux/action.types';
import { SETUUID } from '../../redux/action.types';
import { SETUSERMESSAGE } from '../../redux/action.types';
import { SETRESPMESSAGE } from '../../redux/action.types';
import { SETCOMPANYDETAILS } from '../../redux/action.types';
import { SETHOMETABS } from '../../redux/action.types';
import { SETCOMPANYNAME } from '../../redux/action.types';
import { SETCOMPANYCIN } from '../../redux/action.types';
import {
  SETCOMPANYAPICONTEXT,
  SETCOMPANYMENUCLICKED,
  SETISCOMPANYDATAVISIBLE,
  SETLOADING,
  SETLOADINGDE,
  SETLOADINGANSWERSTREAM,
  SETLOADINGANSWERSTREAMDE,
  SETSHOWCOMPANYBUTTON,
  SETISCOMPANYDATAPOPULATED,
  SETALLMESSAGES,
  SETALLMESSAGESDE,
  SETCHATSCROLL,
  SETCHATSCROLLDE,
  SETCHATSEARCHINPUT,
  SETCHATSEARCHINPUTDE,
  SHOWCOMPANYPAGE,
  UPDATEPREVIOUSROUTE,
  SETASKBOTWIDTH,
  SETSTOPRESPONSE,
  RESETSTATE,
  SETREGENERATERESPONSE,
  SETQUESTIONTABS,
  SETISFIRSTTIMEUSER,
  SETISSTEPFINISHED,
  SETSHOWTOURGUIDE,
  SETCURRENTSTEP,
  ENDTOURGUIDE,
  SETCOMPANYDASHBOARDDETAILS,
  SETDASHBOARDQUESTION,
  SETCOMPANYLISTDETIALS,
  SETSELECTEDDOCEXP,
  SETDETAGS,
  SETSUGGESTEDDEQUESTIONS,
  SETTEARSHEETURL,
  SETDOCCOMPARESTART
} from '../../redux/action.types';
const INITIAL_STATE = {
  gridConfig: null,
  colConfig: null,
  filterInput: [],
  filter: [],
  previousFilter: [],
  uuID: null,
  userMessage: [],
  respMessage: [],
  companyDetails: [],
  companyDashBoardDetails: [],
  hometabs: '',
  companyName: '',
  companyCin: '',
  companyApiContext: {},
  isCompanyMenuClicked: false,
  isCompanyDataVisible: false,
  loading: false,
  loadingDE: false,
  loadingAnswerStream: false,
  loadingAnswerStreamDE: false,
  showCompanyButton: false,
  isCompanyDataPopulated: false,
  allMessages: [],
  allMessagesDE: [],
  chatScroll: true,
  chatScrollDE: false,
  chatsearchInput: '',
  chatsearchInputDE: '',
  showCompanyPage: '',
  previousRouteNavigation: '',
  stopResponse: false,
  stopResponseDE: false,
  regenerateResponse: false,
  askGridWidth: null,
  questionTabs: 'Company & Theme',
  explorePagination: {},
  news: {},
  dashBoardNews: {},
  isFirstTimeUser: false,
  isStepFinished: false,
  whiteLabelDetails: {},
  currentStep: 0,
  showTourGuide: false,
  tearSheetKeys: {},
  tearSheetEntitiesOptions: [],
  tearSheetTemplateOptions: [],
  tearSheetData: null,
  mcaData: {},
  dashBoardQuestion: null,
  companyListDetials: null,
  selectedDocExp: [],
  deTags: [],
  suggestedDeQuestions: { questions: [], loading: false },
  tearsheetUrl: '',
  docCompareStart: true,
  globalErrorMessage: false,
  globalSuccessMessage: false,
  globalInfoMessage: false,
  globalLoading: false
};
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETGRIDCONFIG:
      return {
        ...state,
        gridConfig: action.data
      };
    case SETCOLCONFIG:
      return {
        ...state,
        colConfig: action.data
      };
    case SETFILTER:
      return {
        ...state,
        filter: action.data
      };
    case SETINPUTFILTER:
      return {
        ...state,
        filterInput: action.data
      };
    case SETPREVIOUSFILTER:
      return {
        ...state,
        previousFilter: action.data
      };
    case SETUUID:
      return {
        ...state,
        uuID: action.data
      };
    case SETUSERMESSAGE:
      return {
        ...state,
        userMessage: action.data
      };
    case SETRESPMESSAGE:
      return {
        ...state,
        respMessage: action.data
      };
    case SETCOMPANYDETAILS:
      return {
        ...state,
        companyDetails: action.data
      };
    case SETCOMPANYDASHBOARDDETAILS:
      return {
        ...state,
        companyDashBoardDetails: action.data
      };
    case SETHOMETABS:
      return {
        ...state,
        hometabs: action.data
      };
    case SETCOMPANYNAME:
      return {
        ...state,
        companyName: action.data
      };
    case SETCOMPANYCIN:
      return {
        ...state,
        companyCin: action.data
      };
    case SETCOMPANYAPICONTEXT:
      return {
        ...state,
        companyApiContext: action.data
      };
    case SETCOMPANYMENUCLICKED:
      return {
        ...state,
        isCompanyMenuClicked: action.data
      };
    case SETISCOMPANYDATAVISIBLE:
      return {
        ...state,
        isCompanyDataVisible: action.data
      };
    case SETLOADING:
      return {
        ...state,
        loading: action.data
      };
    case SETLOADINGDE:
      return {
        ...state,
        loadingDE: action.data
      };
    case SETLOADINGANSWERSTREAM:
      return {
        ...state,
        loadingAnswerStream: action.data
      };
    case SETLOADINGANSWERSTREAMDE:
      return {
        ...state,
        loadingAnswerStreamDE: action.data
      };
    case SETSHOWCOMPANYBUTTON:
      return {
        ...state,
        showCompanyButton: action.data
      };
    case SETISCOMPANYDATAPOPULATED:
      return {
        ...state,
        isCompanyDataPopulated: action.data
      };
    case SETALLMESSAGES:
      return {
        ...state,
        allMessages: action.data
      };
    case SETALLMESSAGESDE:
      return {
        ...state,
        allMessagesDE: action.data
      };
    case SETCHATSCROLL:
      return {
        ...state,
        chatScroll: action.data
      };
    case SETCHATSCROLLDE:
      return {
        ...state,
        chatScrollDE: action.data
      };
    case SETCHATSEARCHINPUT:
      return {
        ...state,
        chatsearchInput: action.data
      };
    case SETCHATSEARCHINPUTDE:
      return {
        ...state,
        chatsearchInputDE: action.data
      };
    case SHOWCOMPANYPAGE:
      return {
        ...state,
        showCompanyPage: action.data
      };
    case UPDATEPREVIOUSROUTE:
      return {
        ...state,
        previousRouteNavigation: action.data
      };
    case SETASKBOTWIDTH:
      return {
        ...state,
        askGridWidth: action.data
      };
    case SETSTOPRESPONSE:
      return {
        ...state,
        stopResponse: action.data
      };
    case SETSTOPRESPONSEDE:
      return {
        ...state,
        stopResponseDE: action.data
      };
    case SETREGENERATERESPONSE:
      return {
        ...state,
        regenerateResponse: action.data
      };
    case SETQUESTIONTABS:
      return {
        ...state,
        questionTabs: action.data
      };
    case SETEXPLOREPAGINATION:
      return {
        ...state,
        explorePagination: action.data
      };
    case SETNEWS:
      return {
        ...state,
        news: action.data
      };
    case SETDASHBOARDNEWS:
      return {
        ...state,
        dashBoardNews: action.data
      };
    case SETISFIRSTTIMEUSER:
      return {
        ...state,
        isFirstTimeUser: action.data
      };
    case SETISSTEPFINISHED:
      return {
        ...state,
        isStepFinished: action.data
      };
    case SETWHITELABELDETAILS:
      return {
        ...state,
        whiteLabelDetails: action.data
      };
    case SETCURRENTSTEP:
      return {
        ...state,
        currentStep: action.data
      };
    case SETSHOWTOURGUIDE:
      return {
        ...state,
        showTourGuide: action.data
      };
    case ENDTOURGUIDE:
      return {
        ...state,
        showTourGuide: false,
        currentStep: 0
      };
    case SETTEARSHEETKEYS:
      return {
        ...state,
        tearSheetKeys: action.data
      };
    case SETTEARSHEETENTITIESOPTIONS:
      return {
        ...state,
        tearSheetEntitiesOptions: action.data
      };
    case SETTEARSHEETTEMPLATEOPTIONS:
      return {
        ...state,
        tearSheetTemplateOptions: action.data
      };
    case SETTEARSHEETDATA:
      return {
        ...state,
        tearSheetData: action.data
      };
    case SETMCADATA:
      return {
        ...state,
        mcaData: action.data
      };
    case SETDASHBOARDQUESTION:
      return {
        ...state,
        dashBoardQuestion: action.data
      };
    case SETCOMPANYLISTDETIALS:
      return {
        ...state,
        companyListDetials: action.data
      };
    case SETSELECTEDDOCEXP:
      return {
        ...state,
        selectedDocExp: action.data
      };
    case SETDETAGS:
      return {
        ...state,
        deTags: action.data
      };
    case SETSUGGESTEDDEQUESTIONS:
      return {
        ...state,
        suggestedDeQuestions: action.data
      };
    case SETTEARSHEETURL:
      return {
        ...state,
        tearsheetUrl: action.data
      };
    case SETDOCCOMPARESTART:
      return {
        ...state,
        docCompareStart: action.data
      };
    case SETGLOBALERRORMESSAGE:
      return {
        ...state,
        globalErrorMessage: action.data
      };
    case SETGLOBALSUCCESSMESSAGE:
      return {
        ...state,
        globalSuccessMessage: action.data
      };
    case SETGLOBALINFOMESSAGE:
      return {
        ...state,
        globalInfoMessage: action.data
      };
    case SETGLOBALLOADER:
      return {
        ...state,
        globalLoading: action.data
      };
    case RESETSTATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
