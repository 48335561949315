import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import React from 'react';
import { TextField, IconButton } from '@material-ui/core';
import { ReactComponent as PostQuestion } from '../../../../icons/LogoMark.svg';
import { useSelector, useDispatch } from 'react-redux';
import { setChatSearchInputDE } from '../../../action';
import LoadingDots from '../../../../atoms/loadingDots';
import NoDocTooltip from '../../noDocTooltip';
import { setChatSearchInputPT } from '../../../../../redux/pages/portfolioTracker/action';

const isSeparateState = !(process.env.REACT_APP_GLOBAL_STATE === 'true');
const setChatSearchInputPoTr = isSeparateState ? setChatSearchInputPT : setChatSearchInputDE;

const Input = ({ handleQuestionClick, checkIFqueryRunning }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const selectedDocExp = useSelector(
    (state) => state.portfolioTrackerReducer.portfolioSelectedDocExplorer
  );
  const suggestedDeQuestions = useSelector((state) => state.tokenReducer.suggestedDeQuestions);

  const sources = selectedDocExp?.length
    ? `${selectedDocExp.length} ${selectedDocExp.length === 1 ? 'Source' : 'Sources'}`
    : '';
  const dispatch = useDispatch();

  // const loadingAnswerStreamDE = useSelector((state) => state.tokenReducer.loadingAnswerStreamDE);
  const loadingAnswerStreamDE = useSelector((state) =>
    isSeparateState
      ? state.portfolioTrackerReducer.loadingAnswerStreamPT
      : state.tokenReducer.loadingAnswerStreamDE
  );

  // const chatsearchInputDE = useSelector((state) => state.tokenReducer.chatsearchInputDE);
  const chatsearchInputDE = useSelector((state) =>
    isSeparateState
      ? state.portfolioTrackerReducer.chatsearchInputPT
      : state.tokenReducer.chatsearchInputDE
  );

  const handleChange = (event) => {
    if (checkIFqueryRunning()) {
      return;
    }
    dispatch(setChatSearchInputPoTr(event.target.value));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleQuestionClick(event, chatsearchInputDE);
    }
  };

  const isDocIds = selectedDocExp && selectedDocExp.length > 0 && !suggestedDeQuestions.loading;
  return (
    <div className={classes.inputContainer}>
      {sources && <div className={classes.sourcesText}>{sources}</div>}
      <NoDocTooltip>
        <TextField
          value={chatsearchInputDE}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          fullWidth
          disabled={!isDocIds}
          variant="outlined"
          placeholder={t('askBot.searchBar')}
          className={classes.textField}
          InputProps={{
            endAdornment: (
              <IconButton
                style={{ padding: '0px' }}
                onClick={(event) => {
                  if (chatsearchInputDE) {
                    handleQuestionClick(event, chatsearchInputDE);
                  }
                }}
              >
                {loadingAnswerStreamDE ? <LoadingDots /> : <PostQuestion />}
              </IconButton>
            )
          }}
        />
      </NoDocTooltip>
    </div>
  );
};

export default Input;
