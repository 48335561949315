import { Box, Typography, makeStyles } from '@material-ui/core';
import { formatedTime } from '../../utils/formatedTime';
import { useTranslation } from 'react-i18next';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { Info } from '@material-ui/icons';
import { ReactComponent as RestorePage } from '../../../icons/refreshReport.svg';
import { ReactComponent as DownloadReport } from '../../../icons/downloadReport.svg';
import { ReactComponent as DownloadReportDone } from '../../../icons/downloadDone.svg';
import { ReactComponent as DeleteBinIcon } from '../../../icons/DeleteBinIcon.svg';
import { ReactComponent as EditReportIcon } from '../../../icons/EditReportIcon.svg';
import { ReactComponent as DuplicateIcon } from '../../../icons/DuplicateIcon.svg';
import { updateParamsInURL } from '../../../../helpers';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  tearSheetHeader: {
    display: 'flex',
    position: 'relative'
  },
  printButton: {
    display: 'flex',
    gap: '32px',
    position: 'absolute',
    right: '20px',
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      right: '30px'
    }
  },
  '@media print': {
    printButton: {
      visibility: 'hidden'
    }
  },
  infoBox: {
    display: 'flex',
    alignItems: 'center'
  },
  infoDiv: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 6px',
    // background: '#cccccc',
    // borderRadius: '5px',
    marginRight: '15px',
    'border-left': '3px solid var(--SystemColor-blue400, #3473EE)',
    background: 'var(--SystemColor-blue100, #F4F8FF)',
    'box-shadow': '0px 0px 8px 0px rgba(0, 0, 0, 0.10)'
  },
  infoIcon: {
    marginRight: '4px',
    fontSize: '21px',
    color: '#3473EE'
  },
  infoTypo: {
    color: '#1d1d1d',
    fontSize: '14px'
  },
  reportNameCls: {
    'text-transform': 'capitalize',
    'font-family': 'Satoshi',
    'font-size': '22px',
    'font-style': 'normal',
    'font-weight': 500,
    'line-height': '32px',
    marginTop: 0
  },
  'MuiSvgIcon-root': {
    color: '#767676 !important'
  },
  toastMessageText: {
    'font-size': '22px'
  }
}));

const Message = ({ classes, msg }) => {
  return (
    <Box className={classes.infoBox}>
      <div className={classes.infoDiv}>
        <Info className={classes.infoIcon} />
        <Typography className={classes.infoTypo}>{msg}</Typography>
      </div>
    </Box>
  );
};
const TearSheetHeader = ({
  tearSheetData,
  triggerLatest,
  handleReportDeleteOpen,
  copyToClipboard
}) => {
  const [searchParams] = useSearchParams();
  const entity_type = searchParams.get('entity_type') || 'company';
  const { generated_from_latest_template, report_name } = tearSheetData || {};
  const metaData = tearSheetData?.metadata;
  const tearSheetStatus = metaData?.report_status;
  const shareReport = metaData?.share_report;
  const classes = useStyles();
  const [height, setHeight] = useState('auto');
  const { t } = useTranslation();
  const [tooltipTitle, setTooltipTitle] = useState('Copy');
  const [downloadTooltipTitle, setdownloadTooltipTitle] = useState('Download');

  const navigate = useNavigate();
  const location = useLocation();

  const handleEdit = () => {
    updateParamsInURL({
      navigate,
      location,
      addParams: { action: 'edit', entity_id: metaData?.entity_id }
    });
  };

  const handleDelete = () => {
    handleReportDeleteOpen(metaData?.entity_id, metaData?.report_id, report_name);
  };

  const showLoader =
    tearSheetStatus === t('tearSheet.inProgress') || tearSheetStatus === t('tearSheet.inQueue');
  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    const aspectRatio = naturalHeight / naturalWidth;
    setHeight(50 * aspectRatio); // Maintain aspect ratio with max width 60px
  };
  const handleCopyClick = () => {
    copyToClipboard(); // Call your copy function
    setTooltipTitle('Copied!');

    // Reset back to "Copy" after 2 seconds
    setTimeout(() => {
      setTooltipTitle('Copy');
    }, 2000);
  };
  const handleDownloadClick = () => {
    setdownloadTooltipTitle('Downloaded!');
    setTimeout(() => {
      setdownloadTooltipTitle('Download');
    }, 3000);
  };

  return (
    <>
      <div className={classes.tearSheetHeader}>
        {metaData?.logo && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '50px'
            }}
          >
            <img
              src={metaData?.logo}
              alt="Logo"
              width="50"
              height={height}
              onLoad={handleImageLoad}
              style={{ objectFit: 'contain' }}
            />

            {/* (
            <Business
              style={{
                maxWidth: '80%',
                maxHeight: '80%',
                objectFit: 'contain'
              }}
            />
          ) */}
          </div>
        )}
        <div
          style={{
            fontFamily: 'Satoshi',
            paddingLeft: entity_type === 'company' ? '15px' : undefined,
            fontWeight: '600',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <p
            style={{
              fontFamily: 'Satoshi',
              fontSize: '22px',
              fontWeight: '500'
            }}
          >
            {metaData?.entity_name}
          </p>
        </div>
        <>
          {metaData?.updated_at && (
            <div
              style={{
                fontFamily: 'Satoshi',
                paddingLeft: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <p
                style={{
                  fontFamily: 'Satoshi',
                  fontSize: '16px',
                  fontStyle: 'italic'
                }}
              >
                ({formatedTime(metaData?.updated_at, true)})
              </p>
            </div>
          )}
          <div className={classes.printButton}>
            {showLoader ? (
              <>
                <Message classes={classes} msg={t('tearSheet.InProg')} />
                <IconButton>
                  <Tooltip title={t('tearSheet.inProcessToolTip')}>
                    <CircularProgress size={24} />
                  </Tooltip>
                </IconButton>
              </>
            ) : (
              <>
                {generated_from_latest_template === false && (
                  <Message classes={classes} msg={t('tearSheet.notLatest')} />
                )}
                {shareReport && <Message classes={classes} msg={t('tearSheet.nonEditable')} />}
                {!shareReport && (
                  <>
                    <Tooltip title="Refresh" arrow>
                      <IconButton
                        style={{
                          padding: '0px'
                        }}
                        onClick={triggerLatest}
                      >
                        <RestorePage
                          style={{
                            width: '20px',
                            height: '22.11px'
                          }}
                          sx={{ fill: '#767676' }}
                        />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </>
            )}
            {!showLoader && (
              <>
                <Tooltip title="Edit" arrow>
                  <IconButton
                    style={{
                      padding: '0px'
                    }}
                    onClick={handleEdit}
                  >
                    <EditReportIcon
                      style={{
                        width: '20px',
                        height: '20px'
                      }}
                      sx={{ fill: '#767676' }}
                    />
                  </IconButton>
                </Tooltip>
                {!shareReport && (
                  <Tooltip title="Delete" arrow>
                    <IconButton
                      style={{
                        padding: '0px'
                      }}
                      onClick={handleDelete}
                    >
                      <DeleteBinIcon
                        style={{
                          width: '22px',
                          height: '22px'
                        }}
                        sx={{ fill: '#767676' }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title={tooltipTitle} arrow>
                  <IconButton style={{ padding: '0px' }} onClick={handleCopyClick}>
                    <DuplicateIcon
                      style={{ width: '20px', height: '20px' }}
                      sx={{ fill: '#767676' }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title={downloadTooltipTitle} arrow>
                  <IconButton
                    style={{
                      padding: '0px'
                    }}
                    onClick={() => {
                      window.print();
                      handleDownloadClick();
                    }}
                  >
                    {downloadTooltipTitle === 'Download' ? (
                      <DownloadReport
                        style={{
                          width: '20px',
                          height: '20px'
                        }}
                        sx={{ fill: '#767676' }}
                      />
                    ) : (
                      <DownloadReportDone
                        style={{
                          width: '20px',
                          height: '20px'
                        }}
                        sx={{ fill: '#767676' }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
        </>
      </div>
      <p className={classes.reportNameCls}>{report_name}</p>
    </>
  );
};

export default TearSheetHeader;
