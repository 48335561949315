import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles, Typography } from '@material-ui/core';
import AgreementContent from './trialAgreementContent';
import UserAgreementContent from './userAgreementContent';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import CustomButton from '../../../atoms/buttons/button';
import SecondaryButton from '../../../atoms/buttons/secondaryBtn';
const useStyles = makeStyles(() => ({
  mainDiv: {
    width: '900px',
    height: '700px',
    borderRadius: '8px',
    maxWidth: '1200px !important'
  },
  heading: {
    'background-image': 'linear-gradient(90deg, #004CFF 0%, #06BCC1 10%)',
    'background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    fontSize: '18px',
    fontWeight: 600
  },
  boxShadow: {
    boxShadow: '8px 0px 8px 0px #00000024',
    zIndex: 1
  },
  actionBox: {
    padding: '8px 20px',
    display: 'flex',
    flexDirection: 'column'
  },
  agreeTypo: {
    fontSize: '14px',
    fontWeight: 600
  },
  btnBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '8px'
  },
  agreeBtn: {
    width: '100px',
    marginRight: '12px',
    '& p': {
      fontSize: '14px'
    }
  },
  cancelBtn: {
    width: '100px',
    background: '#797F8D !important',
    '& p': {
      fontSize: '14px'
    }
  }
}));

const TrialAgreementDialog = ({ dialogProps }) => {
  const { open, handleClose, handleTrialSigned, route } = dialogProps;
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.mainDiv }}
      >
        <DialogTitle id="alert-dialog-title" className={classes.boxShadow}>
          <Typography className={classes.heading}>
            {route ? t('trialAgreement.heading') : t('agreement.heading')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          {route ? <AgreementContent /> : <UserAgreementContent />}
        </DialogContent>
        <DialogActions className={classes.boxShadow}>
          <Box className={classes.actionBox}>
            <Typography className={classes.agreeTypo}>
              By clicking "I Agree" or using the Software, you acknowledge that you have read,
              understood, and agree to be bound by the terms and conditions of this Agreement.
            </Typography>
            <Box className={classes.btnBox}>
              <CustomButton
                btnText="I Agree"
                styleClass={classes.agreeBtn}
                handleClick={() => handleTrialSigned(true)}
              />
              <SecondaryButton
                btnText="Cancel"
                styleClass={classes.cancelBtn}
                handleClick={handleClose}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TrialAgreementDialog;
