import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './footer.css';
import { routesWithoutHeaderAndFooter } from '../utils/utils';

const useStyles = makeStyles({
  footerMainDiv: {
    display: 'flex',
    height: '15px',
    padding: '10px',
    'justify-content': 'center',
    'align-items': 'center',
    gap: '10px',
    '& p': {
      fontFamily: 'Satoshi!important',
      fontWeight: 'normal',
      fontSize: '15px',
      color: '#FFFFFF'
    }
  },
  '@media print': {
    footerMainDiv: {
      visibility: 'hidden'
    }
  }
});

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const today = new Date();
  const current_year = today.getFullYear();
  const copyright = t('footer.copyright').replace('{current_year}', current_year);
  const [disableHeader, setDisableHeader] = useState(false);

  useEffect(() => {
    setDisableHeader(routesWithoutHeaderAndFooter.includes(location?.pathname));
  }, []);

  return (
    !disableHeader && (
      <>
        <footer className="footer">
          <div className={classes.footerMainDiv}>
            <Typography className="copyrightText">{copyright}</Typography>
          </div>
        </footer>
      </>
    )
  );
};
export default Footer;
