import { Typography, makeStyles } from '@material-ui/core';
import { formatedTime } from '../../utils/formatedTime';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
  heading1: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#1D1D1D'
  },
  lastUpdatedText: {
    marginLeft: '7px',
    fontSize: '1rem',
    fontWeight: '400',
    fontStyle: 'italic',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      whiteSpace: 'nowrap'
    }
  },
  alternateCell: {
    backgroundColor: '#f6f7fe'
  },
  markdownTxt: {
    color: '#1D1D1D',
    fontWeight: 500,
    // fontSize: '16px',
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '14px'
    // },
    margin: '0px 0px 25px 15px',
    [theme.breakpoints.down('xs')]: {
      margin: '0px'
    }
  },
  th: {
    border: '1px solid #CFCFCF',
    padding: '5px',
    textAlign: 'center',
    backgroundColor: '#FFFFFF'
  },
  td: {
    border: '1px solid #CFCFCF',
    padding: '5px',
    backgroundColor: '#FFFFFF',
    '& > a': {
      display: 'block',
      textAlign: 'center'
    }
  },
  markdownUL: {
    '& > li > p': {
      margin: '0px'
    }
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%'
    }
  }
}));

const TearSheetContent = ({ tearSheetData }) => {
  const classes = useStyles();
  const renderTable = (props) => {
    const { children } = props;
    return (
      <div style={{ overflow: 'auto', margin: '16px 0px' }}>
        <table className={classes.table}>{children}</table>
      </div>
    );
  };

  const renderLink = ({ href, children, title }) => {
    if (href.startsWith('##')) {
      return (
        <span
          title={title}
          style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} // Look like a link
          onClick={(e) => e.preventDefault()} // Prevent click action
        >
          {children}
        </span>
      );
    }

    return (
      <a href={href} target="_blank" rel="noopener noreferrer" title={title}>
        {children}
      </a>
    );
  };

  let alternate = useRef(null);
  let rowIndex = useRef(0);
  const renderTr = (props) => {
    const { children } = props;
    rowIndex.current = rowIndex.current + 1;
    if (rowIndex.current % 2) alternate.current = true;
    else alternate.current = false;
    return <tr>{children}</tr>;
  };
  const renderTd = (props) => {
    const { children } = props;
    alternate.current = !alternate.current;
    const className = alternate.current ? classes.alternateCell : '';
    return <td className={`${classes.td} ${className}`}>{children}</td>;
  };

  const renderSubHeading = ({ children }) => {
    return (
      <p>
        <strong>{children}</strong>
      </p>
    );
  };
  const renderList = ({ children }) => {
    return <ul className={classes.markdownUL}>{children}</ul>;
  };
  const renderImage = ({ src, alt, title }) => (
    <span>
      <img src={src} alt={alt} className={classes.img} title={title} />
    </span>
  );
  return Object.keys(tearSheetData?.response).map((key, index) => (
    <div key={index} id="tearsheet-section-div">
      {tearSheetData?.response[key] && (
        <>
          <Typography className={classes.heading1}>
            {key}
            {tearSheetData?.metadata?.latest_update_date && key === 'Latest Updates' && (
              <span className={classes.lastUpdatedText}>
                (as of {formatedTime(tearSheetData?.metadata?.latest_update_date)})
              </span>
            )}
          </Typography>
          <Typography className={classes.markdownTxt}>
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                table: renderTable,
                th: ({ children }) => <th className={classes.th}>{children}</th>,
                tr: renderTr,
                td: renderTd,
                a: renderLink,
                h1: renderSubHeading,
                h2: renderSubHeading,
                h3: renderSubHeading,
                h4: renderSubHeading,
                h5: renderSubHeading,
                h6: renderSubHeading,
                ul: renderList,
                ol: renderList,
                img: renderImage
              }}
            >
              {tearSheetData?.response[key]}
            </ReactMarkdown>
          </Typography>
        </>
      )}
    </div>
  ));
};

export default TearSheetContent;
