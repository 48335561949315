import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button, Skeleton, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { getApi } from '../../../../services/token';
import UploadDocExpFile from './upload';
import { Dialog, DialogContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setAllMessagesDE, setSuggestedDeQuestions, setUuID } from '../../action';
import {
  setPortfolioIntialStateFolder,
  setPortfolioDocExplorerloading,
  setPortfolioFolderData,
  setAllMessagesPT
} from '../../../../redux/pages/portfolioTracker/action';
import { getFolderData } from '../services';
import { dispatchHideShowMessage, removeObjRef } from '../../../../helpers';
import { setGlobalErrorMessage } from '../../action';
import { useLocation } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../../../../components/icons/SearchIcon.svg';
import { ReactComponent as RefreshIcon } from '../../../../components/icons/RefreshIcon.svg';
import { TextField } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { getSearchFileData } from '../services';
import FolderTree from './listFileFolder';
import FileHeader from './header';
import { useSelector } from 'react-redux';
//import LoadingPopover from '../../loadingPopover/loadingPopover';
import Tooltip from '@mui/material/Tooltip';
import { setLoadingPortfolioTracker } from '../../../../redux/pages/portfolioTracker/action';

const ingestionUrl = process.env.REACT_APP_INGESTION_API_URL;
const isSingleSelection = process.env.REACT_APP_DOC_EXPLORER_SINGLE_SELECTION;

const isSeparateState = true;
const updateAllMessageAction = isSeparateState ? setAllMessagesPT : setAllMessagesDE;

const useStyles = makeStyles(() => ({
  mainFileGridContainer: {
    // height: '100%',
    height: 'calc(100vh - 180px)',
    paddingLeft: '20px'
  },
  box: {
    padding: '10px 20px',
    background: `transparent linear-gradient(90deg, #8F34EA1A 0%, #2B4BDA1A 100%) 0% 0% no-repeat padding-box`,
    borderRadius: '8px',
    height: '100%'
  },
  docExplorerMainTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '18px !important',
    fontWeight: '600 !important',
    color: '#373737 !important'
  },
  pagination: {
    padding: '0px'
  },
  borderButtonStyle: {
    marginLeft: 'auto',
    position: 'relative',
    height: '32px',
    width: '80px',
    zIndex: 1,
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    textTransform: 'none !important',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1.5px',
      right: '1.5px',
      top: '1.5px',
      bottom: '1.5px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  textBtn: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    fontWeight: '700 !important',
    color: '#171717 !important',
    padding: '0px 2px'
  },
  noFileTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    color: '#171717 !important',
    opacity: '0.7'
  },
  fileName: {
    fontFamily: 'Satoshi !important',
    fontSize: '13px !important',
    fontWeight: '400 !important',
    lineHeight: '20px',
    lineBreak: 'anywhere',
    'text-decoration': 'none'
  },
  fileNameColor: {
    color: '#373737 !important'
  },
  errorMessage: {
    color: 'red!important'
  },
  root: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E8E8E8',
    borderRadius: '4px',
    '& .MuiInput-underline:before': {
      display: 'none'
    },
    '& .MuiInput-underline:after': {
      display: 'none'
    },
    '& .MuiInput-underline:hover:before': {
      display: 'none'
    },
    '& .MuiInputBase-input': {
      caretColor: '#B0B0B0'
    }
  },
  searchIcon: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    color: '#80a6ff',
    padding: '0px 5px 0px 10px'
  },
  deleteIcon: {
    display: 'none',
    cursor: 'pointer',
    padding: '5px 0px 5px 5px'
  },
  hoverable: {
    '&:hover $deleteIcon': {
      display: 'block'
    }
  },
  exceptionIcon: {
    // width: '16px',
    // height: '16px',
    paddingLeft: '5px',
    cursor: 'pointer'
  }
}));

const DocExplorerFiles = ({ checkIFqueryRunning }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [openUploadState, setOpenUploadState] = useState(false);
  const [input, setInput] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  //const [loadingInsights, setLoadingInsights] = useState(false);

  const { currentWatchlistCompany } = useSelector((state) => state.portfolioTrackerReducer);
  const { company: currentCompany } = currentWatchlistCompany || {};

  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const parent_folder_id = params.get('parent_folder_id');
    if (params.get('openBox') === 'true' && !parent_folder_id) {
      handleClick();
      params.delete('openBox');
      params.delete('parent_folder_id');
      const newUrl = params.toString()
        ? `${location.pathname}?${params.toString()}`
        : location.pathname;
      window.history.replaceState(null, '', newUrl);
    }
  }, [location]);
  const allMessagesDE = useSelector((state) => state.portfolioTrackerReducer.allMessagesPT);
  const getInsights = async (updatedDeIds = '') => {
    if (!updatedDeIds) return;
    try {
      //setLoadingInsights(true);
      dispatch(setLoadingPortfolioTracker(true));
      const newAllmessagesDE = removeObjRef(allMessagesDE);
      dispatch(setSuggestedDeQuestions({ questions: [], loading: true }));
      let ingestionData = await getApi(
        `${ingestionUrl}/v1/document/insights?doc_ids=${updatedDeIds}`
      );
      if (isSingleSelection) {
        dispatch(updateAllMessageAction([]));
      } else {
        if (newAllmessagesDE && Object.keys(newAllmessagesDE).length > 0) {
          const lastKey = Object.keys(newAllmessagesDE).pop();

          newAllmessagesDE[lastKey].follow_up_q = ingestionData.data || [];
          dispatch(updateAllMessageAction({ ...newAllmessagesDE }));
        }
      }

      const {
        summary,
        ['key insights']: key_insights,
        suggested_questions: questions
      } = ingestionData?.data?.data || {};
      dispatch(
        setSuggestedDeQuestions({
          summary,
          key_insights,
          questions,
          loading: false
        })
      );
    } catch (error) {
      dispatch(
        setSuggestedDeQuestions({ summary: '', key_insights: '', questions: [], loading: false })
      );
    } finally {
      dispatch(setLoadingPortfolioTracker(false));
      //setLoadingInsights(false);
    }
  };

  const handleCheckboxChange = async (updatedSelectedDocs) => {
    try {
      if (checkIFqueryRunning()) {
        return;
      }
      dispatch(setUuID(uuidv4()));

      const updatedDeIds = Array.from(new Set(updatedSelectedDocs.flatMap((d) => d.id)));
      const isOneDocSelected = updatedDeIds && updatedDeIds.length === 1;

      if (isOneDocSelected) {
        await getInsights(updatedDeIds.join(','));
      }
    } catch (error) {
      console.error('Error in handleCheckboxChange:');
    }
  };

  const handleClick = () => {
    setOpenUploadState(true);
  };

  const handleClosePopup = () => {
    setOpenUploadState(false);
  };

  const handleUploadPopup = () => {
    setOpenUploadState(false);
    handleRefresh();
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);

    // Clear the previous debounce timeout and set a new one
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Set a new timeout to update the input after 3 seconds
    setDebounceTimeout(
      setTimeout(() => {
        setInput(event.target.value);
      }, 1000)
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
      setInput(inputValue); // Call setInput with the current input value
    }
  };

  const handleRefresh = async () => {
    const companyCin = currentCompany?.cin || ''; //
    if (companyCin) {
      const companyFilter = companyCin ? `&cin_list=${companyCin}` : '';
      dispatch(setPortfolioIntialStateFolder());
      setLoading(true);
      dispatch(setPortfolioDocExplorerloading(true));
      try {
        const url = `${process.env.REACT_APP_INGESTION_API_URL}/v2/document?input_source=company_portfolio${companyFilter}&node_type=file`;
        const folderData = await getApi(url);
        const initialData = folderData.data.data.items;
        dispatch(setPortfolioFolderData(initialData || []));
      } catch (error) {
        console.error('Error fetching initial folder data:', error);
      } finally {
        setLoading(false);
        dispatch(setPortfolioDocExplorerloading(false));
      }
    }
  };

  useEffect(() => {
    const getSearchFileDataFunction = async (input) => {
      setLoading(true);
      dispatch(setPortfolioDocExplorerloading(true));
      try {
        dispatch(setPortfolioDocExplorerloading(true));
        const folderData = await getSearchFileData(input);
        dispatch(setPortfolioFolderData(folderData || []));
      } catch (error) {
        console.error(`Error fetching data for ${input}:`, error);
      } finally {
        setLoading(false);
        dispatch(setPortfolioDocExplorerloading(false));
      }
    };
    if (input !== null) {
      getSearchFileDataFunction(input);
    }
  }, [input]);

  const fetchInitialData = async () => {
    const companyCin = currentCompany?.cin || ''; //
    if (companyCin) {
      dispatch(setPortfolioIntialStateFolder());
      dispatch(setPortfolioDocExplorerloading(true));
      try {
        const initialData = await getFolderData(null, companyCin);
        dispatch(setPortfolioFolderData(initialData || []));
      } catch (error) {
        dispatch(setPortfolioFolderData([]));
        dispatchHideShowMessage(
          dispatch,
          error.message || 'Something went wrong , please try again later',
          setGlobalErrorMessage,
          3000
        );
      } finally {
        dispatch(setPortfolioDocExplorerloading(false));
      }
    }
  };

  console.log(typeof fetchInitialData);
  // useEffect(() => {
  //   // if (folderData.length === 0) {
  //   //   fetchInitialData();
  //   // }
  //   fetchInitialData();
  // }, [dispatch]);

  return (
    <>
      <Dialog open={openUploadState} fullWidth={true} maxWidth="lg">
        <DialogContent style={{ padding: '24px' }}>
          <UploadDocExpFile
            handleClosePopup={handleClosePopup}
            handleUploadPopup={handleUploadPopup}
          />
        </DialogContent>
      </Dialog>

      <div className={classes.mainFileGridContainer}>
        <Box className={classes.box}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '6px 0px 20px 0px'
            }}
          >
            <div>
              <Typography className={classes.docExplorerMainTxt}>
                {t('docExplorer.documents')}
              </Typography>
            </div>
            {/* <div style={{ opacity: currentWatchlistCompany === null ? '0.5' : '1' }}>
              <Button className={classes.borderButtonStyle} onClick={() => handleClick()}>
                <Typography className={classes.textBtn}>Upload</Typography>
              </Button>
            </div> */}
            <Tooltip
              title={
                currentWatchlistCompany === null
                  ? 'Select a company from watchlist to upload files'
                  : ''
              }
              arrow
            >
              <span>
                <Button
                  className={classes.borderButtonStyle}
                  onClick={() => handleClick()}
                  style={{ opacity: currentWatchlistCompany === null ? '0.5' : '1' }}
                  disabled={currentWatchlistCompany === null}
                >
                  <Typography className={classes.textBtn}>Upload</Typography>
                </Button>
              </span>
            </Tooltip>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column'
            }}
          >
            <div style={{ height: 'calc(100vh - 270px)', overflowX: 'hidden', overflowY: 'auto' }}>
              <form noValidate style={{ padding: '0px 10px 10px 0px' }}>
                <div style={{ display: 'flex' }}>
                  <TextField
                    className={classes.root}
                    placeholder={'Search Documents'}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                      startAdornment: <SearchIcon className={classes.searchIcon} />
                    }}
                  />
                  <div style={{ paddingLeft: '5px' }} />
                  <RefreshIcon
                    style={{
                      cursor: 'pointer',
                      border: '1px solid #797F8D',
                      borderRadius: '4px',
                      padding: '2.25px 5.5px',
                      height: '25px',
                      background: '#FFFFFF'
                    }}
                    onClick={() => {
                      handleRefresh();
                    }}
                  />
                </div>
              </form>
              {loading === true ? (
                <>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <div key={index}>
                      <Card
                        sx={{
                          maxWidth: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          backgroundColor: '#FAFAFA',
                          borderRadius: '8px',
                          border: '1px solid #E8E8E8'
                        }}
                        elevation={0}
                      >
                        <Skeleton animation="wave" height={10} width="20%" style={{ margin: 20 }} />
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="70%"
                          style={{ marginBottom: 10, marginLeft: 20, marginRight: 6 }}
                        />
                      </Card>
                      <br />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <FileHeader />
                  <div
                    style={{
                      height: 'calc(100vh - 350px)',
                      overflowY: 'auto',
                      width: '100%'
                    }}
                  >
                    <FolderTree
                      handleCheckboxChange={handleCheckboxChange}
                      checkIFqueryRunning={checkIFqueryRunning}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default DocExplorerFiles;
